import React, { useEffect } from "react"
import { isNil } from "ramda"
import { Flex, List } from "reakit"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import Heading from "common/Heading"
import Text from "common/Text"
import Icon from "common/Icon"
import Divider from "common/Divider"
import Button from "common/Button"
import LoadingIndicator from "common/LoadingIndicator"
import {
  BlockListItem,
  CustomListItem,
} from "pages/PermissionRequestDetails/styles"
import {
  usePermission,
  usePermissionFind,
  usePermissionLoading,
} from "store/hooks/permission"

const PermissionRequestDetails = props => {
  const { id } = props.match.params
  const loading = usePermissionLoading()
  const permission = usePermission()
  const findPermission = usePermissionFind()

  useEffect(() => {
    if (id) {
      findPermission(id)
    }
  }, [findPermission, id])

  return (
    <LayoutSidebarOverlay
      title="Pedido"
      afterClose={() => {
        props.history.push("/pedidos-de-credenciais")
      }}
    >
      {({ closeSidebar }) => {
        return (
          <LoadingIndicator loading={loading}>
            {isNil(permission) ? null : (
              <Flex column maxWidth="33rem" flexGrow={1}>
                <Heading
                  align="center"
                  margin="medium"
                  palette="primary"
                  variant="display3"
                >
                  {permission.person.name}
                </Heading>
                <Text
                  align="center"
                  margin="extralarge"
                  palette="grayscale"
                  variant="body2"
                >
                  {permission.id}
                </Text>
                <Divider palette="primary" />
                <BlockListItem>
                  <Text margin="big" palette="primary" variant="body" uppercase>
                    Trabalho especial
                  </Text>
                  <List>
                    {permission.permissions.map((permission, key) => {
                      return (
                        <CustomListItem
                          key={key}
                          margin="medium"
                          variant="body2"
                          use={[Flex, "li"]}
                          customAlignment
                        >
                          <span>{permission.name}</span>
                        </CustomListItem>
                      )
                    })}
                  </List>
                </BlockListItem>
                <Divider palette="primary" />
                <BlockListItem>
                  <Text margin="big" palette="primary" variant="body" uppercase>
                    Credenciais Solicitadas
                  </Text>
                  <List>
                    {permission.credentials.map(credential => {
                      const completed = false
                      const iconPalette = completed ? "primary" : "white"
                      return (
                        <CustomListItem
                          key="credential.id"
                          margin="medium"
                          variant="body2"
                          use={[Flex, "li"]}
                          completed={completed}
                        >
                          <Icon palette={iconPalette}>check</Icon>
                          <span>{credential.name}</span>
                        </CustomListItem>
                      )
                    })}
                  </List>
                </BlockListItem>
              </Flex>
            )}

            <LayoutSidebarOverlay.Footer>
              <Button type="button" onClick={closeSidebar}>
                Ok
              </Button>
            </LayoutSidebarOverlay.Footer>
          </LoadingIndicator>
        )
      }}
    </LayoutSidebarOverlay>
  )
}

export default PermissionRequestDetails
