import React, { useEffect } from "react"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import { ErrorMessage, Formik } from "formik"
import { Flex, Step } from "reakit"
import { StepItem, StepsWrapper } from "common/PersonForm/index.styled"
import LayerFeedback from "common/LayerFeedback"
import Button from "common/Button"
import AdvanceFormButton from "common/AdvanceFormButton"
import InputPassword from "common/InputPassword"
import { ValidationSchema } from "common/ChangePassword/ValidationSchema"
import { ErrorContainer, ErrorLabel } from "common/Error"
import {
  useChangePassword,
  useChangePasswordReset,
  usePasswordUpdated,
} from "store/hooks/authentication"

const ChangePassword = props => {
  const updateSuccess = usePasswordUpdated()
  const reset = useChangePasswordReset()
  const changePassword = useChangePassword()

  useEffect(() => {
    reset()
  }, [reset])

  const personValidationStructure = [
    {
      step: 0,
      requiredFields: ["currentPassword", "newPassword"],
    },
  ]

  const canSubmitForm = step => {
    return step.ids.length - 1 === step.current + 1
  }

  const isLastStep = step => {
    return step.ids.length === step.current + 1
  }

  const getSubmitButtonLabel = step => {
    if (isLastStep(step)) {
      return "Fechar"
    }

    if (canSubmitForm(step)) {
      return "Concluir"
    }

    return "Avançar"
  }

  const handleSubmit = data => {
    changePassword(data)
  }

  return (
    <LayoutSidebarOverlay
      title="Alterar Senha"
      afterClose={() => {
        props.handleClose()
      }}
    >
      {() => {
        return (
          <Formik
            initialValues={{ currentPassword: "", newPassword: "" }}
            onSubmit={handleSubmit}
            validationSchema={ValidationSchema}
            enableReinitialize={true}
          >
            {({ values, isValid, handleChange }) => (
              <>
                <Step.Container initialState={{ current: 0 }}>
                  {step => {
                    return (
                      <>
                        <StepsWrapper>
                          <StepItem step="0" order={0} {...step}>
                            <Flex column maxWidth="33rem" flexGrow={1}>
                              <InputPassword
                                value={values.currentPassword || ""}
                                name="currentPassword"
                                id="currentPassword"
                                label="SENHA ATUAL"
                                onChange={handleChange}
                              />
                              <InputPassword
                                value={values.newPassword || ""}
                                name="newPassword"
                                id="newPassword"
                                label="NOVA SENHA"
                                tooltip="A senha deve conter mais de 6 caracteres com letras maiúsculas, minúsculas e números"
                                onChange={handleChange}
                              />
                              <ErrorContainer>
                                <ErrorMessage
                                  name="currentPassword"
                                  render={msg => <ErrorLabel>{msg}</ErrorLabel>}
                                />
                                <ErrorMessage
                                  name="newPassword"
                                  render={msg => <ErrorLabel>{msg}</ErrorLabel>}
                                />
                              </ErrorContainer>
                            </Flex>
                          </StepItem>
                          <StepItem step="1" order={1} {...step}>
                            <LayerFeedback
                              icon="check"
                              palette="secondary"
                              title="Senha atualizada"
                              message="Sua senha foi atualizada com sucesso"
                            />
                          </StepItem>
                        </StepsWrapper>
                        <LayoutSidebarOverlay.Footer>
                          {!isLastStep(step) || (step > 0 && !isValid) ? (
                            <Button
                              use={Step.Previous}
                              {...step}
                              type="button"
                              palette="white"
                            >
                              Voltar
                            </Button>
                          ) : (
                            ""
                          )}
                          <AdvanceFormButton
                            closeSidebar={props.handleClose}
                            step={step}
                            structure={personValidationStructure}
                            updateSuccess={updateSuccess}
                          >
                            {getSubmitButtonLabel(step)}
                          </AdvanceFormButton>
                        </LayoutSidebarOverlay.Footer>
                      </>
                    )
                  }}
                </Step.Container>
              </>
            )}
          </Formik>
        )
      }}
    </LayoutSidebarOverlay>
  )
}

export default ChangePassword
