import { styled } from "reakit"
import Paper from "common/Paper"

const PaperStyled = styled(Paper)`
  align-items: center;
  display: flex;
  justify-content: center;
`

export default PaperStyled
