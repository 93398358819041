import { styled } from "reakit"

const myCustomColour = ['#1f25ab', '#1e8dcc', '#dc0909', '#fde607', '#5109dc']
export const ChipStyled = styled.div`
  background: ${({colorKey}) => myCustomColour[colorKey]};
  color: white;
  padding: 2%;
  font-size: small;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-right: 10px;
`

export const MapEventLabelStyled = styled.div`
  font-size: medium;
  font-weight: 400;
  color: #005369;
  margin-left: 10px;
`

export const MapEventHeaderStyled = styled.div`
  font-size: large;
  font-weight: bolder;
  color: #005369;
  background: rgba(0, 0, 0, 0.08);
  padding: 2%;
`

export const MapEventLine = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: center;
  transition: all 0.2s ease;
  background: ${({ highlight }) => (highlight ? "#EAEAEA" : "unset")}
  :hover {
    background: rgba(0, 0, 0, 0.03);
    cursor: pointer;
  }
`
