import { useDispatch, useSelector } from "react-redux"
import { useCallback } from "react"
import {
  findPermissionRequestFindAllAction,
  permissionRequestReset,
  findPermissionRequestRequest,
  permissionRequestUpdateStatus,
} from "store/ducks/permission-request"

export const usePermissionsRequestFind = () => {
  const dispatch = useDispatch()
  return useCallback(
    (page, size, force) => {
      dispatch(permissionRequestReset())
      dispatch(findPermissionRequestFindAllAction(page, size))
    },
    [dispatch]
  )
}

export const usePermissionRequestFind = () => {
  const dispatch = useDispatch()
  return useCallback(
    id => {
      dispatch(permissionRequestReset())
      dispatch(findPermissionRequestRequest(id))
    },
    [dispatch]
  )
}

export const usePermissionRequestUpdateStatus = () => {
  const dispatch = useDispatch()
  return useCallback(
    permission => {
      dispatch(permissionRequestReset())
      dispatch(permissionRequestUpdateStatus(permission))
    },
    [dispatch]
  )
}

export const usePermissionsRequest = () => {
  return useSelector(state => state.permissionRequest.permissionsRequest)
}

export const usePermissionRequest = () => {
  return useSelector(state => state.permissionRequest.permissionRequest)
}

export const usePermissionRequestLoading = () => {
  return useSelector(state => state.permissionRequest.loading)
}

export const useDeletePermissionRequestSuccess = () => {
  return useSelector(state => state.permissionRequest.deleteSuccess)
}

export const useUpdatePermissionRequestSuccess = () => {
  return useSelector(state => state.permissionRequest.updateSuccess)
}

export const useCreatePermissionRequestSuccess = () => {
  return useSelector(state => state.permissionRequest.createSuccess)
}

export const usePermissionRequestLast = () => {
  return useSelector(state => state.permissionRequest.last)
}

export const useTotalPages = () => {
  return useSelector(state => state.permissionRequest.totalPages)
}
