import React from "react"
import {format} from "date-fns"
import Table, {ActionData, ActionHeader, ShowMoreItens} from "common/Table"
import LoadingIndicator from "common/LoadingIndicator"
import {useProcedureLast, useProceduresLoading} from "store/hooks/procedure"
import Filter, {GetActivatedFilter} from "../Filter"
import {GetActivatedSorted} from "../Sort"
import {useFindProceduresRequestHandler} from "../../store/hooks/procedure"
import SortLabel from "../SortLabel"
import {isEmpty} from "ramda";
import Text from "../Text";
import PaperStyled from "../PaperStyled";
import {StorageUtils} from "../../utils/StorageUtils";

const ProcedureTableList = props => {
  const loading = useProceduresLoading()
  const last = useProcedureLast()
  const procedureRequest = useFindProceduresRequestHandler()
  const formatCredentials = credential =>
    credential.map(credential => credential.name).join(", ")

  return (
    <>
      <Table>
        <thead>
        <tr>
          <th
            style={{
              backgroundColor: GetActivatedSorted("name", "procedure")
                ? "rgb(233,233,233)"
                : "white",
            }}
          >
            <SortLabel
              props={props}
              request={procedureRequest}
              name="name"
              label="Nome da Autorização"
              screen="procedure"
            />
            <Filter
              applied={GetActivatedFilter("name", "procedure")}
              screen="procedure"
              name="name"
              filterType="inputFilter"
              setCurrentPage={props.setCurrentPage}
              defaultValue={StorageUtils.getDefaultStorageValue("procedure", "name")}
              filterFn={procedureRequest}
            />
          </th>
          <th
            style={{
              backgroundColor: GetActivatedSorted("credentials", "procedure")
                ? "rgb(233,233,233)"
                : "white",
            }}
          >
            <SortLabel
              props={props}
              request={procedureRequest}
              name="credentials"
              label="Credenciais"
              screen="procedure"
            />
          </th>

          <th
            style={{
              backgroundColor: GetActivatedSorted("updatedAt", "procedure")
                ? "rgb(233,233,233)"
                : "white",
            }}
          >
            <SortLabel
              props={props}
              request={procedureRequest}
              name="updatedAt"
              label="Alterado em"
              screen="procedure"
            />
            <Filter
              applied={GetActivatedFilter("updatedAt", "procedure")}
              screen="procedure"
              name="updatedAt"
              filterType="dateFilter"
              setCurrentPage={props.setCurrentPage}
              defaultValue={StorageUtils.getDefaultStorageValue("procedure", "updatedAt")}
              filterFn={procedureRequest}
            />
          </th>
          <ActionHeader>Ações</ActionHeader>
        </tr>
        </thead>
        {!isEmpty(props.items) ? (
          <tbody>
          {props.items.map((item, key) => {
            return (
              <tr key={key} data-testid="table-row">
                <td>{item.name}</td>
                <td>
                  {item.credentials ? formatCredentials(item.credentials) : ""}
                </td>
                <td>{format(item.updatedAt, "DD/MM/YYYY hh:mm")}</td>
                <ActionData>{props.renderActions(item)}</ActionData>
              </tr>
            )
          })}
          </tbody>
        ) : (
          <PaperStyled>
            <Text>Nenhuma Autorização cadastrada.</Text>
          </PaperStyled>
        )}
      </Table>
      {loading && <LoadingIndicator loading={true}/>}
      {!loading && !last && <ShowMoreItens handleClick={props.loadMore}/>}
    </>
  )
}

export default ProcedureTableList

const Styles = {
  scroll: {
    height: 600,
    overflow: "scroll",
  },
}
