import React, { useEffect } from "react"
import { isNil } from "ramda"
import { Box, Flex, styled } from "reakit"
import { theme } from "styled-tools"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import Text from "common/Text"
import Button from "common/Button"
import LoadingIndicator from "common/LoadingIndicator"
import {
  useFindUserRequestHandler,
  useUsersLoading,
  useUser,
} from "../../../store/hooks/user"
import { withRouter } from "react-router-dom"

const BlockListItem = styled(Box)`
  padding-bottom: ${theme("spacing.large")};
  padding-top: ${theme("spacing.large")};
`

const UserDetails = props => {
  const loading = useUsersLoading()
  const { id } = props.match.params
  const findUser = useFindUserRequestHandler()
  const user = useUser()

  useEffect(() => {
    if (id) {
      findUser(id)
    }
  }, [findUser, id])

  return (
    <LayoutSidebarOverlay
      title="Usuário"
      subtitle={user ? user.id : ""}
      afterClose={() => {
        props.history.push("/usuarios")
      }}
    >
      {({ closeSidebar }) => {
        return (
          <LoadingIndicator loading={loading}>
            {isNil(user) ? null : (
              <Flex column maxWidth="33rem" flexGrow={1}>
                <BlockListItem>
                  <Text margin="extralarge" palette="primary" variant="h6">
                    NOME: {user.name}
                  </Text>
                </BlockListItem>
              </Flex>
            )}
            <LayoutSidebarOverlay.Footer>
              <Button type="button" onClick={closeSidebar}>
                Ok
              </Button>
            </LayoutSidebarOverlay.Footer>
          </LoadingIndicator>
        )
      }}
    </LayoutSidebarOverlay>
  )
}

export default withRouter(UserDetails)
