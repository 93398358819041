import React, {useEffect} from 'react'
import {FormGroup, ControlLabel, FormControl} from 'react-bootstrap'
import Icon from "../Icon";
import {InputStyled} from "./index";


function InputWithLabel({
  inputType,
  defaultValue,
  placeholder,
  label,
  onChange,
  required,
  min,
  max,
  readOnly,
  maxlength,
  minlength,
  isInvalid,
  onChangeText,
  className,
  onChangeWithDelay,
  inputRef,
  disabled,
  value,
  inputId,
  labelIconProps,
  onlyNumbers,
  onKeyDown
}) {

  let timeout = null;

  const blockInvalidChars = event => ['e', 'E', '.'].includes(event.key)
      && event.preventDefault()
  const handleOnKeyDown = (event) => {
    onKeyDown && onKeyDown(event)
    onlyNumbers && blockInvalidChars(event)
  }

  useEffect(() => {
    onChangeText && onChangeText(defaultValue)
  }, [defaultValue])

  return (
      <FormGroup controlId={inputId}>
        <ControlLabel>
          {label}
          {labelIconProps && <Icon style={labelIconProps.style}
                                   icon={labelIconProps.icon}/>}
        </ControlLabel>
        <InputStyled
            type={inputType}
            className={`${isInvalid ? 'is-invalid' : null} ${className}`}
            placeholder={placeholder}
            onChange={e => {
              const value = e.target.value
              onChange && onChange(e)
              onChangeText && onChangeText(value)
              if (onChangeWithDelay) {

                if (timeout) {
                  clearTimeout(timeout);
                  console.log('limpando timeout', value)
                }

                timeout = setTimeout(() => {
                  console.log('executando timeout', value)
                  onChangeWithDelay(value);
                  timeout = null;
                }, 300);
              }
            }}
            onKeyDown={handleOnKeyDown}
            ref={inputRef}
            defaultValue={defaultValue}
            required={required}
            min={min}
            maxLength={maxlength}
            minLength={minlength}
            max={max}
            readOnly={readOnly}
            disabled={disabled}
            value={value}
        />
      </FormGroup>
  )
}

export {InputWithLabel}
