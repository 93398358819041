import React, { useCallback } from "react"
import Table from "common/Table"
import {
  useMarkDetail,
  useMonitoring,
  useMonitoringUnpreparedData,
  useSetDetail,
  useSetMapCenter,
} from "../../store/hooks/map"
import {
  ChipStyled,
  MapEventHeaderStyled,
  MapEventLabelStyled,
  MapEventLine,
} from "./index.styled"

const MapTableList = props => {
  const mapUnpreparedData = useMonitoringUnpreparedData()
  const mapData = useMonitoring()
  const setMapCenter = useSetMapCenter()
  const setDetail = useSetDetail()
  const markDetail = useMarkDetail()

  const handleSetCenter = item => {
    const foundResult = mapData.find(md => item.policy.id === md.id)
    localStorage.setItem(
      "mapCenter",
      JSON.stringify({
        lat: foundResult.lat,
        lng: foundResult.long,
      })
    )
    setMapCenter({
      lat: foundResult.lat,
      lng: foundResult.long,
    })
  }

  const setDet = useCallback(
    id => {
      if (markDetail === id) {
        setDetail(null)
      } else {
        setDetail(id)
      }
    },
    [markDetail]
  )

  return (
    <>
      <MapEventHeaderStyled>Eventos Online</MapEventHeaderStyled>
      {mapUnpreparedData &&
        mapUnpreparedData.map((item, key) => (
          <MapEventLine
            key={key}
            highlight={markDetail && markDetail === item.policy.id}
            onClick={() => {
              setDet(item.policy.id)
              handleSetCenter(item)
            }}
          >
            <MapEventLabelStyled>{item.policy.name}</MapEventLabelStyled>
            <ChipStyled colorKey={key} >
              {item.totalApproved + item.totalWarning + item.totalProblems}
            </ChipStyled>
          </MapEventLine>
        ))}
    </>
  )
}

export default MapTableList
