import { all } from "redux-saga/effects"
import authEffects from "./authentication"
import policyEffects from "./policy"
import areaEffects from "./area"
import mapEffects from "./map"
import personEffects from "./person"
import procedureEffects from "./procedure"
import userEffects from "./user"
import beaconEffects from "./beacon"
import credentialEffects from "./credential"
import responsibleEffects from "./responsible"
import reportEffects from "./report"
import permissionEffects from "./permission"
import monitoringEffects from "./monitoring"
import alertsEffects from "./alerts"
import organizationEffects from "./organizations"
import checkpointEffects from "./checkpoint"
import headcountEffects from "./headcount"
import permissionRequestEffects from "./permission-request"

export default function* rootSaga() {
  return yield all([
    ...headcountEffects,
    ...authEffects,
    ...personEffects,
    ...checkpointEffects,
    ...policyEffects,
    ...areaEffects,
    ...mapEffects,
    ...procedureEffects,
    ...userEffects,
    ...beaconEffects,
    ...credentialEffects,
    ...responsibleEffects,
    ...reportEffects,
    ...permissionEffects,
    ...monitoringEffects,
    ...alertsEffects,
    ...organizationEffects,
    ...permissionRequestEffects,
  ])
}
