import React, { useEffect } from "react"
import { ContainerStyled } from "../index.styled"
import { ErrorMessage, useFormikContext } from "formik"
import { ErrorContainer, ErrorLabel } from "../../../../common/Error"
import { useFindProfiles, useProfiles } from "../../../../store/hooks/user"
import InputPassword from "../../../../common/InputPassword"
import Text from "../../../../common/Text"
import { BlockListItem } from "../../../PermissionRequestDetails/styles"

const StepUserData = ({ values }) => {
  const { handleChange } = useFormikContext()
  const findProfiles = useFindProfiles()
  const profiles = useProfiles()

  useEffect(() => {
    findProfiles()
  }, [])

  return (
    <ContainerStyled>
      <BlockListItem>
        <Text margin="small" palette="primary" variant="h6">
          NOME: {values.name}
        </Text>
      </BlockListItem>
      <BlockListItem>
        <Text margin="small" palette="primary" variant="h6">
          E-MAIL: {values.email}
        </Text>
      </BlockListItem>
      <InputPassword
        tooltip="A senha deve conter mais de 6 caracteres com letras maiúsculas, minúsculas e números."
        value={values.password}
        name="password"
        id="password"
        label="SENHA"
        onChange={handleChange}
      />
      <InputPassword
        value={values.confirmPassword}
        name="confirmPassword"
        id="confirmPassword"
        label="CONFIRMAR SENHA"
        onChange={handleChange}
      />
      <ErrorContainer>
        <ErrorMessage
          name="name"
          render={msg => <ErrorLabel>{msg}</ErrorLabel>}
        />
        <ErrorMessage
          name="email"
          render={msg => <ErrorLabel>{msg}</ErrorLabel>}
        />
        <ErrorMessage
          name="password"
          render={msg => <ErrorLabel>{msg}</ErrorLabel>}
        />
        <ErrorMessage
          name="confirmPassword"
          render={msg => <ErrorLabel>{msg}</ErrorLabel>}
        />
      </ErrorContainer>
    </ContainerStyled>
  )
}

export default StepUserData
