import {CREDENTIAL_UPDATE_SUCCESS} from "./credential";

export const LOAD_EXPIRED_REQUEST = "LOAD_EXPIRED_REQUEST"
export const LOAD_TOEXPIRE_REQUEST = "LOAD_TOEXPIRE_REQUEST"
export const LOAD_REQUESTED_REQUEST = "LOAD_REQUESTED_REQUEST"
export const LOAD_EXPIRED_REQUEST_DELETED_FALSE = "LOAD_EXPIRED_REQUEST_DELETED_FALSE"
export const LOAD_EXPIRED_SUCCESS = "LOAD_EXPIRED_SUCCESS"
export const LOAD_EXPIRED_SUCCESS_DELETED_FALSE = "LOAD_EXPIRED_SUCCESS_DELETED_FALSE"
export const LOAD_TOEXPIRE_SUCCESS = "LOAD_TOEXPIRE_SUCCESS"
export const LOAD_REQUESTED_SUCCESS = "LOAD_REQUESTED_SUCCESS"
export const REPORT_REQUEST_ERROR = "REPORT_REQUEST_ERROR"
export const LOAD_PERSON_CREDENTIAL_REQUEST = "LOAD_PERSON_CREDENTIAL_REQUEST"
export const LOAD_PERSON_CREDENTIAL_REQUEST_SUCCESS = "LOAD_PERSON_CREDENTIAL_REQUEST_SUCCESS"
export const LOAD_PERSON_CREDENTIAL_RENEW_REQUEST = "LOAD_PERSON_CREDENTIAL_RENEW_REQUEST"
export const LOAD_PERSON_CREDENTIAL_RENEW_SUCCESS = "LOAD_PERSON_CREDENTIAL_RENEW_SUCCESS"
export const RENEW_CREDENTIAL_ERROR = "RENEW_CREDENTIAL_ERROR"
export const CLEAR_RENEW_STATUS = "CLEAR"

export const loadExpiredRequest = (page, size, force) => ({
  type: LOAD_EXPIRED_REQUEST,
  payload: {
    page,
    size,
    force
  },
})
export const loadExpiredRequestNotDeleted = (page, size, force) => ({
  type: LOAD_EXPIRED_REQUEST_DELETED_FALSE,
  payload: {
    page,
    size,
    force
  },
})
export const loadRenewExpiredRequest = (id) => ({
  type: LOAD_PERSON_CREDENTIAL_RENEW_REQUEST,
  payload: {
    id
  },
})
export const personCredentialRenewSuccessAction = () => ({
  type: LOAD_PERSON_CREDENTIAL_RENEW_SUCCESS,
})

export const loadToExpireRequest = (size, page) => ({
  type: LOAD_TOEXPIRE_REQUEST,
  payload: {
    size,
    page,
  },
})

export const loadRequestedRequest = (size, page) => ({
  type: LOAD_REQUESTED_REQUEST,
  payload: {
    size,
    page,
  },
})

export const loadPersonCredentialSuccess = personCredentials => ({
  type: LOAD_PERSON_CREDENTIAL_REQUEST_SUCCESS,
  payload: {
    personCredentials
  },
})

export const loadPersonCredentialsRequest = (id) => ({
  type: LOAD_PERSON_CREDENTIAL_REQUEST,
  payload: {
    id,
  },
})

export const loadExpiredSuccess = (result, last, totalPages) => ({
  type: LOAD_EXPIRED_SUCCESS,
  payload: {
    result,
    totalPages,
    last,
  },
})
export const loadExpiredSuccessDeletedFalse = (result, last, totalPages) => ({
  type: LOAD_EXPIRED_SUCCESS_DELETED_FALSE,
  payload: {
    result,
    totalPages,
    last,
  },
})
export const clear = () => ({
  type: CLEAR_RENEW_STATUS,
})
export const loadToExpireSuccess = (result, last) => ({
  type: LOAD_TOEXPIRE_SUCCESS,
  payload: {
    result,
    last,
  },
})

export const loadRequestedSuccess = (result, last) => ({
  type: LOAD_REQUESTED_SUCCESS,
  payload: {
    result,
    last,
  },
})

export const renewCredentialError = error => ({
  type: RENEW_CREDENTIAL_ERROR,
  payload: {
    error,
  },
})

export const reportRequestError = error => ({
  type: REPORT_REQUEST_ERROR,
  payload: {
    error,
  },
})

const INITIAL_STATE = {
  error: null,
  loading: false,
  last: true,
  expired: [],
  toExpire: [],
  requested: [],
  createSuccess: false,
  renewSuccess: false,
}

const reportReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_REQUESTED_REQUEST:
    case LOAD_TOEXPIRE_REQUEST:
    case LOAD_EXPIRED_REQUEST:
    case LOAD_EXPIRED_REQUEST_DELETED_FALSE:
    case LOAD_PERSON_CREDENTIAL_REQUEST: {
      return {
        ...state,
        loading: true,
        last: true,
      }
    }
    case CLEAR_RENEW_STATUS:
      return {
        ...state,
        renewSuccess: false,
        error: null,
      }
    case LOAD_REQUESTED_SUCCESS: {
      const {result, last} = action.payload
      return {
        ...state,
        requested: result,
        loading: false,
        last,
      }
    }
    case LOAD_PERSON_CREDENTIAL_RENEW_REQUEST:
      return {
        ...state,
        loading: true,
        createSuccess: false,
      }
    case LOAD_PERSON_CREDENTIAL_REQUEST_SUCCESS: {
      const {personCredentials} = action.payload
      return {
        ...state,
        personCredentials,
        loading: false,
      }
    }
    case LOAD_TOEXPIRE_SUCCESS: {
      const {result, last} = action.payload
      return {
        ...state,
        toExpire: result,
        loading: false,
        last,
      }
    }
    case LOAD_EXPIRED_SUCCESS: {
      const {result, last, totalPages} = action.payload
      return {
        ...state,
        expired: result,
        loading: false,
        last,
        totalPages
      }
    }
    case LOAD_EXPIRED_SUCCESS_DELETED_FALSE: {
      const {result, last, totalPages} = action.payload
      return {
        ...state,
        expired: result,
        loading: false,
        last,
        totalPages
      }
    }
    case LOAD_PERSON_CREDENTIAL_RENEW_SUCCESS:
      return {
        ...state,
        loading: false,
        renewSuccess: true,
      }
    case CREDENTIAL_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        updateSuccess: true,
      }
    case REPORT_REQUEST_ERROR: {
      const {error} = action.payload
      return {
        ...state,
        error,
        loading: false,
      }
    }
    case RENEW_CREDENTIAL_ERROR:
      const { error } = action.payload
      return {
        ...state,
        error,
        loading: false,
      }
    default:
      return state
  }
}

export default reportReducer
