import React, { Fragment } from "react"
import { Flex, Block, Backdrop, Portal, Sidebar, styled } from "reakit"
import { theme, palette } from "styled-tools"

const SidebarStyled = styled(Sidebar)`
  background-color: ${palette("white")};
  flex-direction: column;
  min-width: 40rem;
  z-index: 1000;
  padding-bottom: ${theme("spacing.large")};
  padding-top: ${theme("spacing.large")};
  padding-left: ${theme("spacing.large")};
  padding-right: ${theme("spacing.large")};
  z-index: 1000;
  max-width: ${props => props.maxWidthParam};
`

const SidebarLayer = props => {
  return (
    <Fragment>
      <Sidebar.Container>
        {sidebar => {
          return (
            <Block>
              <Backdrop
                fade
                use={[Portal, Sidebar.Hide]}
                {...sidebar}
                visible={props.visible}
                onClick={props.handleBackdropClick}
              />
              <SidebarStyled
                use={[Portal, Flex]}
                slide
                align={props.sidebarPosition}
                {...sidebar}
                visible={props.visible}
                maxWidthParam={props.maxWidthParam}
              >
                {props.children}
              </SidebarStyled>
            </Block>
          )
        }}
      </Sidebar.Container>
    </Fragment>
  )
}

export default SidebarLayer
