import React from "react"
import { styled } from "reakit"
import { ifProp, palette } from "styled-tools"

const IconStyled = styled.i`
  color: ${({ color }) => (color ? color : palette(props => props.palette))};
  font-size: ${ifProp(
    "size",
    props => props.theme.spacing[props.size],
    props => props.theme.spacing.big
  )} !important;
`

const Icon = ({ children, ...props }) => {
  return (
    <IconStyled className="material-icons" {...props}>
      {children}
    </IconStyled>
  )
}

export default Icon
