import React from "react"
import { Provider } from "react-redux"
import persistedStore from "../../store"
import { useAuthRehydrated } from "store/hooks/authentication"

const PreLoader = ({ children }) => {
  return (
    <Provider store={persistedStore.store}>
      <CheckRehydrated>{children}</CheckRehydrated>
    </Provider>
  )
}

const CheckRehydrated = ({ children }) => {
  const rehydrated = useAuthRehydrated()
  if (!rehydrated) {
    return null
  }
  return <>{children}</>
}

export default PreLoader
