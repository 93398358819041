import { useDispatch, useSelector } from "react-redux"
import { useCallback } from "react"
import {
  areaCreateRequestAction,
  areaDeleteRequestAction,
  areaResetAction,
  areaUpdateRequestAction,
  findAreaRequestAction,
  listAreaRequestAction,
} from "../ducks/area"

export const useListAreaRequestHandler = () => {
  const dispatch = useDispatch()
  return useCallback(
    page => {
      dispatch(listAreaRequestAction(page))
    },
    [dispatch]
  )
}

export const useFindAreaRequestHandler = () => {
  const dispatch = useDispatch()
  return useCallback(
    id => {
      dispatch(findAreaRequestAction(id))
    },
    [dispatch]
  )
}

export const useCreateAreaHandlers = () => {
  const dispatch = useDispatch()
  return useCallback(
    area => {
      dispatch(areaCreateRequestAction(area))
    },
    [dispatch]
  )
}

export const useUpdateAreaHandlers = () => {
  const dispatch = useDispatch()
  return useCallback(
    area => {
      dispatch(areaUpdateRequestAction(area))
    },
    [dispatch]
  )
}

export const useDeleteAreaHandlers = () => {
  const dispatch = useDispatch()
  return useCallback(
    id => {
      dispatch(areaDeleteRequestAction(id))
    },
    [dispatch]
  )
}

export const useAreasLoading = () => {
  return useSelector(state => state.area.loading)
}

export const useAreas = () => {
  return useSelector(state => state.area.areas)
}

export const useAreaUpdateSuccess = () => {
  return useSelector(state => state.area.updateSuccess)
}

export const useAreaDeleteSuccess = () => {
  return useSelector(state => state.area.deleteSuccess)
}

export const useAreaCreateSuccess = () => {
  return useSelector(state => state.area.createSuccess)
}

export const useArea = () => {
  return useSelector(state => state.area.area)
}

export const useTotalPages = () => {
  return useSelector(state => state.area.totalPages)
}

export const useAreaReset = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(areaResetAction())
  }, [dispatch])
}
