import React, { useState } from "react"
import API from "lib/api"
import useCustomFetch from "lib/useCustomFetch"
import { getFormattedOptions } from "utils/get-formatted-options"
import FilterableList from "common/PermissionRequestForm/FilterableList"

const StepPersons = props => {
  const [searchPersons, setSearchPersons] = useState("")

  const { data: personsResponse, fetching: fetchingPersons } = useCustomFetch({
    endpoint: `${API.PERSON}/persons?name=${searchPersons}&size=20`,
  })

  const persons = getFormattedOptions(personsResponse)

  const onSearchPersonss = value => {
    setSearchPersons(value)
  }

  return (
    <FilterableList
      name="employees"
      id="employees"
      placeholder="Buscar"
      label="Quem irá realizar?"
      items={persons}
      validate={props.validate}
      selectedItems={props.selectedItems}
      onChange={onSearchPersonss}
      loading={fetchingPersons}
    />
  )
}

export default StepPersons
