export const FIND_CHECKPOINTS_REQUEST = "FIND_CHECKPOINTS_REQUEST"
export const FIND_CHECKPOINTS_SUCCESS = "FIND_CHECKPOINTS_SUCCESS"
export const FIND_CHECKPOINTS_ERROR = "FIND_CHECKPOINTS_ERROR"

export const findCheckpointsRequestAction = page => ({
  type: FIND_CHECKPOINTS_REQUEST,
  payload: {
    page,
  },
})

export const findCheckpointsSuccessAction = (
  checkpoints,
  totalPages,
  last,
  filtering
) => ({
  type: FIND_CHECKPOINTS_SUCCESS,
  payload: {
    checkpoints,
    totalPages,
    last,
    filtering,
  },
})

export const findCheckpointsErrorAction = error => ({
  type: FIND_CHECKPOINTS_ERROR,
  payload: {
    error,
  },
})

const INITIAL_STATE = {
  error: null,
  loading: false,
  nodes: [],
  checkpoints: [],
  checkpoint: null,
  totalPages: 0,
  filtering: false,
  last: true,
}

const checkpointReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FIND_CHECKPOINTS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FIND_CHECKPOINTS_SUCCESS:
      const { checkpoints, totalPages, last, filtering } = action.payload
      return {
        ...state,
        checkpoints,
        totalPages,
        filtering,
        loading: false,
        last,
      }
    case FIND_CHECKPOINTS_ERROR:
      const { error } = action.payload
      return {
        ...state,
        error,
        loading: false,
      }
    default:
      return state
  }
}

export default checkpointReducer
