import { useDispatch, useSelector } from "react-redux"
import { useCallback } from "react"
import {
    mapDataInitialRequestAction,
    mapDataRequestAction,
    setDetailAction,
    setMapCenterAction,
    useMapDataRequest,
} from "../ducks/map"

export const useMonitoringRequest = () => {
    const dispatch = useDispatch()
    return useCallback(() => {
        dispatch(mapDataRequestAction())
    }, [dispatch])
}

export const useMapingRequest = () => {
    const dispatch = useDispatch()
    return useCallback(() => {
        dispatch(useMapDataRequest())
    }, [dispatch])
}

export const useMonitoringLoading = () => {
    return useSelector(state => state.map.loading)
}

export const useMonitoring = () => {
    return useSelector(state => state.map.map)
}

export const useMapingData = () => {
    return useSelector(state => state.map.data)
}

export const useMonitoringUnpreparedData = () => {
    return useSelector(state => state.map.mapUnprepared)
}

export const useSetMapCenter = () => {
    const dispatch = useDispatch()
    return useCallback(
        center => {
            dispatch(setMapCenterAction(center))
        },
        [dispatch]
    )
}

export const useSetDetail = () => {
    const dispatch = useDispatch()
    return useCallback(
        id => {
            dispatch(setDetailAction(id))
        },
        [dispatch]
    )
}

export const useMapDataInitialRequest = () => {
    const dispatch = useDispatch()
    return useCallback(() => {
        dispatch(mapDataInitialRequestAction())
    }, [dispatch])
}

export const useMapCenter = () => {
    return useSelector(state => state.map.center)
}

export const useMarkDetail = () => {
    return useSelector(state => state.map.detail)
}
