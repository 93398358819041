import React from "react"
import GlobalSearch from "common/GlobalSearch"
import UserMenu from "common/UserMenu"
import logoByond from "assets/images/logo-byond.svg"
import {
  HeaderStyled,
  SearchWrapper,
  BrandLink,
  ChangeModuleContainer,
  ChangeModuleLabel,
  GridIcon,
  ArrowIcon,
} from "./index.styled"
import {
  useNavigationChangeModuleDisplay,
  useNavigationModuleName,
} from "store/hooks/navigation"

const Header = () => {
  const handleChangeDialogDisplayAction = useNavigationChangeModuleDisplay()
  const moduleName = useNavigationModuleName()

  const openModuleDialog = () => {
    handleChangeDialogDisplayAction(true)
  }

  return (
    <HeaderStyled>
      <BrandLink to="/" data-testid="brand-link">
        <img src={logoByond} alt="Logotipo ByondGO!" />
      </BrandLink>
      <ChangeModuleContainer onClick={openModuleDialog}>
        <GridIcon />
        <ChangeModuleLabel>{moduleName}</ChangeModuleLabel>
        <ArrowIcon />
      </ChangeModuleContainer>
      <SearchWrapper>
        <GlobalSearch />
      </SearchWrapper>
      <UserMenu />
    </HeaderStyled>
  )
}

export default Header
