import { useDispatch, useSelector } from "react-redux"
import { useCallback } from "react"
import {
  findNodesRequestAction,
  findPoliciesRequestAction, findPoliciesUnpagedRequestAction,
  findPolicyRequestAction,
  policyCreateRequestAction,
  policyDeleteRequestAction,
  policyResetAction,
  policyUpdateRequestAction,
  policyUpdateStatusRequestAction,
} from "../ducks/policy"

export const useFindPoliciesUnpagedRequestHandler = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(findPoliciesUnpagedRequestAction())
  }, [dispatch])
}

export const useFindPoliciesRequestHandler = () => {
  const dispatch = useDispatch()
  return useCallback(
    page => {
      dispatch(findPoliciesRequestAction(page))
    },
    [dispatch]
  )
}

export const useFindPolicyRequestHandler = () => {
  const dispatch = useDispatch()
  return useCallback(
    id => {
      dispatch(findPolicyRequestAction(id))
    },
    [dispatch]
  )
}

export const useCreatePolicyHandlers = () => {
  const dispatch = useDispatch()
  return useCallback(
    policy => {
      dispatch(policyCreateRequestAction(policy))
    },
    [dispatch]
  )
}

export const useUpdatePolicyHandlers = () => {
  const dispatch = useDispatch()
  return useCallback(
    policy => {
      dispatch(policyUpdateRequestAction(policy))
    },
    [dispatch]
  )
}

export const useUpdatePolicyStatusHandlers = () => {
  const dispatch = useDispatch()
  return useCallback(
    dados => {
      dispatch(policyUpdateStatusRequestAction(dados))
    },
    [dispatch]
  )
}

export const useDeletePolicyHandlers = () => {
  const dispatch = useDispatch()
  return useCallback(
    id => {
      dispatch(policyDeleteRequestAction(id))
    },
    [dispatch]
  )
}

export const useListNodesHandlers = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(findNodesRequestAction())
  }, [dispatch])
}

export const useFindNodesSuccess = () => {
  return useSelector(state => state.policy.nodesSuccess)
}

export const useNodes = () => {
  return useSelector(state => state.policy.nodes)
}

export const usePoliciesLoading = () => {
  return useSelector(state => state.policy.loading)
}

export const usePolicies = () => {
  return useSelector(state => state.policy.policies)
}

export const usePolicyUpdateSuccess = () => {
  return useSelector(state => state.policy.updateSuccess)
}

export const usePolicyDeleteSuccess = () => {
  return useSelector(state => state.policy.deleteSuccess)
}

export const usePolicyCreateSuccess = () => {
  return useSelector(state => state.policy.createSuccess)
}

export const useUpdatePolicySuccess = () => {
  return useSelector(state => state.policy.updateSuccess)
}

export const usePolicy = () => {
  return useSelector(state => state.policy.policy)
}

export const usePoliciesFilter = () => {
  return useSelector(state => state.policy.filtering)
}

export const useTotalPages = () => {
  return useSelector(state => state.policy.totalPages)
}

export const usePolicyReset = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(policyResetAction())
  }, [dispatch])
}

export const usePolicyLast = () => {
  return useSelector(state => state.policy.last)
}
