import { Box, styled } from "reakit"
import { palette, theme } from "styled-tools"

export const Slider = styled(Box)`
  width: 100%;
  height: 20px;
  margin-top: 35px;
  margin-bottom: ${theme("spacing.big")};
  position: relative;

  div {
    height: 10px;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    width: 100%;
    border-radius: 15px;
  }

  div:first-child {
    background: #e1e4e8;
    z-index: 1;
  }

  div:last-child {
    background: linear-gradient(
      90deg,
      rgba(35, 64, 120, 1) 0%,
      rgba(7, 122, 191, 1) 100%
    );
    width: ${props => props.filled}%;
    max-width: 100%;
    transition: width 400ms;
    z-index: 2;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    & > span {
      display: block;
      height: 20px;
      width: 20px;
      border-radius: 100%;
      background: ${palette("white")};
      border: 4px solid ${props => palette(props.palette, props.tone)};
      position: absolute;
      top: 0;
      right: 0;
      transform: translateY(-25%);
    }
  }

  .tooltip {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    background: ${props => palette(props.palette, props.tone)};
    color: white;
    transform: translate(-50%, -160%);
    padding: 5px 15px;
    border-radius: 4px;
    text-align: center;

    &::after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 4px 0 4px;
      border-color: ${props => palette(props.palette, props.tone)} transparent
        transparent transparent;
      position: absolute;
      bottom: -2px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .total {
    position: absolute;
    z-index: 3;
    top: 0;
    right: ${theme("spacing.extrasmall")};
    transform: translateY(-145%);
    color: ${palette("grayscale")};
    font-size: 15px;
    font-weight: ${theme("typography.weight.bold")};
  }

  .hardwareName {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    transform: translateY(-145%);
    color: ${palette("grayscale")};
    font-size: 15px;
    font-weight: ${theme("typography.weight.bold")};
  }
`
