import React, { useEffect, useState } from "react"
import { isEmpty, pathOr } from "ramda"
import { format } from "date-fns"
import Table from "common/Table"
import Tag from "common/Tag"
import EmptyStateMessage from "common/EmptyStateMessage"
import {
  getCredentialTypeLabel,
  getStatusLabel,
  getStatusPalette,
} from "utils/get-status"
import { useAlerts, useLoadPersonsAlerts } from "store/hooks/alerts"

const AlertsTableList = props => {
  const [currentPage, setCurrentPage] = useState(0)
  const loadPersonsAlerts = useLoadPersonsAlerts()
  const personAlerts = useAlerts()

  useEffect(() => {
    loadPersonsAlerts(currentPage, props.limit || 20, false, props.profileId)
    setCurrentPage(currentPage + 1)
  }, [])

  return (
    <>
      {!isEmpty(personAlerts) ? (
        <>
          <Table>
            <thead>
              <tr>
                <th>Ponto de Controle</th>
                <th>Credencial</th>
                <th>Status</th>
                <th>Data/hora</th>
                {/* <th width="5%" /> */}
              </tr>
            </thead>
            <tbody>
              {personAlerts.map((item, key) => {
                return (
                  <tr key={key} data-testid="table-row">
                    <td>{pathOr("n/a", ["policy", "name"], item)}</td>
                    <td>{getCredentialTypeLabel(item.status)}</td>
                    <td>
                      <Tag palette={getStatusPalette(item.status)}>
                        {getStatusLabel(item.status)}
                      </Tag>
                    </td>
                    <td>{format(item.eventDate, "DD/MM/YYYY [às] HH:mm")}</td>
                    {/* <td>{props.renderActions(item)}</td> */}
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </>
      ) : (
        <EmptyStateMessage>Não há alertas cadastrados.</EmptyStateMessage>
      )}
    </>
  )
}

export default AlertsTableList
