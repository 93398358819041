import { Component } from "react"
import WebFont from "webfontloader"
import { STATUS } from "./constants"

class WebFontLoader extends Component {
  constructor(props) {
    super(props)

    this.state = {
      config: this.props.config,
      status: STATUS.IDLE,
    }
  }

  componentDidMount() {
    if (this.state.status !== STATUS.IDLE) {
      return
    }

    this.loadFonts()
  }

  handleLoading = () => {
    this.setState({ status: STATUS.LOADING })
  }

  handleActive = () => {
    this.setState({ status: STATUS.ACTIVE })
  }

  handleInactive = () => {
    this.setState({ status: STATUS.INACTIVE })
  }

  loadFonts = () =>
    WebFont.load({
      loading: this.handleLoading,
      active: this.handleActive,
      inactive: this.handleInactive,
      ...this.state.config,
    })

  render() {
    return this.props.children
  }
}

export default WebFontLoader
