import styled from "styled-components"

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  text-align: center;
  transition: 0.5s all ease;
`

export const ErrorLabel = styled.span`
  font-size: 11px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: red;
  transition: 0.5s all ease;
`
