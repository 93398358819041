import React, { useEffect, useState } from "react"
import { isEmpty } from "ramda"
import Layout from "common/Layout"
import PageToolbar from "common/PageToolbar"
import Paper from "common/Paper"
import Text from "common/Text"
import CheckpointTableList from "../../common/CheckpointTableList"
import {
  useCheckpointFilter,
  useCheckpoints,
  useCheckpointsLoading,
  useFindCheckpointsRequestHandler,
  useTotalPages,
} from "../../store/hooks/checkpoint"

const Checkpoint = props => {
  const [currentPage, setCurrentPage] = useState(0)
  const findCheckpoints = useFindCheckpointsRequestHandler()
  const checkpoints = useCheckpoints()
  const totalPages = useTotalPages()
  const loading = useCheckpointsLoading()
  const filtering = useCheckpointFilter()
  let i = 0
  const loadMore = () => {
    if (!loading) {
      setCurrentPage(currentPage + 1)
      findCheckpoints(currentPage)
    }
  }

  useEffect(() => {
    findCheckpoints(currentPage)
    setInterval(() => findCheckpoints(currentPage), 10000)
    setCurrentPage(currentPage + 1)
  }, [])

  return (
    <Layout>
      <PageToolbar title="Checkpoint Nodes" />
      {!isEmpty(checkpoints) || filtering ? (
        <Paper>
          <CheckpointTableList
            setCurrentPage={setCurrentPage}
            items={checkpoints}
            loadMore={loadMore}
            hasMore={totalPages}
          />
        </Paper>
      ) : (
        <Paper>
          <Text>Nenhum checkpoint cadastrado.</Text>
        </Paper>
      )}
    </Layout>
  )
}

export default Checkpoint
