import React, {useCallback, useEffect, useRef, useState} from 'react';
import './dropDown.css'
import {BtnContainer, ButtonStyled, IconStyled} from "../Filter/styles";

const DropDownFilter = ({setCurrentPage, filterFn, name, screen}) => {
  const ref = useRef()

  const [open, setOpen] = React.useState(false);
  const [filterParam, setFilterParam] = useState("")
  const [credentialStatus, setCredentialStatus] = useState()
  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClickOutside = useCallback(
    event => {
      if (ref && ref.current && !ref.current.contains(event.target)) {
        setOpen(false)
      }
    },
    [ref]
  )

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true)
    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  }, [])

  const changeSelectOptionHandler = (e) => {
    setCredentialStatus(e.target.value)
  };
  const applyFilter = () => {
    setFilterParam(credentialStatus)
  }

  useEffect(() => {
    handleFilter()
  }, [filterParam])

  const handleClearFilter = useCallback(e => {
    let filterNewObject = JSON.parse(localStorage.getItem("filter"))
    if (filterNewObject && filterNewObject[screen]) {
      filterNewObject[screen] = filterNewObject[screen].filter(
        i => i.field !== name
      )
      setFilterParam("")
      localStorage.setItem("filter", JSON.stringify(filterNewObject))
      setCurrentPage(0)
      setTimeout(() => filterFn(0), 0)
    }
    setCredentialStatus("")
  }, [])

  const handleFilter = useCallback(() => {
      if (filterParam) {
        const filter = localStorage.getItem("filter")
        let filterNewObject
        if (!filter) {
          filterNewObject = {
            [screen]: [],
          }
        } else {
          filterNewObject = JSON.parse(filter)
          if (!filterNewObject[screen]) {
            filterNewObject[screen] = []
          }
          filterNewObject[screen] = filterNewObject[screen].filter(
            i => i.field !== name
          )
        }
        filterNewObject[screen].push({
          field: name,
          operator: "contains",
          value: filterParam,
        })
        localStorage.setItem("filter", JSON.stringify(filterNewObject))
        setCurrentPage(0)
        setTimeout(() => filterFn(0), 0)
      }
    },
    [filterParam]
  )
  return (
    <div>
      <div style={{display: "flex", marginBottom: "2rem"}}>
        <IconStyled
          color={filterParam ? "#005368" : "#00cfc3"}
          palette="primary"
          className="material-icons"
          onClick={() => handleOpen()}
        >
          filter_list
        </IconStyled>
      </div>
      {open ? (
        <div ref={ref} className="dropdown">
          <ul className="menu">
            <li style={{borderBottom: "1px solid", borderRadius: "0", textAlign: "center"}} className="menu-title">
              filtrar por status
            </li>
            <li className="menu-item">
              <button
                onClick={changeSelectOptionHandler}
                style={{ backgroundColor: credentialStatus === "APPROVED" ? "lightgray" : "white" }}
                value="APPROVED">Aceita
              </button>
            </li>
            <li className="menu-item">
              <button
                onClick={changeSelectOptionHandler}
                style={{ backgroundColor: credentialStatus === "EXPIRED" ? "lightgray" : "white" }}
                value="EXPIRED">Expirada
              </button>
            </li>
            <li className="menu-item">
              <button
                onClick={changeSelectOptionHandler}
                style={{ backgroundColor: credentialStatus === "REQUESTED" ? "lightgray" : "white" }}
                value="REQUESTED">Requisitado
              </button>
            </li>
            <li className="menu-item">
              <button
                onClick={changeSelectOptionHandler}
                style={{ backgroundColor: credentialStatus === "BLOCKED" ? "lightgray" : "white" }}
                value="BLOCKED">Bloqueado
              </button>
            </li>
            <li>
              <BtnContainer>
                <ButtonStyled onClick={applyFilter}>Filtrar</ButtonStyled>
                <ButtonStyled secondary onClick={handleClearFilter}>
                  Limpar
                </ButtonStyled>
              </BtnContainer>
            </li>
          </ul>
        </div>
      ) : null}
    </div>
  );
};
export default DropDownFilter;
