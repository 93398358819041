import React from "react"
import { Step } from "reakit"
import Button from "common/Button"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import LayerFeedback from "common/LayerFeedback"
import { Formik } from "formik"
import AdvanceFormButton from "common/AdvanceFormButton"
import { useUpdatePersonSuccess } from "store/hooks/person"
import StepGroupData from "../StepGroupData"
import { StepItem, StepsWrapper } from "../../PersonForm/index.styled"
import {useCreateGroupSuccess} from "../../../store/hooks/alerts";

const GroupForm = props => {
  const updateSuccess = useUpdatePersonSuccess()
  const createSuccess = useCreateGroupSuccess()


  const handleSubmitForm = values => {
    props.onSubmit(values)
  }

  const groupValidationStructure = [
    {
      step: 0,
      requiredFields: ["friendlyName", "emails"],
    },
  ]

  const canSubmitForm = step => {
    return step.ids.length - 1 === step.current + 1
  }

  const isLastStep = step => {
    return step.ids.length === step.current + 1
  }

  const getSubmitButtonLabel = step => {
    if (isLastStep(step)) {
      return "Fechar"
    }
    if (canSubmitForm(step)) {
      return "Concluir"
    }
    return "Avançar"
  }
  return (
    <Formik
      initialValues={props.initialValues}
      onSubmit={handleSubmitForm}
      enableReinitialize={true}
    >
      {({ values, setFieldValue, isValid }) => (
        <Step.Container initialState={{ current: 0 }}>
          {step => (
            <>
              <StepsWrapper>
                <StepItem step="0" order={0} {...step}>
                  <StepGroupData
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                </StepItem>
                <StepItem step="1" order={1} {...step}>
                  <LayerFeedback
                    icon="check"
                    palette="secondary"
                    title={
                      values.id ? "Registro atualizado" : "Cadastro realizado"
                    }
                    message={
                      values.id ? "Grupo foi atualizado com sucesso." : "Grupo foi cadastrado com sucesso."
                    }
                  />
                </StepItem>
              </StepsWrapper>
              <LayoutSidebarOverlay.Footer>
                {(!isLastStep(step) || (step.current > 0 && !isValid)) && (
                  <Button
                    onClick={() => props.closeSidebar()}
                    {...step}
                    type="button"
                    palette="white"
                  >
                    Voltar
                  </Button>
                )}
                <AdvanceFormButton
                  closeSidebar={props.closeSidebar}
                  step={step}
                  structure={groupValidationStructure}
                  createSuccess={createSuccess}
                  updateSuccess={updateSuccess}
                >
                  {getSubmitButtonLabel(step)}
                </AdvanceFormButton>
              </LayoutSidebarOverlay.Footer>
            </>
          )}
        </Step.Container>
      )}
    </Formik>
  )
}

export default GroupForm
