import { Box, Input, styled } from "reakit"
import { ifProp, palette, theme } from "styled-tools"

export const InputStyled = styled(Input)`
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
`

export const FieldWrapper = styled(Box)`
  cursor: pointer;
  margin-right: ${theme("spacing.small")};
  position: relative;
  padding: ${props => props.theme.baseline}px;

  input {
    cursor: pointer;
  }
`

export const CustomCheckbox = styled(Box)`
  border: 1px solid
    ${ifProp("checked", palette("primary"), palette("grayscale"))};
  background-color: ${ifProp("checked", palette("primary"), palette("white"))};
  border-radius: ${props => props.theme.baseline / 2}px;
  cursor: pointer;
  height: ${props => props.theme.baseline * 4}px;
  transition: all 200ms;
  width: ${props => props.theme.baseline * 4}px;
`
