import React from "react"
import PageToolbar from "common/PageToolbar"
import Text from "common/Text"
import Paper from "common/Paper"
import { PaperGroup, TableHeader } from "pages/Monitoring/index.styled"
import Layout from "common/Layout"
import Chip from "common/Chip"
import ChartPlacesDashboard from "pages/Dashboard/ChartPlaces"
import HardwareInfoBar from "pages/Dashboard/HardwareInfoBar"
import { InlineFlex } from "reakit"
import {
  endOfToday,
  endOfYesterday,
  isToday,
  isYesterday,
  startOfToday,
  startOfYesterday,
} from "date-fns"
import classnames from "classnames"
import ViolationsTableList from "pages/Dashboard/ViolationsTableList"

const Dashboard = params => {
  const mock = ["", "", "", "", ""]
  function onDateFilterChange(s, s2) {}

  return (
    <Layout>
      <PageToolbar title="Dashboard" />
      <PaperGroup>
        <Paper flex="2">
          <Text variant="body" uppercase margin="big" bold padding="15px">
            Total de Hardwares Cadastrados
          </Text>
          <ChartPlacesDashboard paddingLeft="35px" />
        </Paper>
        <Paper flex="1">
          <Text
            variant="body"
            uppercase
            margin="extralarge"
            bold
            paddingTop="15px"
          >
            Hardwares Online/Offline
          </Text>
          {mock.map((item, key) => {
            return (
              <HardwareInfoBar key={key} total={12} active={10} legend="Hardware 1" />
            )
          })}
        </Paper>
      </PaperGroup>
      <Paper marginBottom="20px" marginTop="20px">
        <TableHeader>
          <Text variant="body" uppercase bold>
            Violações Recentes
          </Text>
          <InlineFlex alignItems="center" marginLeft="25px">
            <Chip
              uppercase
              onClick={onDateFilterChange(
                startOfYesterday().toISOString(),
                endOfYesterday().toISOString()
              )}
              className={classnames({
                active: isYesterday(params.startDate),
              })}
            >
              Ontem
            </Chip>
            <Chip
              uppercase
              onClick={onDateFilterChange(
                startOfToday().toISOString(),
                endOfToday().toISOString()
              )}
              className={classnames({
                active: isToday(params.startDate),
              })}
            >
              Hoje
            </Chip>
          </InlineFlex>
        </TableHeader>
        {/*TABLE VIOLACOES*/}
        <ViolationsTableList />
      </Paper>
    </Layout>
  )
}

export default Dashboard
