import React, {useEffect, useState} from 'react';
import {Col, Row, Button, ListGroup, ListGroupItem, Form} from 'react-bootstrap';
import Icon from "../Icon";
import InputText from "../InputText";
import {InputWithLabel} from "../InputText/InputWithLabel";

function TodoList({data = [], getItems, valueKey = "value", inputType}) {
  const [newItem, setNewItem] = useState("");
  const [items, setItems] = useState(data);

  useEffect(() => {
    getItems && getItems(items);
  }, [items]);

  const addNewItem = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const newEmail = {email: newItem, active: true};
      const newItems = [...items, newEmail];
      setItems(newItems);
      setNewItem("");
    } catch (error) {
    }
  };

  const updateItem = (index, editedValue) => {
    const itemsUpdated = items.map((x, i) => (i === index ? {...x, [valueKey]: editedValue} : x));
    setItems(itemsUpdated);
  };

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(items)) {
      setItems(data);
    }
  }, [data]);

  const deleteItem = (index) => {
    setItems(data.filter((_, i) => i !== index));
  };

  const ListItem = ({text, index}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedValue, setEditedValue] = useState(text);

    const saveItem = () => {
      updateItem(index, editedValue);
      setIsEditing(false);
    };
    return (
      <ListGroupItem>
        <Form>
          <div style={{display: 'flex', alignItems: 'center', paddingTop: '2rem'}}>
            <div className="ml-3" style={{flexGrow: 1, marginRight: '1rem'}}>
              <InputText
                className="form-control"
                disabled={!isEditing}
                required
                value={editedValue}
                inputType="text"
                onChange={e => setEditedValue(e.target.value)}
              />
            </div>
            <div style={{display: 'flex', alignItems: 'center', paddingBottom: '2.7rem', justifyContent: 'space-between'}}>
              <Button style={{border: 'none', marginRight: '1.5rem'}} onClick={() => deleteItem(index)}>
                <Icon size="big">delete</Icon>
              </Button>
              {isEditing && (
                <Button style={{border: 'none', marginRight: '1.5rem'}} onClick={saveItem}>
                  Save
                </Button>
              )}
            </div>
          </div>
        </Form>
      </ListGroupItem>
    );
  };
  return (
    <div>
      <Form onSubmit={addNewItem}>
        <Row className="align-items-center">
          <Col md={6}>
            <InputWithLabel
              id="email"
              name="email"
              required="required"
              inputType={inputType}
              placeholder="Email"
              value={newItem}
              onChangeText={setNewItem}
            />
          </Col>
          <Col md={6} className="mt-4">
            <Button style={styles.submitButton} bsStyle="primary" type="submit">Adicionar</Button>
          </Col>
        </Row>
      </Form>
      <Row>
        <Col md={6} className="mt-3">
          <ListGroup style={styles.listGroupContainer}>
            {items.length > 0 ? items.map((data, index) => (
              <ListItem text={data[valueKey]} key={index} index={index}/>
            )) : <h5>Sem itens adicionados.</h5>}
          </ListGroup>
        </Col>
      </Row>
    </div>
  );
}

const styles = {
  submitButton: {
    backgroundColor: '#005369',
    borderRadius: '1rem',
    border: 'none',
    color: 'white',
    marginTop: '10px',
    padding: '8px 8px',
    textAlign: 'center',
    display: 'inline-block',
    fontSize: '16px',
    size: '5px ',
    transitionDuration: '0.4s',
    cursor: 'pointer'
  },
};

export default TodoList;
