import React, { useEffect, useState } from "react"
import { Radar } from "react-chartjs-2"
import { Box, Flex } from "reakit"
import { theme } from "styled-tools"
import Typography from "@material-ui/core/Typography"
import { StyledTabs, StyledTab } from "./index.styled"
import LoadingIndicator from "common/LoadingIndicator"
import { isEmpty } from "ramda"
import EmptyStateMessage from "common/EmptyStateMessage"

const options = {
  legend: {
    display: false,
  },
  scale: {
    ticks: {
      display: false,
    },
  },
}

const TabPanel = props => {
  const { children, value, index, ...other } = props
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

const RadarDashBoard = ({
  loading,
  alerts,
  firstTabRequest,
  seccondTabRequest,
}) => {
  const [value, setValue] = useState(0)

  useEffect(() => {
    firstTabRequest()
  }, [])

  const handleChange = (_, newValue) => {
    setValue(newValue)
    if (newValue === 0) {
      firstTabRequest()
    } else if (newValue === 1) {
      seccondTabRequest()
    }
  }
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    }
  }

  return (
    <Flex column flexGrow={1}>
      <StyledTabs
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        <StyledTab label="PROIBIDOS" {...a11yProps(0)} />
        <StyledTab label="RESTRITOS" {...a11yProps(1)} />
      </StyledTabs>

      <TabPanel value={value} index={0} dir={theme.direction}>
        <PanelData loading={loading} alerts={alerts} options={options} />
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        <PanelData loading={loading} alerts={alerts} options={options} />
      </TabPanel>
    </Flex>
  )
}

const PanelData = ({ loading, alerts, options }) => {
  return (
    <LoadingIndicator loading={loading}>
      {alerts && !isEmpty(alerts) && !isEmpty(alerts.labels) ? (
        <Radar data={alerts} options={options} />
      ) : (
        <EmptyStateMessage textAlign="center">
          Não há dados a serem exibidos.
        </EmptyStateMessage>
      )}
    </LoadingIndicator>
  )
}

export default RadarDashBoard
