import React from "react"
import Table from "common/Table"
import { format } from "date-fns"


function ListItem(props) {
  const value = props.value;
  return (
    // Errado! Não há necessidade de definir a chave aqui:
    <li key={value.toString()}>
      {value}
    </li>
  );
}

const TimeAndPlaceTableList = props => {
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Local</th>
            <th>Hora Início</th>
            <th>Hora Fim</th>
            <th>Duração</th>
          </tr>
        </thead>
        <tbody>
          {props.items.map((item, key) => {
            return (
              <tr key={key} data-testid="table-row">
                <td>{item.policy.name}</td>
                <td>
                  {item.startDate
                    ? format(item.startDate, "DD/MM/YYYY HH:mm:ss")
                    : ""}
                </td>
                <td>
                  {item.endDate ? format(item.endDate, "DD/MM/YYYY HH:mm:ss") : ""}
                </td>
                <td>
                  {item.durationInSeconds 
                    ? new Date(item.durationInSeconds * 1000)
                        .toISOString()
                        .substr(11, 8)
                    : "00:00:01"}
                </td>
              </tr>)}
            )}
        </tbody>
      </Table>
    </>
  )
}

export default TimeAndPlaceTableList
