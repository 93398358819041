import React, {useEffect, useState} from "react"
import Table, {ActionData, ActionHeader, ShowMoreItens} from "common/Table"
import LoadingIndicator from "common/LoadingIndicator"
import {useCredentialLast, useCredentials, useFindCredentials, useLoadCredentials} from "store/hooks/credential"
import {GetActivatedSorted} from "../Sort"
import SortLabel from "../SortLabel"
import Filter, {GetActivatedFilter} from "../Filter"
import {format} from "date-fns"
import {isEmpty} from "ramda";
import PaperStyled from "../PaperStyled";
import Text from "../Text";
import {StorageUtils} from "../../utils/StorageUtils";

const CredentialTableList = props => {
  const last = props.last ? props.last : useCredentialLast()
  const credentialRequest = useFindCredentials()

  const selectCredentialFields = () => {
    return props && props.items && props.items.map((x) => ({value: x.name, label: x.name}))
  }
  return (
    <>
      <Table>
        <thead>
        <tr>
          <th
            style={{
              backgroundColor: GetActivatedSorted("name", "credential")
                ? "rgb(233,233,233)"
                : "white",
            }}
          >
            <SortLabel
              props={props}
              request={credentialRequest}
              name="name"
              label="Nome da Credencial"
              screen="credential"
            />
            <Filter
              screen="credential"
              name="name"
              applied={GetActivatedFilter("name", "credential")}
              setCurrentPage={props.setCurrentPage}
              filterFn={credentialRequest}
              defaultValue={StorageUtils.getDefaultStorageValue("credential", "name")}
              selectOptions={selectCredentialFields()}
              filterType="selectFilter"
            />
          </th>
          <th
            style={{
              backgroundColor: GetActivatedSorted("categoria", "credential")
                ? "rgb(233,233,233)"
                : "white",
            }}
          >
            <SortLabel
              props={props}
              request={credentialRequest}
              name="categoria"
              label="Categoria"
              screen="credential"
            />
          </th>
          <th
            style={{
              backgroundColor: GetActivatedSorted("responsavel", "credential")
                ? "rgb(233,233,233)"
                : "white",
            }}
          >
            <SortLabel
              props={props}
              request={credentialRequest}
              name="responsavel"
              label="Responsável"
              screen="credential"
            />
          </th>
          <th
            style={{
              backgroundColor: GetActivatedSorted("validityInDays", "credential")
                ? "rgb(233,233,233)"
                : "white",
            }}
          >
            <SortLabel
              props={props}
              request={credentialRequest}
              name="validityInDays"
              label="Vigência (dias)"
              screen="credential"
            />
            <Filter
              applied={GetActivatedFilter("validityInDays", "credential")}
              screen="credential"
              filterType="inputFilter"
              name="validityInDays"
              defaultValue={StorageUtils.getDefaultStorageValue("credential", "validityInDays")}
              setCurrentPage={props.setCurrentPage}
              filterFn={credentialRequest}
            />
          </th>
          <th
            style={{
              backgroundColor: GetActivatedSorted("validityInDays", "credential")
                ? "rgb(233,233,233)"
                : "white",
            }}
          >
            <SortLabel
              props={props}
              request={credentialRequest}
              name="updatedAt"
              label="Alterado em:"
              screen="credential"
            />
            <Filter
              applied={GetActivatedFilter("updatedAt", "credential")}
              screen="credential"
              name="updatedAt"
              setCurrentPage={props.setCurrentPage}
              defaultValue={StorageUtils.getDefaultStorageValue("credential", "updatedAt")}
              filterFn={credentialRequest}
              filterType="dateFilter"
            />
          </th>
          <ActionHeader>Ações</ActionHeader>
        </tr>
        </thead>
        {!isEmpty(props.items) ? (
          <tbody>
          {props.items.map((item, key) => {
            return (
              <tr key={key} data-testid="table-row">
                <td>{item.name}</td>
                <td>{item.categoria}</td>
                <td>{item.responsavel}</td>
                <td>{item.validityInDays}</td>
                <td>{format(item.updatedAt, "DD/MM/YYYY hh:mm")}</td>
                <ActionData>{props.renderActions(item)}</ActionData>
              </tr>
            )
          })}
          </tbody>
        ) : (
          <PaperStyled>
            <Text>Nenhuma credencial cadastrada.</Text>
          </PaperStyled>
        )}
      </Table>
      {props.fetching && <LoadingIndicator loading={true}/>}
      {!props.fetching && !last && (
        <ShowMoreItens handleClick={props.loadMore}/>
      )}
    </>
  )
}

export default CredentialTableList
