import { Box, Flex, styled } from "reakit"
import { ifProp, theme } from "styled-tools"
import Text from "common/Text"

export const ExpandableBlockStyled = styled(Box)`
  margin-bottom: ${theme("spacing.large")};
`

export const Head = styled(Flex)`
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  position: relative;
`

export const Title = styled(Flex)`
  padding-left: ${theme("spacing.large")};

  > i {
    position: absolute;
    left: 0;
  }
`

export const TextStyled = styled(Text)`
  text-decoration: ${ifProp("done", "line-through", "none")};
`

export const Content = styled(Box)`
  padding-bottom: ${theme("spacing.small")};
  padding-left: ${theme("spacing.large")};
  padding-top: ${theme("spacing.small")};
`
