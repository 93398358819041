import React, { useEffect, useState } from "react"
import PageToolbar from "common/PageToolbar"
import Layout from "common/Layout"
import { useHistory } from "react-router-dom"
import {
  useHeadcountFindListHandler,
  useHeadcounts,
} from "store/hooks/headcount"
import Flex from "../../common/Flex"
import Bold from "../../common/Bold"
import TextPattern from "../../common/TextPattern"
import Dashboard from "./Dashboard"
import { Metrics } from "../../configs"
import { AlertBox } from "./styles"

const Headcount = () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const findHeadcounts = useHeadcountFindListHandler()
  const headcounts = useHeadcounts()
  const history = useHistory()

  useEffect(() => {
    findHeadcounts()
    setInterval(() => findHeadcounts(), 30000)
  }, [findHeadcounts])

  return (
    <Layout>
      <PageToolbar title="Tela de Controle" />
      {headcounts && (
        <Flex dir="column">
          <Flex responsive marginBottom={Metrics.spacingMD}>
            <AlertBox alert={true}>
              <Flex width="50%">
                <TextPattern textAlign="left" size={Metrics.fontSize.xxsm}>
                  <Bold>VPS EM ALERTA</Bold>
                </TextPattern>
              </Flex>
              <Flex width="50%" justifyContent="center">
                <TextPattern size={Metrics.fontSize.md}>
                  <Bold>{headcounts.alert_vps}</Bold>
                </TextPattern>
              </Flex>
            </AlertBox>
            <AlertBox alert={false}>
              <Flex width="50%">
                <TextPattern textAlign="left" size={Metrics.fontSize.xxsm}>
                  Desempenho da Produção
                </TextPattern>
              </Flex>
              <Flex width="50%" justifyContent="center">
                <TextPattern size={Metrics.fontSize.md}>
                  <Bold>{headcounts.production_performance}%</Bold>
                </TextPattern>
              </Flex>
            </AlertBox>
            <AlertBox alert={false} dir="column">
              <Flex alignItems="flex-start" dir="column" width="100%">
                <Flex>
                  <TextPattern size={Metrics.fontSize.xxsm}>
                    <Bold>Turno: {headcounts.shift}</Bold>
                  </TextPattern>
                </Flex>
                <Flex>
                  <TextPattern size={Metrics.fontSize.xxsm}>
                    <Bold>Head Count: {headcounts.head_count} pessoas</Bold>
                  </TextPattern>
                </Flex>
              </Flex>
            </AlertBox>
          </Flex>
          <Flex marginBottom={Metrics.spacingSM}>
            <Flex width="70%" marginRight="1%">
              <TextPattern size={Metrics.fontSize.xsm}>
                <Bold>Listagem de Linhas</Bold>
              </TextPattern>
            </Flex>
            <Flex maxWidth="29%">
              <TextPattern size={Metrics.fontSize.xsm}>
                <Bold>Head Count</Bold>
              </TextPattern>
            </Flex>
          </Flex>
          {/*{headcounts.vps &&*/}
          {/*  headcounts.vps.map((vp, index) => (*/}
          {/*    <Flex dir="column" marginBottom={Metrics.spacingSM}>*/}
          {/*      <Flex*/}
          {/*        responsive*/}
          {/*        onClick={() => history.push("/headcount-detail/1")}*/}
          {/*      >*/}
          {/*        <Flex*/}
          {/*          alignItems="center"*/}
          {/*          justifyContent="space-between"*/}
          {/*          borderRadius={Metrics.spacingMinimun}*/}
          {/*          boxShadow="5px 5px 15px #cacaca"*/}
          {/*          width="70%"*/}
          {/*          maxHeight="122px"*/}
          {/*          marginRight="1%"*/}
          {/*          padding={Metrics.spacingLG}*/}
          {/*        >*/}
          {/*          <TextPattern size={Metrics.fontSize.xxlg}>*/}
          {/*            <Bold>{vp.name}</Bold>*/}
          {/*          </TextPattern>*/}
          {/*          <Dashboard onMarkerClick={() => console.log()} />*/}
          {/*        </Flex>*/}
          {/*        <Flex*/}
          {/*          alignItems="center"*/}
          {/*          justifyContent="space-between"*/}
          {/*          backgroundColor={vp.alert ? "#EC5451" : "unset"}*/}
          {/*          borderRadius={Metrics.spacingMinimun}*/}
          {/*          boxShadow="5px 5px 15px #cacaca"*/}
          {/*          maxHeight="122px"*/}
          {/*          width="29%"*/}
          {/*          padding={`${Metrics.spacingLG} ${Metrics.spacingMD}`}*/}
          {/*        >*/}
          {/*          <Flex>*/}
          {/*            <TextPattern size={Metrics.fontSize.xlg}>*/}
          {/*              <Bold>{vp.person_qt}</Bold>*/}
          {/*            </TextPattern>*/}
          {/*          </Flex>*/}
          {/*          <Flex dir="column">*/}
          {/*            <TextPattern size={Metrics.fontSize.xxxsm}>*/}
          {/*              Tempo de Alerta*/}
          {/*            </TextPattern>*/}
          {/*            <TextPattern size={Metrics.fontSize.xxsm}>*/}
          {/*              <Bold>{vp.alert_time} minutos</Bold>*/}
          {/*            </TextPattern>*/}
          {/*          </Flex>*/}
          {/*          <Flex dir="column">*/}
          {/*            <TextPattern size={Metrics.fontSize.xxsm}>*/}
          {/*              <Bold>Alerta acumulado</Bold>*/}
          {/*            </TextPattern>*/}
          {/*            <TextPattern size={Metrics.fontSize.xxsm}>*/}
          {/*              <Bold>{vp.accumulated} minutos</Bold>*/}
          {/*            </TextPattern>*/}
          {/*          </Flex>*/}
          {/*        </Flex>*/}
          {/*      </Flex>*/}
          {/*    </Flex>*/}
          {/*  ))}*/}
          {headcounts &&
            headcounts.map((vp, index) => (
              <Flex dir="column" marginBottom={Metrics.spacingSM}>
                <Flex responsive>
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    borderRadius={Metrics.spacingMinimun}
                    boxShadow="5px 5px 15px #cacaca"
                    width="70%"
                    maxHeight="122px"
                    marginRight="1%"
                    padding={Metrics.spacingLG}
                  >
                    <TextPattern size={Metrics.fontSize.xxlg}>
                      <Bold>{vp.name}</Bold>
                    </TextPattern>
                    <Dashboard
                      policyId={vp.id}
                      onMarkerClick={e =>
                        history.push(
                          `/headcount-detail/${vp.id}/${vp.name}/${e.category}`
                        )
                      }
                    />
                  </Flex>
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    backgroundColor={vp.alert ? "#EC5451" : "unset"}
                    borderRadius={Metrics.spacingMinimun}
                    boxShadow="5px 5px 15px #cacaca"
                    maxHeight="122px"
                    width="29%"
                    padding={`${Metrics.spacingLG} ${Metrics.spacingMD}`}
                  >
                    <Flex>
                      <TextPattern size={Metrics.fontSize.xxlg}>
                        <Bold> {vp.currentPersonsPresent} / {vp.currentPersonsExpected}</Bold>
                      </TextPattern>
                    </Flex>
                    <Flex dir="column">
                      <TextPattern size={Metrics.fontSize.xxxsm}>
                        Tempo de Alerta
                      </TextPattern>
                      <TextPattern size={Metrics.fontSize.xxsm}>
                        <Bold>{ vp.timeIsOpenInSeconds ? Math.trunc(vp.timeIsOpenInSeconds / 60) : '0' } minutos</Bold>
                      </TextPattern>
                    </Flex>
                    <Flex dir="column">
                      <TextPattern size={Metrics.fontSize.xxsm}>
                        <Bold>Alerta acumulado</Bold>
                      </TextPattern>
                      <TextPattern size={Metrics.fontSize.xxsm}>
                        <Bold>{ vp.timeIsOpenInSeconds ? Math.trunc(vp.timeIsOpenInSeconds / 60) : '0' } minutos</Bold>
                      </TextPattern>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            ))}
        </Flex>
      )}
    </Layout>
  )
}

export default Headcount
