import React from "react"
import { Heading as ReakitHeading, styled } from "reakit"
import { theme, ifProp } from "styled-tools"

const HeadingStyled = styled(ReakitHeading)`
  font-weight: ${ifProp(
    "bold",
    theme("typography.weight.bold"),
    theme("typography.weight.normal")
  )};
  margin-bottom: ${props => props.theme.spacing[props.margin]};
  margin-left: ${theme("spacing.none")};
  margin-right: ${theme("spacing.none")};
  margin-top: ${theme("spacing.none")};
  text-align: ${props => props.align};
  text-transform: ${ifProp("uppercase", "uppercase", "none")};
  font-size: ${ifProp(
    "variant",
    props => props.theme.typography.size[props.variant],
    props => props.theme.typography.size[props.use]
  )};
`

const Heading = ({ children, use = "h1", ...props }) => {
  return (
    <HeadingStyled use={use} {...props} maxWidth={props.maxWidth}>
      {children}
    </HeadingStyled>
  )
}

export default Heading
