import React from "react"
import { Route, withRouter } from "react-router-dom"
import { useAuthenticated, useAuthLoading } from "store/hooks/authentication"
import LoadingIndicator from "common/LoadingIndicator"
import persistedStore from "store"

const PrivateRoute = props => {
  const authLoading = useAuthLoading()
  const authenticated = useAuthenticated()
  const isAuthorized = hasAnyAuthority(props.roles)

  if (authLoading) {
    return <LoadingIndicator loading={true} />
  }

  if (!authenticated) {
    props.history.push("/login")
  }

  if (!isAuthorized) {
    props.history.push("/")
  }

  return <Route {...props} />
}

export const hasAnyAuthority = roles => {
  const authUser = persistedStore.store.getState().auth.user
  if (!roles || roles.length === 0) {
    return true
  }
  if (roles) {
    const authorities = []
    authUser.profiles.forEach(p => {
      if (p.authorities && p.authorities.length > 0) {
        authorities.push(...p.authorities.map(a => a.role))
      }
    })
    const hasRole = authorities.filter(item => roles.indexOf(item) >= 0)
    return hasRole.length > 0
  }
  return false
}

export default withRouter(PrivateRoute)
