import { call, put, select, takeLatest } from "redux-saga/effects"
import api from "services/api"
import API from "lib/api"
import { concat, isEmpty } from "ramda"
import {
  findPermissionRequestFindAllSuccess,
  findPermissionRequestSuccess,
  PERMISSION_REQUEST_FINDALL_REQUEST,
  PERMISSION_REQUEST_FINDONE_REQUEST,
  PERMISSION_REQUEST_UPDATE_STATUS,
  permissionRequestError,
  permissionRequestUpdateStatusSuccess,
} from "store/ducks/permission-request"

export function* findPermissionsRequest(action) {
  try {
    const { page, size } = action.payload
    const filter = JSON.parse(localStorage.getItem("filter"))
    const sort = JSON.parse(localStorage.getItem("sort"))
    const emptyFilter = filter ? !isEmpty(filter.filter) : false

    const apiCall = () => {
      let params
      params = {
        page,
        size: size,
        sort:
          sort && sort.permissionRequestManagement
            ? sort.permissionRequestManagement.sortString
            : "createdAt,desc",
      }
      if (filter && filter.permissionRequestManagement) {
        filter.permissionRequestManagement.forEach(f => {
          if (f.field === 'createdAt') {
            params.startDate = f.value.startDate;
            params.endDate = f.value.endDate;
          } else if (f.field === 'updatedAt') {
            params.updatedSince = f.value.startDate;
            params.updatedUntil = f.value.endDate;
          } else {
            params[f.field] = f.value;
          }
        })
      }

      return api
        .get(`${API.PERSON}/permissions-request`, {
          params,
        })
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    const { permissionsRequest } = yield select(
      state => state.permissionRequest
    )
    const { content, first, last, totalPages } = yield call(apiCall)
    if (!first) {
      yield put(
        findPermissionRequestFindAllSuccess(
          concat(permissionsRequest, content || []),
          last,
          totalPages
        )
      )
    } else {
      yield put(findPermissionRequestFindAllSuccess(content, last))
    }
  } catch (err) {
    yield put(permissionRequestError(err))
  }
}

function* findPersonCredential(id) {
  const apiCall = () => {
    return api
      .get(`${API.PERSON}/permissions-request/${id}/person-credentials`)
      .then(response => response.data)
      .catch(() => {
        return null
      })
  }
  return yield call(apiCall)
}

export function* findPermissionRequest(action) {
  try {
    const { id } = action.payload
    const apiCall = () => {
      return api
        .get(`${API.PERSON}/permissions-request/${id}`)
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    const response = yield call(apiCall)
    if (response) {
      const personCredentialsResponse = yield call(
        findPersonCredential,
        response.id
      )
      response.personCredentials = personCredentialsResponse
    }
    yield put(findPermissionRequestSuccess(response))
  } catch (err) {
    yield put(permissionRequestError(err))
  }
}

export function* updatePersonCredentialStatus(action) {
  try {
    const { data } = action.payload
    const apiCall = () => {
      return api
        .put(`${API.PERSON}/person-credentials/${data.id}/status`, data)
        .then(response => response.data)
        .catch(err => {
          throw err
        })
    }
    yield call(apiCall)
    yield put(permissionRequestUpdateStatusSuccess())
  } catch (err) {
    yield put(permissionRequestError(err))
  }
}

export default [
  takeLatest(PERMISSION_REQUEST_FINDALL_REQUEST, findPermissionsRequest),
  takeLatest(PERMISSION_REQUEST_FINDONE_REQUEST, findPermissionRequest),
  takeLatest(PERMISSION_REQUEST_UPDATE_STATUS, updatePersonCredentialStatus),
]
