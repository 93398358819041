import React, { useEffect } from "react"
import { isNil } from "ramda"
import { Box, Flex, styled } from "reakit"
import { theme } from "styled-tools"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import Text from "common/Text"
import Button from "common/Button"
import LoadingIndicator from "common/LoadingIndicator"
import {
  useFindPolicyRequestHandler,
  usePoliciesLoading,
  usePolicy,
} from "../../../store/hooks/policy"
import { withRouter } from "react-router-dom"

const BlockListItem = styled(Box)`
  padding-bottom: ${theme("spacing.large")};
  padding-top: ${theme("spacing.large")};
`

const PolicyDetails = props => {
  const loading = usePoliciesLoading()
  const { id } = props.match.params
  const findPolicy = useFindPolicyRequestHandler()
  const policy = usePolicy()

  useEffect(() => {
    if (id) {
      findPolicy(id)
    }
  }, [findPolicy, id])

  return (
    <LayoutSidebarOverlay
      title="Política"
      subtitle={policy ? policy.id : ""}
      afterClose={() => {
        props.history.push("/politicas")
      }}
    >
      {({ closeSidebar }) => {
        return (
          <LoadingIndicator loading={loading}>
            {isNil(policy) ? null : (
              <Flex column maxWidth="33rem" flexGrow={1}>
                <BlockListItem>
                  <Text margin="extralarge" palette="primary" variant="h6">
                    NOME: {policy.name}
                  </Text>
                  <Text margin="extralarge" palette="primary" variant="h6">
                    DESCRIÇÃO: {policy.description}
                  </Text>
                </BlockListItem>
              </Flex>
            )}
            <LayoutSidebarOverlay.Footer>
              <Button type="button" onClick={closeSidebar}>
                Ok
              </Button>
            </LayoutSidebarOverlay.Footer>
          </LoadingIndicator>
        )
      }}
    </LayoutSidebarOverlay>
  )
}

export default withRouter(PolicyDetails)
