const API = {
  PERSON: "person/v1",
  BLEZONE: "blezone/v1",
  PERSONV2: "person/v2",
  TON: "ton/v1",
  CLOUD: "cloud/v1",
  AUTH: "auth/v1",
  ALERTS: "alerts/v1"
}

export default API
