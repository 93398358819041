import React, { Fragment } from "react"
import { Box, Flex, styled } from "reakit"
import { theme } from "styled-tools"
import Header from "common/Header"
import SideNavigation from "common/SideNavigation"
import ModuleChangeV2 from "common/ModuleChangeV2"

const Wrapper = styled(Flex)`
  height: calc(100% - 70px);
`

const Content = styled(Box)`
  padding-left: ${theme("spacing.medium")};
  padding-right: ${theme("spacing.large")};
  overflow-y: scroll;
  width: 100%;

  /* test to have always full page on content level. */
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const Layout = props => {
  return (
    <Fragment>
      <ModuleChangeV2 />
      <Header />
      <Wrapper>
        {!props.withoutMenu && (
          <Box>
            <SideNavigation />
          </Box>
        )}
        <Content>{props.children}</Content>
      </Wrapper>
    </Fragment>
  )
}

export default Layout
