import React, { useState, useEffect, useContext } from "react"
import LoadingIndicator from "common/LoadingIndicator"
import { ShowMoreItens } from "../../../../common/Table"
import EmptyStateMessage from "common/EmptyStateMessage"
import Table from "common/Table"
import { isEmpty } from "ramda"
import { CredentialReportContext } from "pages/Monitoring/CredentialReport"
import { useLastPageReport } from "../../../../store/hooks/report"
import Link from "common/Link"

const ReportView = props => {
  const last = useLastPageReport()
  const { data, loadData, loading, emptyLabel } = props
  const [page, setPage] = useState(0)
  const context = useContext(CredentialReportContext)

  useEffect(() => {
    loadData(page)
    setPage(oldvalue => (oldvalue += 1))
  }, [])

  const loadMore = () => {
    if (!loading) {
      setPage(oldvalue => (oldvalue += 1))
      loadData(page, 20)
    }
  }

  useEffect(() => {
    context.setCredentialsReport(data)
  }, [data])

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>COLABORADOR</th>
            <th>CREDENCIAL</th>
          </tr>
        </thead>
        <tbody>
          {data.filter(x=> x.person).map((item, key) => {
            return (
              <tr key={key} data-testid="table-row">
                <td>
                  <Link to={`/perfil/${item.person.id}`}>
                    {item.person.name}
                  </Link>
                </td>
                <td>{item.credential && item.credential.name}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      {!isEmpty(data) ? (
        <>
          {loading && <LoadingIndicator loading={true} />}
          {!loading && !last && <ShowMoreItens handleClick={loadMore} />}
        </>
      ) : (
        <EmptyStateMessage textAlign="center">
          {emptyLabel ? emptyLabel : "Não há informações a serem exibidas"}
        </EmptyStateMessage>
      )}
    </>
  )
}

export default ReportView
