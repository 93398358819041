import React from "react"
import { styled } from "reakit"
import { theme } from "styled-tools"
import Text from "common/Text"

const TextStyled = styled(Text)`
  padding-bottom: ${theme("spacing.small")};
  padding-top: ${theme("spacing.small")};
`

const EmptyStateMessage = ({ children, ...props }) => {
  return (
    <TextStyled variant="body3" palette="grayscale" margin="none" {...props}>
      {children}
    </TextStyled>
  )
}

export default EmptyStateMessage
