import React, {useCallback, useEffect, useState} from "react"
import {ContainerStyled} from "../index.styled"
import {ErrorMessage, useFormikContext} from "formik"
import SimpleSelectV2 from "../../../../common/SimpleSelectV2"
import InputText from "../../../../common/InputText"
import {ErrorContainer, ErrorLabel} from "../../../../common/Error"
import FieldAutoCompleteV2 from "../../../../common/FieldAutocompleteV2"
import {
  useLoadResponsibles,
  useResponsibles,
} from "../../../../store/hooks/responsible"
import FieldAutoCompleteWithCreateButton from "../../../../common/FieldAutoCompleteWIthCreate"
import {
  useLoadOrganization,
  useOrganizations,
} from "../../../../store/hooks/organizations"
import {FormSwitch} from "../../../../common/FormSwitch";

const StepCredentialData = ({values}) => {
  const {handleChange} = useFormikContext()
  const queryOrganizations = useLoadOrganization()
  const organizationsData = useOrganizations()
  const [suggestionsOrganization, setSuggestionsOrganization] = useState([])

  const vigencia = [
    {label: "15 DIAS", id: 15},
    {label: "30 DIAS", id: 30},
    {label: "2 MESES", id: 60},
    {label: "6 MESES", id: 180},
    {label: "1 ANO", id: 365},
  ]

  const handleFindOrganization = useCallback(param => {
    queryOrganizations(param)
  }, [])

  useEffect(() => {
    setSuggestionsOrganization(organizationsData)
  }, [organizationsData])
  return (
    <ContainerStyled>
      <InputText
        value={values.name}
        name="name"
        id="name"
        label="Nome"
        disabled={!!values.renew}
        onChange={handleChange}
      />
      <InputText
        value={values.categoria}
        name="categoria"
        id="categoria"
        label="Categoria"
        disabled={!!values.renew}
        onChange={handleChange}
      />
      <SimpleSelectV2
        propertyToShow="label"
        valueProperty="id"
        label="Vigência"
        list={vigencia}
        name="validityInDays"
        id="validityInDays"
        disabled={!!values.renew}
        value={values.validityInDays}
      />
      <FieldAutoCompleteWithCreateButton
        name="organizationUnity"
        id="organizationUnity"
        label="Departamento Responsável"
        value={values.organizationUnity}
        minLength={2}
        completeMethod={handleFindOrganization}
        fieldName="name"
        suggestions={suggestionsOrganization}
        disabled={!!values.renew}
        labelButton="Criar Área"
      />
      <label style={{fontSize: "1.5rem", color: "#005369", paddingBottom: "1rem"}}>Criar com a permissão de trabalho?</label>
      <FormSwitch
        onLabel={"Sim"}
        size={"sm"}
        offLabel={"Não"}
        value={values.canCreateWithWorkPermission}
        name="canCreateWithWorkPermission"
        type="round"
        onChange={handleChange}
      />
      <ErrorContainer>
        <ErrorMessage
          name="categoria"
          render={msg => <ErrorLabel>{msg}</ErrorLabel>}
        />
        <ErrorMessage
          name="name"
          render={msg => <ErrorLabel>{msg}</ErrorLabel>}
        />
        <ErrorMessage
          name="responsavel"
          render={msg => <ErrorLabel>{msg}</ErrorLabel>}
        />
        <ErrorMessage
          name="validityInDays"
          render={msg => <ErrorLabel>{msg}</ErrorLabel>}
        />
      </ErrorContainer>
    </ContainerStyled>
  )
}

export default StepCredentialData
