import React from "react"
import { format } from "date-fns"
import Table, { ShowMoreItens } from "common/Table"
import LoadingIndicator from "common/LoadingIndicator"
import { withRouter } from "react-router-dom"
import Link from "common/Link"

const EventsTableList = props => {
  const goToProfile = id => {
    props.history.push(`/perfil/${id}`)
  }

  // const last = useEventLast()
  const last = false
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Pessoa</th>
            <th>Local</th>
            <th>Hora Início</th>
            <th>Hora Fim</th>
            <th>Duração</th>
          </tr>
        </thead>
        <tbody>
          {props.items.map((item, key) => {
            return (
              <tr key={key} data-testid="table-row">
                <td onClick={() => goToProfile(item.person.id)}>
                  <Link to={`/perfil/${item.person.id}`}>
                    {item.person.name}
                  </Link>
                </td>
                <td>{item.policy.name}</td>
                <td>
                  {item.startDate? format(item.startDate, "DD/MM/YYYY HH:mm:ss") : ""}
                </td>
                <td>
                  {item.endDate ? format(item.endDate, "DD/MM/YYYY HH:mm:ss") : ""}
                </td>
                <td>
                {item.durationInSeconds > 0 ?
                            new Date(item.durationInSeconds * 1000)
                              .toISOString()
                              .substr(11, 8) : "00:00:01"
                          }
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      <>
        {props.loading && <LoadingIndicator loading={true} />}
        {!props.loading && !last && (
          <ShowMoreItens handleClick={props.loadMore} />
        )}
      </>
    </>
  )
}

export default withRouter(EventsTableList)
