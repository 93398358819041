import { call, put, select, takeLatest } from "redux-saga/effects"
import api from "../../services/api"
import API from "../../lib/api"
import { clone, concat, isEmpty } from "ramda"
import { format } from "date-fns"
import {
  CREATE_PERSON_REQUEST,
  LOAD_AUTO_COMPLETE_PERSONS_REQUEST,
  LOAD_PERSON_REQUEST,
  LOAD_PERSONS_REQUEST,
  UPDATE_PERSON_REQUEST,
  DELETE_PERSON_REQUEST,
  createPersonSuccess,
  deletePersonError,
  deletePersonSuccess,
  loadAutoCompletePersonsError,
  loadAutoCompletePersonsSuccess,
  loadPersonError,
  loadPersonsError,
  loadPersonsSuccess,
  loadPersonSuccess,
  updatePersonError,
  updatePersonSuccess,
  LOAD_PERSON_PERMISSIONS_REQUEST,
  LOAD_PERSON_BEACONS_REQUEST,
  loadPersonPermissionsSuccess,
  loadPersonPermissionsError,
  loadPersonBeaconsSuccess,
  loadPersonBeaconsError,
  FIND_PERSON_TIME_AND_PLACES_REQUEST,
  findPersonTimeInPlacesSuccess,
  findPersonTimeInPlacesError,
  LOAD_UNPAGED_PERSONS_REQUEST,
  loadUnpagedPersonsSuccess,
  DOWNLOAD_TIME_AND_PLACES_REQUEST,
  findPersonTimeInPlacesSummarySuccess,
  findPersonTimeInPlacesSummaryError,
  FIND_PERSON_TIME_AND_PLACES_SUMMARY_REQUEST,
  findPersonTimeInPlacesLast,
} from "../ducks/person"
import { DateUtils } from "utils/DateUtils"
import { showErrorToast, showSuccessToast } from "utils/toast"
import { getChartData } from "store/saga/utils"

export function* findPersonPermissions(action) {
  try {
    const { id } = action.payload
    const findPersonApiCall = () => {
      return api
        .get(`${API.PERSON}/persons/${id}/permissions`)
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    const response = yield call(findPersonApiCall)
    yield put(loadPersonPermissionsSuccess(response))
  } catch (err) {
    yield put(loadPersonPermissionsError(err))
  }
}

export function* findPersonTimeInPlaces(action) {
  try {
    const { filter, page } = action.payload
    const size = action.payload.size ? action.payload.size : 10
    const params = {
      sort: "startDate,desc",
      page,
      size,
    }
    if (filter.personId) {
      params._personId = filter.personId
    }
    if (filter.policyId) {
      params._policyId = filter.policyId
    }
    if (filter.dateBegin) {
      params._dateBegin = filter.dateBegin
    }
    if (filter.dateEnd) {
      params._dateEnd = filter.dateEnd
    }
    if (filter.isOpen) {
      params.isOpen = filter.isOpen
    }
    if (filter.minSecondsToShow) {
      params.minSecondsToShow = filter.minSecondsToShow
    }
    const findPersonTimeInPlacesApiCall = () => {
      return api
        .get(`${API.PERSON}/time-in-places`, {
          params,
        })
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    const { timePlaces } = yield select(state => state.person)
    const response = yield call(findPersonTimeInPlacesApiCall)
    yield put(
      findPersonTimeInPlacesSuccess([...timePlaces, ...response.content])
    )
    yield put(findPersonTimeInPlacesLast(response.last))
  } catch (err) {
    yield put(findPersonTimeInPlacesError(err))
  }
}

export function* downloadTimeInPlaces(action) {
  try {
    const { filter } = action.payload
    const params = {
      sort: "startDate,desc",
    }
    if (filter.personId) {
      params._personId = filter.personId
    }
    if (filter.policyId) {
      params._policyId = filter.policyId
    }
    if (filter.dateBegin) {
      params._dateBegin = filter.dateBegin
    }
    if (filter.dateEnd) {
      params._dateEnd = filter.dateEnd
    }
    const downloadTimeInPlacesApiCall = () => {
      return api
        .get(`${API.PERSON}/time-in-places/csv`, {
          params,
        })
        .then(response => {
          return response
        })
        .catch(err => {
          throw err
        })
    }
    showSuccessToast(`Aguarde o download do CSV`)
    const response = yield call(downloadTimeInPlacesApiCall)
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: response.data.type })
    )
    const link = document.createElement("a")
    link.href = url
    const contentDisposition = response.headers["content-disposition"]
    const filename = contentDisposition.substring(
      contentDisposition.indexOf("=") + 1
    )
    link.setAttribute("download", `${filename}`)
    link.click()

    // yield put(findPersonTimeInPlacesSuccess(response.content))
  } catch (err) {
    // yield put(findPersonTimeInPlacesError(err))
  }
}

export function* findPersonBeacons(action) {
  try {
    const { id } = action.payload
    const findPersonApiCall = () => {
      return api
        .get(`${API.PERSON}/persons/${id}/beacons?sort=updatedAt,desc`)
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    const response = yield call(findPersonApiCall)
    yield put(loadPersonBeaconsSuccess(response))
  } catch (err) {
    yield put(loadPersonBeaconsError(err))
  }
}

export function* findUnpagedPersons(action) {
  try {
    const { page } = action.payload
    const sort = JSON.parse(localStorage.getItem("sort"))
    const findUnpagedPersonsDataApiCall = () => {
      let params
      params = {
        page,
        size: 9999,
        sort: sort && sort.persons ? sort.persons.sortString : "createdAt,desc",
      }
      return api
        .get(`${API.PERSON}/persons`, {
          params,
        })
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    const { content } = yield call(findUnpagedPersonsDataApiCall)
    yield put(loadUnpagedPersonsSuccess(content))
  } catch (err) {
    yield put(loadPersonsError(err))
  }
}

export function* findPersons(action) {
  try {
    const { page, size, force } = action.payload
    const filter = JSON.parse(localStorage.getItem("filter"))
    const sort = JSON.parse(localStorage.getItem("sort"))
    const emptyFilter = filter ? !isEmpty(filter.filter) : false
    const findPersonsDataApiCall = () => {
      let params
      params = {
        page,
        size: size,
        sort: sort && sort.person ? sort.person.sortString : "createdAt,desc",
      }
      if (filter && filter.person) {
        filter.person.forEach(f => {
          params[f.field] = f.value
        })
      }
      return api
        .get(`${API.PERSON}/persons`, {
          params,
        })
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    const {
      persons,
      lastPageFind,
      totalPages: totalPagesPerson,
    } = yield select(state => state.person)
    if (page === lastPageFind && !force) {
      yield put(loadPersonsSuccess(persons, totalPagesPerson, page))
    }
    const { content, totalPages, first, last } = yield call(
      findPersonsDataApiCall
    )
    if (!first && !force) {
      yield put(
        loadPersonsSuccess(
          concat(persons, content || []),
          totalPages,
          page,
          last,
          emptyFilter
        )
      )
    } else {
      yield put(
        loadPersonsSuccess(content, totalPages, page, last, emptyFilter)
      )
    }
  } catch (err) {
    yield put(loadPersonsError(err))
  }
}

function* findPersonBeaconActive(personId) {
  const findPersonBeaconActiveApiCall = () => {
    return api
      .get(`${API.PERSON}/person-beacons/person/${personId}/active`)
      .then(response => response.data)
      .catch(() => {
        return null
      })
  }
  return yield call(findPersonBeaconActiveApiCall)
}

function* findPersonBeaconsByPerson(personId) {
  const apiCall = () => {
    return api
      .get(
        `${API.PERSON}/persons/${personId}/beacons?sort=updatedAt,desc&size=5`
      )
      .then(response => response.data)
      .catch(() => {
        return null
      })
  }
  return yield call(apiCall)
}

export function* findPerson(action) {
  try {
    const { id, withBeacons } = action.payload
    const findPersonApiCall = () => {
      return api
        .get(`${API.PERSON}/persons/${id}`)
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    const response = yield call(findPersonApiCall)
    if (response && response.birthDate) {
      response.birthDate = format(
        new Date(Date.parse(response.birthDate)),
        "DD/MM/YYYY"
      )
    }
    if (response) {
      const beaconActive = yield call(findPersonBeaconActive, response.id)
      if (beaconActive) {
        response.beacon = beaconActive.beacon
      }
    }
    if (withBeacons) {
      const personBeacons = yield call(findPersonBeaconsByPerson, id)
      if (personBeacons) {
        response.beacons = personBeacons.content
      }
    }
    yield put(loadPersonSuccess(response))
  } catch (err) {
    yield put(loadPersonError(err))
  }
}

export function* deletePerson(action) {
  try {
    const { id } = action.payload
    const deletePersonApiCall = () => {
      return api
        .delete(`${API.PERSON}/persons/${id}`)
        .then(response => response)
        .catch(err => {
          throw err
        })
    }
    yield call(deletePersonApiCall)
    yield put(deletePersonSuccess())
  } catch (err) {
    if (err && err.data && err.data.message) {
      showErrorToast(err.data.message)
    }
    yield put(deletePersonError(err))
  }
}

export function* updatePerson(action) {
  try {
    const { person } = action.payload
    const personCopy = clone(person)
    personCopy.birthDate = DateUtils.convertStringBRToDate(personCopy.birthDate)
    const updatePersonApiCall = () => {
      return api
        .put(`${API.PERSON}/persons/${person.id}`, personCopy)
        .then(response => response.data)
        .catch(err => {
          throw err
        })
    }
    const response = yield call(updatePersonApiCall)
    yield put(updatePersonSuccess(response))
  } catch (err) {
    if (err && err.data && err.data.message) {
      showErrorToast(err.data.message)
    }
    yield put(updatePersonError(err))
  }
}

export function* createPerson(action) {
  try {
    const { person } = action.payload
    const personCopy = clone(person)
    personCopy.birthDate = DateUtils.convertStringBRToDate(personCopy.birthDate)
    if (!personCopy.managerId) {
      delete personCopy.managerId
    }
    const createPersonApiCall = () => {
      return api
        .post(`${API.PERSON}/persons`, personCopy)
        .then(response => response.data)
        .catch(err => {
          throw err
        })
    }
    const response = yield call(createPersonApiCall)
    yield put(createPersonSuccess(response))
  } catch (err) {
    if (err && err.data && err.data.message) {
      showErrorToast(err.data.message)
    }
    yield put(updatePersonError(err))
  }
}

export function* findAutocompletePersons(action) {
  try {
    const { name, unpaged } = action.payload
    const findAutocompletePersonsDataApiCall = () => {
      return api
        .get(`${API.PERSON}/persons`, {
          params: {
            name,
            unpaged,
          },
        })
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }

    const { content } = yield call(findAutocompletePersonsDataApiCall)
    yield put(loadAutoCompletePersonsSuccess(content))
  } catch (err) {
    yield put(loadAutoCompletePersonsError(err))
  }
}

export function* findPersonTimeInPlacesSummary(action) {
  try {
    const { startDate, endDate, personId, daysFilter } = action.payload
    const apiCall = () => {
      return api
        .get(`${API.PERSON}/time-in-places/summary/persons/${personId}`, {
          params: {
            startDate,
            endDate,
          },
        })
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    const { content } = yield call(apiCall)
    const chartData = getChartData(content, daysFilter)
    yield put(findPersonTimeInPlacesSummarySuccess(content, chartData))
  } catch (err) {
    yield put(findPersonTimeInPlacesSummaryError(err))
  }
}

export default [
  takeLatest(DOWNLOAD_TIME_AND_PLACES_REQUEST, downloadTimeInPlaces),
  takeLatest(FIND_PERSON_TIME_AND_PLACES_REQUEST, findPersonTimeInPlaces),
  takeLatest(LOAD_PERSON_PERMISSIONS_REQUEST, findPersonPermissions),
  takeLatest(LOAD_PERSON_BEACONS_REQUEST, findPersonBeacons),
  takeLatest(LOAD_AUTO_COMPLETE_PERSONS_REQUEST, findAutocompletePersons),
  takeLatest(LOAD_PERSONS_REQUEST, findPersons),
  takeLatest(LOAD_UNPAGED_PERSONS_REQUEST, findUnpagedPersons),
  takeLatest(LOAD_PERSON_REQUEST, findPerson),
  takeLatest(DELETE_PERSON_REQUEST, deletePerson),
  takeLatest(UPDATE_PERSON_REQUEST, updatePerson),
  takeLatest(CREATE_PERSON_REQUEST, createPerson),
  takeLatest(
    FIND_PERSON_TIME_AND_PLACES_SUMMARY_REQUEST,
    findPersonTimeInPlacesSummary
  ),
]
