import styled from "styled-components"
import { Metrics } from "../../configs"

export const AlertBox = styled.div`
  display: flex;
  width: 250px;
  padding: ${Metrics.spacingMD};
  flex-direction: ${({ dir }) => dir};
  box-shadow: 5px 5px 15px #cacaca;
  border-bottom: 4px solid ${({ alert }) => (alert ? `#EC5451` : `#00ae69`)};
  margin-right: ${Metrics.spacingSM};
  margin-bottom: ${Metrics.spacingMD};
  align-items: center;
`

export const PersonBlock = styled.div`
  display: flex;
  padding: ${Metrics.spacingMinimun} ${Metrics.spacingMD};
  border-left: 6px solid ${({ alert }) => (alert ? `#EC5451` : `#00ae69`)};
  margin-bottom: ${Metrics.spacingSM};
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #e2e2e2;
  border-radius: 8px;
`

export const StatusChip = styled.div`
  background: ${({ alert }) => (alert ? `#EC5451` : `#00ae69`)};
  padding: 8px;
  border-radius: 15px;
`
