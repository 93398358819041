import React from "react"
import { Step } from "reakit"
import Button from "common/Button"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import LayerFeedback from "common/LayerFeedback"
import StepPolicyData from "./StepPolicyData"
import { StepItem, StepsWrapper } from "./index.styled"
import StepPolicyDetail from "./StepPolicyDetail"
import { Formik } from "formik"
import ValidationSchema from "./ValidationSchema"
import AdvanceFormButton from "../../../common/AdvanceFormButton"

const PolicyForm = props => {
  const handleSubmitForm = values => {
    props.onSubmit(values)
  }

  const policyCreationStructure = [
    {
      step: 0,
      requiredFields: ["name", "description", "type", "latitude", "longitude"],
    },
    {
      step: 1,
      requiredFields: ["duration"],
    },
  ]

  const canSubmitForm = step => {
    return step.ids.length - 1 === step.current + 1
  }

  const isLastStep = step => {
    return step.ids.length === step.current + 1
  }

  const getSubmitButtonLabel = step => {
    if (isLastStep(step)) {
      return "Fechar"
    }

    if (canSubmitForm(step)) {
      return "Concluir"
    }

    return "Avançar"
  }

  return (
    <>
      {props.initialValues && (
        <Formik
          initialValues={props.initialValues}
          validationSchema={ValidationSchema}
          onSubmit={handleSubmitForm}
          enableReinitialize={true}
        >
          {({ isValid, values }) => (
            <Step.Container initialState={{ current: 0 }}>
              {step => {
                return (
                  <>
                    <>
                      <StepsWrapper>
                        <StepItem step="0" order={0} {...step}>
                          <StepPolicyData values={values} />
                        </StepItem>
                        <StepItem step="1" order={1} {...step}>
                          <StepPolicyDetail values={values} />
                        </StepItem>
                        <StepItem step="2" order={2} {...step}>
                          <LayerFeedback
                            icon="check"
                            palette="secondary"
                            title="Cadastro realizado"
                            message="Política foi cadastrada com sucesso."
                          />
                        </StepItem>
                      </StepsWrapper>
                      <LayoutSidebarOverlay.Footer>
                        {!isLastStep(step) || (step > 0 && !isValid) ? (
                          <Button
                            use={Step.Previous}
                            {...step}
                            type="button"
                            palette="white"
                          >
                            Voltar
                          </Button>
                        ) : (
                          ""
                        )}
                        <AdvanceFormButton
                          closeSidebar={props.closeSidebar}
                          step={step}
                          structure={policyCreationStructure}
                        >
                          {getSubmitButtonLabel(step)}
                        </AdvanceFormButton>
                      </LayoutSidebarOverlay.Footer>
                    </>
                  </>
                )
              }}
            </Step.Container>
          )}
        </Formik>
      )}
    </>
  )
}

export default PolicyForm
