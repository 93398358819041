export const PERMISSION_REQUEST_FINDALL_REQUEST =
  "PERMISSION_REQUEST_FINDALL_REQUEST"
export const PERMISSION_REQUEST_FINDALL_SUCCESS =
  "PERMISSION_REQUEST_FINDALL_SUCCESS"
export const PERMISSION_REQUEST_FINDONE_REQUEST =
  "PERMISSION_REQUEST_FINDONE_REQUEST"
export const PERMISSION_REQUEST_FINDONE_SUCCESS =
  "PERMISSION_REQUEST_FINDONE_SUCCESS"
export const PERMISSION_REQUEST_UPDATE_STATUS =
  "PERMISSION_REQUEST_UPDATE_STATUS"
export const PERMISSION_REQUEST_UPDATE_STATUS_SUCCESS =
  "PERMISSION_REQUEST_UPDATE_STATUS_SUCCESS"
// export const PERMISSION_REQUEST_CREATE_REQUEST =
//   "PERMISSION_REQUEST_CREATE_REQUEST"
// export const PERMISSION_REQUEST_CREATE_SUCCESS =
//   "PERMISSION_REQUEST_CREATE_SUCCESS"
// export const PERMISSION_REQUEST_UPDATE_REQUEST =
//   "PERMISSION_REQUEST_UPDATE_REQUEST"
// export const PERMISSION_REQUEST_UPDATE_SUCCESS =
//   "PERMISSION_REQUEST_UPDATE_SUCCESS"
export const PERMISSION_REQUEST_ERROR = "PERMISSION_REQUEST_ERROR"
export const PERMISSION_REQUEST_RESET = "PERMISSION_REQUEST_RESET"

export const findPermissionRequestFindAllAction = (page, size) => ({
  type: PERMISSION_REQUEST_FINDALL_REQUEST,
  payload: {
    page,
    size,
  },
})

export const findPermissionRequestFindAllSuccess = (
  permissionsRequest,
  last,
  totalPages
) => ({
  type: PERMISSION_REQUEST_FINDALL_SUCCESS,
  payload: {
    permissionsRequest,
    last,
    totalPages,
  },
})

export const findPermissionRequestRequest = id => ({
  type: PERMISSION_REQUEST_FINDONE_REQUEST,
  payload: {
    id,
  },
})

export const findPermissionRequestSuccess = permissionRequest => ({
  type: PERMISSION_REQUEST_FINDONE_SUCCESS,
  payload: {
    permissionRequest,
  },
})

export const permissionRequestUpdateStatus = data => ({
  type: PERMISSION_REQUEST_UPDATE_STATUS,
  payload: {
    data,
  },
})

export const permissionRequestUpdateStatusSuccess = () => ({
  type: PERMISSION_REQUEST_UPDATE_STATUS_SUCCESS,
})

export const permissionRequestError = error => ({
  type: PERMISSION_REQUEST_ERROR,
  payload: {
    error,
  },
})

export const permissionRequestReset = () => ({
  type: PERMISSION_REQUEST_RESET,
})

const INITIAL_STATE = {
  error: null,
  loading: false,
  permissionsRequest: [],
  permissionRequest: null,
  updateSuccess: false,
  deleteSuccess: false,
  createSuccess: false,
  last: true,
  totalPages: null,
}

const permissionRequestReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PERMISSION_REQUEST_FINDALL_REQUEST:
    case PERMISSION_REQUEST_FINDONE_REQUEST:
    case PERMISSION_REQUEST_UPDATE_STATUS:
      return {
        ...state,
        loading: true,
      }
    case PERMISSION_REQUEST_FINDALL_SUCCESS:
      const { permissionsRequest, last, totalPages } = action.payload
      return {
        ...state,
        permissionsRequest,
        last,
        totalPages,
        loading: false,
      }
    case PERMISSION_REQUEST_FINDONE_SUCCESS:
      const { permissionRequest } = action.payload
      return {
        ...state,
        permissionRequest,
        loading: false,
      }
    case PERMISSION_REQUEST_UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        updateSuccess: true,
      }
    case PERMISSION_REQUEST_RESET:
      return {
        ...state,
        updateSuccess: false,
        createSuccess: false,
        deleteSuccess: false,
        loading: false,
      }
    case PERMISSION_REQUEST_ERROR:
      const { error } = action.payload
      return {
        ...state,
        error,
        loading: false,
      }
    default:
      return state
  }
}

export default permissionRequestReducer
