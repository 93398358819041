import React, {useEffect, useState} from "react"
import Table, { ActionData, ActionHeader, ShowMoreItens } from "common/Table"
import LoadingIndicator from "common/LoadingIndicator"
import { GetActivatedSorted } from "../Sort"
import SortLabel from "../SortLabel"
import Filter, { GetActivatedFilter } from "../Filter"
import { format } from "date-fns"
import { withRouter } from "react-router-dom"
import {
  usePermissionRequestLast,
  usePermissionRequestLoading,
  usePermissionsRequestFind,
} from "store/hooks/permission-request"
import {getStatusLabel, SELECT_OPTIONS} from "../../utils/get-status";
import Text from "../Text";
import {isEmpty} from "ramda";
import PaperStyled from "../PaperStyled";
import {StorageUtils} from "../../utils/StorageUtils";

const PermissionRequestManagementTableList = props => {
  const loading = usePermissionRequestLoading()
  const last = usePermissionRequestLast()
  const loadPermissions = usePermissionsRequestFind()

  const formatPermissions = perms => perms.map(perm => perm.name).join(", ")
  const goToProfile = id => {
    props.history.push(`/perfil/${id}`)
  }

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>
              <label>Nome</label>
            </th>
            <th>
              <label>Autorização</label>
            </th>
            <th
              style={{
                backgroundColor: GetActivatedSorted("createdAt", "credential")
                  ? "rgb(233,233,233)"
                  : "white",
              }}
            >
              <SortLabel
                props={props}
                request={loadPermissions}
                name="createdAt"
                label="Criado em:"
                screen="permissionRequestManagement"
              />
              <Filter
                applied={GetActivatedFilter("createdAt", "permissionRequestManagement")}
                screen="permissionRequestManagement"
                name="createdAt"
                setCurrentPage={props.setCurrentPage}
                filterFn={loadPermissions}
                defaultValue={StorageUtils.getDefaultStorageValue("permissionRequestManagement", "createdAt")}
                filterType="dateFilter"
              />
            </th>
            <th
              style={{
                backgroundColor: GetActivatedSorted("updatedAt", "permissionRequestManagement")
                  ? "rgb(233,233,233)"
                  : "white",
              }}
            >
              <SortLabel
                props={props}
                request={loadPermissions}
                name="updatedAt"
                label="Alterado em:"
                screen="permissionRequestManagement"
              />
              <Filter
                applied={GetActivatedFilter("updatedAt", "permissionRequestManagement")}
                screen="permissionRequestManagement"
                name="updatedAt"
                setCurrentPage={props.setCurrentPage}
                filterFn={loadPermissions}
                defaultValue={StorageUtils.getDefaultStorageValue("permissionRequestManagement", "updatedAt")}
                filterType="dateFilter"
              />
            </th>
            <th
              style={{
                backgroundColor: GetActivatedSorted("status", "credential")
                  ? "rgb(233,233,233)"
                  : "white",
              }}
            >
              <SortLabel
                props={props}
                request={loadPermissions}
                name="status"
                label="Situação:"
                screen="permissionRequestManagement"
              />
              <Filter
                applied={GetActivatedFilter("status", "permissionRequestManagement")}
                screen="permissionRequestManagement"
                name="status"
                setCurrentPage={props.setCurrentPage}
                filterFn={loadPermissions}
                defaultValue={StorageUtils.getDefaultStorageValue("permissionRequestManagement", "status")}
                filterType="selectFilter"
                selectOptions={SELECT_OPTIONS}
              />
            </th>
            <ActionHeader>Ações</ActionHeader>
          </tr>
        </thead>
        {!isEmpty(props.items) ? (
          <tbody>
          {props.items.map((item, key) => {
            return (
              <tr key={key} data-testid="table-row">
                <td
                  onClick={() =>
                    item.person ? goToProfile(item.person.id) : " "
                  }
                >
                  {item.person ? item.person.name : " "}
                </td>
                <td>
                  {item.permissions ? formatPermissions(item.permissions) : ""}
                </td>
                <td>{format(item.createdAt, "DD/MM/YYYY hh:mm")}</td>
                <td>{format(item.updatedAt, "DD/MM/YYYY hh:mm")}</td>
                <td>{getStatusLabel(item.status)}</td>
                <ActionData>{props.renderActions(item)}</ActionData>
              </tr>
            )
          })}
        </tbody>
        ) : (
        <PaperStyled>
          <Text>Nenhum pedido de credencimento cadastrado.</Text>
        </PaperStyled>
        )}
      </Table>
      {loading && <LoadingIndicator loading={true} />}
      {!loading && !last && <ShowMoreItens handleClick={props.loadMore} />}
    </>
  )
}

export default withRouter(PermissionRequestManagementTableList)
