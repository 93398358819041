import * as Yup from "yup"
import { DateUtils } from "utils/DateUtils"
import api from "../../services/api"
import API from "lib/api"

let lastDocIdSearch = null
let lastRegistrationIdSearch = null

const handleValidateDocId = async (docId, personId) => {
  const docInUse = await api
    .get(`${API.PERSON}/persons/docIds/${docId}`)
    .then(r => personId !== r.data.id)
    .catch(() => false)
  lastDocIdSearch = {
    value: docId,
    inUse: docInUse,
  }
  return docInUse
}

const handleValidateRegistrationId = async (registrationId, personId) => {
  const registrationIdInUse = await api
    .get(`${API.PERSON}/persons/registrationIds/${registrationId}`)
    .then(r => personId !== r.data.id)
    .catch(() => false)
  lastRegistrationIdSearch = {
    value: registrationId,
    inUse: registrationIdInUse,
  }
  return registrationIdInUse
}

export const ValidationSchema = Yup.lazy(values => {
  return Yup.object().shape({
    name: Yup.string()
      .nullable()
      .required("O campo Nome é obrigatório."),
    docId: Yup.string()
      .trim()
      .nullable()
      .required("O campo Documento é obrigatório.")
      .test(
        "docId",
        "O Número de Documento informado já está em uso.",
        async val => {
          if (val) {
            if (!lastDocIdSearch || lastDocIdSearch.value !== val) {
              const inUse = await handleValidateDocId(val, values.id)
              return !inUse
            }
            return !lastDocIdSearch.inUse
          }
          return false
        }
      ),
    registrationId: Yup.string()
      .trim()
      .nullable()
      .test(
        "registrationId",
        "A Matrícula informada já está em uso.",
        async val => {
          if (val) {
            if (
              !lastRegistrationIdSearch ||
              lastRegistrationIdSearch.value !== val
            ) {
              const inUse = await handleValidateRegistrationId(val, values.id)
              return !inUse
            }
            return !lastRegistrationIdSearch.inUse
          }
          return true
        }
      ),
    birthDate: Yup.string()
      .min(8, "A Data de Nascimento informada não é valida")
      .nullable()
      .test("birthDate", "A Data de Nascimento informada não é valida", val => {
        if (val && val !== "") {
          return DateUtils.validateStringBRDateLimitToday(val)
        }
        return true
      }),
  })
})
