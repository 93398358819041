import { InlineFlex, styled } from "reakit"

export const UserMenuHeader = styled(InlineFlex)`
  align-items: center;
  cursor: pointer;

  > span,
  > i {
    margin-left: 1.5rem;
  }
`

export const UserMenuContent = styled.ul`
  background: white;
  list-style: none;
  padding: 0;
  margin: 0;
`

export const UserMenuContentItem = styled.li`
  button {
    border-radius: 0;
    justify-content: flex-start;
  }
`
