import { call, put, select, takeLatest } from "redux-saga/effects"
import api from "../../services/api"
import API from "../../lib/api"
import {
    MAP_DATA_INITIAL_REQUEST,
    MAP_DATA_REQUEST,
    mapDataSuccessAction,
    mapUnpreparedDataSuccessAction,
    setMapCenterAction, useMapDataSucess,
} from "../ducks/map"

export function* getMapCompanyInitialValue() {
    try {
        const {user} = yield select(state => state.auth)
        const findMapDataApiCall = () => {
            return api
                .get(`${API.AUTH}/companies/${user.company.id}`)
                .then(response => {
                    return response.data
                })
                .catch(err => {
                    throw err
                })
        }
        const {latitude: lat, longitude: lng} = yield call(findMapDataApiCall)
        if (lat && lng) {
            yield put(setMapCenterAction({lat, lng}))
        }
    } catch (err) {
        console.error(err)
    }
}

export function* getMapData(searchElement, fromIndex) {
    try {
        const findMapDataApiCall = () => {
            return api
                .get(`${API.PERSON}/policies/violations/summary`)
                .then(response => {
                    return response.data
                })
                .catch(err => {
                    throw err
                })
        }
        const response = yield call(findMapDataApiCall)
        // const response = [
        //   {
        //     policy: {
        //       id: "1",
        //       name: "teste",
        //       latitude: -27.57036,
        //       longitude: -48.508442,
        //     },
        //     total: 1,
        //     totalProblems: 0,
        //     totalWarning: 1,
        //     totalApproved: 0,
        //   },
        //   {
        //     policy: {
        //       id: "2",
        //       name: "teste 2",
        //       latitude: -27.57125,
        //       longitude: -48.508327,
        //     },
        //     total: 10,
        //     totalProblems: 6,
        //     totalWarning: 1,
        //     totalApproved: 3,
        //   },
        //   {
        //     policy: {
        //       id: "3",
        //       name: "teste 3",
        //       latitude: -27.57425,
        //       longitude: -48.508447,
        //     },
        //     total: 3,
        //     totalProblems: 0,
        //     totalWarning: 0,
        //     totalApproved: 3,
        //   },
        // ]
        let totalPerson = 0
        let preparedMapData = []
        response.forEach(r => {
            if (r.total) {
                totalPerson += r.total
            }
            if (r.totalProblems) {
                preparedMapData.push({
                    id: r.policy.id,
                    name: r.policy.name,
                    lat: r.policy.latitude,
                    long: r.policy.longitude,
                    type: 0,
                    value: r.totalProblems,
                    totalProblems: r.totalProblems,
                    totalWarning: r.totalWarning,
                    totalApproved: r.totalApproved,
                })
            }
            if (r.totalWarning) {
                preparedMapData.push({
                    id: r.policy.id,
                    name: r.policy.name,
                    lat: r.policy.latitude,
                    long: r.policy.longitude,
                    type: 1,
                    value: r.totalWarning,
                    totalProblems: r.totalProblems,
                    totalWarning: r.totalWarning,
                    totalApproved: r.totalApproved,
                })
            }
            if (r.totalApproved) {
                preparedMapData.push({
                    id: r.policy.id,
                    name: r.policy.name,
                    lat: r.policy.latitude,
                    long: r.policy.longitude,
                    type: 2,
                    value: r.totalApproved,
                    totalProblems: r.totalProblems,
                    totalWarning: r.totalWarning,
                    totalApproved: r.totalApproved,
                })
            }
            let duplicatedResults = preparedMapData.filter(
                p => p.lat === r.policy.latitude && p.long === r.policy.longitude
            )
            if (duplicatedResults.length > 1) {
                duplicatedResults = duplicatedResults.map(dr => {
                    return {
                        id: dr.id,
                        lat: dr.lat,
                        long: dr.long,
                        type: dr.type,
                        value: dr.value,
                        totalProblems: dr.totalProblems,
                        totalWarning: dr.totalWarning,
                        totalApproved: dr.totalApproved,
                    }
                })
                preparedMapData = preparedMapData.filter(
                    p => p.lat !== r.policy.latitude && p.long !== r.policy.longitude
                )
                preparedMapData = [...preparedMapData, ...duplicatedResults].sort(
                    (a, b) => (a.value < b.value ? 1 : -1)
                )
            }
        })
        yield put(useMapDataSucess(totalPerson))
        yield put(mapUnpreparedDataSuccessAction(response))
        yield put(mapDataSuccessAction(preparedMapData))
    } catch (err) {
        console.log(err)
        // yield put(authFailedAction(err));
    }
}

export default [
    takeLatest(MAP_DATA_REQUEST, getMapData),
    takeLatest(MAP_DATA_INITIAL_REQUEST, getMapCompanyInitialValue),
]
