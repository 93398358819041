import React, { useEffect } from "react"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import {
  useCredential,
  useCredentialReset,
  useFindCredentialRequestHandler,
  useUpdateCredentialHandlers,
} from "../../../store/hooks/credential"
import { withRouter } from "react-router-dom"
import CredentialForm from "../CredentialForm"

const CredentialEdit = props => {
  const updateCredentialRequest = useUpdateCredentialHandlers()
  const findCredential = useFindCredentialRequestHandler()
  const credential = useCredential()
  const credentialReset = useCredentialReset()
  const { id } = props.match.params

  const onSubmit = values => {
    updateCredentialRequest(values)
  }

  useEffect(() => {
    findCredential(id)
  }, [findCredential, id])

  useEffect(() => {
    return () => credentialReset()
  }, [])

  return (
    <LayoutSidebarOverlay
      title="Editar credencial"
      afterClose={() => {
        props.history.push("/credenciais")
      }}
    >
      {({ closeSidebar }) => {
        return (
          <>
            {credential && (
              <CredentialForm
                closeSidebar={closeSidebar}
                onSubmit={onSubmit}
                initialValues={credential}
              />
            )}
          </>
        )
      }}
    </LayoutSidebarOverlay>
  )
}

export default withRouter(CredentialEdit)
