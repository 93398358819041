export const LOAD_BEACONS_REQUEST = "LOAD_BEACONS_REQUEST"
export const LOAD_BEACONS_SUCCESS = "LOAD_BEACONS_SUCCESS"
export const LOAD_BEACONS_ERROR = "LOAD_BEACONS_ERROR"
export const BEACONS_RESET = "BEACONS_RESET"

export const loadBeaconsRequest = (size, description) => ({
  type: LOAD_BEACONS_REQUEST,
  payload: {
    size,
    description,
  },
})

export const loadBeaconsSuccess = beacons => ({
  type: LOAD_BEACONS_SUCCESS,
  payload: {
    beacons,
  },
})

export const loadBeaconsError = error => ({
  type: LOAD_BEACONS_ERROR,
  payload: {
    error,
  },
})

export const resetBeacons = () => ({
  type: BEACONS_RESET,
})

const INITIAL_STATE = {
  error: null,
  loading: false,
  beacons: [],
  beacon: null,
}

const beaconReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_BEACONS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case LOAD_BEACONS_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        error,
        loading: false,
      }
    }
    case LOAD_BEACONS_SUCCESS: {
      const { beacons } = action.payload
      return {
        ...state,
        beacons,
        loading: false,
      }
    }
    case BEACONS_RESET: {
      return {
        ...state,
        beacons: [],
        beacon: null,
        loading: false,
      }
    }
    default:
      return state
  }
}

export default beaconReducer
