import styled from "styled-components"

export const DataContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 15px 5px;
  background: white;
  justify-content: space-around;
  color: rgba(0, 0, 0, 0.54);
`
export const DataCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const DataTitle = styled.label`
  font-size: medium;
  font-family: Roboto;
`

export const DataValue = styled.label`
  margin-top: 5x;
  font-family: Roboto;
  font-size: large;
`
