import React, {useState, useEffect} from "react"
import {Map, Marker, TileLayer} from "react-leaflet"
import "../../Maps/styles.css"
import L from "leaflet"
import MapMark from "../../../common/MapMark";
import {useMarkDetail, useMonitoring, useMonitoringUnpreparedData} from "../../../store/hooks/map";
import Leaflet from "leaflet";

delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
})

function MapContainerV2() {

  const mapData = useMonitoring()
  const mapUnpreparedData = useMonitoringUnpreparedData()
  const markDetail = useMarkDetail()

  const [latitude, setLatitude] = useState("")
  const [longitude, setLongitude] = useState("")

  useEffect(() => {
    getLocation()
  }, [mapData]);

  const getLocation = () => {
    mapData && mapData.map(x => {
      setLongitude(x.long)
      setLatitude(x.lat)
    })
  }

  const myCustomColour = ['#1f25ab', '#1e8dcc', '#dc0909', '#fde607', '#5109dc']

  const markerHtmlStyles = (i) => {
    return (
    `background-color: ${myCustomColour[i]};
    width: 3rem;
    height: 3rem;
    display: block;
    left: -1.5rem;
    top: -1.5rem;
    position: relative;
    border-radius: 2rem 2rem 0;
    transform: rotate(45deg);
    border: 1px solid #FFFFFF`
    )
  }

  const icon = (i) => Leaflet.divIcon({
    className: "my-custom-pin",
    iconAnchor: [0, 24],
    labelAnchor: [-6, 0],
    popupAnchor: [0, -36],
    html: `<span style="${markerHtmlStyles(i)}" />`
  })

  const position = [latitude, longitude]
  return (
    <>
      {longitude &&
        <Map center={position} zoom={14}
             zoomControl={false}
        >
          <TileLayer
            url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
            subdomains={['mt1', 'mt2', 'mt3']}
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'

          />
          {mapUnpreparedData &&
            mapUnpreparedData
            .filter(i => (markDetail ? i.policy.id === markDetail : true))
            .map((m, i) => (
              <div>
                <MapMark
                  mark={m}
                />
                <Marker

                  icon={icon(i)}
                  position={[m.policy.latitude, m.policy.longitude]}/>
              </div>
            ))}
        </Map>
      }
    </>
  )
}
export default MapContainerV2
