import React, { useState } from "react"
import Text from "common/Text"
import Divider from "common/Divider"

const ProcedureDestroyInfo = props => {
  const [procedure] = useState(props.procedure)
  return (
    <>
      {procedure && (
        <>
          <Text palette="primary" variant="h4" align="left" margin="big" bold>
            {procedure.name}
          </Text>
          <Text
            palette="grayscale"
            variant="body2"
            align="center"
            margin="medium"
          >
            {procedure.id}
          </Text>
          <Divider palette="primary" />
        </>
      )}
    </>
  )
}

export default ProcedureDestroyInfo
