import React, { useEffect } from "react"
import { isNil } from "ramda"
import { Box, Flex, styled } from "reakit"
import { theme } from "styled-tools"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import Text from "common/Text"
import Button from "common/Button"
import LoadingIndicator from "common/LoadingIndicator"
import {
  useFindCredentialRequestHandler,
  useCredentialsLoading,
  useCredential,
} from "../../../store/hooks/credential"
import { withRouter } from "react-router-dom"

const BlockListItem = styled(Box)`
  padding-bottom: ${theme("spacing.large")};
  padding-top: ${theme("spacing.large")};
`

const CredentialDetails = props => {
  const loading = useCredentialsLoading()
  const { id } = props.match.params
  const findCredential = useFindCredentialRequestHandler()
  const credential = useCredential()

  useEffect(() => {
    if (id) {
      findCredential(id)
    }
  }, [findCredential, id])

  return (
    <LayoutSidebarOverlay
      title="Credencial"
      subtitle={credential ? credential.id : ""}
      afterClose={() => {
        props.history.push("/credenciais")
      }}
    >
      {({ closeSidebar }) => {
        return (
          <LoadingIndicator loading={loading}>
            {isNil(credential) ? null : (
              <Flex column maxWidth="33rem" flexGrow={1}>
                <BlockListItem>
                  <Text margin="extralarge" palette="primary" variant="h6">
                    NOME: {credential.name}
                  </Text>
                  <Text margin="extralarge" palette="primary" variant="h6">
                    VIGÊNCIA(DIAS): {credential.validityInDays}
                  </Text>
                </BlockListItem>
              </Flex>
            )}
            <LayoutSidebarOverlay.Footer>
              <Button type="button" onClick={closeSidebar}>
                Ok
              </Button>
            </LayoutSidebarOverlay.Footer>
          </LoadingIndicator>
        )
      }}
    </LayoutSidebarOverlay>
  )
}

export default withRouter(CredentialDetails)
