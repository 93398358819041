import { call, put, select, takeLatest } from "redux-saga/effects"
import api, { handleParamsApi } from "../../services/api"
import API from "../../lib/api"
import {
  FIND_PROFILES_REQUEST,
  FIND_USER_REQUEST,
  FIND_USERS_REQUEST,
  findProfilesSuccessAction,
  findUsersSuccessAction,
  findUserSuccessAction,
  USER_CREATE_REQUEST,
  USER_DELETE_REQUEST,
  USER_UPDATE_REQUEST,
  userCreateSuccessAction,
  userDeleteSuccessAction,
  userUpdateSuccessAction,
} from "../ducks/user"
import { concat } from "ramda"

export function* findProfiles() {
  try {
    const findProfilesApiCall = () => {
      return api
        .get(`${API.AUTH}/profiles`, {
          params: {
            paged: false,
          },
        })
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    const { content } = yield call(findProfilesApiCall)
    yield put(findProfilesSuccessAction(content))
  } catch (err) {
    console.log(err)
    // yield put(authFailedAction(err));
  }
}

export function* findUsers(action) {
  try {
    const sort = JSON.parse(localStorage.getItem("sort"))
    const filter = JSON.parse(localStorage.getItem("filter"))
    const { page } = action.payload
    const findUsersApiCall = () => {
      let params
      params = {
        page,
        size: 10,
        sort: sort && sort.user ? sort.user.sortString : "createdAt,desc",
        ...handleParamsApi(filter && filter.user),
      }
      return api
        .get(`${API.AUTH}/users`, {
          params,
        })
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    const { content, totalPage, first, last } = yield call(findUsersApiCall)
    if (!first) {
      const { users } = yield select(state => state.user)
      yield put(
        findUsersSuccessAction(concat(users, content || []), totalPage, last)
      )
    } else {
      yield put(findUsersSuccessAction(content, totalPage, last))
    }
  } catch (err) {
    console.log(err)
    // yield put(authFailedAction(err));
  }
}

export function* findUser(action) {
  try {
    const { id } = action.payload
    const findUserApiCall = () => {
      return api
        .get(`${API.AUTH}/users/${id}`)
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    const response = yield call(findUserApiCall)
    response.confirmPassword = ""
    response.profiles = response.profiles.map(p => p.id)
    yield put(findUserSuccessAction(response))
  } catch (err) {
    console.log(err)
    // yield put(authFailedAction(err));
  }
}

export function* deleteUser(action) {
  try {
    const { id } = action.payload
    const deleteUserApiCall = () => {
      return api
        .delete(`${API.AUTH}/users/${id}`)
        .then(response => {
          console.log(response)
        })
        .catch(err => {
          console.log(err)
          throw err
        })
    }
    yield call(deleteUserApiCall)
    yield put(userDeleteSuccessAction())
  } catch (err) {
    // console.log(err)
  }
}

export function* createUser(action) {
  try {
    const { user } = action.payload
    let profiles = []
    user.profiles.forEach(p => profiles.push({ id: p }))
    user.profiles = profiles
    const createUserApiCall = () => {
      return api
        .post(`${API.AUTH}/users`, user)
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    const updateUserProfilesApiCall = param => {
      const profiles = user.profiles.map(p => p.id)
      return api
        .put(`${API.AUTH}/users/${param.id}/profiles`, profiles)
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    const response = yield call(createUserApiCall)
    yield call(updateUserProfilesApiCall, response)
    yield put(userCreateSuccessAction())
  } catch (err) {
    console.log(err)
  }
}

export function* updateUser(action) {
  try {
    const { user } = action.payload
    let profiles = []
    user.profiles.forEach(p => profiles.push({ id: p }))
    user.profiles = profiles
    const updateUserApiCall = () => {
      return api
        .put(`${API.AUTH}/users/${user.id}`, user)
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    const updateUserProfilesApiCall2 = param => {
      const profiles = user.profiles.map(p => p.id)
      return api
        .put(`${API.AUTH}/users/${param.id}/profiles`, profiles)
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    const response = yield call(updateUserApiCall)
    yield call(updateUserProfilesApiCall2, response)

    yield put(userUpdateSuccessAction())
  } catch (err) {
    console.log(err)
  }
}

export default [
  takeLatest(FIND_PROFILES_REQUEST, findProfiles),
  takeLatest(FIND_USERS_REQUEST, findUsers),
  takeLatest(FIND_USER_REQUEST, findUser),
  takeLatest(USER_UPDATE_REQUEST, updateUser),
  takeLatest(USER_DELETE_REQUEST, deleteUser),
  takeLatest(USER_CREATE_REQUEST, createUser),
]
