import React, { useState, useEffect } from "react"
import classnames from "classnames"
import {
  startOfToday,
  endOfToday,
  startOfYesterday,
  endOfYesterday,
  isToday,
  isYesterday,
} from "date-fns"
import Chip from "common/Chip"

export const initialDateFilterValues = {
  start: startOfToday().toISOString(),
  end: endOfToday().toISOString(),
}

const DateFilter = props => {
  const [dates, setDates] = useState(initialDateFilterValues)

  const onDateChange = day => () => {
    if (day === "yesterday") {
      setDates({
        start: startOfYesterday().toISOString(),
        end: endOfYesterday().toISOString(),
      })

      return
    }

    setDates(initialDateFilterValues)
  }

  useEffect(() => {
    if (props.onChangeDate) {
      props.onChangeDate(dates)
    }
  }, [dates])

  return (
    <>
      <Chip
        uppercase
        onClick={onDateChange("yesterday")}
        className={classnames({
          active: isYesterday(dates.start),
        })}
      >
        Ontem
      </Chip>
      <Chip
        uppercase
        onClick={onDateChange("today")}
        className={classnames({
          active: isToday(dates.start),
        })}
      >
        Hoje
      </Chip>
    </>
  )
}

export default DateFilter
