import { call, put, select, takeLatest } from "redux-saga/effects"
import api from "../../services/api"
import API from "../../lib/api"
import {
  FIND_NODES_REQUEST,
  FIND_POLICIES_REQUEST,
  FIND_POLICIES_UNPAGED_REQUEST,
  FIND_POLICY_REQUEST,
  findNodesSuccessAction,
  findPoliciesSuccessAction,
  findPoliciesUnpagedSuccessAction,
  findPolicySuccessAction,
  POLICY_CREATE_REQUEST,
  POLICY_DELETE_REQUEST,
  POLICY_UPDATE_REQUEST,
  POLICY_UPDATE_STATUS_REQUEST,
  policyCreateSuccessAction,
  policyDeleteSuccessAction,
  policyResetAction,
  policyUpdateRequestAction,
  policyUpdateSuccessAction,
} from "../ducks/policy"
import { concat, isEmpty } from "ramda"
import { showErrorToast } from "../../utils/toast"

export function* findNodes() {
  try {
    const findNodesApiCall = () => {
      return api
        .get(`${API.CLOUD}/nodes`, {
          params: {
            size: 1000,
            sort: "friendlyName,asc",
          },
        })
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    const { content, totalPage } = yield call(findNodesApiCall)
    yield put(findNodesSuccessAction(content, totalPage))
  } catch (err) {
    console.log(err)
    // yield put(authFailedAction(err));
  }
}

export function* findPoliciesUnpaged() {
  try {
    const findPoliciesUnpagedApiCall = () => {
      let params
      params = {
        size: 999,
        sort: "name,asc",
      }
      return api
        .get(`${API.BLEZONE}/policies`, {
          params,
        })
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    const { content } = yield call(findPoliciesUnpagedApiCall)
    yield put(findPoliciesUnpagedSuccessAction(content))
  } catch (err) {
    console.log(err)
    // yield put(authFailedAction(err));
  }
}

export function* findPolicies(action) {
  try {
    const { page } = action.payload
    const filter = JSON.parse(localStorage.getItem("filter"))
    const sort = JSON.parse(localStorage.getItem("sort"))
    const emptyFilter = filter ? !isEmpty(filter.filter) : false
    const findPoliciesApiCall = () => {
      let params
      params = {
        page,
        size: 10,
        sort: sort && sort.policy ? sort.policy.sortString : "createdAt,desc",
      }
      if (filter && filter.policy) {
        filter.policy.forEach(f => {
          params[f.field] = f.value
        })
      }
      return api
        .get(`${API.BLEZONE}/policies`, {
          params,
        })
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    const { policies } = yield select(state => state.policy)
    const { content, totalPage, first, last } = yield call(findPoliciesApiCall)
    if (!first) {
      yield put(
        findPoliciesSuccessAction(
          concat(policies, content || []),
          totalPage,
          last,
          emptyFilter
        )
      )
    } else {
      yield put(
        findPoliciesSuccessAction(content, totalPage, last, emptyFilter)
      )
    }
  } catch (err) {
    console.log(err)
    // yield put(authFailedAction(err));
  }
}

export function* findPolicy(action) {
  try {
    const { id } = action.payload
    const findPolicyApiCall = () => {
      return api
        .get(`${API.BLEZONE}/policies/${id}`)
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    const findPolicyPermissionApiCall = () => {
      return api
        .get(`${API.PERSON}/policies/${id}/policy-permissions`)
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    const response = yield call(findPolicyApiCall)
    let policyPerm = yield call(findPolicyPermissionApiCall)
    response.permissions = []
    if (policyPerm) {
      policyPerm.permissions.forEach(p => response.permissions.push(p.id))
      response.policyPermissionId = policyPerm.id
      response.policyPermissionVersion = policyPerm.version
    }
    yield put(findPolicySuccessAction(response))
  } catch (err) {
    console.log(err)
    // yield put(authFailedAction(err));
  }
}

export function* deletePolicy(action) {
  try {
    const { id } = action.payload
    const deletePolicyApiCall = () => {
      return api
        .delete(`${API.BLEZONE}/policies/${id}`)
        .then(response => {
          return
        })
        .catch(err => {
          throw err
        })
    }
    yield call(deletePolicyApiCall)
    yield put(policyDeleteSuccessAction())
  } catch (err) {
    console.log(err)
  }
}

export function* createPolicy(action) {
  try {
    const { policy } = action.payload
    const createPolicyApiCall = () => {
      return api
        .post(`${API.BLEZONE}/policies`, policy)
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    const createPolicyPermissionApiCall = response => {
      const permissions = []
      policy.permissions.forEach(p => permissions.push({ id: p }))
      const requestObject = {
        id: response.id,
        permissions,
      }
      return api
        .post(`${API.PERSON}/policy-permissions`, requestObject)
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    const response = yield call(createPolicyApiCall)
    // yield call(createPolicyPermissionApiCall, response)
    yield put(policyCreateSuccessAction())
    yield put(policyResetAction())
    // yield put(redirectAction('/sistema/procedures'));
  } catch (err) {
    console.log(err)
  }
}

export function* updateStatusPolicy(action) {
  try {
    const { data } = action.payload
    const findPolicyApiCall = () => {
      return api
        .get(`${API.BLEZONE}/policies/${data.id}`)
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    const findPolicyPermissionApiCall = () => {
      return api
        .get(`${API.PERSON}/policies/${data.id}/policy-permissions`)
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    const response = yield call(findPolicyApiCall)
    let policyPerm = yield call(findPolicyPermissionApiCall)
    response.permissions = []
    if (policyPerm) {
      policyPerm.permissions.forEach(p => response.permissions.push(p.id))
      response.policyPermissionId = policyPerm.id
      response.policyPermissionVersion = policyPerm.version
    }
    yield put(policyUpdateRequestAction({ ...response, active: data.active }))
  } catch (err) {
    console.log(err)
    // yield put(authFailedAction(err));
  }
}

export function* updatePolicy(action) {
  try {
    const { policy } = action.payload
    const updatePolicyApiCall = () => {
      return api
        .put(`${API.BLEZONE}/policies/${policy.id}`, policy)
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    const updatePolicyPermissionApiCall = response => {
      let updatePolicyPermission = []
      policy.permissions.forEach(p => updatePolicyPermission.push({ id: p }))
      // updatePolicyPermission.version = policy.policyPermissionVersion
      return api
        .put(
          `${API.PERSON}/policies/${policy.id}/permissions`,
          updatePolicyPermission
        )
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    const response = yield call(updatePolicyApiCall)
    yield call(updatePolicyPermissionApiCall, response)
    yield put(policyUpdateSuccessAction())
    yield put(policyResetAction())
  } catch (err) {
    if (err.data && err.data.message && /trava/.test(err.data.message)) {
      showErrorToast(err.data.message)
    }
    console.log(err)
  }
}

export default [
  takeLatest(FIND_NODES_REQUEST, findNodes),
  takeLatest(FIND_POLICIES_REQUEST, findPolicies),
  takeLatest(FIND_POLICIES_UNPAGED_REQUEST, findPoliciesUnpaged),
  takeLatest(FIND_POLICY_REQUEST, findPolicy),
  takeLatest(POLICY_UPDATE_REQUEST, updatePolicy),
  takeLatest(POLICY_UPDATE_STATUS_REQUEST, updateStatusPolicy),
  takeLatest(POLICY_DELETE_REQUEST, deletePolicy),
  takeLatest(POLICY_CREATE_REQUEST, createPolicy),
]
