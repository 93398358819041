import React, { useEffect } from "react"
import Flex from "../../../common/Flex"
import { Metrics } from "../../../configs"
import TextPattern from "../../../common/TextPattern"
import {
  useHeadcount,
  useHeadcountFindDetailHandler,
} from "store/hooks/headcount"
import profileImg from "../../../assets/images/profile-ph.png"
import { PersonBlock, StatusChip } from "../../Headcount/styles"
import Bold from "../../../common/Bold"
import Dashboard from "../../Headcount/Dashboard"
import Text from "../../../common/Text"

const ExternalHeadcountDetail = (props) => {
  console.log(props.match.params.name)
  const {policyId, datetime,name} = props.match.params;
  const findHeadcountDetail = useHeadcountFindDetailHandler()
  const headcount = useHeadcount()


  useEffect(() => findHeadcountDetail(policyId, datetime), [
    findHeadcountDetail,
    policyId,
    datetime,
  ])

  return (
    <Flex dir="column" padding={Metrics.spacingMinimun}>
      <Flex justifyContent="space-between">
        <Text>Histórico</Text>
        <Text>Turno 2/4</Text>
      </Flex>
      <Flex
        backgroundColor="#e2e2e2"
        dir="column"
        alignItems="center"
        justifyContent="space-between"
      >
        <TextPattern size={Metrics.fontSize.xxlg}>
          <Bold>{name ? name : ""}</Bold>
        </TextPattern>
        <div style={{width: '100%'}}>
          <Dashboard
          policyId={policyId}
          onMarkerClick={e => findHeadcountDetail(policyId, e.category)} />
        </div>
      </Flex>
      {headcount &&
        headcount.map((p, index) => (
          <PersonBlock key={index}>
            <Flex>
              <img
                src={profileImg}
                style={{
                  maxWidth: "40px",
                  background: "white",
                  borderRadius: "150px",
                }}
              />
              <Flex dir="column" marginLeft={Metrics.spacingMinimun}>
                <TextPattern textAlign="left" size={Metrics.fontSize.xxsm}>
                  {p.person.name}
                </TextPattern>
                <TextPattern textAlign="left" size={Metrics.fontSize.xxxsm}>
                  {p.person.role}
                </TextPattern>
              </Flex>
            </Flex>
            <Flex width="20%">
              <StatusChip alert={!p.present}>
                <TextPattern
                  textAlign="left"
                  color="#fff"
                  size={Metrics.fontSize.xxxsm}
                >
                  {p.present ? "Presente" : "Ausente"}
                </TextPattern>
              </StatusChip>
            </Flex>
          </PersonBlock>
        ))}
    </Flex>
  )
}

export default ExternalHeadcountDetail
