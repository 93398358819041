import React, { useEffect, useState } from "react"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import PersonForm from "common/PersonForm"
import { useCreatePerson, usePersonReset } from "store/hooks/person"

const PersonCreate = props => {
  const createPersonRequest = useCreatePerson()
  const personReset = usePersonReset()
  const [person] = useState({
    name: "",
    docId: "",
    birthDate: "",
    shift: "",
    permissionsId: [],
    managerId: "",
    type: "",
    registrationId: "",
  })

  useEffect(() => {
    return () => {
      personReset()
    }
  }, [])

  const handleSubmit = values => {
    createPersonRequest(values)
  }

  return (
    <LayoutSidebarOverlay
      title="Novo cadastro"
      afterClose={() => {
        props.history.push("/pessoa")
      }}
    >
      {({ closeSidebar }) => {
        return (
          <PersonForm
            closeSidebar={closeSidebar}
            onSubmit={handleSubmit}
            initialValues={person}
          />
        )
      }}
    </LayoutSidebarOverlay>
  )
}

export default PersonCreate
