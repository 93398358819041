import React from "react"
import logoByond from "assets/images/logo-byond.svg"
import { Container, Logo } from "./styles"

const LoginContainer = ({ children }) => {
  return (
    <Container>
      <Logo src={logoByond} />
      {children}
    </Container>
  )
}

export default LoginContainer
