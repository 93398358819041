import { styled } from "reakit"
import { ifElse } from "ramda"

export const ContainerSwitch = styled.div`
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  text-align: ${({ textAlign }) => (textAlign ? "left" : "right")};
  border-radius: ${({ borderRadius }) =>
    borderRadius === "round" ? "30px" : "5px"};
  border-color: ${({ borderColor }) => borderColor};
  position: relative;
  border-width: 1px;
  border-style: solid;
  padding: ${({ size }) => {
    if (size === "sm") {
      return "5px 10px"
    } else if (size === "md") {
      return "10px 15px"
    } else if (size === "lg") {
      return "15px 15px"
    }
  }};
  transition: all 1.5s ease;
  max-width: ${({ size }) => {
    if (size === "sm") {
      return "85px"
    } else if (size === "md") {
      return "100px"
    } else if (size === "lg") {
      return "125px"
    }
  }};
`

export const Toogle = styled.div`
  right: ${({ right }) => right};
  width: ${({ width }) => (width === "round" ? "35%" : "20%")};
  border-radius: ${({ borderRadius }) =>
    borderRadius === "round" ? "30px" : "5px"};
  top: 0;
  position: absolute;
  background-color: white;
  border-color: black;
  border-width: 1px;
  height: 100%;
  transition: all 0.5s ease;
  box-shadow: 3px 3px 10px #cacaca;
`

export const ContainerLabel = styled.label`
  text-transform: uppercase;
  font-size: small;
`
