import { styled } from "reakit"
import { NavLink } from "react-router-dom"

export const LinkStyled = styled(NavLink)`
  align-items: center;
  background-color: transparent;
  border-radius: 5px;
  color: #cfd0d1;
  display: flex;
  height: 70px;
  justify-content: center;
  width: 80px;
  text-decoration: none;
  transition: all 900ms ease;

  &:hover {
    color: #005369;
    transform: scale(1.1);
  }

  &.active {
    background-color: #005369;
    box-shadow: 0 5px 15px 0 #acb2c1;
    color: white;
    width: 95px;
    transform: scale(1.2);
  }
`

export const LinkExternalStyled = styled.a`
  align-items: center;
  background-color: transparent;
  border-radius: 5px;
  color: #cfd0d1;
  display: flex;
  height: 70px;
  justify-content: center;
  width: 80px;
  text-decoration: none;
  transition: color 200ms;

  &:hover {
    color: #005369;
  }

  &.active {
    background-color: #005369;
    box-shadow: 0 5px 15px 0 #acb2c1;
    color: white;
    width: 95px;
  }
`
