export const FIND_PROFILES_REQUEST = "FIND_PROFILES_REQUEST"
export const FIND_PROFILES_SUCCESS = "FIND_PROFILES_SUCCESS"
export const FIND_USERS_REQUEST = "FIND_USERS_REQUEST"
export const FIND_USERS_SUCCESS = "FIND_USERS_SUCCESS"
export const FIND_USERS_ERROR = "FIND_USERS_ERROR"
export const FIND_USER_REQUEST = "FIND_USER_REQUEST"
export const FIND_USER_SUCCESS = "FIND_USER_SUCCESS"
export const FIND_NODES_REQUEST = "FIND_NODES_REQUEST"
export const FIND_NODES_SUCCESS = "FIND_NODES_SUCCESS"
export const FIND_USER_ERROR = "FIND_USER_ERROR"
export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST"
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS"
export const USER_UPDATE_ERROR = "USER_UPDATE_ERROR"
export const USER_CREATE_REQUEST = "USER_CREATE_REQUEST"
export const USER_CREATE_SUCCESS = "USER_CREATE_SUCCESS"
export const USER_CREATE_ERROR = "USER_CREATE_ERROR"
export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST"
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS"
export const USER_DELETE_ERROR = "USER_DELETE_ERROR"
export const USER_RESET = "USER_RESET"
export const USER_TOOGLE_ACTIVATE = "USER_TOOGLE_ACTIVATE"

export const findUsersRequestAction = page => ({
  type: FIND_USERS_REQUEST,
  payload: {
    page,
  },
})

export const findUsersSuccessAction = (users, totalPages, last) => ({
  type: FIND_USERS_SUCCESS,
  payload: {
    users,
    totalPages,
    last,
  },
})

export const findProfilesRequestAction = () => ({
  type: FIND_PROFILES_REQUEST,
})

export const findProfilesSuccessAction = profiles => ({
  type: FIND_PROFILES_SUCCESS,
  payload: {
    profiles,
  },
})

export const findNodesRequestAction = () => ({
  type: FIND_NODES_REQUEST,
})

export const findNodesSuccessAction = nodes => ({
  type: FIND_NODES_SUCCESS,
  payload: {
    nodes,
  },
})

export const findUsersErrorAction = error => ({
  type: FIND_USERS_ERROR,
  payload: {
    error,
  },
})

export const findUserRequestAction = id => ({
  type: FIND_USER_REQUEST,
  payload: {
    id,
  },
})

export const findUserSuccessAction = user => ({
  type: FIND_USER_SUCCESS,
  payload: {
    user,
  },
})

export const userCreateRequestAction = user => ({
  type: USER_CREATE_REQUEST,
  payload: {
    user,
  },
})

export const userCreateSuccessAction = () => ({
  type: USER_CREATE_SUCCESS,
})

export const userUpdateRequestAction = user => ({
  type: USER_UPDATE_REQUEST,
  payload: {
    user,
  },
})

export const userUpdateSuccessAction = () => ({
  type: USER_UPDATE_SUCCESS,
})

export const userDeleteRequestAction = id => ({
  type: USER_DELETE_REQUEST,
  payload: {
    id,
  },
})

export const userDeleteSuccessAction = () => ({
  type: USER_DELETE_SUCCESS,
})

export const userResetAction = () => ({
  type: USER_RESET,
})

const INITIAL_STATE = {
  error: null,
  loading: false,
  nodes: [],
  profiles: [],
  users: [],
  user: null,
  totalPages: 0,
  updateSuccess: false,
  createSuccess: false,
  deleteSuccess: false,
  nodesSuccess: false,
  last: true,
}

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FIND_PROFILES_REQUEST:
    case FIND_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FIND_USERS_SUCCESS:
      const { users, totalPages, last } = action.payload
      return {
        ...state,
        users,
        totalPages,
        loading: false,
        last,
      }
    case FIND_USERS_ERROR:
      const { error } = action.payload
      return {
        ...state,
        error,
        loading: false,
      }
    case FIND_USER_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FIND_USER_SUCCESS:
      const { user } = action.payload
      return {
        ...state,
        user,
        loading: false,
      }
    case USER_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        updateSuccess: false,
      }
    case USER_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        updateSuccess: true,
      }
    case FIND_PROFILES_SUCCESS:
      const { profiles } = action.payload
      return {
        ...state,
        loading: false,
        profiles,
      }
    case USER_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        createSuccess: false,
      }
    case USER_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        createSuccess: true,
      }
    case USER_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case USER_DELETE_SUCCESS:
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    case USER_RESET:
      return {
        ...state,
        loading: false,
        updateSuccess: false,
        createSuccess: false,
        deleteSuccess: false,
      }
    default:
      return state
  }
}

export default userReducer
