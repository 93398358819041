import React, { useEffect, useState } from "react"
import { Route, Switch } from "react-router-dom"
import { isEmpty } from "ramda"
import Layout from "common/Layout"
import PageToolbar, { PageToolbarContent } from "common/PageToolbar"
import Paper from "common/Paper"
import Text from "common/Text"
import Link from "common/Link"
import Button from "common/Button"
import UserCreate from "./UserCreate"
import {
  useFindUsersRequestHandler,
  useTotalPages,
  useUserCreateSuccess,
  useUserDeleteSuccess,
  useUsers, useUsersFiltering,
  useUsersLoading,
  useUserUpdateSuccess,
} from "../../store/hooks/user"
import UserEdit from "./UserEdit"
import PrivateRoute from "common/PrivateRoute"
import UserDestroy from "./UserDestroy"
import UserDetails from "./UserDetails"
import UserTableList from "../../common/UserTableList"
import IconButton from "../../common/IconButton"
import UserChangePassword from "./UserChangePassword"

const User = props => {
  const [currentPage, setCurrentPage] = useState(0)
  const findUsers = useFindUsersRequestHandler()
  const users = useUsers()
  const totalPages = useTotalPages()
  const loading = useUsersLoading()
  const updateSuccess = useUserUpdateSuccess()
  const deleteSuccess = useUserDeleteSuccess()
  const createSuccess = useUserCreateSuccess()

  const loadMore = () => {
    setCurrentPage(currentPage + 1)
  }

  useEffect(() => {
    findUsers(currentPage)
  }, [findUsers, currentPage, updateSuccess, deleteSuccess, createSuccess])

  return (
    <Layout>
      <PageToolbar title="Cadastro de usuários">
        <PageToolbarContent>
          <Link to={`${props.match.path}/new`} marginleft="auto">
            <Button bold palette="secondary">
              Novo Login
            </Button>
          </Link>
        </PageToolbarContent>
      </PageToolbar>
      {!isEmpty(users) ? (
        <Paper>
          <UserTableList
            setCurrentPage={setCurrentPage}
            items={users.filter(u => !u.deleted)}
            loadMore={loadMore}
            hasMore={totalPages}
            renderActions={item => (
              <>
                <Link to={`${props.match.path}/change-pwd/${item.id}`}>
                  <IconButton>lock_outline</IconButton>
                </Link>
                <Link to={`${props.match.path}/delete/${item.id}`}>
                  <IconButton>delete_outline</IconButton>
                </Link>
                <Link to={`${props.match.path}/edit/${item.id}`}>
                  <IconButton>create</IconButton>
                </Link>
                <Link to={`${props.match.path}/detail/${item.id}`}>
                  <IconButton>navigate_next</IconButton>
                </Link>
              </>
            )}
          />
        </Paper>
      ) : (
        <Paper>
          <Text>Nenhum login cadastrada.</Text>
        </Paper>
      )}
      <Switch>
        <PrivateRoute
          path={`${props.match.path}/new`}
          component={UserCreate}
        />
        <PrivateRoute
          path={`${props.match.path}/change-pwd/:id`}
          component={UserChangePassword}
        />
        <PrivateRoute
          path={`${props.match.path}/edit/:id`}
          component={UserEdit}
        />
        <Route
          path={`${props.match.path}/detail/:id`}
          component={UserDetails}
        />
        <Route
          path={`${props.match.path}/delete/:id`}
          component={UserDestroy}
        />
      </Switch>
    </Layout>
  )
}

export default User
