import { useDispatch, useSelector } from "react-redux"
import { useCallback } from "react"
import { checkpointResetAction, findCheckpointRequestAction, findCheckpointsRequestAction } from "../ducks/checkpoint"

export const useFindCheckpointsRequestHandler = () => {
  const dispatch = useDispatch()
  return useCallback(
    page => {
      dispatch(findCheckpointsRequestAction(page))
    },
    [dispatch]
  )
}

export const useCheckpointsLoading = () => {
  return useSelector(state => state.checkpoint.loading)
}

export const useCheckpoints = () => {
  return useSelector(state => state.checkpoint.checkpoints)
}

export const useTotalPages = () => {
  return useSelector(state => state.checkpoint.totalPages)
}

export const useCheckpointFilter = () => {
  return useSelector(state => state.checkpoint.filtering)
}

export const useCheckpointLast = () => {
  return useSelector(state => state.checkpoint.last)
}
