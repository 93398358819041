import React, {useEffect, useState} from "react"
import {Route, Switch} from "react-router-dom"
import {isEmpty} from "ramda"
import Layout from "common/Layout"
import PageToolbar, {PageToolbarContent} from "common/PageToolbar"
import Paper from "common/Paper"
import Text from "common/Text"
import Link from "common/Link"
import IconButton from "common/IconButton"
import Button from "common/Button"
import ProcedureCreate from "./ProcedureCreate"
import ProcedureTableList from "../../common/ProcedureTableList"
import {
  useFindProceduresRequestHandler,
  useProcedureCreateSuccess,
  useProcedureDeleteSuccess,
  useProcedures,
  useProceduresFiltering,
  useProceduresLoading,
  useProcedureUpdateSuccess,
  useTotalPages,
} from "../../store/hooks/procedure"
import ProcedureEdit from "./ProcedureEdit"
import PrivateRoute from "common/PrivateRoute"
import ProcedureDestroy from "./ProcedureDestroy"
import ProcedureDetails from "./ProcedureDetails"

const Procedure = props => {
  const [currentPage, setCurrentPage] = useState(0)
  const findProcedures = useFindProceduresRequestHandler()
  const procedures = useProcedures()
  const totalPages = useTotalPages()
  const filtering = useProceduresFiltering()
  const loading = useProceduresLoading()
  const updateSuccess = useProcedureUpdateSuccess()
  const deleteSuccess = useProcedureDeleteSuccess()
  const createSuccess = useProcedureCreateSuccess()

  const loadMore = () => {
    setCurrentPage(currentPage + 1)
  }

  useEffect(() => {
    findProcedures(currentPage)
  }, [findProcedures, currentPage, updateSuccess, deleteSuccess, createSuccess])

  return (
    <Layout>
      <PageToolbar title="Autorizações">
        <PageToolbarContent>
          <Link to={`${props.match.path}/new`} marginleft="auto">
            <Button bold palette="secondary">
              Nova Autorização
            </Button>
          </Link>
        </PageToolbarContent>
      </PageToolbar>
        <Paper>
          <ProcedureTableList
            proceduresFiltering={filtering}
            setCurrentPage={setCurrentPage}
            items={procedures}
            loadMore={loadMore}
            hasMore={totalPages}
            renderActions={item => (
              <div style={{display: "flex", justifyContent: "space-around"}}>
                <Link to={`${props.match.path}/delete/${item.id}`}>
                  <IconButton>delete_outline</IconButton>
                </Link>
                <Link to={`${props.match.path}/edit/${item.id}`}>
                  <IconButton>create</IconButton>
                </Link>
                <Link to={`${props.match.path}/detail/${item.id}`}>
                  <IconButton>navigate_next</IconButton>
                </Link>
              </div>
            )}
          />
        </Paper>
      <Switch>
        <PrivateRoute
          path={`${props.match.path}/new`}
          component={ProcedureCreate}
        />
        <PrivateRoute
          path={`${props.match.path}/edit/:id`}
          component={ProcedureEdit}
        />
        <Route
          path={`${props.match.path}/detail/:id`}
          component={ProcedureDetails}
        />
        <Route
          path={`${props.match.path}/delete/:id`}
          component={ProcedureDestroy}
        />
      </Switch>
    </Layout>
  )
}

export default Procedure
