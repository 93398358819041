import React, { useEffect } from "react"
import { isNil, pathOr } from "ramda"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import PermissionRequestForm from "common/PermissionRequestForm"
import LoadingIndicator from "common/LoadingIndicator"
import {
  usePermission,
  usePermissionFind,
  usePermissionLoading,
} from "store/hooks/permission"

const PermissionRequestCreate = props => {
  const { id } = props.match.params
  const loading = usePermissionLoading()
  const permission = usePermission()
  const findPermission = usePermissionFind()
  const subtitle = isNil(permission) ? "..." : permission.id

  useEffect(() => {
    if (id) {
      findPermission(id)
    }
  }, [findPermission, id])

  const onSubmit = values => {
    console.log("Edit credential: ", values)
  }

  const getFormattedData = (data = {}) => {
    const permissions = pathOr([], "permissions", data)
    const person = pathOr({}, "person", data)

    return {
      "credential-type": "credential-special",
      "special-work": permissions.map(permission => ({
        id: permission.id,
        label: permission.name,
      })),
      employees: [
        {
          id: person.id,
          label: person.name,
        },
      ],
    }
  }

  return (
    <LayoutSidebarOverlay
      title="Editando pedido"
      subtitle={subtitle}
      afterClose={() => {
        props.history.push("/pedidos-de-credenciais")
      }}
    >
      {({ closeSidebar }) => {
        return (
          <LoadingIndicator loading={loading}>
            <PermissionRequestForm
              closeSidebar={closeSidebar}
              onSubmit={onSubmit}
              initialValues={getFormattedData(permission)}
            />
          </LoadingIndicator>
        )
      }}
    </LayoutSidebarOverlay>
  )
}

export default PermissionRequestCreate
