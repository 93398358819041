import { call, takeLatest } from "redux-saga/effects"
import api from "../../services/api"
import API from "../../lib/api"
import {
  AREA_CREATE_REQUEST,
  AREA_DELETE_REQUEST,
  AREA_UPDATE_REQUEST,
  areaDeleteSuccessAction,
  areaUpdateSuccessAction,
  FIND_AREA_REQUEST,
  findAreaSuccessAction,
  findNodesSuccessAction,
  LIST_AREA_REQUEST,
  listAreaSuccessAction,
} from "../ducks/area"
import { put } from "@redux-saga/core/effects"
import { concat } from "ramda"
import { useAreas } from "../hooks/area"

export function* findNodes() {
  try {
    const findNodesApiCall = () => {
      return api
        .get(`${API.CLOUD}/nodes`, {
          params: {
            paged: false,
          },
        })
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    const { content, totalPage } = yield call(findNodesApiCall)
    yield put(findNodesSuccessAction(content, totalPage))
  } catch (err) {
    console.log(err)
    // yield put(authFailedAction(err));
  }
}

export function* listArea(action) {
  try {
    const { page } = action.payload
    const findPoliciesApiCall = () => {
      return api
        .get(`${API.BLEZONE}/areas`, {
          params: {
            page,
            size: 10,
            sort: "createdAt,desc",
          },
        })
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    const { content, totalPage, first } = yield call(findPoliciesApiCall)
    if (!first) {
      const areas = yield call(useAreas())
      yield put(listAreaSuccessAction(concat(areas, content || []), totalPage))
    } else {
      yield put(listAreaSuccessAction(content, totalPage))
    }
  } catch (err) {
    console.log(err)
    // yield put(authFailedAction(err));
  }
}

export function* findArea(action) {
  try {
    const { id } = action.payload
    const findAreaApiCall = () => {
      return api
        .get(`${API.BLEZONE}/areas/${id}`)
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    const response = yield call(findAreaApiCall)
    yield put(findAreaSuccessAction(response))
  } catch (err) {
    console.log(err)
    // yield put(authFailedAction(err));
  }
}

export function* deleteArea(action) {
  try {
    const { id } = action.payload
    const deleteAreaApiCall = () => {
      return api
        .delete(`${API.BLEZONE}/areas/${id}`)
        .then(response => {
          return
        })
        .catch(err => {
          throw err
        })
    }
    yield call(deleteAreaApiCall)
    yield put(areaDeleteSuccessAction())
  } catch (err) {
    console.log(err)
  }
}

export function* createArea(action) {
  try {
    const { area } = action.payload
    const createAreaApiCall = () => {
      return api
        .post(`${API.BLEZONE}/areas`, area)
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    yield call(createAreaApiCall)
    // yield put(redirectAction('/sistema/procedures'));
  } catch (err) {
    console.log(err)
  }
}

export function* updateArea(action) {
  try {
    const { area } = action.payload
    const updateAreaApiCall = () => {
      return api
        .put(`${API.BLEZONE}/areas/${area.body.id}`, area.body)
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    yield call(updateAreaApiCall)
    yield put(areaUpdateSuccessAction())
  } catch (err) {
    console.log(err)
  }
}

export default [
  takeLatest(LIST_AREA_REQUEST, listArea),
  takeLatest(FIND_AREA_REQUEST, findArea),
  takeLatest(AREA_UPDATE_REQUEST, updateArea),
  takeLatest(AREA_DELETE_REQUEST, deleteArea),
  takeLatest(AREA_CREATE_REQUEST, createArea),
]
