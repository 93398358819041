import React from "react"
import { format } from "date-fns"
import Table from "common/Table"

const AreaTableList = props => {
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Tecnologia</th>
            <th>Tipo</th>
            <th>Created</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {props.items ? (
            props.items.map((item, key) => {
              return (
                <tr key={key} data-testid="table-row">
                  <td>{item.name}</td>
                  <td>{item.scope}</td>
                  <td>{item.type}</td>
                  <td>{format(item.createdAt, "DD/MM/YYYY HH:mm")}</td>
                  <td>{props.renderActions(item)}</td>
                </tr>
              )
            })
          ) : (
            <tr></tr>
          )}
        </tbody>
      </Table>
    </>
  )
}

export default AreaTableList
