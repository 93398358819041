import React from "react"
import { isNil } from "ramda"
import { Avatar as ReakitAvatar, styled } from "reakit"
import personPlaceholder from "assets/images/person-placeholder.svg"

const AvatarStyled = styled(ReakitAvatar)`
  height: ${props => props.size};
  width: ${props => props.size};
`

const Avatar = props => {
  const source = isNil(props.src) ? personPlaceholder : props.src
  return <AvatarStyled {...props} src={source} />
}

export default Avatar
