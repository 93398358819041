import LoadingIndicator from "common/LoadingIndicator"
import Table, {ActionData, ActionHeader} from "common/Table"
import {format} from "date-fns"
import React, {useState} from "react"
import {
  useLoadingPerson,
  useLoadPersons,
  usePersonLast, usePersons,
} from "../../store/hooks/person"
import {ShowMoreItens} from "../Table/index"
import {withRouter} from "react-router-dom"
import {GetActivatedSorted} from "../Sort"
import SortLabel from "../SortLabel"
import Filter, {GetActivatedFilter} from "../Filter"
import CheckBoxFilter from "../CheckBoxFilter";
import {StorageUtils} from "../../utils/StorageUtils";

const PersonTableList = props => {
  const loadingPerson = useLoadingPerson()
  const personRequest = useLoadPersons()
  const last = usePersonLast()

  const persons = usePersons()

  const selectFilterFields = () => {
    return persons && persons.map((x) => ({value: x.name, label: x.name}))
  }
  const goToProfile = id => {
    props.history.push(`/perfil/${id}`)
  }
  const renderWorkBond = (item) => {
    switch (item.workBond) {
      case "EMPLOYEE":
        return "Funcionário"
      case "VISITOR":
        return "Visitante"
      case "OUTSOURCED":
        return "Terceirizado"
    }
  }
  const activeResult = (item) => {
    if (item.disabled === false) {
      return "Ativo"
    } else {
      return "Inativo"
    }
  }

  return (
    <>
      <Table>
        <thead>
        <tr>
          <th
            style={{
              backgroundColor: GetActivatedSorted("name", "person")
                ? "rgb(233,233,233)"
                : "white",
            }}
          >
            <SortLabel
              props={props}
              request={personRequest}
              name="name"
              label="Nome"
              screen="person"
            />
            <Filter
              applied={GetActivatedFilter("name", "person")}
              screen="person"
              name="name"
              setCurrentPage={props.setCurrentPage}
              filterFn={personRequest}
              selectOptions={selectFilterFields()}
              defaultValue={StorageUtils.getDefaultStorageValue("person", "name")}
              filterType="selectFilter"
            />
          </th>
          <th
            style={{
              backgroundColor: GetActivatedSorted("docId", "person")
                ? "rgb(233,233,233)"
                : "white",
            }}
          >
            <SortLabel
              props={props}
              request={personRequest}
              name="docId"
              label="Nº Doc"
              screen="person"
            />
            <Filter
              screen="person"
              applied={GetActivatedFilter("docId", "person")}
              name="docId"
              filterType="inputFilter"
              defaultValue={StorageUtils.getDefaultStorageValue("person", "docId")}
              setCurrentPage={props.setCurrentPage}
              filterFn={personRequest}
            />
          </th>
          <th
            style={{
              backgroundColor: GetActivatedSorted("createdAt", "person")
                ? "rgb(233,233,233)"
                : "white",
            }}
          >
            <SortLabel
              props={props}
              request={personRequest}
              name="createdAt"
              label="Data Criação"
              screen="person"
            />
          </th>
          <th
            style={{
              backgroundColor: GetActivatedSorted("updatedAt", "person")
                ? "rgb(233,233,233)"
                : "white",
            }}
          >
            <SortLabel
              props={props}
              request={personRequest}
              name="updatedAt"
              label="Alterado em"
              screen="person"
            />
          </th>
          <th
            style={{
              backgroundColor: GetActivatedSorted("workBond", "person")
                ? "rgb(233,233,233)"
                : "white",
            }}
          >
            <SortLabel
              props={props}
              request={personRequest}
              name="workBond"
              label="Vínculo"
              screen="person"
            />
          </th>
          <th>
            <CheckBoxFilter
              screen="person"
              applied={GetActivatedFilter("listAll", "person")}
              name="listAll"
              setCurrentPage={props.setCurrentPage}
              filterFn={personRequest}
            />
          </th>
          <ActionHeader>Ações</ActionHeader>
        </tr>
        </thead>
        <tbody>
        {props.items
          ? props.items.map((item, key) => {
            return (
              <tr
                key={key}
                style={{cursor: "pointer"}}
                data-testid="table-row"
              >
                <td onClick={() => goToProfile(item.id)}>{item.name}</td>
                <td onClick={() => goToProfile(item.id)}>{item.docId}</td>
                <td onClick={() => goToProfile(item.id)}>
                  {item.createdAt
                    ? format(item.createdAt, "DD/MM/YYYY H:mm")
                    : ""}
                </td>
                <td onClick={() => goToProfile(item.id)}>
                  {item.updatedAt
                    ? format(item.updatedAt, "DD/MM/YYYY H:mm")
                    : ""}
                </td>
                <td onClick={() => goToProfile(item.id)}>
                  {renderWorkBond(item)}
                </td>
                <td onClick={() => goToProfile(item.id)}>
                  {activeResult(item)}
                </td>
                <ActionData>{props.renderActions(item)}</ActionData>
              </tr>
            )
          })
          : ""}
        </tbody>
      </Table>
      {loadingPerson && <LoadingIndicator loading={true}/>}
      {!loadingPerson && !last && (
        <ShowMoreItens handleClick={props.loadMore}/>
      )}
    </>
  )
}

export default withRouter(PersonTableList)
