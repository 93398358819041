import React, { useEffect, useState } from "react"
import { Route, Switch } from "react-router-dom"
import { isEmpty } from "ramda"
import Layout from "common/Layout"
import PageToolbar, { PageToolbarContent } from "common/PageToolbar"
import Paper from "common/Paper"
import Text from "common/Text"
import Link from "common/Link"
import Button from "common/Button"
import LoadingIndicator from "common/LoadingIndicator"
import AreaCreate from "./AreaCreate"
import {
  useAreaCreateSuccess,
  useAreaDeleteSuccess,
  useAreas,
  useAreasLoading,
  useAreaUpdateSuccess,
  useListAreaRequestHandler,
  useTotalPages,
} from "../../store/hooks/area"
import AreaEdit from "./AreaEdit"
import AreaTableList from "../../common/AreaTableList"
import IconButton from "../../common/IconButton"
import PrivateRoute from "common/PrivateRoute"

const Area = props => {
  const [currentPage, setCurrentPage] = useState(0)
  const listArea = useListAreaRequestHandler()
  const areas = useAreas()
  const totalPages = useTotalPages()
  const loading = useAreasLoading()
  const updateSuccess = useAreaUpdateSuccess()
  const deleteSuccess = useAreaDeleteSuccess()
  const createSuccess = useAreaCreateSuccess()

  const loadMore = () => {
    setCurrentPage(currentPage + 1)
  }

  useEffect(() => {
    listArea(currentPage)
  }, [listArea, currentPage, updateSuccess, deleteSuccess, createSuccess])

  return (
    <Layout>
      <PageToolbar title="Áreas">
        <PageToolbarContent>
          <Link to={`${props.match.path}/new`} marginleft="auto">
            <Button bold palette="secondary">
              Nova área
            </Button>
          </Link>
        </PageToolbarContent>
      </PageToolbar>
      <LoadingIndicator loading={loading}>
        {!isEmpty(areas) ? (
          <Paper>
            <AreaTableList
              loadMore={loadMore}
              renderActions={item => (
                <h1>
                  <Link to={`${props.match.path}/delete/${item.id}`}>
                    <IconButton>delete_outline</IconButton>
                  </Link>
                  <Link to={`${props.match.path}/edit/${item.id}`}>
                    <IconButton>create</IconButton>
                  </Link>
                  <Link to={`${props.match.path}/detail/${item.id}`}>
                    <IconButton>navigate_next</IconButton>
                  </Link>
                </h1>
              )}
            />
          </Paper>
        ) : (
          <Paper>
            <Text>Nenhuma política cadastrada.</Text>
          </Paper>
        )}
      </LoadingIndicator>
      <Switch>
        <PrivateRoute path={`${props.match.path}/new`} component={AreaCreate} />
        <PrivateRoute
          path={`${props.match.path}/edit/:id`}
          component={AreaEdit}
        />
        {/*<Route*/}
        {/*  path={`${props.match.path}/detail/:id`}*/}
        {/*  component={AreaDetails}*/}
        {/*/>*/}
        {/*<Route*/}
        {/*  path={`${props.match.path}/delete/:id`}*/}
        {/*  component={AreaDestroy}*/}
        {/*/>*/}
      </Switch>
    </Layout>
  )
}

export default Area
