import React, { useState } from "react"
import { ContainerStyled } from "../index.styled"
import { ErrorMessage, useFormikContext } from "formik"
import SimpleSelectV2 from "../../../../common/SimpleSelectV2"
import InputText from "../../../../common/InputText"
import { ErrorContainer, ErrorLabel } from "../../../../common/Error"
import { FormSwitch } from "../../../../common/FormSwitch"

const StepPolicyData = ({ values }) => {
  const { handleChange } = useFormikContext()

  const [types] = useState([
    { id: "PRESENCE", label: "Presence" },
    { id: "IN_OUT", label: "In Out" },
    { id: "PROXIMITY", label: "Proximidade" },
  ])

  return (
    <ContainerStyled>
      <InputText
        value={values.name}
        name="name"
        id="name"
        label="NOME"
        onChange={handleChange}
      />
      <InputText
        value={values.description}
        name="description"
        id="description"
        label="DESCRIÇÃO"
        onChange={handleChange}
      />
      {values.type === "ENTERPRISE_PRESENCE" ? (
        ""
      ) : (
        <SimpleSelectV2
          propertyToShow="label"
          label="TIPO"
          list={types}
          valueProperty="id"
          name="type"
          id="type"
          value={values.type}
        />
      )}
      <InputText
        value={values.latitude}
        tooltip="Digite as coordenadas de latitude antes das coordenadas de longitude. Verifique se o primeiro número da coordenada de latitude está entre -90 e 90."
        name="latitude"
        inputType="number"
        id="latitude"
        label="LATITUDE"
        onChange={handleChange}
      />
      <InputText
        value={values.longitude}
        tooltip="Digite as coordenadas de longitude. Verifique se o primeiro número da coordenada de longitude está entre -180 e 180."
        name="longitude"
        inputType="number"
        id="longitude"
        label="LONGITUDE"
        onChange={handleChange}
      />
      {values.id ? (
        <FormSwitch
          onLabel={"ATIVO"}
          offLabel={"INATIVO"}
          value={values.active}
          name="active"
          type="round"
          onChange={handleChange}
        />
      ) : (
        ""
      )}

      <ErrorContainer>
        <ErrorMessage
          name="name"
          render={msg => <ErrorLabel>{msg}</ErrorLabel>}
        />
        <ErrorMessage
          name="description"
          render={msg => <ErrorLabel>{msg}</ErrorLabel>}
        />
        <ErrorMessage
          name="type"
          render={msg => <ErrorLabel>{msg}</ErrorLabel>}
        />
        <ErrorMessage
          name="latitude"
          render={msg => <ErrorLabel>{msg}</ErrorLabel>}
        />
        <ErrorMessage
          name="longitude"
          render={msg => <ErrorLabel>{msg}</ErrorLabel>}
        />
      </ErrorContainer>
    </ContainerStyled>
  )
}

export default StepPolicyData
