import { setToken } from "lib/token"

export const AUTH_REQUEST = "AUTH_REQUEST"
export const AUTH_FROM_TOKEN_REQUEST = "AUTH_FROM_TOKEN_REQUEST"
export const AUTH_SUCCESS = "AUTH_SUCCESS"
export const AUTH_FAILED = "AUTH_FAILED"
export const AUTH_LOGOUT = "AUTH_LOGOUT"
export const AUTH_LOGOUT_SUCCESS = "AUTH_LOGOUT_SUCCESS"
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST"
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS"
export const FORGOT_PASSWORD_FAILED = "FORGOT_PASSWORD_FAILED"
export const AUTH_LOGOUT_FAILED = "AUTH_LOGOUT_FAILED"
export const AUTH_SET_REHYDRATED = "AUTH_SET_REHYDRATED"
export const USER_SUCCESS = "USER_SUCCESS"
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST"
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED"
export const RESET_PASSWORD_REDIRECT = "RESET_PASSWORD_REDIRECT"
export const RESET_PASSWORD_VALIDATE_KEY = "RESET_PASSWORD_VALIDATE_KEY"
export const RESET_PASSWORD_VALID_KEY = "RESET_PASSWORD_VALID_KEY"
export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST"
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS"
export const UPDATE_PASSWORD_ERROR = "UPDATE_PASSWORD_ERROR"
export const UPDATE_PASSWORD_RESET = "UPDATE_PASSWORD_RESET"

export const authRequestAction = (email, password) => ({
  type: AUTH_REQUEST,
  payload: {
    email,
    password,
  },
})

export const authFromTokenRequestAction = (token) => ({
  type: AUTH_FROM_TOKEN_REQUEST,
  payload: {
    token
  },
})

export const authSuccessAction = token => ({
  type: AUTH_SUCCESS,
  payload: {
    token,
  },
})

export const authFailedAction = error => ({
  type: AUTH_FAILED,
  payload: {
    error,
  },
})

export const forgotPasswordRequestAction = email => ({
  type: FORGOT_PASSWORD_REQUEST,
  payload: {
    email,
  },
})

export const forgotPasswordSuccessAction = () => ({
  type: FORGOT_PASSWORD_SUCCESS,
})

export const forgotPasswordFailedAction = error => ({
  type: FORGOT_PASSWORD_FAILED,
  payload: {
    error,
  },
})

export const authLogoutAction = () => ({
  type: AUTH_LOGOUT,
})

export const authLogoutSuccessAction = () => ({
  type: AUTH_LOGOUT_SUCCESS,
})

export const authLogoutFailedAction = error => ({
  type: AUTH_LOGOUT_FAILED,
  payload: {
    error,
  },
})

export const authSetRehydrated = value => ({
  type: AUTH_SET_REHYDRATED,
  payload: {
    value,
  },
})

export const authUserSuccessAction = user => ({
  type: USER_SUCCESS,
  payload: {
    user,
  },
})

export const resetPasswordRequestAction = (newPassword, key) => ({
  type: RESET_PASSWORD_REQUEST,
  payload: {
    newPassword,
    key,
  },
})

export const resetPasswordRequestSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS,
})

export const resetPasswordFailedAction = error => ({
  type: RESET_PASSWORD_FAILED,
  payload: {
    error,
  },
})

export const resetPasswordRedirectAction = redirect => ({
  type: RESET_PASSWORD_REDIRECT,
  payload: {
    redirect,
  },
})

export const resetPasswordValidateKeyAction = key => ({
  type: RESET_PASSWORD_VALIDATE_KEY,
  payload: {
    key,
  },
})

export const resetPasswordValidKeyAction = () => ({
  type: RESET_PASSWORD_VALID_KEY,
})

export const changePasswordRequest = data => ({
  type: UPDATE_PASSWORD_REQUEST,
  payload: {
    data,
  },
})

export const changePasswordRequestSuccess = () => ({
  type: UPDATE_PASSWORD_SUCCESS,
})

export const changePasswordReset = () => ({
  type: UPDATE_PASSWORD_RESET,
})

export const changePasswordRequestError = error => ({
  type: UPDATE_PASSWORD_ERROR,
  payload: {
    error,
  },
})

const INITIAL_STATE = {
  authenticated: false,
  token: null,
  error: null,
  loading: false,
  changePasswordLoading: false,
  user: null,
  rehydrated: false,
  resetPasswordRedirect: false,
  passwordUpdated: false,
}

const authenticationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH_REQUEST:
    case AUTH_FROM_TOKEN_REQUEST:
      return {
        ...state,
        authenticated: false,
        loading: true,
      }
    case AUTH_SUCCESS:
      const { token } = action.payload
      return {
        ...state,
        token,
        authenticated: true,
        loading: false,
      }
    case AUTH_FAILED:
      const { error } = action.payload
      return {
        ...state,
        error,
        authenticated: false,
        token: null,
        loading: false,
      }
    case AUTH_LOGOUT: {
      return {
        ...state,
        loading: true,
      }
    }
    case AUTH_LOGOUT_SUCCESS: {
      setToken(null)
      return {
        ...state,
        loading: false,
        token: null,
        authenticated: false,
      }
    }
    case AUTH_LOGOUT_FAILED: {
      const { error } = action.payload
      return {
        ...state,
        loading: false,
        authenticated: false,
        token: null,
        error,
      }
    }
    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case FORGOT_PASSWORD_FAILED: {
      const { error } = action.payload
      return {
        ...state,
        error,
        loading: false,
      }
    }
    case AUTH_SET_REHYDRATED: {
      const { value } = action.payload
      return {
        ...state,
        rehydrated: value,
      }
    }
    case USER_SUCCESS: {
      const { user } = action.payload
      return {
        ...state,
        user,
      }
    }
    case RESET_PASSWORD_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }
    case RESET_PASSWORD_FAILED: {
      const { error } = action.payload
      return {
        ...state,
        error,
        loading: false,
      }
    }
    case RESET_PASSWORD_REDIRECT: {
      const { redirect } = action.payload
      return {
        ...state,
        resetPasswordRedirect: redirect,
        loading: false,
      }
    }
    case RESET_PASSWORD_VALIDATE_KEY: {
      return {
        ...state,
        loading: true,
      }
    }
    case RESET_PASSWORD_VALID_KEY: {
      return {
        ...state,
        loading: false,
      }
    }
    case UPDATE_PASSWORD_RESET: {
      return {
        ...state,
        changePasswordLoading: false,
        passwordUpdated: false,
      }
    }
    case UPDATE_PASSWORD_REQUEST: {
      return {
        ...state,
        changePasswordLoading: true,
        passwordUpdated: false,
      }
    }
    case UPDATE_PASSWORD_SUCCESS: {
      return {
        ...state,
        changePasswordLoading: false,
        passwordUpdated: true,
      }
    }
    case UPDATE_PASSWORD_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        changePasswordLoading: false,
        error,
      }
    }
    default:
      return state
  }
}

export default authenticationReducer
