import React, { useEffect, useState } from "react"
import { ContainerStyled } from "../index.styled"
import { useFormikContext } from "formik"
import { useListNodesHandlers, useNodes } from "../../../../store/hooks/policy"
import InputText from "../../../../common/InputText"
import FieldCheckboxV2 from "../../../../common/FieldCheckboxV2"
import MultiSelectCheckboxV2 from "../../../../common/MultiSelectCheckboxV2"
import {
  useFindProceduresRequestHandler,
  useProcedures,
} from "../../../../store/hooks/procedure"

const StepPolicyDetail = ({ values }) => {
  const { handleChange } = useFormikContext()
  const listNodesRequest = useListNodesHandlers()
  const proceduresRequest = useFindProceduresRequestHandler()
  const nodes = useNodes()
  const [checkinNodes, setCheckinNodes] = useState([])
  const [checkoutNodes, setCheckoutNodes] = useState([])
  const procedures = useProcedures()
  const sortByName = (a, b) => {
    return a.name.localeCompare(b.name)
  }
  const filterCheckinNode = value => {
    setCheckinNodes(nodes.filter(n => !value.map(v => v).includes(n.id)))
  }
  const filterCheckoutNode = value => {
    setCheckoutNodes(nodes.filter(n => !value.includes(n.id)))
  }

  useEffect(() => {
    values.checkInNodes = values.checkInNodes.map(n => (n.id ? n.id : n))
    values.checkOutNodes = values.checkOutNodes.map(n => (n.id ? n.id : n))
  }, [])

  useEffect(() => {
    listNodesRequest()
    proceduresRequest(null)
  }, [listNodesRequest])

  useEffect(() => {
    // setCheckinNodes(nodes)
    setCheckinNodes(
      nodes.filter(n => !values.checkOutNodes.map(v => v).includes(n.id))
    )

    // setCheckoutNodes(nodes)
    setCheckoutNodes(nodes.filter(n => !values.checkInNodes.includes(n.id)))
  }, [nodes])

  return (
    <>
      <ContainerStyled>
        {values.type === "IN_OUT" ||
        values.type === "PRESENCE" ||
        values.type === "PROXIMITY" ? (
          <MultiSelectCheckboxV2
            name="checkInNodes"
            id="checkInNodes"
            list={checkinNodes}
            valueProperty="id"
            propertyToShow="friendlyName"
            value={values.checkInNodes || []}
            label="NODES DE CHECK-IN"
            sideEffectChangeFn={filterCheckoutNode}
            uniqueProp
          />
        ) : (
          ""
        )}
        {values.type === "PROXIMITY" || values.type === "IN_OUT" ? (
          <InputText
            value={values.checkInRSSI}
            name="checkInRSSI"
            id="checkInRSSI"
            inputType="number"
            label="LIMITE DE SINAL"
            onChange={handleChange}
          />
        ) : (
          ""
        )}
        {values.type === "ENTERPRISE_PRESENCE" || values.type === "IN_OUT" ? (
          <>
            <MultiSelectCheckboxV2
              name="checkOutNodes"
              id="checkOutNodes"
              list={checkoutNodes}
              valueProperty="id"
              propertyToShow="friendlyName"
              value={values.checkOutNodes || []}
              label="NODES DE CHECK-OUT"
              sideEffectChangeFn={filterCheckinNode}
              uniqueProp
            />
            <InputText
              tooltip="Limpe automaticamente violações quando a intensidade do sinal é mais fraca do que…"
              value={values.checkOutRSSI}
              name="checkOutRSSI"
              id="checkOutRSSI"
              inputType="number"
              label="SINAL DE LIMPEZA"
              onChange={handleChange}
            />
          </>
        ) : (
          ""
        )}
        <FieldCheckboxV2
          value={values.isEnableIO || false}
          checked={values.isEnableIO}
          name="isEnableIO"
          id="isEnableIO"
          label="HABILITAR TRAVA"
          onChange={handleChange}
        />
        {values.isEnableIO && (
          <InputText
            tooltip="Valor mínimo do RSSI para considerar a liberação da trava."
            value={values.minRSSIToEnableIO}
            name="minRSSIToEnableIO"
            id="minRSSIToEnableIO"
            inputType="number"
            label="RSSI MÍNIMO PARA HABILITAR A TRAVA"
            onChange={handleChange}
          />
          )}
          <InputText
            tooltip="A quantidade de tempo de espera antes que uma violação seja limpa automaticamente após o limite ser ultrapassado."
            value={values.violationTTLInSeconds}
            name="violationTTLInSeconds"
            id="violationTTLInSeconds"
            inputType="number"
            label="TEMPO LIMITE DE LIMPEZA AUTOMÁTICA"
            onChange={handleChange}
          />
        <MultiSelectCheckboxV2
          name="permissions"
          id="permissions"
          list={procedures.sort(sortByName)}
          value={values.permissions || []}
          label="PERMISSÕES DE ACESSO"
          propertyToShow="name"
          valueProperty="id"
          uniqueProp
        />
      </ContainerStyled>
    </>
  )
}

export default StepPolicyDetail
