import React, { useState, useEffect } from "react"
import { Switch } from "react-router-dom"
import { isEmpty } from "ramda"
import Layout from "common/Layout"
import Paper from "common/Paper"
import Text from "common/Text"
import PageToolbar from "common/PageToolbar"
import IconButton from "common/IconButton"
import Link from "common/Link"
import PaperStyled from "common/PaperStyled"
import PrivateRoute from "common/PrivateRoute"
import CredentialExpiredList from "../../common/CredentialExpiredList";
import {useLoadCredentialsExpiredNotDeleted, useLoadingReport, useRenewCredentialError, useRenewCredentialSuccess, useReportExpiredData, useTotalPages} from "../../store/hooks/report";
import CredentialRenew from "./ExpiredCredentialsRenew";


const ExpiredCredentials = props => {
  const [currentPage, setCurrentPage] = useState(0)
  const loadingPersonCredential = useLoadingReport()

  const expiredData = useReportExpiredData()
  const totalPages = useTotalPages()
  const loadExpiredData = useLoadCredentialsExpiredNotDeleted()
  const renewCredentialError = useRenewCredentialError()
  const renewCredentialSuccess = useRenewCredentialSuccess()
  useEffect(() => {
    if(renewCredentialError || renewCredentialSuccess){
      loadExpiredData(0, 20, true)
      setCurrentPage(1)
    }
  }, [renewCredentialError, renewCredentialSuccess])
  const loadMore = () => {
    if (!loadingPersonCredential) {
      setCurrentPage(currentPage + 1)
      loadExpiredData(currentPage, 20)
    }
  }

  useEffect(() => {
    loadExpiredData(currentPage, 20, false)
    setCurrentPage(currentPage + 1)
  }, [])

  return (
    <Layout>
      <PageToolbar title="Credenciais Expiradas"/>
      {!isEmpty(expiredData) ? (
        <Paper>
          <CredentialExpiredList
            setCurrentPage={setCurrentPage}
            items={expiredData}
            loadMore={loadMore}
            hasMore={totalPages < currentPage}
            fetching={loadingPersonCredential}
            renderActions={item => {
              return (
                <div>
                  <Link to={`${props.match.path}/status/${item.id}`}>
                    <IconButton>create</IconButton>
                  </Link>
                </div>
              )
            }}
          />
        </Paper>
      ) : (
        <PaperStyled>
          <Text>Nenhuma Credencial vencida.</Text>
        </PaperStyled>
      )}
      <Switch>
        <PrivateRoute
          path={`${props.match.path}/status/:id`}
          component={CredentialRenew}
        />
      </Switch>
    </Layout>
  )
}

export default ExpiredCredentials
