import React from "react"
import { isEmpty } from "ramda"
import { Flex, InlineFlex, styled } from "reakit"
import Text from "common/Text"
import EmptyStateMessage from "common/EmptyStateMessage"
import Divider from "common/Divider"

const BlockWrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
`

const BlockItem = styled(InlineFlex)`
  width: 100%;

  & > * {
    width: 100%;
  }
`

const InfoBlock = props => {
  return (
    <>
      <Text variant="body" palette="primary" margin="small" uppercase bold>
        {props.title}
      </Text>
      <BlockWrapper>
        {!isEmpty(props.items) ? (
          props.items.map((item, key) => {
            return (
              <BlockItem key={key}>
                {item.title && (
                  <Text
                    variant="body2"
                    palette="grayscale"
                    margin="extrasmall"
                    uppercase
                  >
                    {item.title}
                  </Text>
                )}
                {item.value && (
                  <Text
                    variant="body2"
                    palette="grayscale"
                    align="right"
                    margin="extrasmall"
                    uppercase
                  >
                    {item.value}
                  </Text>
                )}
              </BlockItem>
            )
          })
        ) : (
          <EmptyStateMessage>{props.emptyState}</EmptyStateMessage>
        )}
      </BlockWrapper>
      {!props.withoutDivider && <Divider palette="primary" />}
    </>
  )
}

export default InfoBlock
