import React, { useEffect, useState } from "react"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { defaultDashboardOptions } from "pages/Headcount/Dashboard/util"
import {
  useHeadcountsDashboardData,
  useHeadcountsFindDashboardDataHandler,
} from "store/hooks/headcount"
import { format } from "date-fns"

const Dashboard = ({ onMarkerClick, policyId }) => {
  const [data, setData] = useState(null)
  const dashboardData = useHeadcountsDashboardData()
  const findHeadcountsDashboardData = useHeadcountsFindDashboardDataHandler()

  useEffect(() => {
    if (dashboardData && dashboardData[policyId]) {
      setData(dashboardData[policyId])
    }
  }, [dashboardData])

  useEffect(() => {
    findHeadcountsDashboardData(policyId)
    setInterval(() => findHeadcountsDashboardData(policyId), 30000)
  }, [findHeadcountsDashboardData])

  const options = {
    ...defaultDashboardOptions,
    xAxis: {
      categories:
        data && data.headCounts ? data.headCounts.map(ep => ep.time) : [],
      labels: {
        formatter: ({ value }) => {
          return format(value, "HH:mm:ss")
        },
      },
    },
    series: [
      {
        name: "Meta HC",
        data:
          data && data.headCounts
            ? data.headCounts.map(ep => ep.quantityPersonsExpected)
            : [],
        color: "#adc7ee",
        marker: {
          enabled: false,
        },
        events: {
          click: ({ point }) => onMarkerClick && onMarkerClick(point),
        },
      },
      {
        name: "Presenças OK",
        data:
          (data &&
            data.headCounts &&
            data.headCounts.map(h => {
              return {
                y: h.quantityPersonsPresent,
                color: h.alert ? "#FF0000" : "#6394DC",
              }
            })) ||
          [],
        fillOpacity: 0,
        marker: {
          symbol: "circle",
        },
        events: {
          click: ({ point }) => onMarkerClick && onMarkerClick(point),
        },
      },
      {
        name: "Alertas",
        color: "#FF0000",
        marker: {
          enabled: false,
        },
      },
    ],
  }
  return <HighchartsReact highcharts={Highcharts} options={options} />
}

export default Dashboard
