import { useDispatch, useSelector } from "react-redux"
import { useCallback } from "react"
import {
  findMonitoringAlertsRequestAction,
  findMonitoringDataRequestAction,
} from "store/ducks/monitoring"

export const useFindMonitoringData = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(findMonitoringDataRequestAction())
  }, [dispatch])
}

export const useFindMonitoringAlerts = () => {
  const dispatch = useDispatch()
  return useCallback(
    (page, size = 20) => {
      dispatch(findMonitoringAlertsRequestAction(page, size))
    },
    [dispatch]
  )
}

export const useMonitoringData = () => {
  return useSelector(state => state.monitoring.data)
}

export const useMonitoringDataLoading = () => {
  return useSelector(state => state.monitoring.loading)
}

export const useMonitoringDataAlertsLoading = () => {
  return useSelector(state => state.monitoring.loadingAlerts)
}

export const useMonitoringAlerts = () => {
  return useSelector(state => state.monitoring.alerts)
}

export const useMonitoringLastAlerts = () => {
  return useSelector(state => state.monitoring.lastAlerts)
}
