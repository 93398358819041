import React, {useEffect} from "react";
import Layout from "common/Layout";
import Paper from "common/Paper";
import PageToolbar, {PageToolbarContent} from "common/PageToolbar";
import {useGroupAlerts, useLoadGroupAlerts} from "../../store/hooks/alerts";
import Link from "../../common/Link";
import Button from "../../common/Button";
import GroupAlertList from "../../common/GroupAlertList";
import IconButton from "common/IconButton"
import {isEmpty} from "ramda";
import PaperStyled from "../../common/PaperStyled";
import Text from "../../common/Text";

const EmergencyAlerts = (props) => {

  const requestGroup = useLoadGroupAlerts()
  const group = useGroupAlerts()

  useEffect(() => {
    requestGroup(0, 5)
  }, [])
  return (
    <Layout>
      <PageToolbar title="Grupos de alerta">
        <PageToolbarContent>
          <Link to={`/alert-group/new-group`} marginleft="auto">
            <Button bold palette="secondary">
              Novo grupo de alerta
            </Button>
          </Link>
        </PageToolbarContent>
      </PageToolbar>
      {!isEmpty(group) ? (
        <Paper>
          <GroupAlertList
            // loadMore={loadMore}
            // loading={loading}
            items={group}
            renderActions={item => {
              return (
                <div style={{justifyContent: "space-around", display: "flex"}}>
                  <Link to={`${props.match.path}/delete/${item.id}`}>
                    <IconButton>delete_outline</IconButton>
                  </Link>
                  <Link to={`${props.match.path}/editar/${item.id}`}>
                    <IconButton>create</IconButton>
                  </Link>
                </div>
              )
            }}
          />
        </Paper>
      ) : (
        <PaperStyled>
          <Text>Nenhum grupo cadastrada.</Text>
        </PaperStyled>
      )}
    </Layout>
  );
};

export default EmergencyAlerts;
