import React, { useEffect, useState } from "react"
import { Route, Switch } from "react-router-dom"
import { isEmpty } from "ramda"
import Layout from "common/Layout"
import Paper from "common/Paper"
import Text from "common/Text"
import Button from "common/Button"
import PageToolbar, { PageToolbarContent } from "common/PageToolbar"
import PageGlobalFilters from "common/PageGlobalFilters"
import { initialDateFilterValues } from "common/PageGlobalFilters/date"
import { initialPermissionFilterValue } from "common/PageGlobalFilters/permission"
import IconButton from "common/IconButton"
import Link from "common/Link"
import CredentialTableList from "common/CredentialTableList"
import PermissionRequestCreate from "pages/PermissionRequestCreate"
import PermissionRequestDestroy from "pages/PermissionRequestDestroy"
import PermissionRequestEdit from "pages/PermissionRequestEdit"
import PermissionRequestDetails from "pages/PermissionRequestDetails"
import { PaperStyled } from "pages/PermissionRequest/styles"
import {
  useCreatePermissionSuccess,
  useDeletePermissionSuccess,
  usePermissionLoading,
  usePermissions,
  usePermissionsFind,
  usePermissionsLast,
  useUpdatePermissionSuccess,
} from "store/hooks/permission"
import PrivateRoute from "common/PrivateRoute"
import PermissionRequestTableList from "../../common/PermissionRequestTableList"

const PermissionRequest = props => {
  const deleteSuccess = useDeletePermissionSuccess()
  const updateSuccess = useUpdatePermissionSuccess()
  const createSuccess = useCreatePermissionSuccess()
  const permissions = usePermissions()
  const loading = usePermissionLoading()
  const findPermissions = usePermissionsFind()
  const last = usePermissionsLast()
  const [currentPage, setCurrentPage] = useState(0)
  const [permissionFilter, setPermissionFilter] = useState(
    initialPermissionFilterValue
  )

  useEffect(() => {
    findPermissions(currentPage, 20)
    setCurrentPage(currentPage + 1)
  }, [])

  const loadMore = () => {
    if (!loading) {
      setCurrentPage(currentPage + 1)
      findPermissions(currentPage, 20)
    }
  }

  useEffect(() => {
    if (deleteSuccess || updateSuccess || createSuccess) {
      findPermissions(0, 20)
      setCurrentPage(1)
    }
  }, [deleteSuccess, updateSuccess, createSuccess])

  return (
    <Layout>
      <PageToolbar title="Pedido de credenciamento">
        <PageToolbarContent>
          {/*<PageGlobalFilters
            onChangeDate={setDateFilter}
            onChangePermission={setPermissionFilter}
          />*/}
          <Link to={`${props.match.path}/novo`}>
            <Button bold palette="secondary">
              Novo pedido
            </Button>
          </Link>
        </PageToolbarContent>
      </PageToolbar>
        <Paper>
          <PermissionRequestTableList
            setCurrentPage={setCurrentPage}
            items={permissions}
            loadMore={loadMore}
            loading={loading}
            last={last}
            renderActions={item => {
              return (
                <div style={{display: "flex", justifyContent: "space-around"}}>
                  <Link to={`${props.match.path}/deletar/${item.id}`}>
                    <IconButton>delete_outline</IconButton>
                  </Link>
                  <Link to={`${props.match.path}/editar/${item.id}`}>
                    <IconButton>create</IconButton>
                  </Link>
                  <Link to={`${props.match.path}/detalhes/${item.id}`}>
                    <IconButton>navigate_next</IconButton>
                  </Link>
                </div>
              )
            }}
          />
        </Paper>
      <Switch>
        <PrivateRoute
          path={`${props.match.path}/novo`}
          component={PermissionRequestCreate}
        />
        <PrivateRoute
          path={`${props.match.path}/detalhes/:id`}
          component={PermissionRequestDetails}
        />
        <PrivateRoute
          path={`${props.match.path}/deletar/:id`}
          component={PermissionRequestDestroy}
        />
        <PrivateRoute
          path={`${props.match.path}/editar/:id`}
          component={PermissionRequestEdit}
        />
      </Switch>
    </Layout>
  )
}

export default PermissionRequest
