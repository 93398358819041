import * as Yup from "yup"

export const ValidationSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .trim()
    .nullable()
    .required("O campo Senha Atual é obrigatório."),
  newPassword: Yup.string()
    .trim()
    .nullable()
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/,
      " A senha deve conter mais de 6 caracteres com letras maiúsculas, minúsculas e números"
    )
    .required("O campo Nova Senha é obrigatório."),
})
