import styled from "styled-components"

export const FormContainer = styled.div`
  width: 100%;
  max-width: 650px;
  border-radius: 2px 2px 5px 5px;
  background: #fff;
  padding: 10px;
  border-top: 3px solid #00cfc3;
  flex-direction: column;
  display: flex;
`

export const Input = styled.input`
  padding: 0.375rem 0.75rem;
  border-radius: 5px;
  border: 1px solid #ced4da;
  margin-bottom: 1rem;
  height: 30px;
  display: block;
  transition: 0.2s all;
  :focus {
    color: #495057;
    background-color: #fff;
    border-color: #00c4f8;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 95, 120, 0.25);
  }
`

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 310px) {
    flex-direction: column;
  }
`

export const SubmitButton = styled.button`
  cursor: pointer;
  background: #00cfc3;
  border: none;
  color: #fff;
  border-radius: 5px;
  :hover {
    background-color: #00a99f;
  }
  padding: 15px 50px;
  @media (max-width: 310px) {
    width: 100%;
  }
`

export const InfoBox = styled.div`
  background: #ccf5f3;
  padding: 15px;
  margin-bottom: 15px;
  color: #006c65;
  border: 1px solid #b8f2ee;
  border-radius: 4px;
  font-size: 13px;
`

export const GoLoginButton = styled.a`
  cursor: pointer;
  font-size: 12px;
  :hover {
    text-decoration: underline;
  }
`

export const InputLabel = styled.label`
  margin-bottom: 5px;
  font-size: 11px;
`
