import React, {useEffect} from "react"
import {isEmpty} from "ramda"
import Layout from "common/Layout"
import PageToolbar from "common/PageToolbar"
import Paper from "common/Paper"
import Text from "common/Text"
import LoadingIndicator from "common/LoadingIndicator"
import {
  useMapingData,
  useMapingRequest,
  useMonitoringLoading,
  useMonitoringRequest,
  useMonitoringUnpreparedData,
} from "../../store/hooks/map"
import MapTableList from "../../common/MapTableList"
import {DataCard, DataContainer, DataTitle, DataValue} from "./styles"
import MapContainerV2 from "./MapContainerV2";

const MonitoringMap = () => {

  const loading = useMonitoringLoading()
  const mapDataRequest = useMonitoringRequest()
  const mapUnpreparedData = useMonitoringUnpreparedData()
  const mapingRequest = useMapingRequest()
  const mapingData = useMapingData()

  useEffect(() => {
    mapDataRequest()
    mapingRequest()
    setInterval(() => mapDataRequest(), 180000)
  }, [])

  return (
    <Layout>
      <PageToolbar title="Mapa"/>
      <DataContainer style={{}}>
        <DataCard style={{}}>
          <DataTitle>Pessoas na planta</DataTitle>
          <DataValue>{mapingData || 0}</DataValue>
        </DataCard>
        <DataCard>
          <DataTitle>Pessoas irregulares</DataTitle>
          <DataValue>0</DataValue>
        </DataCard>
        <DataCard>
          <DataTitle>Alertas ativos</DataTitle>
          <DataValue>0</DataValue>
        </DataCard>
      </DataContainer>
      <div style={{display: "flex"}}>
        <LoadingIndicator loading={loading}>
          {!isEmpty(mapUnpreparedData) ? (
            <div style={{minWidth: "300px", background: "white"}}>
              <MapTableList/>
            </div>
          ) : (
            <Paper style={{minWidth: "350px"}}>
              <Text>Nenhum evento para exibir.</Text>
            </Paper>
          )}
        </LoadingIndicator>
          <MapContainerV2/>
      </div>
    </Layout>
  )
}

export default MonitoringMap
