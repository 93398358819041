import { toast } from "react-toastify"
import React from "react"
import styled from "styled-components"

const globalConfigs = {
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
}

export const showInfoToast = message => {
  toast.info(<Msg text={message} />, globalConfigs)
}

export const showSuccessToast = message => {
  toast.success(<Msg text={message} />, globalConfigs)
}

export const showWarnToast = message => {
  toast.warn(<Msg text={message} />, globalConfigs)
}

export const showErrorToast = message => {
  toast.error(<Msg text={message} />, globalConfigs)
}

const Msg = ({ text }) => {
  return <MsgStyled>{text}</MsgStyled>
}

const MsgStyled = styled.span`
  font-size: 14px;
`
