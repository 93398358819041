import React, { useEffect, useState } from "react"
import SimpleSelectV2 from "../../../../common/SimpleSelectV2"
import { ContainerStyled } from "../index.styled"
import { ErrorMessage, useFormikContext } from "formik"
import InputText from "../../../../common/InputText"
import { ErrorContainer, ErrorLabel } from "../../../../common/Error"

const StepAreaDetail = ({ values }) => {
  const { handleChange } = useFormikContext()

  const [minDbToEnableIOList] = useState([
    { id: "1", label: "1 minutos" },
    { id: "2", label: "2 minutos" },
    { id: "5", label: "5 minutos" },
    { id: "10", label: "10 minutos" },
    { id: "15", label: "15 minutos" },
    { id: "30", label: "30 minutos" },
    { id: "60", label: "1 hora" },
  ])


  // useEffect(() => {
  //   console.log(nodes)
  // }, [nodes])

  return (
    <>
      <ContainerStyled>
        {/*{values.areaType === "IN_OUT" && (*/}
        {/*  <>*/}
        {/*    <SimpleSelectV2*/}
        {/*      propertyToShow="friendlyName"*/}
        {/*      label="NODES DE CHECK-IN"*/}
        {/*      list={nodes}*/}
        {/*      valueProperty="id"*/}
        {/*      name="checkinNodes"*/}
        {/*      id="checkinNodes"*/}
        {/*      value={values.checkinNodes}*/}
        {/*    />*/}
        {/*  </>*/}
        {/*)}*/}
        {/*{values.areaType === "ENTERPRISE_PRESENCE" ||*/}
        {/*values.areaType === "IN_OUT" ? (*/}
        {/*  <SimpleSelectV2*/}
        {/*    propertyToShow="friendlyName"*/}
        {/*    label="NODES DE CHECK-OUT"*/}
        {/*    list={nodes}*/}
        {/*    valueProperty="id"*/}
        {/*    name="checkoutNodes"*/}
        {/*    id="checkoutNodes"*/}
        {/*    value={values.checkoutNodes}*/}
        {/*  />*/}
        {/*) : (*/}
        {/*  ""*/}
        {/*)}*/}
        {/*{values.scope === "individual" && (*/}
        {/*  <SimpleSelectV2*/}
        {/*    propertyToShow="friendlyName"*/}
        {/*    label="NODES"*/}
        {/*    list={nodes}*/}
        {/*    valueProperty="id"*/}
        {/*    name="nodes"*/}
        {/*    id="nodes"*/}
        {/*    value={values.nodes}*/}
        {/*  />*/}
        {/*)}*/}
        <SimpleSelectV2
          propertyToShow="label"
          tooltip="A quantidade de tempo de espera antes que uma violação seja limpa automaticamente após o limite ser ultrapassado."
          list={minDbToEnableIOList}
          valueProperty="id"
          name="minDbToEnableIO"
          id="minDbToEnableIO"
          label="TEMPO LIMITE DE LIMPEZA AUTOMÁTICA"
          value={values.minDbToEnableIO}
        />
        <InputText
          tooltip="Leituras de sinal maiores que esse valor acionarão violações."
          value={values.checkInMinRSSI}
          name="checkInMinRSSI"
          id="checkInMinRSSI"
          inputType="number"
          label="LIMITE DE SINAL (RSSI mínimo)"
          onChange={handleChange}
        />
        <InputText
          tooltip="Limpe automaticamente violações quando a intensidade do sinal é mais fraca do que…"
          value={values.checkOutMaxRSSI}
          name="checkOutMaxRSSI"
          id="checkOutMaxRSSI"
          inputType="number"
          label="SINAL DE LIMPEZA (RSSI máximo)"
          onChange={handleChange}
        />
        <ErrorContainer>
          <ErrorMessage
            name="minDbToEnableIO"
            render={msg => <ErrorLabel>{msg}</ErrorLabel>}
          />
          <ErrorMessage
            name="checkInMinRSSI"
            render={msg => <ErrorLabel>{msg}</ErrorLabel>}
          />
          <ErrorMessage
            name="checkOutMaxRSSI"
            render={msg => <ErrorLabel>{msg}</ErrorLabel>}
          />
        </ErrorContainer>
      </ContainerStyled>
    </>
  )
}

export default StepAreaDetail
