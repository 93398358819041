import React, { useEffect, useState } from "react"
import { Switch } from "react-router-dom"
import { isEmpty } from "ramda"
import { styled } from "reakit"
import Layout from "common/Layout"
import PageToolbar from "common/PageToolbar"
import Paper from "common/Paper"
import Text from "common/Text"
import Link from "common/Link"
import IconButton from "common/IconButton"
import PermissionManagementLicense from "pages/PermissionManagementLicense"
import PrivateRoute from "common/PrivateRoute"
import {
  useCreatePermissionRequestSuccess,
  useDeletePermissionRequestSuccess,
  usePermissionRequestLoading,
  usePermissionsRequest,
  usePermissionsRequestFind,
  useTotalPages,
  useUpdatePermissionRequestSuccess,
} from "store/hooks/permission-request"
import PermissionRequestManagementTableList from "common/PermissionRequestManagementTableList"

const LinkStyled = styled(Link)`
  align-items: center;
  display: flex;
`

const PermissionManagement = props => {
  const [currentPage, setCurrentPage] = useState(0)
  const loadPermissions = usePermissionsRequestFind()
  const permissionsRequest = usePermissionsRequest()
  const totalPages = useTotalPages()
  // const filtering = usePersonFiltering()
  const loading = usePermissionRequestLoading()
  const deleteSuccess = useDeletePermissionRequestSuccess()
  const updateSuccess = useUpdatePermissionRequestSuccess()
  const createSuccess = useCreatePermissionRequestSuccess()

  useEffect(() => {
    if (deleteSuccess || updateSuccess || createSuccess) {
      loadPermissions(0, 20, true)
      setCurrentPage(1)
    }
  }, [deleteSuccess, updateSuccess, createSuccess])

  const loadMore = () => {
    if (!loading) {
      setCurrentPage(currentPage + 1)
      loadPermissions(currentPage, 20)
    }
  }

  useEffect(() => {
    loadPermissions(currentPage, 20, false)
    setCurrentPage(currentPage + 1)
  }, [])

  return (
    <Layout>
      <PageToolbar title="Credenciamento" />
        <Paper>
          <PermissionRequestManagementTableList
            setCurrentPage={setCurrentPage}
            items={permissionsRequest}
            loadMore={loadMore}
            hasMore={totalPages < currentPage}
            renderActions={item => (
              <LinkStyled to={`${props.match.path}/credenciar/${item.id}`}>
                <Text palette="primary" variant="body3" uppercase>
                  Credenciar
                </Text>
                <IconButton>navigate_next</IconButton>
              </LinkStyled>
            )}
          />
        </Paper>
      <Switch>
        <PrivateRoute
          path={`${props.match.path}/credenciar/:id`}
          component={PermissionManagementLicense}
        />
      </Switch>
    </Layout>
  )
}

export default PermissionManagement
