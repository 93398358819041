import * as Yup from "yup"

const schema = Yup.object().shape({
  name: Yup.string()
    .nullable()
    .required("O campo Nome é obrigatório."),
  description: Yup.string()
    .nullable()
    .required("O campo Descrição é obrigatório."),
  namespace: Yup.string()
    .nullable()
    .required("O campo Namespace é obrigatório."),
  type: Yup.string()
    .nullable()
    .required("O campo Tipo é obrigatório."),
  areaType: Yup.string()
    .nullable()
    .required("O campo Tipo de Área é obrigatório."),
  minDbToEnableIO: Yup.string()
    .nullable()
    .required("O campo Tempo limite de limpeza automática é obrigatório."),
  checkInMinRSSI: Yup.string()
    .nullable()
    .required("O campo Limite de sinal é obrigatório."),
  checkOutMaxRSSI: Yup.string()
    .nullable()
    .required("O campo Sinal de limpeza é obrigatório."),
})

export default schema
