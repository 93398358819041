import React, { useCallback, useEffect, useState } from "react"
import { useLoadCredentials } from "store/hooks/credential"
import SimpleSelectV2 from "../../SimpleSelectV2"
import InputText from "../../InputText"
import FieldAutoCompleteV2 from "../../FieldAutocompleteV2"
import { MaskUtils } from "utils/MasksUtil"
import {ErrorMessage, useFormikContext} from "formik"
import { ErrorContainer, ErrorLabel } from "common/Error"
import { ContainerStyled } from "common/PersonForm/index.styled"
import {
  useAutoCompletePersons,
  useLoadAutoCompletePersons,
  useResetPersons,
} from "store/hooks/person"
import {FormSwitch} from "../../FormSwitch";

const StepPersonData = props => {
  const type = [
    { id: "EMPLOYEE", label: "Funcionário" },
    { id: "OUTSOURCED", label: "Terceirizado" },
    { id: "VISITOR", label: "Visitante" },
  ]
  // autocomplete responsible
  const [suggestionsResponsible, setSuggestionsResponsible] = useState([])
  const loadCredentialsData = useLoadCredentials()
  const personsData = useAutoCompletePersons()
  const findPersons = useLoadAutoCompletePersons()
  const resetPersons = useResetPersons()
  const { values } = props
  const completeMethodResponsible = useCallback(param => {
    findPersons(param, true)
  }, [])

  const {handleChange} = useFormikContext()
  useEffect(() => {
    resetPersons()
  }, [])

  useEffect(() => {
    setSuggestionsResponsible(personsData)
  }, [personsData])

  useEffect(() => {
    loadCredentialsData(null, true)
  }, [loadCredentialsData])

  return (
    <ContainerStyled>
      <InputText
        name="name"
        id="name"
        label="NOME COMPLETO"
        value={values.name || ""}
      />
      <InputText
        name="docId"
        id="docId"
        label="Nº DOCUMENTO"
        value={values.docId || ""}
      />
      <InputText
        name="registrationId"
        id="registrationId"
        label="MATRÍCULA"
        value={values.registrationId || ""}
      />
      <InputText
        name="birthDate"
        id="birthDate"
        label="DATA DE NASCIMENTO"
        value={MaskUtils.formatDate(values.birthDate) || ""}
      />
      <SimpleSelectV2
        label="VÍNCULO"
        list={type}
        name="workBond"
        id="workBond"
        propertyToShow="label"
        valueProperty="id"
        value={values.workBond || ""}
      />
      <FieldAutoCompleteV2
        name="managerId"
        id="managerId"
        label="GESTOR DIRETO"
        value={values.managerId}
        minLength={2}
        completeMethod={completeMethodResponsible}
        fieldName="name"
        suggestions={suggestionsResponsible}
        propertyToShow="name"
      />
      <FormSwitch
        onLabel={"INATIVO"}
        offLabel={"ATIVO"}
        value={values.disabled}
        name="disabled"
        type="round"
        onChange={handleChange}
      />
      <ErrorContainer>
        <ErrorMessage
          name="name"
          render={msg => <ErrorLabel>{msg}</ErrorLabel>}
        />
        <ErrorMessage
          name="docId"
          render={msg => <ErrorLabel>{msg}</ErrorLabel>}
        />
        <ErrorMessage
          name="registrationId"
          render={msg => <ErrorLabel>{msg}</ErrorLabel>}
        />
        <ErrorMessage
          name="birthDate"
          render={msg => <ErrorLabel>{msg}</ErrorLabel>}
        />
      </ErrorContainer>
    </ContainerStyled>
  )
}

export default StepPersonData
