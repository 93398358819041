import { styled } from "reakit"

export const ButtonStyled = styled.button`
  background: ${({ secondary }) => (secondary ? `transparent` : `#145269`)};
  border: 2px solid #145269;
  padding: 2.5% 5%;
  width: 45%;
  border-radius: 8px;
  text-transform: uppercase;
  font-size: small;
  color: ${({ secondary }) => (secondary ? `#145269` : `white`)};
`

export const Container = styled.div`
  float: right;
  margin-right: 10%;
  margin-top: -1%;
`

export const FilterContainer = styled.div`
  position: absolute;
  width: 300px;
  display: flex;
  flex-direction: column;
  padding: 2% 1%;
  background: white;
  box-shadow: 5px 5px 10px gray;
  border-radius: 6px;
  z-index: 1;
`

export const InputStyled = styled.input`
  margin-top: 4%;
  width: 100%;
  padding: 3%;
  font-size: small;
  border-radius: 7px;
  border: 1px solid #cacaca;
`

export const CloseBtnContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
`

export const CloseIconStyled = styled.i`
  color: gray;
  font-size: small;
  cursor: pointer;
`

export const LabelStyled = styled.label``

export const BtnContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`

export const IconStyled = styled.i`
  position: absolute;
  color: ${({ color }) => color};
  font-size: small;
  cursor: pointer;
`
