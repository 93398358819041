import React, {useEffect} from "react"
import {Step} from "reakit"
import Button from "common/Button"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import LayerFeedback from "common/LayerFeedback"
import {StepItem, StepsWrapper} from "./index.styled"
import {Formik} from "formik"
import ValidationSchema from "./ValidationSchema"
import AdvanceFormButton from "../../../common/AdvanceFormButton"
import {
  useCredentialCreateSuccess,
  useCredentialDeleteSuccess,
  useCredentialUpdateSuccess,
} from "../../../store/hooks/credential"
import StepCredentialData from "./StepCredentialData"
import {useRenewCredentialError, useRenewCredentialSuccess} from "../../../store/hooks/report";

const CredentialForm = props => {
  const updateSuccess = useCredentialUpdateSuccess()
  const createSuccess = useRenewCredentialSuccess()
  const deleteSuccess = useCredentialDeleteSuccess()
  const renewCredentialSuccess = useRenewCredentialSuccess()
  const renewCredentialError = useRenewCredentialError()

  const handleSubmitForm = (values) => {
    props.onSubmit(values)
  }
  const credentialCreationStructure = [
    {
      step: 0,
      requiredFields: ["name", "validityInDays"],
    },
  ]

  const canSubmitForm = step => {
    return step.ids.length - 1 === step.current + 1
  }
  const isLastStep = step => {
    return step.ids.length === step.current + 1
  }

  const getSubmitButtonLabel = step => {
    if (isLastStep(step)) {
      return "Fechar"
    }

    if (canSubmitForm(step)) {
      return "Concluir"
    }

    return "Avançar"
  }
  useEffect(() => {
    if (updateSuccess) {
      props.closeSidebar()
    }
  }, [updateSuccess])

  return (
    <>
      <Formik
        initialValues={props.initialValues}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmitForm}
        enableReinitialize={true}
      >
        {({isValid, values}) => (
          <Step.Container initialState={{current: 0}}>
            {step => {
              return (
                <>
                  <>
                    <StepsWrapper>
                      <StepItem step="0" order={0} {...step}>
                        <StepCredentialData values={values}/>
                      </StepItem>
                      <StepItem step="1" order={1} {...step}>
                        {renewCredentialError ?
                          <LayerFeedback
                            icon="warning"
                            palette="warning"
                            title="Erro ao renovar credencial"
                            message={renewCredentialError.data.message}
                          />
                          :
                          <LayerFeedback
                            icon="check"
                            palette="secondary"
                            title="Cadastro Realizado"
                            message="Credencial foi cadastrada com sucesso."
                          />
                        }
                      </StepItem>
                    </StepsWrapper>
                    <LayoutSidebarOverlay.Footer>
                      {!isLastStep(step) || (step > 0 && !isValid) ? (
                        <Button
                          use={Step.Previous}
                          {...step}
                          type="button"
                          palette="white"
                        >
                          Voltar
                        </Button>
                      ) : (
                        ""
                      )}
                      <AdvanceFormButton
                        closeSidebar={props.closeSidebar}
                        step={step}
                        structure={credentialCreationStructure}
                        createSuccess={createSuccess}
                        updateSuccess={updateSuccess}
                        deleteSuccess={deleteSuccess}
                        renewCredentialError={renewCredentialError}
                      >
                        {getSubmitButtonLabel(step)}
                      </AdvanceFormButton>
                    </LayoutSidebarOverlay.Footer>
                  </>
                </>
              )
            }}
          </Step.Container>
        )}
      </Formik>
    </>
  )
}

export default CredentialForm
