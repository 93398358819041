import { call, put, takeLatest } from "redux-saga/effects"
import api from "../../services/api"
import API from "../../lib/api"
import {
  LOAD_BEACONS_REQUEST,
  loadBeaconsError,
  loadBeaconsSuccess,
} from "store/ducks/beacon"

export function* findBeacons(action) {
  try {
    const { size, description } = action.payload
    const findBeaconDataApiCall = () => {
      return api
        .get(`${API.CLOUD}/beacons`, {
          params: {
            size,
            description,
          },
        })
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }

    const { content } = yield call(findBeaconDataApiCall)
    yield put(loadBeaconsSuccess(content))
  } catch (err) {
    yield put(loadBeaconsError(err))
  }
}
export default [takeLatest(LOAD_BEACONS_REQUEST, findBeacons)]
