import {useDispatch, useSelector} from "react-redux"
import {useCallback} from "react"
import {createGroupRequest, deleteGroupRequestAction, findGroupRequestAction, loadAlertsBlockedRequest, loadAlertsIrregularRequest, loadAllAlertsRequest, loadGroupRequestAlerts, requestTriggedButtons, resetGroup,} from "store/ducks/alerts"
import {loadPersonAlertsRequest} from "../ducks/alerts"

export const useLoadAlertsBlocked = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(loadAlertsBlockedRequest())
  }, [dispatch])
}

export const useLoadAlertsIrregular = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(loadAlertsIrregularRequest())
  }, [dispatch])
}

export const useLoadPersonsAlerts = () => {
  const dispatch = useDispatch()
  return useCallback(
    (page, size, force = false, personId) => {
      dispatch(loadPersonAlertsRequest(page, size, force, personId))
    },
    [dispatch]
  )
}
export const useLoadGroupAlerts = () => {
  const dispatch = useDispatch()
  return useCallback(
    (page, size) => {
      dispatch(loadGroupRequestAlerts(page, size,))
    },
    [dispatch]
  )
}
export const useCreateGroup = () => {
  const dispatch = useDispatch()
  return useCallback(
    group => {
      dispatch(createGroupRequest(group))
    },
    [dispatch]
  )
}
export const useGroupReset = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(resetGroup())
  }, [dispatch])
}

export const useFindGroupRequestHandler = () => {
  const dispatch = useDispatch()
  return useCallback(
    id => {
      dispatch(findGroupRequestAction(id))
    },
    [dispatch]
  )
}

export const useAlerts = () => {
  return useSelector(state => state.alerts.alerts)
}
export const useGroupAlerts = () => {
  return useSelector(state => state.alerts.groups)
}

export const useAlertsLoading = () => {
  return useSelector(state => state.alerts.loading)
}

export const useAlertsTotalPages = () => {
  return useSelector(state => state.alerts.totalPages)
}

export const useAlertsDashboard = () => {
  const alerts = useSelector(state => state.alerts.alerts)
  if (alerts) {
    const object = {
      labels: [],
      datasets: [
        {
          backgroundColor: "rgb(33,208,210)",
          data: [],
          borderWidth: 0,
          pointRadius: 0,
        },
      ],
    }
    alerts.forEach(a => {
      object.labels.push(a.name || a.id)
      object.datasets[0].data.push(a.total)
    })
    return object
  } else {
    return []
  }
}

export const useLoadAllAlerts = () => {
  const dispatch = useDispatch()
  return useCallback(
    (page, size) => {
      dispatch(loadAllAlertsRequest(page, size || 20))
    },
    [dispatch]
  )
}
export const useGroupDelete = () => {
  const dispatch = useDispatch()
  return useCallback(
    id => {
      dispatch(deleteGroupRequestAction(id))
    },
    [dispatch]
  )
}

export const useLoadTriggedButtons = () => {
  const dispatch = useDispatch()
  return useCallback(
    (page, size) => {
      dispatch(requestTriggedButtons(page, size))
    },
    [dispatch]
  )
}
export const useAlertAll = () => {
  return useSelector(state => state.alerts.allAlerts)
}
export const useTriggedButtons = () => {
  return useSelector(state => state.alerts.data)
}
export const useTriggedButtonsLoading = () => {
  return useSelector(state => state.alerts.loading)
}
export const useEmergencyAlertLast = () => {
  return useSelector(state => state.alerts.last)
}
export const useEmergencyTotalElements = () => {
  return useSelector(state => state.alerts.totalElements)
}
export const useAlertAllLastPage = () => {
  return useSelector(state => state.alerts.allAlertsLastPage)
}
export const useGroup = () => {
  return useSelector(state => state.alerts.group)
}
export const useDeleteGroupSuccess = () => {
  return useSelector(state => state.alerts.deleteSuccess)
}
export const useCreateGroupSuccess = () => {
  return useSelector(state => state.alerts.createSuccess)
}
