export const LOAD_ORGANIZATION_REQUEST = "LOAD_ORGANIZATION_REQUEST"
export const LOAD_ORGANIZATION_SUCCESS = "LOAD_ORGANIZATION_SUCCESS"
export const LOAD_ORGANIZATION_ERROR = "LOAD_ORGANIZATION_ERROR"
export const LIST_ORGANIZATION_REQUEST = "LIST_ORGANIZATION_REQUEST"
export const LIST_ORGANIZATION_SUCCESS = "LIST_ORGANIZATION_SUCCESS"
export const LIST_ORGANIZATION_ERROR = "LIST_ORGANIZATION_ERROR"
export const FIND_ORGANIZATION_REQUEST = "FIND_ORGANIZATION_REQUEST"
export const FIND_ORGANIZATION_SUCCESS = "FIND_ORGANIZATION_SUCCESS"
export const FIND_ORGANIZATION_ERROR = "FIND_ORGANIZATION_ERROR"
export const ORGANIZATION_UPDATE_REQUEST = "ORGANIZATION_UPDATE_REQUEST"
export const ORGANIZATION_UPDATE_SUCCESS = "ORGANIZATION_UPDATE_SUCCESS"
export const ORGANIZATION_UPDATE_ERROR = "ORGANIZATION_UPDATE_ERROR"
export const ORGANIZATION_CREATE_REQUEST = "ORGANIZATION_CREATE_REQUEST"
export const ORGANIZATION_CREATE_SUCCESS = "ORGANIZATION_CREATE_SUCCESS"
export const ORGANIZATION_CREATE_ERROR = "ORGANIZATION_CREATE_ERROR"
export const ORGANIZATION_DELETE_REQUEST = "ORGANIZATION_DELETE_REQUEST"
export const ORGANIZATION_DELETE_SUCCESS = "ORGANIZATION_DELETE_SUCCESS"
export const ORGANIZATION_DELETE_ERROR = "ORGANIZATION_DELETE_ERROR"
export const ORGANIZATION_RESET = "ORGANIZATION_RESET"

export const loadOrganizationsRequest = (name, unpaged) => ({
  type: LOAD_ORGANIZATION_REQUEST,
  payload: {
    name,
    unpaged,
  },
})

export const loadOrganizationsSuccess = organizationUnits => ({
  type: LOAD_ORGANIZATION_SUCCESS,
  payload: {
    organizationUnits,
  },
})

export const loadOrganizationsError = error => ({
  type: LOAD_ORGANIZATION_ERROR,
  payload: {
    error,
  },
})

export const listOrganizationRequestAction = page => ({
  type: LIST_ORGANIZATION_REQUEST,
  payload: {
    page,
  },
})

export const listOrganizationSuccessAction = (organizationUnits, totalPages) => ({
  type: LIST_ORGANIZATION_SUCCESS,
  payload: {
    organizationUnits,
    totalPages,
  },
})

export const listOrganizationErrorAction = error => ({
  type: LIST_ORGANIZATION_ERROR,
  payload: {
    error,
  },
})

export const findOrganizationRequestAction = id => ({
  type: FIND_ORGANIZATION_REQUEST,
  payload: {
    id,
  },
})

export const findOrganizationSuccessAction = organizationUnity => ({
  type: FIND_ORGANIZATION_SUCCESS,
  payload: {
    organizationUnity,
  },
})

export const organizationCreateRequestAction = organizationUnity => ({
  type: ORGANIZATION_CREATE_REQUEST,
  payload: {
    organizationUnity,
  },
})

export const organizationUpdateRequestAction = organizationUnity => ({
  type: ORGANIZATION_UPDATE_REQUEST,
  payload: {
    organizationUnity,
  },
})

export const organizationUpdateSuccessAction = () => ({
  type: ORGANIZATION_UPDATE_SUCCESS,
})

export const organizationDeleteRequestAction = id => ({
  type: ORGANIZATION_DELETE_REQUEST,
  payload: {
    id,
  },
})

export const organizationDeleteSuccessAction = () => ({
  type: ORGANIZATION_DELETE_SUCCESS,
})

export const organizationResetAction = () => ({
  type: ORGANIZATION_RESET,
})

const INITIAL_STATE = {
  error: null,
  loading: false,
  organizationUnits: [],
  organizationUnity: null,
  totalPages: 0,
  updateSuccess: false,
  createSuccess: false,
  deleteSuccess: false,
}

const organizationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_ORGANIZATION_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case LOAD_ORGANIZATION_SUCCESS: {
      const { organizationUnits } = action.payload
      return {
        ...state,
        organizationUnits,
        loading: false,
      }
    }
    case LOAD_ORGANIZATION_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        error,
        loading: false,
      }
    }
    case LIST_ORGANIZATION_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case LIST_ORGANIZATION_SUCCESS:
      const { organizationUnits, totalPages } = action.payload
      return {
        ...state,
        organizationUnits,
        totalPages,
        loading: false,
      }
    case LIST_ORGANIZATION_ERROR:
      const { error } = action.payload
      return {
        ...state,
        error,
        loading: false,
      }
    case FIND_ORGANIZATION_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FIND_ORGANIZATION_SUCCESS:
      const { organizationUnity } = action.payload
      return {
        ...state,
        organizationUnity,
        loading: false,
      }
    case ORGANIZATION_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        updateSuccess: false,
      }
    case ORGANIZATION_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        updateSuccess: true,
      }
    case ORGANIZATION_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        createSuccess: false,
      }
    case ORGANIZATION_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        createSuccess: true,
      }
    case ORGANIZATION_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case ORGANIZATION_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case ORGANIZATION_RESET:
      return {
        ...state,
        loading: false,
        updateSuccess: false,
        createSuccess: false,
      }
    default:
      return state
  }
}

export default organizationReducer
