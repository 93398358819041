import styled from "styled-components"

export const ShowMoreContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  border-bottom: 1px solid #e4e8f0;
  height: 11px;
  margin: 10px 0 30px;
`
export const ShowMoreButton = styled.div`
  width: 135px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #f5f5f5;
  transition: 0.5s ease all;
  :hover {
    background: #e9e9e9;
  }
`

export const ShowMoreText = styled.span`
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.54);
`
