import React, {useEffect, useState} from "react"
import { Doughnut, Chart } from "react-chartjs-2"
import useCustomFetch from "lib/useCustomFetch"
import API from "lib/api"
import { ChartWrapper, Legend, BoxChart, BoxLegend } from "./index.styled"
import { Box, styled } from "reakit"
import Text from "common/Text"

const originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw
Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
  draw: function() {
    originalDoughnutDraw.apply(this, arguments)

    const chart = this.chart
    const width = chart.chart.width,
      height = chart.chart.height,
      ctx = chart.chart.ctx

    const fontSize = (height / 114).toFixed(2)
    ctx.font = fontSize + "em sans-serif"
    ctx.textBaseline = "middle"

    let sum = 0
    for (let i = 0; i < chart.config.data.datasets[0].data.length; i++) {
      sum += chart.config.data.datasets[0].data[i]
    }

    const text = sum,
      textX = Math.round((width - ctx.measureText(text).width) / 2),
      textY = height / 2

    ctx.fillText(text, textX, textY)
  },
})

export const TextValue = styled(Text)`
  font-size: 1.6rem;
  padding-botom: 1.6rem;
  padding-top: 1.6rem;
  position: absolute;
  right: 0;
`

const ChartPlacesDashboard = props => {
  const [data, setData] = useState({labels: [], datasets: []})
  const [dataShow, setDataShow] = useState([])
  const colors = props.colors || [
    "#5073b8",
    "#0ab39c",
    "#f1963a",
    "#f16548",
    "#5EC778",
    "#F1963A",
    "#F16548",
    "#EA557F",
    "#005369",
    "#69737D",
  ]

/*  // fetch data to populate ChartPlace
  const { data: responseData } = useCustomFetch({
    endpoint: `${API.PERSON}/alerts/policies`,
  })

  // const totalViolations = pathOr(0, ["totalViolations"], responseData)
  const totalViolationByPolicies = pathOr(
    [],
    ["totalViolationByPolicies", "content"],
    responseData
  )*/

  const options = {
    animation: false,
    responsive: true,
    legend: {
      display: false,
    },
  }

  const summaryRequest = useCustomFetch({
    endpoint: `${API.CLOUD}/devices/summary`,
    lazy: true,
  })

  useEffect(() => {
    summaryRequest.doFetch()
  }, [])

  useEffect(() => {
    if (summaryRequest.data) {
      setData({
        labels: Object.keys(summaryRequest.data).map(k => k.substring(8)),
        datasets: [
          {
            data: Object.keys(summaryRequest.data).map(i => summaryRequest.data[i]),
            backgroundColor: colors,
            borderWidth: 0,
          },
        ],
      })
      setDataShow(
          Object.keys(summaryRequest.data).map(k => {
            return {
              name: k.substring(8),
              total: summaryRequest.data[k],
            }
          })
      )
    }
  }, [summaryRequest.data])

  return (
    <ChartWrapper {...props}>
      <BoxLegend>
        {dataShow.map((item, key) => {
          const color = colors[key] || null

          return (
            <Box display="flex" key={key}>
              <Legend palette="grayscale" bulletColor={color} key={key}>
                {item.name}
              </Legend>
              <TextValue>{item.total}</TextValue>
            </Box>
          )
        })}
      </BoxLegend>
      <BoxChart>
        <Doughnut data={data} options={options} />
      </BoxChart>
    </ChartWrapper>
  )
}

export default ChartPlacesDashboard
