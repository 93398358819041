import React from "react"
import Checkbox from "common/Checkbox"
import { FieldStyled, LabelStyled } from "./index.styled"
import { useFormikContext } from "formik"

const FieldCheckboxV2 = ({
  id,
  label,
  value,
  name,
  disabled,
  validate,
  checked,
  onChange,
}) => {
  const { setFieldValue } = useFormikContext()

  const handleChange = () => {
    if (name) {
      setFieldValue(name, !checked)
    }
  }

  return (
    <FieldStyled>
      <LabelStyled htmlFor={id}>{label}</LabelStyled>
      <Checkbox
        value={value}
        name={name}
        disabled={!!disabled}
        onClick={handleChange}
        checked={!!checked}
        validate={validate}
      />
    </FieldStyled>
  )
}

export default FieldCheckboxV2
