import React, { useMemo } from "react"
import {
  CloseIcon,
  Container,
  ModuleContainer,
  WhiteContainer,
  ModuleIcon,
} from "common/ModuleChangeV2/styles"
import { ReactComponent as MonitorIcon } from "assets/images/new-icons/monitor.svg"
import { ReactComponent as PersonIcon } from "assets/images/new-icons/person.svg"
import { ReactComponent as PermissionRequestIcon } from "assets/images/new-icons/pedido-credencial.svg"
import { ReactComponent as AreasIcon } from "assets/images/new-icons/areas.svg"
import { ReactComponent as ProcedureIcon } from "assets/images/new-icons/procedimento.svg"
import { ReactComponent as CredentialsIcon } from "assets/images/new-icons/credenciais.svg"
import { ReactComponent as UserConfigIcon } from "assets/images/new-icons/config-usuarios.svg"
import { ReactComponent as AccreditationIcon } from "assets/images/new-icons/credenciamento.svg"
import { ReactComponent as CheckpointIcon } from "assets/images/new-icons/checkpoint.svg"
import { ReactComponent as PolicyIcon } from "assets/images/new-icons/policy.svg"
import { ReactComponent as MapsIcon } from "assets/images/new-icons/mapa.svg"
import {
  useNavigationChangeModule,
  useNavigationChangeModuleDisplay,
  useNavigationDialogDisplay,
} from "store/hooks/navigation"
import { withRouter } from "react-router-dom"
import { ROLES } from "common/constants"

import RastreabilidadeIcon from "assets/images/new-icons/rastreabilidade_icon.png"
import ConfiguracoesIcon from "assets/images/new-icons/configuracoes_icon.png"
import CredenciaisIcon from "assets/images/new-icons/crendenciais_icon.png"
import { hasAnyAuthority } from "common/PrivateRoute"

export const MODULES_IDS = {
  RASTREABILIDADE: "RASTREABILIDADE",
  GESTAO_CREDENCIAIS: "GESTAO_CREDENCIAIS",
  CONFIGURACAO_SISTEMA: "CONFIGURACAO_SISTEMA",
}

export const MODULES = [
  {
    id: MODULES_IDS.RASTREABILIDADE,
    name: "Rastreabilidade",
    Icon: RastreabilidadeIcon,
    menuItems: [
      {
        title: "Monitoramento",
        to: "/monitoramento",
        Icon: MonitorIcon,
      },
      {
        title: "Headcount",
        to: "/headcount",
        Icon: MonitorIcon,
      },
      {
        title: "Mapas",
        to: "/maps",
        Icon: MapsIcon,
      },
      {
        title: "Pessoa",
        to: "/pessoa",
        Icon: PersonIcon,
      },
      {
        title: "Consulta de Eventos",
        to: "/events-view",
        Icon: PersonIcon,
      },
    ],
  },
  {
    id: MODULES_IDS.GESTAO_CREDENCIAIS,
    name: "Gestão de Credenciais",
    Icon: CredenciaisIcon,
    menuItems: [
      {
        title: "Pedido de credenciamento",
        to: "/pedidos-de-credenciais",
        Icon: PermissionRequestIcon,
      },
      {
        title: "Credenciamento",
        to: "/credenciamento",
        Icon: AccreditationIcon,
      },
      {
        title: "Autorizações",
        to: "/autorizacao",
        Icon: ProcedureIcon,
      },
      {
        title: "Credenciais",
        to: "/credenciais",
        Icon: CredentialsIcon,
      },
/*      {
        title: "Áreas",
        to: "/areas",
        Icon: AreasIcon,
      },*/
    ],
  },
  {
    id: MODULES_IDS.CONFIGURACAO_SISTEMA,
    name: "Configurações do Sistema",
    Icon: ConfiguracoesIcon,
    role: ROLES.ADMIN,
    menuItems: [
      {
        title: "Políticas",
        to: "/politicas",
        Icon: PolicyIcon,
      },
      {
        title: "Usuários",
        to: "/usuarios",
        Icon: UserConfigIcon,
      },
      {
        title: "Checkpoint Nodes",
        to: "/checkpoint-nodes",
        Icon: CheckpointIcon,
      },
    ],
  },
]

const ModuleChangeV2 = ({ history }) => {
  const changeModuleAction = useNavigationChangeModule()
  const dialogDisplay = useNavigationDialogDisplay()
  const handleChangeDialogDisplayAction = useNavigationChangeModuleDisplay()

  const handleChangeDialogDisplay = display => {
    handleChangeDialogDisplayAction(display)
  }

  const changeModule = module => {
    changeModuleAction(module.id, module.name)
    if (module.menuItems && module.menuItems.length > 0) {
      history.push(module.menuItems[0].to)
    }
  }

  const FilteredModules = () => {
    const modules = []
    MODULES.forEach(module => {
      if (module.role) {
        if (hasAnyAuthority([module.role])) {
          modules.push(module)
        }
      } else {
        modules.push(module)
      }
    })
    return modules
  }

  const filteredModulesMemo = useMemo(() => FilteredModules(), [MODULES])

  return (
    <>
      {dialogDisplay && (
        <Container>
          <WhiteContainer moduleslenght={filteredModulesMemo.length}>
            <CloseIcon
              onClick={() => handleChangeDialogDisplay(false)}
              moduleslenght={filteredModulesMemo.length}
            />
            {filteredModulesMemo.map((module, key) => {
              return (
                <ModuleContainer key={key} onClick={() => changeModule(module)}>
                  <ModuleIcon src={module.Icon} alt="" />
                  <span>{module.name}</span>
                </ModuleContainer>
              )
            })}
          </WhiteContainer>
        </Container>
      )}
    </>
  )
}

export default withRouter(ModuleChangeV2)
