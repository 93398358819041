import * as Yup from "yup"

const schema = Yup.object().shape({
  name: Yup.string()
    .nullable()
    .required("O campo Nome é obrigatório."),
  description: Yup.string()
    .nullable()
    .required("O campo Descrição é obrigatório."),
  type: Yup.string()
    .nullable()
    .required("O campo Tipo é obrigatório."),
  latitude: Yup.string()
    .nullable()
    .required("O campo Latitude é obrigatório."),
  longitude: Yup.string()
    .nullable()
    .required("O campo Longitude é obrigatório."),
})

export default schema
