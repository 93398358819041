import { isNil } from "ramda"
import { format } from "date-fns"

export const getFormattedDate = (date, pattern = "DD/MM/YYYY") => {
  if (!isNil(date)) {
    return format(date, pattern)
  }

  return "n/a"
}
