import { useDispatch, useSelector } from "react-redux"
import { useCallback } from "react"
import {
  authFromTokenRequestAction,
  authLogoutAction,
  authRequestAction,
  authSuccessAction,
  changePasswordRequest,
  changePasswordReset,
  forgotPasswordRequestAction,
  resetPasswordRedirectAction,
  resetPasswordRequestAction,
  resetPasswordValidateKeyAction,
} from "../ducks/authentication"

export const useAuthHandlers = () => {
  const dispatch = useDispatch()
  return useCallback(
    ({ email, password }) => {
      dispatch(authRequestAction(email, password))
    },
    [dispatch]
  )
}

export const useAuthFromTokenHandlers = () => {
  const dispatch = useDispatch()
  return useCallback(
    (token) => {
      dispatch(authFromTokenRequestAction(token))
    },
    [dispatch]
  )
}

export const useAuthSuccessHandlers = () => {
  const dispatch = useDispatch()
  return useCallback(
    token => {
      dispatch(authSuccessAction(token))
    },
    [dispatch]
  )
}

export const useForgotPasswordRequest = () => {
  const dispatch = useDispatch()
  return useCallback(
    ({ email }) => {
      dispatch(forgotPasswordRequestAction(email))
    },
    [dispatch]
  )
}

export const useAccessToken = () => {
  return useSelector(state => state.auth.token)
}

export const useAuthError = () => {
  return useSelector(state => state.auth.error)
}

export const useAuthenticated = () => {
  return useSelector(state => state.auth.authenticated)
}

export const useAuthLoading = () => {
  return useSelector(state => state.auth.loading)
}

export const useLogoutHandler = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(authLogoutAction())
  }, [dispatch])
}

export const useAuthRehydrated = () => {
  return useSelector(state => state.auth.rehydrated)
}

export const useAuthUser = () => {
  return useSelector(state => state.auth.user)
}

export const useResetPasswordRequest = () => {
  const dispatch = useDispatch()
  return useCallback(
    ({ newPassword, key }) => {
      dispatch(resetPasswordRequestAction(newPassword, key))
    },
    [dispatch]
  )
}

export const useResetPasswordRedirectChange = () => {
  const dispatch = useDispatch()
  return useCallback(
    redirect => {
      dispatch(resetPasswordRedirectAction(redirect))
    },
    [dispatch]
  )
}

export const useResetPasswordValidateKey = () => {
  const dispatch = useDispatch()
  return useCallback(
    key => {
      dispatch(resetPasswordValidateKeyAction(key))
    },
    [dispatch]
  )
}

export const useChangePasswordReset = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(changePasswordReset())
  }, [dispatch])
}

export const useChangePassword = () => {
  const dispatch = useDispatch()
  return useCallback(
    data => {
      dispatch(changePasswordRequest(data))
    },
    [dispatch]
  )
}

export const useResetPasswordRedirect = () => {
  return useSelector(state => state.auth.resetPasswordRedirect)
}

export const usePasswordUpdated = () => {
  return useSelector(state => state.auth.passwordUpdated)
}

export const useChangePasswordLoading = () => {
  return useSelector(state => state.auth.changePasswordLoading)
}
