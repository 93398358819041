import { useFormikContext } from "formik"
import { useEffect } from "react"

export const FormEnterListener = () => {
  const { handleSubmit, values } = useFormikContext()

  useEffect(() => {
    document.addEventListener("keypress", handleKeyPress)
    return () => {
      document.removeEventListener("keypress", handleKeyPress)
    }
  }, [])

  const handleKeyPress = event => {
    if (event.key === "Enter") {
      handleSubmit(values)
    }
  }

  return null
}
