import React, { useEffect, useState } from "react"
import { pathOr } from "ramda"
import { format } from "date-fns"
import { Box, Flex, Hidden, InlineFlex, styled } from "reakit"
import { theme } from "styled-tools"
import Layout from "common/Layout"
import PageToolbar from "common/PageToolbar"
import Paper from "common/Paper"
import Text from "common/Text"
import Divider from "common/Divider"
import Avatar from "common/Avatar"
import {
  useFindPerson,
  useFindPersonPermissions,
  useLoadingPerson,
  usePerson,
  usePersonPermissions,
  usePersonReset,
  usePersonTimePlaces,
  usePersonTimePlacesRequest,
} from "store/hooks/person"
import InfoBlock from "./InfoBlock"
import CredentialsTableList from "./CredentialsTableList"
import { LinkStyled } from "../Monitoring/index.styled"
import AlertsTableList from "./AlertsTableList"
import InfoBlockBeacon from "pages/EmployeeProfile/InfoBlockBeacon"
import TimeAndPlaceTableList from "../../common/TimeAndPlaceTableList"
import { TabPanel, TabView } from "primereact/tabview"
import TimeInPlacesDashboard from "pages/EmployeeProfile/TimeInPlacesDashboard"
import LoadingIndicator from "../../common/LoadingIndicator"
import { usePersonTimePlacesReset } from "../../store/hooks/person"

const Sidebar = styled(Flex)`
  flex-direction: column;
  flex-grow: 0;
  margin-right: ${theme("spacing.small")};
  position: relative;
  min-width: 350px;
  max-width: 350px;
`

const Content = styled(Flex)`
  flex-grow: 1;
  flex-direction: column;

  & > div {
    margin-bottom: ${theme("spacing.small")};
  }
`

const TableHeader = styled(InlineFlex)`
  align-items: center;
  padding: ${theme("spacing.medium")} ${theme("spacing.extrasmall")};
`

const AvatarWrapper = styled(Box)`
  margin-right: 10px;
`

const EmployeeProfile = props => {
  const [activeIndex, setActiveIndex] = useState(0)
  /*const arcs = 40
  const percent = 0.86
  const makeColorsChart = () => {
    const realPercent = percent * 100
    const arcsToPaint = (arcs * realPercent) / 100
    const arrayReturn = []
    for (let i = 0; i < arcs; i++) {
       (i <= arcsToPaint) {
        arrayReturn.push("#02d0c4")
      } else {
        arrayReturn.push("#caf3ef")
      }
    }
    return arrayReturn
  }*/

  const findTimePlaces = usePersonTimePlacesRequest()
  const resetTimePlaces = usePersonTimePlacesReset()
  const timePlaces = usePersonTimePlaces()
  const personReset = usePersonReset()
  const loading = useLoadingPerson()
  const findPersonDetails = useFindPerson()
  const findPersonPermissions = useFindPersonPermissions()
  const person = usePerson()
  const personPermissionsData = usePersonPermissions()
  const [beacons, setBeacons] = useState([])
  const [personPermissions, setPersonPermissions] = useState([])

  useEffect(() => {
    if (props.match.params.profileId) {
      personReset()
      resetTimePlaces()
      findPersonDetails(props.match.params.profileId, true)
      findTimePlaces({ personId: props.match.params.profileId, minSecondsToShow: 3 })
    }
  }, [findPersonDetails, findTimePlaces, props.match.params.profileId])

  useEffect(() => {
    if (props.match.params.profileId) {
      findPersonPermissions(props.match.params.profileId)
    }
    return () => personReset()
  }, [findPersonPermissions])

  useEffect(() => {
    let data = []
    if (personPermissionsData.content) {
      personPermissionsData.content.forEach(v => {
        data.push({
          title: v.name,
        })
      })
    }
    setPersonPermissions(data)
  }, [personPermissionsData])

  useEffect(() => {
    let data = []
    let activeMacAddress = null
    if (person && person.beacon) {
      const v = person.beacon
      activeMacAddress = v.macAddress
    }
    if (person && person.beacons && person.beacons.length > 0) {
      person.beacons.forEach(v => {
        data.push({
          macAddress: v.macAddress,
          dateDelivery: v.dateDelivery
            ? format(v.dateDelivery, "DD/MM/YYYY HH:mm")
            : "N/A",
          dateReturn: v.dateReturn
            ? format(v.dateReturn, "DD/MM/YYYY HH:mm")
            : "N/A",
          active: v.macAddress === activeMacAddress,
        })
      })
    }
    setBeacons(data)
  }, [person])

  return (
    <Layout>
      <PageToolbar title="Perfil pessoal" />
      <div style={{ display: "flex", width: "100%" }}>
        <Sidebar>
          <LoadingIndicator loading={loading}>
            <Paper>
              <Flex marginBottom={16}>
                <AvatarWrapper>
                  <Avatar size="8rem" />
                </AvatarWrapper>
                <Box>
                  <Text variant="display3" palette="primary">
                    {pathOr("n/a", ["name"], person)}
                  </Text>
                  <Text variant="body2">
                    {pathOr("n/a", ["registrationId"], person)}
                  </Text>
                </Box>
              </Flex>

              <Hidden>
                <Text
                  variant="body"
                  palette="primary"
                  margin="small"
                  uppercase
                  bold
                >
                  Último registro
                </Text>
                <InlineFlex width="100%">
                  <Box width="50%">
                    <Text variant="body2" palette="grayscale">
                      ...
                    </Text>
                  </Box>
                  <Box width="50%">
                    <Text variant="body2" palette="grayscale" align="right">
                      ...
                      {/* 13/03/2019 às 15:09 */}
                    </Text>
                  </Box>
                </InlineFlex>
                <Divider palette="primary" />
              </Hidden>
              {timePlaces && timePlaces.length > 0 && (
                <InfoBlock
                  title="Último registro"
                  items={[
                    {
                      title: "Local",
                      value: timePlaces[0].policy.name,
                    },
                    {
                      title: "Data e Hora",
                      value: format(
                        timePlaces[0].startDate,
                        "DD/MM/YYYY HH:mm"
                      ),
                    },
                  ]}
                />
              )}
              <InfoBlock
                title="Informações"
                items={[
                  {
                    title: "Documento",
                    value: pathOr("n/a", ["docId"], person),
                  },
                  {
                    title: "Data de nascimento",
                    value: pathOr("n/a", ["birthDate"], person),
                  },
                  {
                    title: "Turno",
                    value: pathOr("n/a", ["workShift.name"], person),
                  },
                ]}
              />

              <InfoBlock
                title="Atividades aptas"
                emptyState="Não há atividades cadastradas."
                items={personPermissions}
              />

              <InfoBlockBeacon
                title="Histórico de crachás"
                items={beacons}
                withoutDivider
              />
            </Paper>
          </LoadingIndicator>
        </Sidebar>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <TimeInPlacesDashboard personId={props.match.params.profileId} />
          <TabView
            style={{ width: "100%" }}
            activeIndex={activeIndex}
            onTabChange={e => setActiveIndex(e.index)}
          >
            <TabPanel header="Eventos">
              <InlineFlex width="100%">
                <Content style={{ maxWidth: "100%" }}>
                  {/* HISTORICO DE MOVIMENTACAO */}
                  <Paper>
                    <TableHeader>
                      <Text variant="body" uppercase>
                        Histórico de movimentação
                      </Text>
                    </TableHeader>
                    {timePlaces && <TimeAndPlaceTableList items={timePlaces} />}
                  </Paper>
                </Content>
              </InlineFlex>
            </TabPanel>
            <TabPanel header="Credenciais">
              <Content>
                {/* CREDENCIAIS */}

                <Paper>
                  <TableHeader>
                    <Text variant="body" uppercase>
                      Credenciais
                    </Text>
                  </TableHeader>
                  <CredentialsTableList
                    profileId={props.match.params.profileId}
                    limit={10}
                    renderActions={item => {
                      return (
                        <LinkStyled
                          to={`${props.match.path}/detalhes/${item.id}`}
                        >
                          <Text palette="secondary" variant="body3" uppercase>
                            Ver
                          </Text>
                        </LinkStyled>
                      )
                    }}
                  />
                </Paper>

                {/* ALERTAS */}
                <Paper >
                  <TableHeader>
                    <Text style={{paddingTop: '10rem'}} variant="body" uppercase>
                      Alertas
                    </Text>
                  </TableHeader>
                  <AlertsTableList
                    profileId={props.match.params.profileId}
                    limit={10}
                    // renderActions={item => {
                    //   return (
                    //     <LinkStyled to={`${props.match.path}/detalhes/${item.id}`}>
                    //       <Text palette="secondary" variant="body3" uppercase>
                    //         Ver
                    //       </Text>
                    //     </LinkStyled>
                    //   )
                    // }}
                  />
                </Paper>
              </Content>
            </TabPanel>
          </TabView>
        </div>
      </div>
    </Layout>
  )
}

export default EmployeeProfile

// <Paper>
// <DoughnutContainer className="animated fadeIn">
//   <DoughnutBox>
//   <Doughnut
// data={{
//   datasets: [
//     {
//       data: [19, 3],
//       backgroundColor: [
//         "rgba(75, 192, 192, 1)",
//         "rgb(227,227,227)",
//       ],
//       borderColor: [
//         "rgba(75, 192, 192, 1)",
//         "rgb(227,227,227)",
//       ],
//       borderWidth: 1,
//     },
//   ],
// }}
// options={{
//   cutoutPercentage: 70,
//     rotation: 1 * Math.PI,
//     circumference: 1 * Math.PI,
// }}
// />
// </DoughnutBox>
// </DoughnutContainer>
// </Paper>
