import React, { useEffect, useState } from "react"
import SideNavigationItem from "./SideNavigationItem"
import logoGo from "assets/images/logo-go.svg"
import {
  SideNavigationStyled,
  SideNavigationInner,
  SideLogo,
} from "./index.styled"

import { useNavigationSelectedModule } from "store/hooks/navigation"
import { MODULES } from "common/ModuleChangeV2"
import { useAuthUser } from "../../store/hooks/authentication"

const SideNavigation = () => {
  const [menuItems, setMenuItems] = useState([])
  const selectedModule = useNavigationSelectedModule()
  const authUser = useAuthUser()

  useEffect(() => {
    if (selectedModule) {
      const moduleFind = MODULES.find(module => module.id === selectedModule)
      setMenuItems(moduleFind ? moduleFind.menuItems : [])
    }
  }, [selectedModule])

  return (
    <SideNavigationStyled use="nav">
      <SideNavigationInner use="ul">
        {menuItems
          .filter(m => {
            return !(
              m.title === "Headcount" &&
              authUser &&
              authUser.company &&
              authUser.company.id !== "5cf55339934f000001fa5792"
            )
          })
          .map((item, key) => {
            return (
              <SideNavigationItem key={key} to={item.to} title={item.title}>
                <item.Icon />
              </SideNavigationItem>
            )
          })}
      </SideNavigationInner>
      <SideLogo>
        <img src={logoGo} alt="Logotipo ByondGO!" />
      </SideLogo>
    </SideNavigationStyled>
  )
}

export default SideNavigation
