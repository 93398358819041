import { applyMiddleware, createStore } from "redux"
import createSagaMiddleWare from "redux-saga"
import reducers from "./ducks/index"
import rootSaga from "./saga/index"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import { authSetRehydrated } from "store/ducks/authentication"

const persistConfig = {
  key: "smartaccess",
  storage,
  whitelist: ["auth", "navigation"],
}

const persistedReducer = persistReducer(persistConfig, reducers)

const sagaMiddleware = createSagaMiddleWare()

const store = createStore(persistedReducer, applyMiddleware(sagaMiddleware))

export let persistor = persistStore(store, {}, () => {
  // store.getState().auth.rehydrated = true ---- ISSO TBM RESOLVE MAS NAO É BONITO
  store.dispatch(authSetRehydrated(true))
})

export default {
  store,
  persistor,
}

sagaMiddleware.run(rootSaga)
