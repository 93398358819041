import React from "react"
import {withRouter} from "react-router-dom"
import Table, {ActionData, ActionHeader, ShowMoreItens} from "../Table";
import LoadingIndicator from "../LoadingIndicator";
import {useEmergencyAlertLast} from "../../store/hooks/alerts";

const GroupAlertList = props => {
  const last = props.last ? props.last : useEmergencyAlertLast()

  return (
    <>
      <Table>
        <thead>
        <tr>
          <th
          >
            <label>Grupo</label>
          </th>
          <th
          >
            <label>Emails</label>
          </th>
          <ActionHeader>Ações</ActionHeader>
        </tr>
        </thead>
        <tbody>
        {props.items
          ? props.items.map((item, key) => {
            return (
              <tr
                key={key}
                style={{cursor: "pointer"}}
                data-testid="table-row"
              >
                <td>{item.friendlyName}</td>
                <td>
                  {item.emails.map((x) =>
                    <p>
                      {x.email}
                    </p>
                  )}
                </td>
                <ActionData>{props.renderActions(item)}</ActionData>
              </tr>
            )
          })
          : ""}
        </tbody>
      </Table>
      {props.loading && <LoadingIndicator loading={true}/>}
      {!props.loading && !last && (
        <ShowMoreItens handleClick={props.loadMore}/>
      )}
    </>
  )
}
export default withRouter(GroupAlertList)
