import {ReactComponent as IconEmployeeAbsent} from "assets/images/icon-employee-absent.svg"
import {ReactComponent as IconEmployeeAbsentBig} from "assets/images/icon-employee-absent-big.svg"
import {ReactComponent as IconEmployeeAvailable} from "assets/images/icon-employee-available.svg"
import {ReactComponent as IconActiveBadges} from "assets/images/icon-active-badges.svg"
import {ReactComponent as IconEmergencyAlert} from "assets/images/alert.svg"
import {ReactComponent as IconNewOrder} from "assets/images/icon-new-order.svg"
import {ReactComponent as IconReadyOrders} from "assets/images/icon-ready-orders.svg"
import GaugeChart from "react-gauge-chart"
import Button from "common/Button"
import Layout from "common/Layout"
import Link from "common/Link"
import PageToolbar from "common/PageToolbar"
import Paper from "common/Paper"
import RadarDashBoard from "common/RadarDashboard"
import Text from "common/Text"
import {format} from "date-fns"
import CredentialReport from "pages/Monitoring/CredentialReport"
import MonitoringDetails from "pages/MonitoringDetails"
import {parse as parseQueryString} from "query-string"
import React, {useCallback, useEffect, useState} from "react"
import {Switch} from "react-router-dom"
import {
  GaugeBox,
  GaugeContainer,
  GaugeLabelText,
  GaugeReport,
  LastViolationContainer,
  LinkStyled,
  MonitoringGrid,
  PaperGroup,
  TableHeader,
  ViolationBox,
  ViolationTitle,
  WhiteBox,
  WhiteBoxInfo,
} from "./index.styled"
import MonitoringTableList from "./MonitoringTableList"
import {
  useFindMonitoringAlerts,
  useFindMonitoringData,
  useMonitoringAlerts,
  useMonitoringData,
} from "store/hooks/monitoring"
import {FaChevronRight} from "react-icons/fa"
import {
  useAlertsDashboard,
  useAlertsLoading, useEmergencyTotalElements,
  useLoadAlertsBlocked,
  useLoadAlertsIrregular, useLoadTriggedButtons, useTriggedButtons,
} from "store/hooks/alerts"
import PrivateRoute from "common/PrivateRoute"
import {
  useMonitoringDataAlertsLoading,
  useMonitoringDataLoading,
} from "../../store/hooks/monitoring"

const Monitoring = props => {
  const loading = useMonitoringDataLoading()
  const loadingAlerts = useMonitoringDataAlertsLoading()
  const [pageAlert, setPageAlert] = useState(0)
  const [resumeBlock, setResumeBlock] = useState([
    {
      title: "Presenças do dia",
      value: 0,
      icon: IconEmployeeAvailable,
    },
    {
      title: "Total de crachás ativos",
      value: 0,
      icon: IconActiveBadges,
    },
    {
      title: "Acionamento de trava de alerta",
      value: 0,
      icon: IconEmergencyAlert,
    },
    {
      title: "Trabalhadores com credencial vencida",
      value: 0,
      icon: IconEmployeeAbsent,
    },
    {
      title: "Novos pedidos de credenciamento",
      value: 0,
      icon: IconNewOrder,
    },
    {
      title: "Pedidos finalizados",
      value: 0,
      icon: IconReadyOrders,
    },
  ])
  const [percentCredentialOk, setPercentCredentialOk] = useState()
  const [totalCredentialExpiried, setTotalCredentialExpiried] = useState()

  const monitoringData = useMonitoringData()
  const monitoringAlerts = useMonitoringAlerts()
  const findMonitoringData = useFindMonitoringData()
  const findMonitoringAlerts = useFindMonitoringAlerts()

  const params = parseQueryString(props.location.search)
  const arcs = 40
  const makeColorsChart = () => {
    const realPercent = percentCredentialOk * 100
    const arcsToPaint = (arcs * realPercent) / 100
    const arrayReturn = []
    for (let i = 0; i < arcs; i++) {
      if (i <= arcsToPaint) {
        arrayReturn.push("#02d0c4")
      } else {
        arrayReturn.push("#caf3ef")
      }
    }
    return arrayReturn
  }
  const formatCredentialsOk = () => {
    if (percentCredentialOk) {
      return `${(percentCredentialOk * 100).toFixed(0)}%`
    }
    return "Carregando..."
  }
  const linkCredentials = (resumeBlock, key) => {
    if (resumeBlock.button) {
      return <div>
        <a href={`${resumeBlock.link}`}>
          <WhiteBox key={key} className="animated fadeIn">
            <WhiteBoxInfo setOpacity={loading || loadingAlerts}>
              <span>{resumeBlock.value}</span>
              <label style={{cursor:"pointer"}}>{resumeBlock.title}</label>
            </WhiteBoxInfo>
            <resumeBlock.icon
              style={{opacity: loading || loadingAlerts ? "0.4" : "1"}}
            />
          </WhiteBox>
        </a>
      </div>
    }
  }
  const loadMoreAlerts = useCallback(() => {
    if (!loading) {
      setPageAlert(pageAlert + 1)
      findMonitoringAlerts(pageAlert, 10)
    }
  }, [loading, pageAlert])

  useEffect(() => {
    findMonitoringData()
    findMonitoringAlerts(pageAlert, 10)
    setPageAlert(pageAlert + 1)
  }, [])

  useEffect(() => {
    if (!monitoringData) {
      return
    }
    let total = monitoringData && monitoringData.total
    let totalCredentialOk = monitoringData && monitoringData.totalCredentialOk
    let totalCredentialExpiried = monitoringData && monitoringData.totalCredentialExpiried

    setPercentCredentialOk(totalCredentialOk > 0 ? `${totalCredentialOk / total}` : "0")
    setTotalCredentialExpiried(totalCredentialExpiried > 0 ? `${totalCredentialExpiried / total}` : "0")
    setResumeBlock([
      {
        title: "Presenças do dia",
        value: monitoringData.totalPersonViolatedTodays || 0,
        icon: IconEmployeeAvailable,
      },
      {
        title: "Total de crachás ativos",
        value: monitoringData.totalBeaconActives || 0,
        icon: IconActiveBadges,
      },
      {
        title: "Acionamento de trava de alerta",
        value:  monitoringData.totalTriggedButtons || 0,
        icon: IconEmergencyAlert,
        link : '/#/emergency-alerts',
        button: true,
      },
      {
        title: "Trabalhadores com credencial vencida",
        value: monitoringData.expireds || 0,
        icon: IconEmployeeAbsent,
        button: true,
        link : '/#/expired-credentials'
      },
      {
        title: "Novos pedidos de credenciamento",
        value: monitoringData.news || 0,
        icon: IconNewOrder,
        button: true,
        link : '/#/credenciamento'
      },
      {
        title: "Pedidos finalizados",
        value: monitoringData.finalized || 0,
        icon: IconReadyOrders,
      },
    ])
  }, [monitoringData])
  return (
    <Layout>
      <PageToolbar title="Gerenciamento total">
        {/*<PageToolbarContent>
          <InlineFlex alignItems="center">
            <Chip
              uppercase
              className={classnames({
                active: isYesterday(params.startDate),
              })}
            >
              Ontem
            </Chip>
            <Chip
              uppercase
              className={classnames({
                active: isToday(params.startDate),
              })}
            >
              Hoje
            </Chip>
            <InlineFlex marginLeft={20}>
              <Text margin="none" variant="body2">
                Trabalho especial:
              </Text>
              <Input use="select" marginLeft={10} fontSize={14}>
                <option>Todos</option>
                <option>Trabalho em altura</option>
              </Input>
            </InlineFlex>
          </InlineFlex>
          <Link to="/pedidos-de-credenciais/novo">
            <Button bold palette="secondary">
              Novo pedido
            </Button>
          </Link>
        </PageToolbarContent>*/}
      </PageToolbar>
      <MonitoringGrid>
        <ViolationContainer monitoringData={monitoringData}/>

        <GaugeContainer
          className="animated fadeIn"
          setOpacity={loading || loadingAlerts}
        >
          <GaugeBox>
            <GaugeChart
              formatTextValue={formatCredentialsOk}
              marginInPercent={0}
              id="gauge-chart2"
              nrOfLevels={arcs}
              percent={percentCredentialOk}
              // textColor={loading || loadingAlerts ? "#e4e4e4" : "#02d0c4"}
              textColor="rgb(185, 185, 185)"
              needleColor="transparent"
              needleBaseColor="transparent"
              colors={makeColorsChart()}
              style={{fontWeight: "bold", fontSize: 30}}
            />
            <GaugeLabelText setOpacity={loading || loadingAlerts}>
              dos trabalhadores presentes estão com as credenciais em dia
            </GaugeLabelText>
          </GaugeBox>
          <GaugeReport setOpacity={loading || loadingAlerts}>
            {totalCredentialExpiried ?
              <span>
              <strong>{`${(totalCredentialExpiried * 100).toFixed(0)}%`}</strong> dos trabalhadores
              </span>
              :
              <span>
              <strong>Carregando...</strong>
              </span>
            }
            <label>estão com as credenciais irregulares</label>
            <Link to={`/monitoramento/relatorio-credenciais`}>
              <Button palette="primary">Ver relatório</Button>
            </Link>
          </GaugeReport>
        </GaugeContainer>
        {resumeBlock.map((resumeBlock, key) => (
          <>
            {resumeBlock.button ?
              linkCredentials(resumeBlock)
              :
              <WhiteBox key={key} className="animated fadeIn">
                <WhiteBoxInfo setOpacity={loading || loadingAlerts}>
                  <span>{resumeBlock.value}</span>
                  <label>{resumeBlock.title}</label>
                </WhiteBoxInfo>
                <resumeBlock.icon
                  style={{opacity: loading || loadingAlerts ? "0.4" : "1"}}
                />
              </WhiteBox>
            }
          </>
        ))
        }
      < /MonitoringGrid>
      <PaperGroup className="animated fadeIn" alignItems="flex-start">
        <Paper marginBottom="20px">
          <TableHeader>
            <Text variant="body" uppercase bold>
              Alertas
            </Text>
          </TableHeader>
          <RadarDashboardContainer/>
        </Paper>
        <Paper marginBottom="20px">
          <MonitoringTableList
            alerts={monitoringAlerts || []}
            loadMore={loadMoreAlerts}
            history={props.history}
            renderActions={item => {
              return (
                <LinkStyled to={`${props.match.path}/detalhes/${item.id}`}>
                  <FaChevronRight/>
                </LinkStyled>
              )
            }}
          />
        </Paper>
      </PaperGroup>
      <Switch>
        <PrivateRoute
          path={`${props.match.path}/relatorio-credenciais`}
          component={CredentialReport}
        />
        <PrivateRoute
          path={`${props.match.path}/detalhes/:id`}
          component={MonitoringDetails}
        />
      </Switch>
    </Layout>
  )
}

const RadarDashboardContainer = () => {
  const loading = useAlertsLoading()
  const alerts = useAlertsDashboard()
  const findAlertsBlocked = useLoadAlertsBlocked()
  const findAlertsIrregular = useLoadAlertsIrregular()

  return (
    <RadarDashBoard
      alerts={alerts}
      loading={loading}
      firstTabRequest={findAlertsBlocked}
      seccondTabRequest={findAlertsIrregular}
    />
  )
}

const ViolationContainer = ({monitoringData}) => {
  const loadingAlerts = useMonitoringDataAlertsLoading()
  const loading = useMonitoringDataLoading()
  const monitoringValue =
    monitoringData && monitoringData.lastAlert
      ? monitoringData.lastAlert.total
      : null

  const getMonitoringText = value => {
    if (value === 0) {
      return "Nenhum alerta nas últimas 24h!"
    } else if (value === 1) {
      return `Aconteceu um alerta nas últimas 24h.`
    } else if (value > 0) {
      return `${value} alertas aconteceram nas últimas 24h.`
    }
    return "Carregando dados..."
  }

  const getMonitoringBackground = useCallback(() => {
    if (!loadingAlerts && !loading) {
      if (monitoringValue === 0) {
        return "#92e4e0"
      } else if (monitoringValue > 10) {
        return "#fc8f8f"
      } else {
        return "#faf86f"
      }
    }
    return "#fff"
  }, [monitoringValue, loadingAlerts, loading])

  return (
    <ViolationBox
      className="animated fadeIn"
      backgroundColor={getMonitoringBackground}
    >
      <ViolationTitle setOpacity={loading || loadingAlerts}>
        {getMonitoringText(monitoringValue)}
      </ViolationTitle>
      <>
        {monitoringValue > 0 && (
          <LastViolationContainer setOpacity={loading || loadingAlerts}>
            <IconEmployeeAbsentBig/>
            <span>
        O último alerta aconteceu às{""}
              {monitoringData
                ? format(
                  monitoringData.lastAlert.lastAlert.eventDate,
                  "H:mm [do dia] DD/MM/YYYY[.]"
                )
                : format(new Date(), "H:mm [do dia] DD/MM/YYYY[.]")}
        </span>
          </LastViolationContainer>
        )}
        <Link to={`/alertas`}>
          <Button palette="primary" style={{opacity: loading ? "0.4" : "1"}}>
            Ver relatório
          </Button>
        </Link>
      </>
    </ViolationBox>
  )
}

export default Monitoring
