import { call, put, select, takeLatest } from "redux-saga/effects"
import api from "../../services/api"
import API from "../../lib/api"
import { findCheckpointsSuccessAction } from "../ducks/checkpoint"
import { concat, isEmpty } from "ramda"
import { FIND_CHECKPOINTS_REQUEST } from "../ducks/checkpoint"

export function* findCheckpoints(action) {
  try {
    const { page } = action.payload
    const filter = JSON.parse(localStorage.getItem("filter"))
    const sort = JSON.parse(localStorage.getItem("sort"))
    const emptyFilter = filter ? !isEmpty(filter.filter) : false
    const findCheckpointsApiCall = () => {
      let params
      params = {
        page,
        size: 200,
        sort:
          sort && sort.checkpoint
            ? sort.checkpoint.sortString
            : "friendlyName,asc",
      }
      if (filter && filter.checkpoint) {
        filter.checkpoint.forEach(f => {
          params[f.field] = f.value
        })
      }
      return api
        .get(`${API.CLOUD}/nodes`, {
          params,
        })
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    const { checkpoints } = yield select(state => state.checkpoint)
    const { content, totalPage, first, last } = yield call(
      findCheckpointsApiCall
    )
    if (!first) {
      yield put(
        findCheckpointsSuccessAction(
          concat(checkpoints, content || []),
          totalPage,
          last,
          emptyFilter
        )
      )
    } else {
      yield put(
        findCheckpointsSuccessAction(content, totalPage, last, emptyFilter)
      )
    }
  } catch (err) {
    console.log(err)
    // yield put(authFailedAction(err));
  }
}

export default [takeLatest(FIND_CHECKPOINTS_REQUEST, findCheckpoints)]
