import React from "react"
import InputLabel from "common/InputLabel"
import Input from "@material-ui/core/Input/Input"
import MenuItem from "@material-ui/core/MenuItem/MenuItem"
import Select from "@material-ui/core/Select/Select"
import { styled } from "reakit"
import { theme } from "styled-tools"
import Checkbox from "common/Checkbox"
import { useFormikContext } from "formik"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const StyledMultiSelect = styled(Select)`
  min-width: 100%;
  max-width: 336px;
  .MuiSelect-select {
    font-size: ${theme("typography.size.body2")};
    &:focus {
      background: transparent;
    }
  }
`

const Container = styled.div`
  margin-bottom: ${theme("spacing.big")};
`

const MultiSelectCheckboxV2 = props => {
  const {
    id,
    value,
    uniqueProp,
    name,
    list,
    valueProperty,
    propertyToShow,
    sideEffectChangeFn,
  } = props
  const { setFieldValue } = useFormikContext()

  const handleCheckChange = (event, item) => {
    const isAdd = event.target.checked
    let valueResult = []
    if (isAdd) {
      valueResult = [...value, uniqueProp ? item[valueProperty] : item]
    } else {
      valueResult = value.filter(i => {
        return i !== (uniqueProp ? item[valueProperty] : item)
      })
    }
    handleChange(valueResult)
    if (sideEffectChangeFn) {
      sideEffectChangeFn(valueResult)
    }
  }

  const handleChange = event => {
    if (name) {
      const value = event
      setFieldValue(name, value)
    }
  }

  return (
    <Container>
      <InputLabel id={props.id}>{props.label}</InputLabel>
      <StyledMultiSelect
        labelId={id}
        id={id}
        value={value}
        multiple
        input={<Input />}
        MenuProps={MenuProps}
        renderValue={selected => {
          const retorno = list
            .filter(i =>
              selected
                .map(s => (s[valueProperty] ? s[valueProperty] : s))
                .includes(i[valueProperty])
            )
            .map(i => (propertyToShow ? i[propertyToShow] : i.label))
          return retorno.join(", ")
        }}
      >
        {(list || []).map((item, key) => {
          return (
            <MenuItem key={key} value={uniqueProp ? item[valueProperty] : item}>
              <Checkbox
                onChange={event => handleCheckChange(event, item)}
                checked={
                  value
                    .map(v => (v[valueProperty] ? v[valueProperty] : v))
                    .indexOf(item[valueProperty]) > -1
                }
              />
              {propertyToShow ? item[propertyToShow] : item.label}
            </MenuItem>
          )
        })}
      </StyledMultiSelect>
    </Container>
  )
}

export default MultiSelectCheckboxV2
