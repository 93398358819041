import React, { useState } from "react"
import Text from "common/Text"
import Divider from "common/Divider"

const PersonDestroyInfo = props => {
  const [person] = useState(props.person)
  // const [credentials] = useState(props.credentials)
  return (
    <>
      {person && (
        <>
          <Text palette="primary" variant="h4" align="left" margin="big" bold>
            {person.name}
          </Text>
          <Text
            palette="grayscale"
            variant="body2"
            align="center"
            margin="medium"
          >
            {person.id}
          </Text>

          {/*{credentials.map(item => {
            return (
              <InlineFlex alignItems="flex-start" alignSelf="center">
                <Text
                  palette={getStatusPalette("IRREGULAR")}
                  variant="body2"
                  align="center"
                  uppercase
                  bold
                  margin="medium"
                >
                  STATUS:
                </Text>
                <Tag palette={getStatusPalette("IRREGULAR")} marginLeft>
                  {getStatusLabel("IRREGULAR")}
                </Tag>
              </InlineFlex>
            )
          })}*/}

          <Divider palette="primary" />

          <Text
            palette="primary"
            variant="h6"
            align="left"
            margin="big"
            uppercase
            bold
            marginTop="medium"
          >
            Informações
          </Text>
          <Text
            palette="primary"
            variant="body2"
            align="left"
            margin="medium"
            marginLeft="big"
            uppercase
          >
            Documento: {person.docId}
          </Text>
          <Text
            palette="primary"
            variant="body2"
            align="left"
            margin="medium"
            marginLeft="big"
            uppercase
          >
            Data de Nascimento:{" "}
            {person.birthDate
              ? new Date(person.birthDate).toLocaleDateString()
              : null}
          </Text>
          <Text
            palette="primary"
            variant="body2"
            align="left"
            margin="medium"
            marginLeft="big"
            uppercase
          >
            Turno: {person.shift}
          </Text>

          <Divider palette="primary" />

          <Text
            palette="primary"
            variant="h6"
            align="left"
            margin="big"
            uppercase
            bold
            marginTop="medium"
          >
            Crachá Ativo
          </Text>
          <Text
            palette="primary"
            variant="body2"
            align="left"
            margin="medium"
            marginLeft="big"
            uppercase
          >
            Número: {person.beacon ? person.beacon.id : ""}
          </Text>
          <Text
            palette="primary"
            variant="body2"
            align="left"
            margin="medium"
            marginLeft="big"
            uppercase
          >
            Ativado em:{" "}
            {person.beacon && person.beacon.lastSeen
              ? person.beacon.lastSeen
              : ""}
          </Text>
        </>
      )}
    </>
  )
}

export default PersonDestroyInfo
