import React from "react"
import { Box } from "reakit"
import InputLabel from "common/InputLabel"
import FieldRadiobox from "common/FieldRadiobox"

const StepCredentialType = () => {
  return (
    <Box>
      <InputLabel htmlFor="credential-special">
        Qual o tipo de credencial?
      </InputLabel>
      <FieldRadiobox
        name="credential-type"
        id="credential-special"
        label="Credencial especial"
        value="credential-special"
      />
      <FieldRadiobox
        name="credential-type"
        id="credential-required"
        label="Habilidade obrigatória"
        value="credential-required"
      />
    </Box>
  )
}

export default StepCredentialType
