import Table, {ActionData, ActionHeader, ShowMoreItens} from "common/Table"
import React from "react"
import {withRouter} from "react-router-dom"
import {GetActivatedSorted} from "../Sort"
import SortLabel from "../SortLabel"
import Filter, {GetActivatedFilter} from "../Filter"
import {useLastPageReport, useLoadCredentialsExpired, useLoadingReport} from "../../store/hooks/report";
import LoadingIndicator from "../LoadingIndicator";
import {format} from "date-fns";

const CredentialExpiredList = props => {

  const loadingPersonCredential = useLoadingReport()

  const goToProfile = id => {
    props.history.push(`/perfil/${id}`)
  }

  return (
    <>
      <Table>
        <thead>
        <tr>
          <th
          >
            <label>Nome</label>
          </th>
          <th
          >
            <label>Credencial</label>
          </th>
          <th
          >
            <label>Expirou em</label>
          </th>
          <ActionHeader>Renovar</ActionHeader>
        </tr>
        </thead>
        <tbody>
        {props.items
          ? props.items.map((item, key) => {
            return (
              <tr
                key={key}
                style={{cursor: "pointer"}}
                data-testid="table-row"
              >
                <td onClick={() => goToProfile(item.person.id)}>{item.person && item.person.name}</td>
                <td onClick={() => goToProfile(item.id)}>
                  {item.credential && item.credential.name}
                </td>
                <td onClick={() => goToProfile(item.person.id)}>
                  {format(item.expiryIn, "DD/MM/YYYY hh:mm")}
                </td>
                <ActionData>{props.renderActions(item)}</ActionData>
              </tr>
            )
          })
          : ""}
        </tbody>
      </Table>
      {loadingPersonCredential && <LoadingIndicator loading={true}/>}
      {!loadingPersonCredential &&  !props.hasMore && (
        <ShowMoreItens handleClick={props.loadMore}/>
      )}
    </>
  )
}
export default withRouter(CredentialExpiredList)
