import React, { useEffect } from "react"
import { ErrorMessage, Formik } from "formik"
import { ValidationSchema } from "./ValidationSchema"
import {
  Footer,
  FormContainer,
  InfoBox,
  Input,
  SubmitButton,
  GoLoginButton,
  InputLabel,
} from "./styles"
import { ErrorContainer, ErrorLabel } from "common/Error"
import {
  useResetPasswordRedirect,
  useResetPasswordRedirectChange,
  useResetPasswordRequest,
  useResetPasswordValidateKey,
} from "store/hooks/authentication"
import { withRouter } from "react-router-dom"
import LoginContainer from "common/LoginContainer"

const ResetPassword = props => {
  const redirectToLogin = useResetPasswordRedirect()
  const resetPasswordRedirectChange = useResetPasswordRedirectChange()
  const resetPasswordAction = useResetPasswordRequest()
  const validateKeyAction = useResetPasswordValidateKey()
  const { key } = props.match.params

  const initialValues = {
    newPassword: "",
    newPasswordConfirm: "",
  }

  const handleResetPassword = formValue => {
    resetPasswordAction({ ...formValue, key })
  }

  const handleGoToLogin = () => {
    props.history.push("/login")
  }

  useEffect(() => {
    if (redirectToLogin) {
      resetPasswordRedirectChange(false)
      handleGoToLogin()
    }
  }, [redirectToLogin])

  useEffect(() => {
    validateKeyAction(key)
  }, [key])

  return (
    <LoginContainer>
      <Formik
        initialValues={initialValues}
        onSubmit={handleResetPassword}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
      >
        {({ values, handleChange, handleSubmit }) => (
          <>
            <FormContainer>
              <InfoBox>
                Para configurar sua nova senha, preencha os campos abaixo.
              </InfoBox>
              <InputLabel>Nova senha</InputLabel>
              <Input
                placeholder="Nova senha"
                name="newPassword"
                onChange={handleChange}
                value={values.newPassword}
                type="password"
              />
              <InputLabel>Repita a nova senha</InputLabel>
              <Input
                placeholder="Confirme a nova senha"
                name="newPasswordConfirm"
                onChange={handleChange}
                value={values.newPasswordConfirm}
                type="password"
              />
              <ErrorContainer>
                <ErrorMessage
                  name="newPassword"
                  render={msg => <ErrorLabel>{msg}</ErrorLabel>}
                />
                <ErrorMessage
                  name="newPasswordConfirm"
                  render={msg => <ErrorLabel>{msg}</ErrorLabel>}
                />
              </ErrorContainer>
              <Footer>
                <SubmitButton type="submit" onClick={handleSubmit}>
                  Atualizar
                </SubmitButton>
                <GoLoginButton onClick={handleGoToLogin}>
                  Efetuar login
                </GoLoginButton>
              </Footer>
            </FormContainer>
          </>
        )}
      </Formik>
    </LoginContainer>
  )
}

export default withRouter(ResetPassword)
