export const HEADCOUNTS_FIND_REQUEST = "HEADCOUNTS_FIND_REQUEST"
export const HEADCOUNTS_FIND_SUCCESS = "HEADCOUNTS_FIND_SUCCESS"
export const HEADCOUNTS_FIND_DETAIL_REQUEST = "HEADCOUNTS_FIND_DETAIL_REQUEST"
export const HEADCOUNTS_FIND_DETAIL_SUCCESS = "HEADCOUNTS_FIND_DETAIL_SUCCESS"
export const HEADCOUNTS_FIND_DASHBOARD_DATA_REQUEST =
  "HEADCOUNTS_FIND_DASHBOARD_DATA_REQUEST"
export const HEADCOUNTS_FIND_DASHBOARD_DATA_SUCCESS =
  "HEADCOUNTS_FIND_DASHBOARD_DATA_SUCCESS"
export const HEADCOUNTS_FIND_ERROR = "HEADCOUNTS_FIND_ERROR"
export const HEADCOUNTS_ERROR = "HEADCOUNTS_ERROR"

export const findHeadcountsRequestAction = filter => ({
  type: HEADCOUNTS_FIND_REQUEST,
  payload: {
    filter,
  },
})

export const findHeadcountsSuccessAction = headcounts => ({
  type: HEADCOUNTS_FIND_SUCCESS,
  payload: {
    headcounts,
  },
})
export const findHeadcountDetailRequestAction = (id, time) => ({
  type: HEADCOUNTS_FIND_DETAIL_REQUEST,
  payload: {
    id,
    time,
  },
})

export const findHeadcountDetailSuccessAction = headcount => ({
  type: HEADCOUNTS_FIND_DETAIL_SUCCESS,
  payload: {
    headcount,
  },
})

export const findHeadcountsDashboardDataRequestAction = (policyId) => ({
  type: HEADCOUNTS_FIND_DASHBOARD_DATA_REQUEST,
  payload: {
    policyId,
  },
})

export const findHeadcountsDashboardDataSuccessAction = (
  headcountsDashboard,
  policyId
) => ({
  type: HEADCOUNTS_FIND_DASHBOARD_DATA_SUCCESS,
  payload: {
    headcountsDashboard,
    policyId,
  },
})

export const headCountsError = error => ({
  type: HEADCOUNTS_ERROR,
  payload: {
    error,
  },
})

const INITIAL_STATE = {
  error: null,
  loading: false,
  headcounts: [],
  headcountsDashboard: {},
  headcount: null,
}

const headcountReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HEADCOUNTS_FIND_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case HEADCOUNTS_FIND_DETAIL_SUCCESS:
      const { headcount } = action.payload
      return {
        ...state,
        headcount,
        loading: false,
      }
    case HEADCOUNTS_FIND_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case HEADCOUNTS_FIND_SUCCESS:
      const { headcounts } = action.payload
      return {
        ...state,
        headcounts,
        loading: false,
      }
    case HEADCOUNTS_FIND_DASHBOARD_DATA_REQUEST: {
      const { headcountsDashboard } = INITIAL_STATE
      return {
        ...state,
        headcountsDashboard,
      }
    }
    case HEADCOUNTS_FIND_DASHBOARD_DATA_SUCCESS:
      const { headcountsDashboard, policyId } = action.payload
      return {
        ...state,
        headcountsDashboard: {
          ...state.headcountsDashboard,
          [policyId]: headcountsDashboard,
        },
        loading: false,
      }
    case HEADCOUNTS_ERROR:
      const { error } = action.payload
      return {
        ...state,
        loading: false,
        error,
      }
    default:
      return state
  }
}

export default headcountReducer
