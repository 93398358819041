import React from "react"
import { pathOr } from "ramda"
import { format } from "date-fns"
import Table, { ActionData, ActionHeader, ShowMoreItens } from "common/Table"
import Link from "common/Link"
import { useMonitoringLoading } from "store/hooks/map"
import { useMonitoringLastAlerts } from "store/hooks/monitoring"
import LoadingIndicator from "common/LoadingIndicator"
import EmptyStateMessage from "common/EmptyStateMessage"

const MonitoringTableList = props => {
  const loading = useMonitoringLoading()
  const last = useMonitoringLastAlerts()
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Colaborador</th>
            <th>Local</th>
            <th>Data/hora</th>
            <ActionHeader width="50px" />
          </tr>
        </thead>
        <tbody>
          {props.alerts.map((alert, key) => {
            return (
              <tr key={key} data-testid="table-row">
                <td>
                  <Link to={`/perfil/${alert.person.id}`}>
                    {pathOr("-", ["person", "name"], alert)}
                  </Link>
                </td>
                <td>{pathOr("-", ["policy", "name"], alert)}</td>
                <td>{format(alert.eventDate, "DD/MM/YYYY [às] HH:mm")}</td>
                <ActionData width="50px">
                  {props.renderActions(alert)}
                </ActionData>
              </tr>
            )
          })}
        </tbody>
      </Table>
      {(!props.alerts || props.alerts.length === 0) && (
        <EmptyStateMessage textAlign="center">
          Não há alertas cadastrados.
        </EmptyStateMessage>
      )}
      {loading && <LoadingIndicator loading={true} />}
      {!loading && !last && (
        <ShowMoreItens handleClick={() => props.history.push(`/alertas`)} />
      )}
    </>
  )
}

export default MonitoringTableList
