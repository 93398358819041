import React, { useState } from "react"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import { useCreateCredentialHandlers } from "../../../store/hooks/credential"
import CredentialForm from "../CredentialForm"

const CredentialCreate = props => {
  const createCredentialRequest = useCreateCredentialHandlers()
  const [credential] = useState({
    categoria: null,
    responsavel: null,
    name: "",
    validityInDays: "",
  })

  const onSubmit = values => {
    createCredentialRequest(values)
  }

  return (
    <LayoutSidebarOverlay
      title="Nova credencial"
      afterClose={() => {
        props.history.push("/credenciais")
      }}
    >
      {({ closeSidebar }) => {
        return (
          <CredentialForm
            closeSidebar={closeSidebar}
            onSubmit={onSubmit}
            enableReinitialize={true}
            initialValues={credential}
          />
        )
      }}
    </LayoutSidebarOverlay>
  )
}

export default CredentialCreate
