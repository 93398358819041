import { Button as ReakitButton, styled } from "reakit"
import { AutoComplete } from "primereact/autocomplete"
import { palette, theme } from "styled-tools"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledAutoComplete = styled(AutoComplete)`
  width: 100% !important;
  margin-bottom: ${theme("spacing.big")};
  input {
    background-color: ${palette("white")};
    border: none !important;
    border-bottom: 1px solid #3f3f3f !important;
    box-sizing: border-box;
    color: ${palette("grayscale")};
    display: block;
    font-size: ${theme("typography.size.body2")};
    width: 100%;
    border-radius: 0 !important;
    box-shadow: none !important;
    padding: 0 !important;
  }
`

export const ButtonStyled = styled(ReakitButton)`
  background: ${({ secondary }) => (secondary ? `transparent` : `#145269`)};
  border: 2px solid #145269;
  padding: 2.5% 5%;
  width: 45%;
  border-radius: 8px;
  text-transform: uppercase;
  font-size: small;
  color: ${({ secondary }) => (secondary ? `#145269` : `white`)};
  position: relative;
  left: 55%;
`
