import React, { useEffect } from "react"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import LoadingIndicator from "common/LoadingIndicator"
import ManageLicense from "./ManageLicense"
import {
  usePermissionRequest,
  usePermissionRequestFind,
  usePermissionRequestLoading,
  useUpdatePermissionRequestSuccess,
} from "store/hooks/permission-request"

const PermissionManagementLicense = props => {
  const { id } = props.match.params
  const findPermissionRequest = usePermissionRequestFind()
  const updateSuccess = useUpdatePermissionRequestSuccess()
  const permissionRequest = usePermissionRequest()
  const loading = usePermissionRequestLoading()


  useEffect(() => {
    if (id) {
      findPermissionRequest(id)
    }
  }, [id, findPermissionRequest])

  useEffect(() => {
    if (updateSuccess && id) {
      findPermissionRequest(id)
    }
  }, [updateSuccess, id])

  return (
    <LayoutSidebarOverlay
      title="Credenciar"
      afterClose={() => {
        props.history.push("/credenciamento")
      }}
    >
      {() => {
        return (
          <LoadingIndicator loading={loading}>
            <ManageLicense permissionRequest={permissionRequest} />
          </LoadingIndicator>
        )
      }}
    </LayoutSidebarOverlay>
  )
}

export default PermissionManagementLicense
