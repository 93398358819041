export class StorageUtils {

  static getDefaultStorageValue = (propertyFilter, localField) => {
    const filter = JSON.parse(localStorage.getItem("filter"));
    if (filter && filter[propertyFilter]) {
      const updatedAtCredential = filter[propertyFilter].find(credential => credential.field === localField);
      if (updatedAtCredential) {
        return updatedAtCredential.value
      }
    }
  }
}

