import { Label, Field as ReakitField, styled } from "reakit"
import { palette, theme } from "styled-tools"

export const FieldStyled = styled(ReakitField)`
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-bottom: ${theme("spacing.small")};
`

export const LabelStyled = styled(Label)`
  font-size: ${theme("typography.size.body2")};
  font-weight: ${theme("typography.weight.medium")};
  color: ${palette("grayscale")};
  margin: 0;
  padding: 0;
`
