import { InlineFlex, styled } from "reakit"
import { palette, theme } from "styled-tools"
import { Link } from "react-router-dom"
import { IoIosKeypad, IoIosArrowDown } from "react-icons/io"
import { FaTh } from "react-icons/fa"

export const HeaderStyled = styled.header`
  align-items: center;
  background-color: ${palette("white")};
  display: flex;
  height: 70px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  z-index: 100;
`

export const BrandLink = styled(Link)`
  display: block;
  width: 150px;
  max-width: 150px;
  max-height: 48px;

  img {
    display: block;
    width: 100%;
  }
`

export const SearchWrapper = styled(InlineFlex)`
  align-items: center;
  border-left: 1px solid ${palette("grayscale", 2)};
  flex-grow: 1;
  height: 100%;
  margin-right: ${theme("spacing.big")};
  padding-left: ${theme("spacing.big")};
  padding-right: ${theme("spacing.big")};
`
export const ChangeModuleContainer = styled.div`
  min-width: 100px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin: 0 15px;
  height: 100%;
  border-left: 1px solid #cfd0d1;
  cursor: pointer;
`

export const ChangeModuleLabel = styled.span`
  margin: 0 15px 0 10px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
`
export const GridIcon = styled(FaTh)`
  font-size: 20px;
  color: #005369;
  cursor: pointer;
`

export const ArrowIcon = styled(IoIosArrowDown)`
  color: #005369;
  font-size: 20px;
  cursor: pointer;
`
