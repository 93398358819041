import React, { useEffect, useState } from "react"
import { Route, Switch } from "react-router-dom"
import { isEmpty } from "ramda"
import Layout from "common/Layout"
import PageToolbar, { PageToolbarContent } from "common/PageToolbar"
import Paper from "common/Paper"
import Text from "common/Text"
import Link from "common/Link"
import IconButton from "common/IconButton"
import Button from "common/Button"
import PolicyCreate from "./PolicyCreate"
import PolicyTableList from "../../common/PolicyTableList"
import {
  useFindPoliciesRequestHandler,
  usePolicies,
  usePoliciesLoading,
  usePolicyCreateSuccess,
  usePolicyDeleteSuccess,
  usePolicyUpdateSuccess,
  usePoliciesFilter,
  useTotalPages,
} from "store/hooks/policy"
import PolicyEdit from "./PolicyEdit"
import PolicyDestroy from "./PolicyDestroy"
import PolicyDetails from "./PolicyDetails"
import PrivateRoute from "common/PrivateRoute"

const Policy = props => {
  const [currentPage, setCurrentPage] = useState(0)
  const findPolicies = useFindPoliciesRequestHandler()
  const policies = usePolicies()
  const totalPages = useTotalPages()
  const loading = usePoliciesLoading()
  const filtering = usePoliciesFilter()
  const updateSuccess = usePolicyUpdateSuccess()
  const deleteSuccess = usePolicyDeleteSuccess()
  const createSuccess = usePolicyCreateSuccess()

  useEffect(() => {
    if (deleteSuccess || updateSuccess || createSuccess) {
      findPolicies(0)
      setCurrentPage(1)
    }
  }, [deleteSuccess, updateSuccess, createSuccess])

  const loadMore = () => {
    if (!loading) {
      setCurrentPage(currentPage + 1)
      findPolicies(currentPage)
    }
  }

  useEffect(() => {
    findPolicies(currentPage)
    setCurrentPage(currentPage + 1)
  }, [])

  return (
    <Layout >
      <PageToolbar title="Políticas">
        <PageToolbarContent>
          <Link to={`${props.match.path}/new`} marginleft="auto">
            <Button bold palette="secondary">
              Nova política
            </Button>
          </Link>
        </PageToolbarContent>
      </PageToolbar>
      {!isEmpty(policies) || filtering ? (
        <Paper>
          <PolicyTableList
            setCurrentPage={setCurrentPage}
            items={policies}
            loadMore={loadMore}
            hasMore={totalPages}
            renderActions={item => (
              <div style={{justifyContent: "space-around" ,display: 'flex', alignItems: 'center'}}>
                {item.type === "ENTERPRISE_PRESENCE" ? (
                  ""
                ) : (
                  <Link to={`${props.match.path}/delete/${item.id}`}>
                    <IconButton>delete_outline</IconButton>
                  </Link>
                )}
                <Link to={`${props.match.path}/edit/${item.id}`}>
                  <IconButton>create</IconButton>
                </Link>
                <Link to={`${props.match.path}/detail/${item.id}`}>
                  <IconButton>navigate_next</IconButton>
                </Link>
              </div>
            )}
          />
        </Paper>
      ) : (
        <Paper>
          <Text>Nenhuma política cadastrada.</Text>
        </Paper>
      )}
      <Switch>
        <PrivateRoute
          path={`${props.match.path}/new`}
          component={PolicyCreate}
        />
        <PrivateRoute
          path={`${props.match.path}/edit/:id`}
          component={PolicyEdit}
        />
        <PrivateRoute
          path={`${props.match.path}/detail/:id`}
          component={PolicyDetails}
        />
        <PrivateRoute
          path={`${props.match.path}/delete/:id`}
          component={PolicyDestroy}
        />
      </Switch>
    </Layout>
  )
}

export default Policy
