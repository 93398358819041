import moment from "moment/moment";

export class DateUtils {
  static convertStringToDate(date) {
    if (date) {
      const dateArray = date.split("-")
      return new Date(+dateArray[0], +dateArray[1] - 1, +dateArray[2])
    }
  }

  static convertStringToDateWithoutHours(date) {
    const dateArray = date.split("-")
    return new Date(+dateArray[0], +dateArray[1] - 1, +dateArray[2], 0, 0, 0)
  }

  static convertStringBRToDate(date) {
    if (date) {
      const dateArray = date.split("/")
      let year = dateArray[2]
      const month = +dateArray[1]
      const day = +dateArray[0]
      year = year.substring(0, 4)
      return new Date(+year, month - 1, day)
    }
  }

  static convertStringToStringBR(date) {
    const dateArray = date.split("-")
    return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`
  }
  static dateNowMinusDays(days) {
    const date = new Date();
    date.setDate(date.getDate() - days)
    return date
  }

  static convertIsoToBr(date){
    return moment(date).format('DD/MM/YYYY')
  }
  static convertStringBRToString(date) {
    const dateArray = date.split("/")
    return `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`
  }

  static validateStringBRDate(date) {
    if (date) {
      const dateArray = date.split("/")
      const dateToParse = `${+dateArray[2]}-${+dateArray[1] -
        1}-${+dateArray[0]}`
      return !isNaN(Date.parse(dateToParse))
    }
    return false
  }

  static validateStringBRDateLimitToday(date) {
    if (date) {
      const dateArray = date.split("/")
      let year = dateArray[2]
      const month = +dateArray[1]
      const day = +dateArray[0]
      if (!year || !month || !day) {
        return false
      }
      year = year.substring(0, 4)
      const dateToParse = `${+year}-${month}-${day}`
      const isNan = isNaN(Date.parse(dateToParse))
      if (isNan) {
        return false
      } else {
        const dateConvert = new Date(+year, +month - 1, +day, 0, 0, 0)
        return !(dateConvert >= new Date())
      }
    }
    return false
  }

  static secondsToTimeString(seconds) {
    let aux = seconds

    const horas = Math.trunc(aux / 3600)
    aux = aux - horas * 3600
    const minutos = Math.trunc(aux / 60)
    aux = aux - minutos * 60
    const segundos = aux

    return `${horas.toString(10)}:${this.pad(minutos, 2)}:${this.pad(
      segundos,
      2
    )}`
  }

  static pad(num, size) {
    let s = num + ""
    while (s.length < size) {
      s = "0" + s
    }
    return s
  }

  static BRtoDate(dateStr) {
    const parts = dateStr.split("/")
    return new Date(parts[2], parts[1] - 1, parts[0])
  }

  static zeraHoraTimezone(data) {
    return data
      .substr(0, 11)
      .concat("00:00:00")
      .concat(data.substr(19, 10))
  }
}
