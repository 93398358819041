import {call, put, select, takeLatest} from "redux-saga/effects"
import api from "services/api"
import API from "lib/api"
import {concat, isEmpty} from "ramda"
import {
  createPermissionSuccess,
  deletePermissionSuccess,
  errorPermission,
  findPermissionsSuccess,
  findPermissionSuccess,
  PERMISSION_CREATE_REQUEST,
  PERMISSION_DELETE_REQUEST,
  PERMISSION_REQUEST,
  PERMISSIONS_REQUEST,
} from "store/ducks/permission"

export function* findPermissions(action) {
  try {
    const {size, page} = action.payload
    const filter = JSON.parse(localStorage.getItem("filter"))
    const emptyFilter = filter ? !isEmpty(filter.filter) : false

    const findPermissionsApiCall = () => {
      let params
      params = {
        page,
        size: size,
        sort: "createdAt,desc",
      }
      if (filter && filter.permissions) {
        filter.permissions.forEach(f => {
          if (f.field === 'createdAt') {
            params.startDate = f.value.startDate;
            params.endDate = f.value.endDate;
          } else if (f.field === 'updatedAt') {
            params.updatedSince = f.value.startDate;
            params.updatedUntil = f.value.endDate;
          } else {
            params[f.field] = f.value;
          }
        })
      }
      return api
      .get(`${API.PERSON}/permissions-request`, {
        params
      })
      .then(response => {
        return response.data
      })
      .catch(err => {
        throw err
      })
    }
    const {permissions} = yield select(state => state.permission)
    const {content, first, last} = yield call(findPermissionsApiCall)
    if (!first) {
      yield put(
        findPermissionsSuccess(
          concat(permissions, content || []), last, emptyFilter)
      )
    } else {
      yield put(findPermissionsSuccess(content, last))
    }
  } catch (err) {
    yield put(errorPermission(err))
  }
}

export function* findPermission(action) {
  try {
    const {id} = action.payload
    const findPermissionApiCall = () => {
      return api
      .get(`${API.PERSON}/permissions-request/${id}`)
      .then(response => {
        return response.data
      })
      .catch(err => {
        throw err
      })
    }
    const response = yield call(findPermissionApiCall)
    yield put(findPermissionSuccess(response))
  } catch (err) {
    yield put(errorPermission(err))
  }
}

export function* deletePermission(action) {
  try {
    const {id} = action.payload
    const deletePermissionApiCall = () => {
      return api
      .delete(`${API.PERSON}/permissions-request/${id}`)
      .then(response => response)
      .catch(err => {
        throw err
      })
    }
    yield call(deletePermissionApiCall)
    yield put(deletePermissionSuccess())
  } catch (err) {
    yield put(errorPermission(err))
  }
}

export function* createPermission(action) {
  try {
    const {permission, isRequired} = action.payload
    const url = isRequired
      ? `${API.PERSON}/permissions-request/batch?useDefaultCredentials=true`
      : `${API.PERSON}/permissions-request/batch`
    const createPermissionApiCall = () => {
      return api
      .post(url, permission)
      .then(response => response.data)
      .catch(err => {
        throw err
      })
    }
    yield call(createPermissionApiCall)
    yield put(createPermissionSuccess())
  } catch (err) {
    yield put(errorPermission(err))
  }
}

export default [
  takeLatest(PERMISSIONS_REQUEST, findPermissions),
  takeLatest(PERMISSION_REQUEST, findPermission),
  takeLatest(PERMISSION_DELETE_REQUEST, deletePermission),
  takeLatest(PERMISSION_CREATE_REQUEST, createPermission),
]
