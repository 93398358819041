import React, {useCallback, useEffect, useRef, useState} from "react"
import {
  BtnContainer,
  ButtonStyled,
  CloseBtnContainer,
  CloseIconStyled,
  Container,
  FilterContainer,
  IconStyled, InputStyled,
  LabelStyled,
} from "./styles"
import DropDownFilter from "../DropDownFilter";
import SelectFilter from "../SelectFilter";
import {DateRangeSelector} from "../DateFilter/DateRangeSelector";
import {DateUtils} from "../../utils/DateUtils";

const Filter = ({
  setCurrentPage,
  filterFn,
  name,
  screen,
  applied,
  filterType,
  defaultValue,
  selectOptions,
}) => {
  const ref = useRef()
  const [showFilterBox, setShowFilterBox] = useState(false)
  const previousFilterLs = JSON.parse(localStorage.getItem("filter"))
  const previousFilter =
    previousFilterLs && previousFilterLs[screen]
      ? previousFilterLs[screen].find(i => i.field === name)
      : null
  const [filterParam, setFilterParam] = useState(
    previousFilter ? previousFilter.value : ""
  )

  const handleClickOutside = useCallback(
    event => {
      if (ref && ref.current && !ref.current.contains(event.target)) {
        setShowFilterBox(false)
      }
    },
    [ref]
  )

  const handleClearFilter = useCallback(e => {
    e.stopPropagation()
    let filterNewObject = JSON.parse(localStorage.getItem("filter"))
    if (filterNewObject && filterNewObject[screen]) {
      filterNewObject[screen] = filterNewObject[screen].filter(
        i => i.field !== name
      )
      setFilterParam("")
      localStorage.setItem("filter", JSON.stringify(filterNewObject))
      setCurrentPage(0)
      setTimeout(() => filterFn(0), 0)
    }
  }, [])

  const handleFilter = useCallback(
    e => {
      e.stopPropagation()
      if (filterParam) {
        const filter = localStorage.getItem("filter")
        let filterNewObject
        if (!filter) {
          filterNewObject = {
            [screen]: [],
          }
        } else {
          filterNewObject = JSON.parse(filter)
          if (!filterNewObject[screen]) {
            filterNewObject[screen] = []
          }
          filterNewObject[screen] = filterNewObject[screen].filter(
            i => i.field !== name
          )
        }
        filterNewObject[screen].push({
          field: name,
          operator: "contains",
          value: filterParam,
        })
        localStorage.setItem("filter", JSON.stringify(filterNewObject))
        setCurrentPage(0)
        setTimeout(() => filterFn(0), 0)
      }
      setShowFilterBox(false)
    },
    [filterParam]
  )

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true)
    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  }, [])

  const onChangeSelectFilter = (e) => {
    setFilterParam(e.value)
  }

  const convertDateFilter = (begin, end) => {
    if (begin) {
      const startDate = `${begin.toISOString()}`
      const endDate = `${end.toISOString()}`
      setFilterParam({startDate, endDate})
    }
  }

  const renderFilterType = () => {
    switch (filterType) {
      case 'dropDownfilter':
        return (
          <DropDownFilter
            setCurrentPage={setCurrentPage}
            filterFn={filterFn}
            name={name}
            handleFilter={handleFilter}
            screen={screen}
            applied={applied}
          />
        )
      case 'selectFilter' :
        return (
          <SelectFilter
            defaultValue={defaultValue}
            selectOptions={selectOptions}
            onChange={e => onChangeSelectFilter(e)}
          />
        )
      case 'dateFilter':
        return (
          <DateRangeSelector
            defaultStartDate={defaultValue && DateUtils.convertIsoToBr(defaultValue.startDate)}
            defaultEndDate={defaultValue && DateUtils.convertIsoToBr(defaultValue.endDate)}
            onChange={(beginDate, endDate) => convertDateFilter(beginDate, endDate)}
          />
        )
      case 'inputFilter':
        return (
          <InputStyled
            value={filterParam}
            type="text"
            onChange={e => {
              e.stopPropagation()
              setFilterParam(e.target.value)
            }}
          />
        )
    }
  }

  return (
    <Container>
      {showFilterBox && filterType ?
        <FilterContainer ref={ref}>
          <CloseBtnContainer>
            <CloseIconStyled
              palette="primary"
              active={!showFilterBox}
              className="material-icons"
              onClick={e => {
                e.stopPropagation()
                setShowFilterBox(showPwd => !showPwd)
              }}
            >
              close
            </CloseIconStyled>
          </CloseBtnContainer>
          <LabelStyled>Mostrar registros que contém:</LabelStyled>
          {renderFilterType()}
          <BtnContainer>
            <ButtonStyled onClick={handleFilter}>Filtrar</ButtonStyled>
            <ButtonStyled secondary onClick={handleClearFilter}>
              Limpar
            </ButtonStyled>
          </BtnContainer>
        </FilterContainer>
        :
        <IconStyled
          color={applied ? "#005368" : "#00cfc3"}
          palette="primary"
          active={!showFilterBox}
          className="material-icons"
          onClick={e => {
            e.stopPropagation()
            setShowFilterBox(show => !show)
          }}
        >
          filter_list
        </IconStyled>
      }
    </Container>
  )
}

export default Filter

export const GetActivatedFilter = (column, screen) => {
  const filter = JSON.parse(localStorage.getItem("filter"))
  return (
    filter &&
    filter[screen] &&
    !!filter[screen].find(item => item.field === column)
  )
}
