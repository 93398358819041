import React from 'react'
import { ControlLabel, FormGroup } from 'react-bootstrap'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'

function DateRangeSelector ({ onChange, title, defaultStartDate, defaultEndDate }) {

  const ranges = {
    Hoje: [
      moment().toDate(),
      moment().toDate()
    ],
    Ontem: [
      moment().subtract(1, 'days').toDate(),
      moment().subtract(1, 'days').toDate(),
    ],
    'Últimos 7 dias': [
      moment().subtract(7, 'days').toDate(),
      moment().toDate(),
    ],
    'Últimos 30 dias': [
      moment().subtract(29, 'days').toDate(),
      moment().toDate(),
    ],
    'Este mês': [
      moment().startOf('month').toDate(),
      moment().endOf('month').toDate(),
    ],
    'Último mês': [
      moment().subtract(1, 'months').startOf('month'),
      moment().subtract(1, 'months').endOf('month'),
    ],
  }

  const daysOfWeek = ['dom', 'seg', 'ter','qua','qui','sex','sab']

  const monthNames = ['Jan','Fev','Mar','Abr','Mai','Jun','Jul','Ago','Set','Out','Nov','Dez']

  return (
    <FormGroup>
      <ControlLabel>{ title || 'Data inicio/fim' }</ControlLabel>
      <DateRangePicker
        onCallback={(startDate, endDate) => onChange && onChange(startDate.toDate(), endDate.toDate())}
        initialSettings={{
          ranges,
          locale: {
            format: 'DD/MM/YYYY',
            customRangeLabel: 'Selecione a data',
            daysOfWeek,
            monthNames
          },
          startDate: defaultStartDate,
          endDate: defaultEndDate
        }}
      >
        <input type="text" className="form-control" />
      </DateRangePicker>
    </FormGroup>
  )
}

export { DateRangeSelector }
