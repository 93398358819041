import * as Yup from "yup"

export const ValidationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .trim()
    .min(5, "A senha precisa ter no mínimo 5 caracteres.")
    .required("O campo senha é obrigatório."),
  newPasswordConfirm: Yup.string()
    .trim()
    .required("O campo Confirmação de Senha é obrigatório.")
    .oneOf([Yup.ref("newPassword"), null], "As senhas precisam ser iguais."),
})
