import React, { useEffect, useState } from "react"
import { Flex } from "reakit"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import LayerFeedback from "common/LayerFeedback"
import Button from "common/Button"
import LoadingIndicator from "common/LoadingIndicator"
import ProcedureDestroyInfo from "../../../common/ProcedureDestroyInfo"
import {
  useDeleteProcedureHandlers,
  useFindProcedureRequestHandler,
  useProceduresLoading,
  useProcedure,
  useProcedureDeleteSuccess,
  useProcedureReset,
} from "../../../store/hooks/procedure"
import { withRouter } from "react-router-dom"

const ProcedureDestroy = props => {
  const [destroyed, setDestroyed] = useState(false)
  // const [procedure, setProcedure] = useState(null)
  const procedure = useProcedure()
  const findProcedure = useFindProcedureRequestHandler()
  const deleteProcedure = useDeleteProcedureHandlers()
  const deleteSuccess = useProcedureDeleteSuccess()
  const reset = useProcedureReset()
  const { id } = props.match.params
  const loading = useProceduresLoading()

  useEffect(() => {
    if (id) {
      findProcedure(id)
    }
  }, [findProcedure, id])

  useEffect(() => {
    if (deleteSuccess && id) {
      props.history.push("/autorizacao")
    }
  }, [deleteSuccess])

  useEffect(() => {
    return () => {
      reset()
    }
  }, [reset])

  const onDestroy = () => {
    deleteProcedure(procedure.id)
  }

  return (
    <LayoutSidebarOverlay
      title="Excluir Autorização"
      afterClose={() => {
        props.history.push("/autorizacao")
      }}
    >
      {({ closeSidebar }) => {
        const destroying = loading
        // const isLoading = context.loading && !destroying

        return (
          <LoadingIndicator loading={loading}>
            <Flex column maxWidth="33rem" flexGrow={1}>
              {destroyed ? (
                <LayerFeedback
                  icon="check"
                  palette="secondary"
                  title="Autorização excluída"
                  message="Autorização foi excluida com sucesso."
                />
              ) : (
                <>
                  {procedure && <ProcedureDestroyInfo procedure={procedure} />}
                </>
              )}
            </Flex>
            <LayoutSidebarOverlay.Footer>
              {destroyed ? (
                <Button type="button" onClick={closeSidebar}>
                  Ok
                </Button>
              ) : (
                <>
                  <Button
                    disabled={destroying}
                    type="button"
                    palette="white"
                    onClick={closeSidebar}
                  >
                    Cancelar
                  </Button>
                  <Button
                    disabled={destroying}
                    type="button"
                    onClick={onDestroy}
                  >
                    Excluir
                  </Button>
                </>
              )}
            </LayoutSidebarOverlay.Footer>
          </LoadingIndicator>
        )
      }}
    </LayoutSidebarOverlay>
  )
}

export default withRouter(ProcedureDestroy)
