import React, { useCallback, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import {
  useMarkDetail,
  useMonitoringRequest,
  useSetDetail,
} from "../../store/hooks/map"
import markOk from "assets/images/map_pin_green.svg"
import markWarning from "assets/images/map_pin_yellow.svg"
import markDanger from "assets/images/map_pin_red.svg"
import LayoutSidebarOverlay from "../LayoutSidebarOverlay"
import { withRouter } from "react-router-dom"
import { Paginator } from "primereact/paginator"
import Icon from "../Icon"
import {
  useDownloadTimePlacesRequest,
  useLoadingPerson,
  usePersonTimePlaces, usePersonTimePlacesLast,
  usePersonTimePlacesRequest,
  usePersonTimePlacesReset,
} from "../../store/hooks/person"
import { format } from "date-fns"
import {
  BtnContainer,
  EventContainer,
  EventHeader,
  EventLine,
  EventTitle,
  PrintBtn,
} from "./index.styled"
import Button from "../Button"
import LoadingIndicator from "../LoadingIndicator"
import { ShowMoreItens } from "../Table"
import { useTotalPages } from "../../store/hooks/checkpoint"

const MarkStyled = styled.img`
  width: 40px;
  height: 50px;
`
const BoxMark = styled.div`
  display: flex;
`

const BoxLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  font-size: medium !important;
  color: ${({ color }) => (color ? color : "white")};
  background-color: #037580;
  margin-left: 1px;
  padding: 1%;
  min-width: 40px;
  margin-bottom: 7px;
  border-radius: 5px;
`

const MapMark = props => {
  const [currentPage, setCurrentPage] = useState(0)
  const ref = useRef()
  const setDetail = useSetDetail()
  const detail = useMarkDetail()
  const findTimePlaces = usePersonTimePlacesRequest()
  const resetTimePlaces = usePersonTimePlacesReset()
  const timePlaces = usePersonTimePlaces()
  const downloadTimePlaces = useDownloadTimePlacesRequest()
  const fetching = useLoadingPerson()
  const totalPages = useTotalPages()
  const last = usePersonTimePlacesLast()
  const mapDataRequest = useMonitoringRequest()

  const handleClickOutside = useCallback(
    event => {
      // if (ref && ref.current && !ref.current.contains(event.target)) {
      //   setDetail(null)
      // }
    },
    [ref]
  )

  const loadMore = useCallback(() => {
    if (!fetching) {
      findTimePlaces(
        {
          isOpen: true,
          policyId: props.mark.policy.id,
        },
        currentPage + 1
      )
      setCurrentPage(currentPage + 1)
    }
  }, [fetching, currentPage])

  useEffect(() => {
    if (detail === props.mark.policy.id) {
      findTimePlaces(
        {
          isOpen: true,
          policyId: props.mark.policy.id,
        },
        currentPage,
        20
      )
      mapDataRequest()
    }
  }, [props.mark.policy.id, detail, currentPage])

  useEffect(() => {
    if (!detail) {
      resetTimePlaces()
    }
    return () => resetTimePlaces();
  }, [detail])

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true)
    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  }, [])

  return (
    <>
      {detail === props.mark.policy.id && (
        <LayoutSidebarOverlay
          title="Eventos online"
          afterClose={() => {
            props.history.push("/maps")
            setDetail(null)
          }}
        >
          {({ closeSidebar }) => {
            return (
              <EventContainer>
                <LoadingIndicator loading={fetching}>
                  <div style={{ width: "100%" }}>
                    <EventTitle>Política: {props.mark.policy.name}</EventTitle>
                    <EventHeader>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <label style={{ width: "24px" }} />
                        <label>Nome</label>
                      </div>
                      <label>Data Início</label>
                    </EventHeader>
                    {timePlaces.map((tp, index) => (
                      <EventLine
                        stripped={index % 2 === 0}
                        key={index}
                        onClick={() =>
                          props.history.push(`/perfil/${tp.person.id}`)
                        }
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <label>
                            {true ? (
                              <Icon color="#005369">check</Icon>
                            ) : index % 2 === 0 ? (
                              <Icon color="#ffca00">warning</Icon>
                            ) : (
                              <Icon color="red">cancel</Icon>
                            )}
                          </label>
                          <label>{tp.person.name}</label>
                        </div>
                        <label>
                          {tp.startDate
                            ? format(tp.startDate, "DD/MM/YYYY HH:mm")
                            : ""}
                        </label>
                      </EventLine>
                    ))}
                    {fetching && <LoadingIndicator loading={true} />}
                    {!fetching && !last && (
                      <ShowMoreItens handleClick={loadMore} />
                    )}
                  </div>
                  <BtnContainer>
                    <div></div>
                    {/*<PrintBtn*/}
                    {/*  onClick={() => {*/}
                    {/*    // downloadTimePlaces()*/}
                    {/*  }}*/}
                    {/*>*/}
                    {/*  Imprimir*/}
                    {/*</PrintBtn>*/}
                    <Button
                      onClick={() => {
                        closeSidebar()
                        setDetail(null)
                      }}
                    >
                      Fechar
                    </Button>
                  </BtnContainer>
                </LoadingIndicator>
              </EventContainer>
            )
          }}
        </LayoutSidebarOverlay>
      )}
      <div style={{ position: "relative" }}>
        <div style={{ position: "absolute", top: "-77px", right: "-20px" }}>
          <BoxMark>
            {props.mark.totalApproved > 0 && (
              <BoxLabel>{props.mark.totalApproved}</BoxLabel>
            )}
            {props.mark.totalWarning > 0 && (
              <BoxLabel color="#FBBA00">{props.mark.totalWarning}</BoxLabel>
            )}
            {props.mark.totalProblems > 0 && (
              <BoxLabel color="red">{props.mark.totalProblems}</BoxLabel>
            )}
          </BoxMark>
          <MarkStyled
            src={
              props.mark.totalProblems > 0
                ? markDanger
                : props.mark.totalWarning > 0
                ? markWarning
                : markOk
            }
            onClick={() => setDetail(props.mark.policy.id)}
            {...props}
          />
        </div>
      </div>
    </>
  )
}

export default withRouter(MapMark)
