import React, { useEffect, useState } from "react"
import { Flex } from "reakit"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import LayerFeedback from "common/LayerFeedback"
import Button from "common/Button"
import LoadingIndicator from "common/LoadingIndicator"
import UserDestroyInfo from "../../../common/UserDestroyInfo"
import {
  useDeleteUserHandlers,
  useFindUserRequestHandler,
  useUsersLoading,
  useUser,
  useUserDeleteSuccess,
  useUserReset,
} from "../../../store/hooks/user"
import { withRouter } from "react-router-dom"

const UserDestroy = props => {
  const [destroyed, setDestroyed] = useState(false)
  // const [user, setUser] = useState(null)
  const user = useUser()
  const findUser = useFindUserRequestHandler()
  const deleteUser = useDeleteUserHandlers()
  const deleteSuccess = useUserDeleteSuccess()
  const reset = useUserReset()
  const { id } = props.match.params
  const loading = useUsersLoading()

  useEffect(() => {
    if (id) {
      findUser(id)
    }
  }, [findUser, id])

  useEffect(() => {
    if (deleteSuccess && id) {
      props.history.push("/usuarios")
    }
  }, [deleteSuccess])

  useEffect(() => {
    return () => {
      reset()
    }
  }, [reset])

  const onDestroy = () => {
    deleteUser(user.id)
  }

  return (
    <LayoutSidebarOverlay
      title="Excluir Usuário"
      afterClose={() => {
        props.history.push("/usuarios")
      }}
    >
      {({ closeSidebar }) => {
        const destroying = loading
        // const isLoading = context.loading && !destroying

        return (
          <LoadingIndicator loading={loading}>
            <Flex column maxWidth="33rem" flexGrow={1}>
              {destroyed ? (
                <LayerFeedback
                  icon="check"
                  palette="secondary"
                  title="Usuário excluído"
                  message="Usuário foi excluido com sucesso."
                />
              ) : (
                <>{user && <UserDestroyInfo user={user} />}</>
              )}
            </Flex>
            <LayoutSidebarOverlay.Footer>
              {destroyed ? (
                <Button type="button" onClick={closeSidebar}>
                  Ok
                </Button>
              ) : (
                <>
                  <Button
                    disabled={destroying}
                    type="button"
                    palette="white"
                    onClick={closeSidebar}
                  >
                    Cancelar
                  </Button>
                  <Button
                    disabled={destroying}
                    type="button"
                    onClick={onDestroy}
                  >
                    Excluir
                  </Button>
                </>
              )}
            </LayoutSidebarOverlay.Footer>
          </LoadingIndicator>
        )
      }}
    </LayoutSidebarOverlay>
  )
}

export default withRouter(UserDestroy)
