import { useDispatch, useSelector } from "react-redux"
import { useCallback } from "react"
import {
  clear,
  loadExpiredRequest, loadExpiredRequestNotDeleted, loadPersonCredentialsRequest, loadRenewExpiredRequest,
  loadRequestedRequest,
  loadToExpireRequest,
} from "../ducks/report"

export const useLoadCredentialsExpired = () => {
  const dispatch = useDispatch()
  return useCallback(
    (page, size, force ) => {
      dispatch(loadExpiredRequest(page, size, force))
    },
    [dispatch]
  )
}
export const useLoadCredentialsExpiredNotDeleted = () => {
  const dispatch = useDispatch()
  return useCallback(
    (page, size, force ) => {
      dispatch(loadExpiredRequestNotDeleted(page, size, force))
    },
    [dispatch]
  )
}

export const useFindPersonCredentials = () => {
  const dispatch = useDispatch()
  return useCallback(
    (id) => {
      dispatch(loadPersonCredentialsRequest(id))
    },
    [dispatch]
  )
}

export const useRenewPersonCredentials = () => {
  const dispatch = useDispatch()
  return useCallback(
    (id) => {
      dispatch(loadRenewExpiredRequest(id))
    },
    [dispatch]
  )
}

export const useReportClearState = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(clear())
  },[dispatch])
}

export const useLoadCredentialsRequested = () => {
  const dispatch = useDispatch()
  return useCallback(
    page => {
      dispatch(loadRequestedRequest(20, page))
    },
    [dispatch]
  )
}

export const useLoadCredentialsToExpire = () => {
  const dispatch = useDispatch()
  return useCallback(
    page => {
      dispatch(loadToExpireRequest(20, page))
    },
    [dispatch]
  )
}

export const useLoadingReport = () => {
  return useSelector(state => state.report.loading)
}

export const useLastPageReport = () => {
  return useSelector(state => state.report.last)
}

export const useReportExpiredData = () => {
  return useSelector(state => state.report.expired)
}

export const useReportRequestedData = () => {
  return useSelector(state => state.report.requested)
}

export const useReportToExpireData = () => {
  return useSelector(state => state.report.toExpire)
}
export const useRenewCredentialSuccess = () => {
  return useSelector(state => state.report.renewSuccess)
}
export const usePersonCredentials = () => {
  return useSelector(state => state.report.personCredentials)
}
export const useRenewCredentialError = () => {
  return useSelector(state => state.report.error)
}
export const useTotalPages = () => {
  return useSelector(state => state.report.totalPages)
}
