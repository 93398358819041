import React from "react"
import { Field } from "react-final-form"
import { Flex } from "reakit"
import Radiobox from "common/Radiobox"
import { FieldStyled, LabelStyled } from "./index.styled"

const RadioboxAdapter = ({ input, meta, ...props }) => {
  const handleClick = event => input.onChange(event.target.value)

  return (
    <FieldStyled use={Flex}>
      <LabelStyled htmlFor={props.id}>{props.label}</LabelStyled>
      <Radiobox {...input} {...props} onClick={handleClick} />
    </FieldStyled>
  )
}

const FieldRadiobox = props => {
  return <Field component={RadioboxAdapter} type="radio" {...props} />
}

export default FieldRadiobox
