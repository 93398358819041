import React, { useEffect, useState } from "react"
import { Flex } from "reakit"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import LayerFeedback from "common/LayerFeedback"
import Button from "common/Button"
import LoadingIndicator from "common/LoadingIndicator"
import {
  useDeleteCredentialHandlers,
  useFindCredentialRequestHandler,
  useCredentialsLoading,
  useCredential,
  useCredentialDeleteSuccess,
  useCredentialReset,
} from "../../../store/hooks/credential"
import { withRouter } from "react-router-dom"
import CredentialDestroyInfo from "../../../common/CredentialDestroyInfo"

const CredentialDestroy = props => {
  const credential = useCredential()
  const findCredential = useFindCredentialRequestHandler()
  const deleteCredential = useDeleteCredentialHandlers()
  const deleteSuccess = useCredentialDeleteSuccess()
  const reset = useCredentialReset()
  const { id } = props.match.params
  const loading = useCredentialsLoading()

  useEffect(() => {
    if (id) {
      findCredential(id)
    }
  }, [findCredential, id])

  useEffect(() => {
    return () => {
      reset()
    }
  }, [reset])

  const onDestroy = () => {
    deleteCredential(credential.id)
  }

  return (
    <LayoutSidebarOverlay
      title="Excluir Credencial"
      afterClose={() => {
        props.history.push("/credenciais")
      }}
    >
      {({ closeSidebar }) => {
        const destroying = loading
        // const isLoading = context.loading && !destroying

        return (
          <LoadingIndicator loading={loading}>
            <Flex column maxWidth="33rem" flexGrow={1}>
              {deleteSuccess ? (
                <LayerFeedback
                  icon="check"
                  palette="secondary"
                  title="Credencial excluída"
                  message="Credencial foi excluida com sucesso."
                />
              ) : (
                <>
                  {credential && (
                    <CredentialDestroyInfo credential={credential} />
                  )}
                </>
              )}
            </Flex>
            <LayoutSidebarOverlay.Footer>
              {deleteSuccess ? (
                <Button type="button" onClick={closeSidebar}>
                  Ok
                </Button>
              ) : (
                <>
                  <Button
                    disabled={destroying}
                    type="button"
                    palette="white"
                    onClick={closeSidebar}
                  >
                    Cancelar
                  </Button>
                  <Button
                    disabled={destroying}
                    type="button"
                    onClick={onDestroy}
                  >
                    Excluir
                  </Button>
                </>
              )}
            </LayoutSidebarOverlay.Footer>
          </LoadingIndicator>
        )
      }}
    </LayoutSidebarOverlay>
  )
}

export default withRouter(CredentialDestroy)
