import React from "react"
import { Field } from "react-final-form"
import { Input, Field as ReakitField, styled } from "reakit"
import { palette, theme } from "styled-tools"
import InputLabel from "common/InputLabel"
import CustomTooltip from "../CustomTooltip"

const FieldStyled = styled(ReakitField)`
  margin-bottom: ${theme("spacing.big")};
`

const InputStyled = styled(Input)`
  background-color: ${palette("white")};
  border-bottom: 1px solid #3f3f3f;
  box-sizing: border-box;
  color: ${palette("grayscale")};
  display: block;
  font-size: ${theme("typography.size.body2")};
  padding-top: ${props => props.theme.baseline * 2}px;
  padding-bottom: ${props => props.theme.baseline}px;
  width: 100%;

  /* https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers/ */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px ${palette("white")} inset;
    -webkit-text-fill-color: ${palette("grayscale")};
  }
`

const FieldTextAdapter = ({ input, meta, ...props }) => {
  const handleChange = event => {
    const value = event.target.value
    input.onChange(value)
    if (props.onChange) {
      props.onChange(value)
    }
  }

  return (
    <FieldStyled>
      {props.tooltip ? <CustomTooltip tooltip={props.tooltip} /> : ""}
      <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
      <InputStyled {...input} {...props} onChange={handleChange} />
    </FieldStyled>
  )
}

const FieldText = props => {
  return <Field {...props} component={FieldTextAdapter} />
}

export default FieldText
