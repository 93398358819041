export const MONITORING_ERROR = "MONITORING_ERROR"
export const MONITORING_DATA_REQUEST = "MONITORING_DATA_REQUEST"
export const MONITORING_DATA_SUCCESS = "MONITORING_DATA_SUCCESS"
export const MONITORING_ALERTS_REQUEST = "MONITORING_ALERTS_REQUEST"
export const MONITORING_ALERTS_SUCCESS = "MONITORING_ALERTS_SUCCESS"

export const monitoringErrorAction = error => ({
  type: MONITORING_ERROR,
  payload: {
    error,
  },
})

export const findMonitoringDataRequestAction = () => ({
  type: MONITORING_DATA_REQUEST,
})

export const findMonitoringDataSuccess = data => ({
  type: MONITORING_DATA_SUCCESS,
  payload: {
    data,
  },
})

export const findMonitoringAlertsRequestAction = (page, size) => ({
  type: MONITORING_ALERTS_REQUEST,
  payload: {
    page,
    size,
  },
})

export const findMonitoringAlertsSuccess = (alerts, last) => ({
  type: MONITORING_ALERTS_SUCCESS,
  payload: {
    alerts,
    last,
  },
})

const INITIAL_STATE = {
  error: null,
  loading: false,
  loadingAlerts: false,
  data: null,
  alerts: [],
  lastAlerts: true,
}

const monitoringReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MONITORING_ALERTS_REQUEST:
    case MONITORING_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        loadingAlerts: true,
      }
    case MONITORING_DATA_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        data,
        loading: false,
      }
    }
    case MONITORING_ALERTS_SUCCESS: {
      const { alerts, last } = action.payload
      return {
        ...state,
        alerts,
        loading: false,
        loadingAlerts: false,
        lastAlerts: last,
      }
    }
    case MONITORING_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        loading: false,
        error,
      }
    }
    default:
      return state
  }
}

export default monitoringReducer
