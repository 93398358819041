import React, { useEffect, useState } from "react"
import PageToolbar from "common/PageToolbar"
import Layout from "common/Layout"
import Flex from "../../../common/Flex"
import { Metrics } from "../../../configs"
import { AlertBox, PersonBlock, StatusChip } from "../styles"
import TextPattern from "../../../common/TextPattern"
import Bold from "../../../common/Bold"
import Link from "common/Link"
import Dashboard from "../Dashboard"
import {
  useHeadcount,
  useHeadcountFindDetailHandler,
} from "store/hooks/headcount"
import profileImg from "../../../assets/images/profile-ph.png"
import Whitespace from "../../../common/Whitespace"
import { useParams } from "react-router-dom"
import { format } from "date-fns"
import { set } from "ramda"

const HeadcountDetail = () => {
  const { id, name, time } = useParams()
  const [internalTime, setInternalTime] = useState(null)
  const findHeadcountDetail = useHeadcountFindDetailHandler()
  const headcount = useHeadcount()

  useEffect(() => findHeadcountDetail(id, time), [
    findHeadcountDetail,
    id,
    time,
  ])

  useEffect(() => setInternalTime(time), [time])

  return (
    <Layout>
      <PageToolbar title="Tela de Controle" />
      {headcount && (
        <Flex dir="column">
          <Flex responsive marginBottom={Metrics.spacingMD}>
            <AlertBox alert={true}>
              <Flex width="50%">
                <TextPattern textAlign="left" size={Metrics.fontSize.xxsm}>
                  <Bold>VPS EM ALERTA</Bold>
                </TextPattern>
              </Flex>
              <Flex width="50%" justifyContent="center">
                <TextPattern size={Metrics.fontSize.md}>
                  <Bold>0</Bold>
                </TextPattern>
              </Flex>
            </AlertBox>
            <AlertBox alert={false}>
              <Flex width="50%">
                <TextPattern textAlign="left" size={Metrics.fontSize.xxsm}>
                  Desempenho da Produção
                </TextPattern>
              </Flex>
              <Flex width="50%" justifyContent="center">
                <TextPattern size={Metrics.fontSize.md}>
                  <Bold>0%</Bold>
                </TextPattern>
              </Flex>
            </AlertBox>
            <AlertBox alert={false} dir="column">
              <Flex alignItems="flex-start" dir="column" width="100%">
                <Flex>
                  <TextPattern size={Metrics.fontSize.xxsm}>
                    <Bold>Turno: 0</Bold>
                  </TextPattern>
                </Flex>
                <Flex>
                  <TextPattern size={Metrics.fontSize.xxsm}>
                    <Bold>Head Count: 0 pessoas</Bold>
                  </TextPattern>
                </Flex>
              </Flex>
            </AlertBox>
          </Flex>
          <Flex dir="column" marginBottom={Metrics.spacingSM}>
            <Flex responsive>
              <Flex maxWidth="70%" dir="column" alignItems="flex-start">
                <TextPattern size={Metrics.fontSize.xsm}>
                  <Bold>Listagem de Linhas</Bold>
                </TextPattern>
                <Whitespace height={Metrics.spacingMD} />
                <Flex
                  responsive
                  marginRight="1%"
                  padding={Metrics.spacingLG}
                  dir="column"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius={Metrics.spacingMinimun}
                  boxShadow="5px 5px 15px #cacaca"
                >
                  <Flex
                    width="100%"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <TextPattern size={Metrics.fontSize.xxlg}>
                      <Bold>{name}</Bold>
                    </TextPattern>
                    <Dashboard
                      policyId={id}
                      onMarkerClick={e => {
                        findHeadcountDetail(id, e.category)
                        setInternalTime(e.category)
                      }}
                    />
                  </Flex>

                  <TextPattern size={Metrics.fontSize.xsm}>
                    <Bold>
                      {`Detalhamento do horário: ${format(
                        internalTime,
                        "HH:mm:ss"
                      )}`}
                    </Bold>
                  </TextPattern>
                  <Whitespace height={Metrics.spacingMD} />
                  <Flex
                    width="100%"
                    justifyContent="space-between"
                    padding={`${Metrics.spacingMinimun} ${Metrics.spacingMD}`}
                  >
                    <Whitespace width="20%" />
                    <Flex width="40%" justifyContent="flex-start">
                      <TextPattern size={Metrics.fontSize.xxsm}>
                        <Bold>Nome</Bold>
                      </TextPattern>
                    </Flex>
                    <Flex width="20%" justifyContent="flex-start">
                      <TextPattern size={Metrics.fontSize.xxsm}>
                        <Bold>Status</Bold>
                      </TextPattern>
                    </Flex>
                    <Flex width="20%" justifyContent="flex-start">
                      <TextPattern size={Metrics.fontSize.xxsm}>
                        <Bold>Turno</Bold>
                      </TextPattern>
                    </Flex>
                  </Flex>
                  {headcount.map((p, index) => (
                    <PersonBlock key={index}>
                      <Flex width="19%">
                        <img
                          src={profileImg}
                          style={{
                            maxWidth: "40px",
                            background: "white",
                            borderRadius: "150px",
                          }}
                        />
                      </Flex>
                      <Flex width="40%" dir="column">
                        <TextPattern
                          textAlign="left"
                          size={Metrics.fontSize.xxsm}
                        >
                          <Link to={`/perfil/${p.person.id}`}>
                            {p.person.name}
                          </Link>
                        </TextPattern>
                        <TextPattern
                          textAlign="left"
                          size={Metrics.fontSize.xxxsm}
                        >
                          {p.person.role}
                        </TextPattern>
                      </Flex>
                      <Flex width="20%">
                        <StatusChip alert={!p.present}>
                          <TextPattern
                            textAlign="left"
                            color="#fff"
                            size={Metrics.fontSize.xxxsm}
                          >
                            {p.present ? "Presente" : "Ausente"}
                          </TextPattern>
                        </StatusChip>
                      </Flex>
                      <Flex width="20%">
                        <TextPattern
                          textAlign="left"
                          size={Metrics.fontSize.xxsm}
                        >
                          {p.person.workShift.name}
                        </TextPattern>
                      </Flex>
                    </PersonBlock>
                  ))}
                </Flex>
              </Flex>
              <Flex maxWidth="29%" dir="column" alignItems="flex-start">
                <TextPattern size={Metrics.fontSize.xsm}>
                  <Bold>Head Count</Bold>
                </TextPattern>
                <Whitespace height={Metrics.spacingMD} />
                <Flex
                  alignItems="flex-start"
                  backgroundColor={
                    true || headcount.head_count_block.alert
                      ? "#EC5451"
                      : "unset"
                  }
                  borderRadius={Metrics.spacingMinimun}
                  boxShadow="5px 5px 15px #cacaca"
                  responsive
                  height="300px"
                  padding={`${Metrics.spacingLG} ${Metrics.spacingMD}`}
                  dir="column"
                >
                  <Flex
                    width="100%"
                    alignItems="flex-start"
                    justifyContent="space-between"
                    marginBottom={Metrics.spacingSM}
                  >
                    <Flex>
                      <TextPattern size={Metrics.fontSize.xlg}>
                        <Bold>{0}</Bold>
                      </TextPattern>
                    </Flex>
                    <Flex dir="column">
                      <TextPattern size={Metrics.fontSize.xxxsm}>
                        Tempo de Alerta
                      </TextPattern>
                      <TextPattern size={Metrics.fontSize.xxsm}>
                        <Bold>{1} minutos</Bold>
                      </TextPattern>
                    </Flex>
                    <Flex dir="column">
                      <TextPattern size={Metrics.fontSize.xxsm}>
                        <Bold>Alerta acumulado</Bold>
                      </TextPattern>
                      <TextPattern size={Metrics.fontSize.xxsm}>
                        <Bold>{1} minutos</Bold>
                      </TextPattern>
                    </Flex>
                  </Flex>
                  <TextPattern size={Metrics.fontSize.sm}>
                    <Bold>Histórico de Alerta</Bold>
                  </TextPattern>
                  <Whitespace height={Metrics.spacingSM} />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Layout>
  )
}

export default HeadcountDetail
