import React, {useEffect, useState} from "react";
import Layout from "common/Layout";
import Paper from "common/Paper";
import PageToolbar, {PageToolbarContent} from "common/PageToolbar";
import {useLoadTriggedButtons, useTriggedButtons, useTriggedButtonsLoading} from "../../store/hooks/alerts";
import EmergencyAlertsList from "../../common/EmergencyAlertList";
import {isEmpty} from "ramda";
import PaperStyled from "../../common/PaperStyled";
import Text from "../../common/Text";
import Link from "../../common/Link";
import Button from "../../common/Button";

const EmergencyAlerts = (props) => {
    const triggedButtons = useTriggedButtons();
    const loading = useTriggedButtonsLoading();
    const loadTriggedButtons = useLoadTriggedButtons();
    const [currentPage, setCurrentPage] = useState(0);

    const loadMore = () => {
        if (!loading) {
            setCurrentPage(currentPage + 1);
            loadTriggedButtons(currentPage, 10);
        }
    };

    useEffect(() => {
        loadTriggedButtons(currentPage, 10);
        setCurrentPage(currentPage + 1);
    }, []);

    return (
        <Layout>
            <PageToolbar title="Ultimos alertas de emergencia">
                <PageToolbarContent>
                    <Link to={`${props.match.path}/alert-group`} marginleft="auto">
                        <Button bold palette="secondary">
                            Grupo de alerta
                        </Button>
                    </Link>
                </PageToolbarContent>
            </PageToolbar>
            {!isEmpty(triggedButtons) ? (
                <Paper>
                    <EmergencyAlertsList
                        loadMore={loadMore}
                        loading={loading}
                        items={triggedButtons}/>
                </Paper>
            ) : (
                <PaperStyled>
                    <Text>Nenhuma trava de alerta acionada.</Text>
                </PaperStyled>
            )}
        </Layout>
    );
};

export default EmergencyAlerts;
