import React, { createContext, useState, useEffect } from "react"
import { Switch, Route } from "react-router-dom"
import { isEmpty } from "ramda"
import Layout from "common/Layout"
import Paper from "common/Paper"
import Text from "common/Text"
import Button from "common/Button"
import PageToolbar, { PageToolbarContent } from "common/PageToolbar"
import IconButton from "common/IconButton"
import Link from "common/Link"
import PersonTableList from "common/PersonTableList"
import PersonCreate from "pages/PersonManagement/PersonCreate"
import PersonDestroy from "pages/PersonManagement/PersonDestroy"
import PersonEdit from "pages/PersonManagement/PersonEdit"
import PersonDetails from "pages/PersonManagement/PersonDetails"
import PaperStyled from "common/PaperStyled"
import {
  useCreatePersonSuccess,
  useDeletePersonSuccess,
  useLoadingPerson,
  useLoadPersons, usePersonFiltering,
  usePersons,
  usePersonsTotalPages,
  useUpdatePersonSuccess,
} from "store/hooks/person"
import PrivateRoute from "common/PrivateRoute"
import ProcedureTableList from "../../common/ProcedureTableList"

export const PersonContext = createContext({})

const Person = props => {
  const [currentPage, setCurrentPage] = useState(0)
  const loadPersons = useLoadPersons()
  const persons = usePersons()
  const personsTotalPages = usePersonsTotalPages()
  const filtering = usePersonFiltering()
  const loadingPerson = useLoadingPerson()
  const deleteSuccess = useDeletePersonSuccess()
  const updateSuccess = useUpdatePersonSuccess()
  const createSuccess = useCreatePersonSuccess()

  useEffect(() => {
    if (deleteSuccess || updateSuccess || createSuccess) {
      loadPersons(0, 20, true)
      setCurrentPage(1)
    }
  }, [deleteSuccess, updateSuccess, createSuccess])

  const loadMore = () => {
    if (!loadingPerson) {
      setCurrentPage(currentPage + 1)
      loadPersons(currentPage, 20)
    }
  }

  useEffect(() => {
    loadPersons(currentPage, 20, false)
    setCurrentPage(currentPage + 1)
  }, [])

  return (
    <Layout>
      <PageToolbar title="Cadastro de Pessoa">
        <PageToolbarContent>
          <Link to={`${props.match.path}/novo`} marginleft="auto">
            <Button bold palette="secondary">
              Novo cadastro
            </Button>
          </Link>
        </PageToolbarContent>
      </PageToolbar>
      {!isEmpty(persons) || filtering ? (
        <Paper>
          <PersonTableList
            setCurrentPage={setCurrentPage}
            items={persons}
            loadMore={loadMore}
            fetching={loadingPerson}
            hasMore={personsTotalPages > currentPage}
            renderActions={item => {
              return (
                <div style={{justifyContent: "space-around", display: "flex"}}>
                  <Link to={`${props.match.path}/deletar/${item.id}`}>
                    <IconButton>delete_outline</IconButton>
                  </Link>
                  <Link to={`${props.match.path}/editar/${item.id}`}>
                    <IconButton>create</IconButton>
                  </Link>
                  <Link to={`${props.match.path}/detalhes/${item.id}`}>
                    <IconButton>navigate_next</IconButton>
                  </Link>
                </div>
              )
            }}
          />
        </Paper>
      ) : (
        <PaperStyled>
          <Text>Nenhuma pessoa cadastrada.</Text>
        </PaperStyled>
      )}
      <Switch>
        <PrivateRoute
          path={`${props.match.path}/novo`}
          component={PersonCreate}
        />
        <PrivateRoute
          path={`${props.match.path}/detalhes/:id`}
          component={PersonDetails}
        />
        <PrivateRoute
          path={`${props.match.path}/deletar/:id`}
          component={PersonDestroy}
        />
        <PrivateRoute
          path={`${props.match.path}/editar/:id`}
          component={PersonEdit}
        />
      </Switch>
    </Layout>
  )
}

export default Person
