import React from "react"
import styled from "styled-components"

export const FlexStyled = styled.div`
  ${({ clicable }) =>
    clicable
      ? `
        cursor: pointer;
  `
      : ``}
  ${({ responsive, breakWidth }) =>
    responsive
      ? `
    @media (max-width: ${breakWidth}) {
        flex-direction: column;
        max-width: none !important;
    }
  `
      : ``}
  ${({ inverseResponsive, breakWidth }) =>
    inverseResponsive
      ? `
    @media (max-width: ${breakWidth}) {
        flex-direction: row !important;
    }
  `
      : ``}
`

export default ({
  dir,
  children,
  textAlign,
  alignItems,
  height,
  onClick,
  backgroundColor,
  wordBreak,
  responsive,
  clicable,
  inverseResponsive,
  breakWidth = "1024px",
  ...rest
}) => {
  return (
    <FlexStyled
      clicable={clicable}
      responsive={responsive}
      inverseResponsive={inverseResponsive}
      breakWidth={breakWidth}
      onClick={onClick}
      style={{
        display: "flex",
        flexDirection: dir,
        alignItems,
        textAlign,
        height,
        backgroundColor,
        wordBreak,
        ...rest,
      }}
    >
      {children}
    </FlexStyled>
  )
}
