export const CHANGE_MODULE = "CHANGE_MODULE"
export const CHANGE_MODULE_DIALOG = "CHANGE_MODULE_DIALOG"

export const changeModuleAction = (selectedModuleId, moduleName) => ({
  type: CHANGE_MODULE,
  payload: {
    selectedModuleId,
    moduleName,
  },
})

export const changeModuleDialogDisplayAction = display => ({
  type: CHANGE_MODULE_DIALOG,
  payload: {
    display,
  },
})

const INITIAL_STATE = {
  dialogDisplay: false,
  moduleName: null,
  selectedModule: null,
}

const navigationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_MODULE:
      const { selectedModuleId, moduleName } = action.payload
      return {
        ...state,
        selectedModule: selectedModuleId,
        moduleName,
        dialogDisplay: false,
      }
    case CHANGE_MODULE_DIALOG: {
      const { display } = action.payload
      return {
        ...state,
        dialogDisplay: display,
      }
    }
    default:
      return state
  }
}

export default navigationReducer
