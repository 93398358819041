import React from "react"
import { SortLabelContainer } from "./styles"
import { Sort } from "../Sort"

export const SortLabel = ({
  onPageChange,
  props,
  request,
  name,
  label,
  screen,
}) => {
  return (
    <SortLabelContainer
      onClick={() => {
        Sort(onPageChange || props.setCurrentPage, request, name, screen)
      }}
    >
      <label>{label}</label>
    </SortLabelContainer>
  )
}

export default SortLabel
