import React from "react"
import { Paragraph as ReakitParagraph, styled } from "reakit"
import { ifProp, theme } from "styled-tools"

const TextStyled = styled(ReakitParagraph)`
  font-size: ${ifProp(
    "variant",
    props => props.theme.typography.size[props.variant],
    props => props.theme.typography.size.body
  )};
  font-weight: ${ifProp(
    "bold",
    theme("typography.weight.bold"),
    theme("typography.weight.normal")
  )};
  margin-bottom: ${props => props.theme.spacing[props.margin]};
  margin-left: ${props => props.theme.spacing[props.marginLeft]};
  margin-top: ${props => props.theme.spacing[props.marginTop]};
  margin-right: ${props => props.theme.spacing[props.marginRight]};
  text-align: ${props => props.align};
  text-transform: ${ifProp("uppercase", "uppercase", "none")};
  align-self: ${props => props.alignSelf};
`

const Text = ({ children, ...props }) => {
  return <TextStyled {...props}>{children}</TextStyled>
}

export default Text
