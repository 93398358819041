import React from "react"
import { Box, styled } from "reakit"
import { palette, theme } from "styled-tools"

const PaperStyled = styled(Box)`
  background-color: ${palette("white")};
  padding: ${theme("spacing.small")};
  margin-bottom: 20px;

  // @TOOD: refactor it to be defined as prop.
  box-shadow: -10px 10px 20px 0 rgba(30, 30, 30, 0.05);
`

const Paper = ({ children, ...props }) => {
  return <PaperStyled {...props}>{children}</PaperStyled>
}

export default Paper
