import { useDispatch, useSelector } from "react-redux"
import { useCallback } from "react"
import {
  findHeadcountDetailRequestAction,
  findHeadcountsDashboardDataRequestAction,
  findHeadcountsRequestAction,
} from "../ducks/headcount"

export const useHeadcountFindListHandler = () => {
  const dispatch = useDispatch()
  return useCallback(
    filter => {
      dispatch(findHeadcountsRequestAction(filter))
    },
    [dispatch]
  )
}

export const useHeadcountFindDetailHandler = () => {
  const dispatch = useDispatch()
  return useCallback(
    (id, time) => {
      dispatch(findHeadcountDetailRequestAction(id, time))
    },
    [dispatch]
  )
}

export const useHeadcountsFindDashboardDataHandler = () => {
  const dispatch = useDispatch()
  return useCallback(
    policyId => {
      dispatch(findHeadcountsDashboardDataRequestAction(policyId))
    },
    [dispatch]
  )
}

export const useHeadcount = () => {
  return useSelector(state => state.headcount.headcount)
}

export const useHeadcounts = () => {
  return useSelector(state => state.headcount.headcounts)
}

export const useHeadcountLoading = () => {
  return useSelector(state => state.headcount.loading)
}

export const useHeadcountsDashboardData = () => {
  return useSelector(state => state.headcount.headcountsDashboard)
}
