import React, { useState } from "react"
import { pathOr } from "ramda"
import { InlineFlex } from "reakit"
import AsyncSelect from "react-select/async"
import Text from "common/Text"
import useCustomFetch from "lib/useCustomFetch"
import API from "lib/api"

export const initialPermissionFilterValue = ""

const PermissionFilter = props => {
  const [permission, setPermission] = useState(initialPermissionFilterValue)

  const [search, setSearch] = useState("")

  const permissionRequest = useCustomFetch({
    endpoint: `${API.PERSON}/permissions?size=20&name=${search}`,
  })

  const loadOptions = inputValue => {
    setTimeout(() => {
      setSearch(inputValue)
    }, 500)
  }

  const handleInputChange = value => {
    const inputValue = value.replace(/\W/g, "")
    return inputValue
  }

  const options = pathOr([], ["data", "content"], permissionRequest).map(
    item => ({
      label: item.name,
      value: item.id,
    })
  )

  return (
    <InlineFlex marginLeft={20}>
      <Text margin="none" variant="body2" alignSelf="center">
        Trabalho especial:
      </Text>
      <AsyncSelect
        cacheOptions
        loadOptions={loadOptions}
        defaultOptions
        onInputChange={handleInputChange}
      />
    </InlineFlex>
  )
}

export default PermissionFilter
