import styled from "styled-components"
import { Metrics } from "../../configs"

export const AlertBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 32%;
  padding: ${Metrics.spacingMinimun} ${Metrics.spacingSM};
  flex-direction: ${({ dir }) => dir};
  box-shadow: 5px 5px 15px #cacaca;
  border-radius: 6px;
  border-left: 4px solid ${({ alert }) => (alert ? `#EC5451` : `#00ae69`)};
  margin: 0 ${Metrics.spacingMinimun};
  align-items: center;
`
