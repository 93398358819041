import React, { useEffect } from "react"
import Flex from "../../common/Flex"
import { useHistory, useParams } from "react-router-dom"
import Text from "../../common/Text"
import { Metrics } from "../../configs"
import TextPattern from "../../common/TextPattern"
import Bold from "../../common/Bold"
import {
  useHeadcountFindListHandler,
  useHeadcounts,
} from "../../store/hooks/headcount"
import { AlertBox } from "./styles"
import Dashboard from "../Headcount/Dashboard"

const ExternalHeadcount = props => {
  const { token } = useParams()
  const findHeadcounts = useHeadcountFindListHandler()
  const headcounts = useHeadcounts()
  const history = useHistory()

  useEffect(() => findHeadcounts(), [findHeadcounts])

  return (
    <Flex dir="column">
      <Flex marginBottom={Metrics.spacingMD}>
        <AlertBox alert={true}>
          <Flex>
            <TextPattern textAlign="center" size={Metrics.fontSize.xxsm}>
              <Bold>VPS EM ALERTA</Bold>
            </TextPattern>
          </Flex>
          <Flex justifyContent="center">
            <TextPattern size={Metrics.fontSize.md}>
              <Bold>{headcounts.alert_vps}</Bold>
            </TextPattern>
          </Flex>
        </AlertBox>
        <AlertBox alert={false}>
          <Flex>
            <TextPattern textAlign="left" size={Metrics.fontSize.xxsm}>
              Desempenho da Produção
            </TextPattern>
          </Flex>
          <Flex justifyContent="center">
            <TextPattern size={Metrics.fontSize.md}>
              <Bold>{headcounts.production_performance}%</Bold>
            </TextPattern>
          </Flex>
        </AlertBox>
        <AlertBox alert={false} dir="column">
          <Flex alignItems="flex-start" dir="column">
            {/*<Flex>*/}
            {/*  <TextPattern size={Metrics.fontSize.xxsm}>*/}
            {/*    <Bold>Turno: {headcounts.shift}</Bold>*/}
            {/*  </TextPattern>*/}
            {/*</Flex>*/}
            <Flex>
              <TextPattern size={Metrics.fontSize.xxsm}>
                <Bold>Head Count: {headcounts.head_count} pessoas</Bold>
              </TextPattern>
            </Flex>
          </Flex>
        </AlertBox>
        {console.log(headcounts)}
      </Flex>
      {headcounts &&
        headcounts.map((vp, index) => (
          <Flex
            key={index}
            dir="column"
            padding={`0 ${Metrics.spacingMD}`}
            marginBottom={Metrics.spacingSM}
            onClick={() =>
              history.push(
                `/external-headcount-detail/${token}/${new Date().toISOString()}/${vp.id}/${vp.name}`
              )
            }
          >
            <Flex
              alignItems="center"
              justifyContent="space-between"
              backgroundColor={vp.alert ? "#EC5451" : "unset"}
              borderRadius={Metrics.spacingSM}
              boxShadow="5px 5px 15px #cacaca"
              maxHeight="122px"
              padding={`${Metrics.spacingSM} ${Metrics.spacingMD}`}
            >
              <Flex dir="column">
                <TextPattern size={Metrics.fontSize.xlg}>
                  <Bold>{vp.name}</Bold>
                </TextPattern>
                <Flex dir="column">
                  <TextPattern size={Metrics.fontSize.xxxsm}>
                    Tempo de Alerta
                  </TextPattern>
                  <TextPattern size={Metrics.fontSize.xxsm}>
                    <Bold>
                      {vp.timeIsOpenInSeconds
                        ? Math.trunc(vp.timeIsOpenInSeconds / 60)
                        : "0"}{" "}
                      minutos
                    </Bold>
                  </TextPattern>
                </Flex>
              </Flex>
              <Flex dir="column">
                <TextPattern size={Metrics.fontSize.xsm}>
                  <Bold>Head Count</Bold>
                </TextPattern>
                <TextPattern size={Metrics.fontSize.xsm}>
                  <Bold>
                    {vp.currentPersonsPresent} / {vp.currentPersonsExpected}
                  </Bold>
                </TextPattern>
              </Flex>
            </Flex>
          </Flex>
        ))}
    </Flex>
  )
}

export default ExternalHeadcount
