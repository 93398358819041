import React from "react"
import {isEmpty, isNil} from "ramda"
import {Form} from "react-final-form"
import {format} from "date-fns"
import {Box, Flex, List, styled} from "reakit"
import {theme} from "styled-tools"
import Text from "common/Text"
import Divider from "common/Divider"
import LoadingIndicator from "common/LoadingIndicator"
import ExpandableBlock from "pages/PermissionManagementLicense/ExpandableBlock"
import CredentialManagementForm from "pages/PermissionManagementLicense/CredentialManagementForm"
import {usePermissionRequestLoading} from "store/hooks/permission-request"

const BlockListItem = styled(Box)`
  padding-bottom: ${theme("spacing.large")};
  padding-top: ${theme("spacing.large")};
`

const listCredentialName = (permissionRequestCredential) => {
  if (permissionRequestCredential.credential && permissionRequestCredential.credential.name) {
    return permissionRequestCredential.credential.name
  } else {
    return "Credencial deletada!"
  }
}

const ManageLicense = props => {
  if (isNil(props.permissionRequest)) {
    return <></>
  }
  const credentials = props.permissionRequest && props.permissionRequest.personCredentials || props.permissionRequest.credentials
  const loading = usePermissionRequestLoading()
  return (
    <Flex column maxWidth="33rem" flexGrow={1}>
      <BlockListItem>
        <Text margin="big" palette="primary" variant="body" uppercase>
          Trabalho especial
        </Text>
        <List>
          {props.permissionRequest.permissions.map((permission, key) => {
            return (
              <Text
                key={key}
                margin="medium"
                variant="body2"
                use={[Flex, "li"]}
              >
                {permission && permission.name}
              </Text>
            )
          })}
        </List>
      </BlockListItem>
      <Divider palette="primary"/>
      <BlockListItem>
        <Text margin="big" palette="primary" variant="body" uppercase>
          Credenciais solicitadas
        </Text>
      </BlockListItem>
      <LoadingIndicator loading={loading}>
        <></>
        {credentials.map(
          permissionRequestCredential => {
            const initialValues = isEmpty(permissionRequestCredential)
              ? {}
              : {
                expiryIn: permissionRequestCredential.expiryIn
                  ? format(
                    permissionRequestCredential.expiryIn,
                    "DD/MM/YYYY HH:mm"
                  )
                  : null,
                observation: permissionRequestCredential.observation,
              }
            return (
              <ExpandableBlock
                key={permissionRequestCredential.id}
                status={permissionRequestCredential.status}
                title={listCredentialName(permissionRequestCredential)}
              >
                <Form
                  initialValues={initialValues}
                  onSubmit={() => {
                  }}
                  render={({handleSubmit, values}) => {
                    return (
                      <CredentialManagementForm
                        handleSubmit={handleSubmit}
                        permissionRequestCredential={
                          permissionRequestCredential
                        }
                        permissionRequest={props.permissionRequest}
                        values={values}
                      />
                    )
                  }}
                />
              </ExpandableBlock>
            )
          }
        )}
      </LoadingIndicator>
    </Flex>
  )
}

export default ManageLicense
