import React, { useCallback, useState } from "react"
import { InlineBlock, Popover } from "reakit"
import Avatar from "common/Avatar"
import Button from "common/Button"
import IconButton from "common/IconButton"
import Text from "common/Text"
import {
  UserMenuHeader,
  UserMenuContent,
  UserMenuContentItem,
} from "./index.styled"
import {
  useAuthenticated,
  useAuthUser,
  useLogoutHandler,
} from "store/hooks/authentication"
import { withRouter } from "react-router-dom"
import ChangePassword from "common/ChangePassword"

const UserMenu = () => {
  const authenticated = useAuthenticated()
  const [changePasswordDisplay, setChangePasswordDisplay] = useState(false)
  const user = useAuthUser()
  const handlerLogout = useLogoutHandler()

  const handleLogout = () => {
    handlerLogout()
  }

  const handleChangePassword = useCallback(() => {
    if (authenticated) {
      setChangePasswordDisplay(true)
    }
  }, [authenticated])

  return (
    <Popover.Container>
      {popover => {
        return (
          <InlineBlock>
            {changePasswordDisplay && (
              <ChangePassword
                handleClose={() => setChangePasswordDisplay(false)}
              />
            )}
            <UserMenuHeader use={Popover.Toggle} {...popover}>
              <Avatar alt="Kitten" size="4rem" />
              <Text use="span" palette="grayscale" variant="body2">
                {user && user.name}
              </Text>
              <IconButton as="span">arrow_drop_down</IconButton>
            </UserMenuHeader>
            <Popover fade hideOnClickOutside {...popover}>
              <Popover.Arrow />
              <UserMenuContent>
                <UserMenuContentItem>
                  <Button
                    palette="white"
                    uppercase
                    full
                    onClick={handleChangePassword}
                  >
                    Alterar Senha
                  </Button>
                  <Button palette="white" uppercase full onClick={handleLogout}>
                    Sair
                  </Button>
                </UserMenuContentItem>
              </UserMenuContent>
            </Popover>
          </InlineBlock>
        )
      }}
    </Popover.Container>
  )
}

export default withRouter(UserMenu)
