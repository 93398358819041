import React, { useEffect, useState } from "react"
import { ContainerStyled } from "../index.styled"
import { ErrorMessage, useFormikContext } from "formik"
import SimpleSelectV2 from "../../../../common/SimpleSelectV2"
import InputText from "../../../../common/InputText"
import RadioInput from "../../../../common/RadioInput"
import { ErrorContainer, ErrorLabel } from "../../../../common/Error"

const StepAreaData = ({ values }) => {
  const { handleChange } = useFormikContext()

  const [types] = useState([
    { id: "restrito", label: "Restrito" },
    { id: "FORBIDDEN", label: "Proibido" },
    { id: "permitido", label: "Permitido" },
    { id: "ENTERPRISE_PRESENCE", label: "Enterprise Presence" },
    { id: "PRESENCE", label: "Presence" },
    { id: "IN_OUT", label: "In Out" },
    { id: "PROXIMITY", label: "Proximidade" },
  ])
  const [areaTypes] = useState([
    { id: "ENTERPRISE_PRESENCE", label: "Enterprise Presence" },
    { id: "PRESENCE", label: "Presence" },
    { id: "IN_OUT", label: "In Out" },
    { id: "PROXIMITY", label: "Proximidade" },
  ])

  // useEffect(() => console.log(values), [values])

  return (
    <ContainerStyled>
      <InputText
        value={values.name}
        name="name"
        id="name"
        label="NOME"
        onChange={handleChange}
      />
      <InputText
        value={values.description}
        name="description"
        id="description"
        label="DESCRIÇÃO"
        onChange={handleChange}
      />
      <RadioInput
        value={values.namespace || null}
        name="namespace"
        label="Namespace"
        options={[{ value: "ble", label: "BLE" }]}
      />
      <SimpleSelectV2
        propertyToShow="label"
        tooltip="O Nível crítico é para categorizar a política. ele pode ser: Proibido, restrito ou permitido."
        label="NÍVEL DE CRITICIDADE"
        list={types}
        valueProperty="id"
        name="type"
        id="type"
        value={values.type}
      />
      <SimpleSelectV2
        propertyToShow="label"
        label="TIPO"
        list={areaTypes}
        valueProperty="id"
        name="areaType"
        id="areaType"
        value={values.areaType}
      />
      <InputText
        value={values.coordinates}
        tooltip="Digite as coordenadas de latitude antes das coordenadas de longitude. Verifique se o primeiro número da coordenada de latitude está entre -90 e 90. Verifique se o primeiro número da coordenada de longitude está entre -180 e 180."
        name="coordinates"
        id="coordinates"
        label="COORDENADAS"
        onChange={handleChange}
      />
      {values.areaType === "PRESENCE" || values.type === "PROXIMITY" ? (
        <RadioInput
          value={values.scope}
          name="scope"
          label="SCOPE"
          options={[
            { value: "GLOBAL", label: "Use Global Scope" },
            { value: "INDIVIDUAL", label: "Select Individual Devices" },
          ]}
        />
      ) : (
        ""
      )}
      <ErrorContainer>
        <ErrorMessage
          name="name"
          render={msg => <ErrorLabel>{msg}</ErrorLabel>}
        />
        <ErrorMessage
          name="description"
          render={msg => <ErrorLabel>{msg}</ErrorLabel>}
        />
        <ErrorMessage
          name="namespace"
          render={msg => <ErrorLabel>{msg}</ErrorLabel>}
        />
        <ErrorMessage
          name="type"
          render={msg => <ErrorLabel>{msg}</ErrorLabel>}
        />
        <ErrorMessage
          name="areaType"
          render={msg => <ErrorLabel>{msg}</ErrorLabel>}
        />
      </ErrorContainer>
    </ContainerStyled>
  )
}

export default StepAreaData
