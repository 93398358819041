import React from "react"
import InputLabel from "common/InputLabel"
import Input from "@material-ui/core/Input/Input"
import MenuItem from "@material-ui/core/MenuItem/MenuItem"
import Select from "@material-ui/core/Select/Select"
import { pathOr } from "ramda"
import { styled } from "reakit"
import { theme } from "styled-tools"
import CustomTooltip from "../CustomTooltip"
import { useFormikContext } from "formik"

const StyledSelect = styled(Select)`
  min-width: 100%;
  max-width: 336px;
  .MuiSelect-select {
    font-size: ${theme("typography.size.body2")};
    &:focus {
      background: transparent;
    }
  }
`

const SelectContainer = styled.div`
  margin-top: 3%;
  position: relative;
  margin-bottom: ${theme("spacing.medium")};
  .MuiInput-underline:after {
    border-bottom: 2px solid #005369 !important;
  }
`

const InputLabelStyled = styled(InputLabel)`
  font-size: 1.3rem;
`

const SimpleSelectV2 = ({
  input,
  meta,
  value,
  valueProperty,
  name,
  propertyToShow,
  ...props
}) => {
  const { setFieldValue } = useFormikContext()

  const handleChangeSelect = event => {
    const value = event.target.value
    setFieldValue(name, value)
  }

  return (
    <SelectContainer>
      {props.tooltip ? <CustomTooltip tooltip={props.tooltip} /> : ""}
      <InputLabelStyled id={props.id}>{props.label}</InputLabelStyled>
      <StyledSelect
        value={value}
        labelId={props.id}
        id={props.id}
        {...props}
        {...input}
        input={<Input />}
        onChange={handleChangeSelect}
      >
        {pathOr([], ["list"], props).map((item, key) => {
          return (
            <MenuItem
              key={key}
              value={valueProperty ? item[valueProperty] : item}
            >
              {item[propertyToShow]}
            </MenuItem>
          )
        })}
      </StyledSelect>
    </SelectContainer>
  )
}

export default SimpleSelectV2
