import { useDispatch, useSelector } from "react-redux"
import { useCallback } from "react"
import { loadCredentialsRequest } from "store/ducks/credential"
import {
  credentialCreateRequestAction,
  credentialDeleteRequestAction,
  credentialResetAction,
  credentialUpdateRequestAction,
  findCredentialRequestAction,
  findCredentialsRequestAction,
  loadPersonCredentialsRequest,
} from "../ducks/credential"

export const useLoadCredentials = () => {
  const dispatch = useDispatch()
  return useCallback(
    (size = null, unpaged = false) => {
      dispatch(loadCredentialsRequest(size, unpaged))
    },
    [dispatch]
  )
}

export const useFindCredentials = () => {
  const dispatch = useDispatch()
  return useCallback(
    (page, size) => {
      dispatch(findCredentialsRequestAction(page, size))
    },
    [dispatch]
  )
}

export const useLoadPersonsCredentials = () => {
  const dispatch = useDispatch()
  return useCallback(
    (page, size, force = false, personId) => {
      dispatch(loadPersonCredentialsRequest(page, size, force, personId))
    },
    [dispatch]
  )
}

export const useCredentialFiltering = () => {
  return useSelector(state => state.credential.filtering)
}

export const useLoadingCredential = () => {
  return useSelector(state => state.credential.loading)
}

export const useCredentials = () => {
  return useSelector(state => state.credential.credentials)
}

export const useCredentialUpdateSuccess = () => {
  return useSelector(state => state.credential.updateSuccess)
}

export const useCredentialDeleteSuccess = () => {
  return useSelector(state => state.credential.deleteSuccess)
}

export const useCredentialCreateSuccess = () => {
  return useSelector(state => state.credential.createSuccess)
}

export const useFindCredentialRequestHandler = () => {
  const dispatch = useDispatch()
  return useCallback(
    id => {
      dispatch(findCredentialRequestAction(id))
    },
    [dispatch]
  )
}

export const useCreateCredentialHandlers = () => {
  const dispatch = useDispatch()
  return useCallback(
    credential => {
      dispatch(credentialCreateRequestAction(credential))
    },
    [dispatch]
  )
}

export const useUpdateCredentialHandlers = () => {
  const dispatch = useDispatch()
  return useCallback(
    credential => {
      dispatch(credentialUpdateRequestAction(credential))
    },
    [dispatch]
  )
}

export const useDeleteCredentialHandlers = () => {
  const dispatch = useDispatch()
  return useCallback(
    id => {
      dispatch(credentialDeleteRequestAction(id))
    },
    [dispatch]
  )
}

export const useCredentialsLoading = () => {
  return useSelector(state => state.credential.loading)
}

export const useCredential = () => {
  return useSelector(state => state.credential.credential)
}

export const useTotalPages = () => {
  return useSelector(state => state.credential.totalPages)
}

export const useCredentialReset = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(credentialResetAction())
  }, [dispatch])
}

export const useCredentialLast = () => {
  return useSelector(state => state.credential.last)
}
