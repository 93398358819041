import React, { useState } from "react"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import { useCreateAreaHandlers } from "../../../store/hooks/area"
import AreaForm from "../AreaForm"

const AreaCreate = props => {
  const createAreaRequest = useCreateAreaHandlers()
  const [area] = useState({
    checkInMinRSSI: "",
    checkOutMaxRSSI: "",
    company: {
      id: " ",
    },
    description: "",
    duration: 0,
    height: 0,
    isEnableIO: true,
    coordinates: "latitude, longitude",
    latitude: 0,
    longitude: 0,
    minDbToEnableIO: "",
    name: "",
    namespace: "",
    nodes: [],
    areaType: "",
    scope: "",
    type: "",
    version: 0,
  })

  const onSubmit = values => {
    if (!values.scope) {
      values.scope = "GLOBAL"
    }
    createAreaRequest(values)
  }

  return (
    <LayoutSidebarOverlay
      title="Nova política"
      afterClose={() => {
        props.history.push("/areas")
      }}
    >
      {({ closeSidebar }) => {
        return (
          <AreaForm
            closeSidebar={closeSidebar}
            onSubmit={onSubmit}
            enableReinitialize={true}
            initialValues={area}
          />
        )
      }}
    </LayoutSidebarOverlay>
  )
}

export default AreaCreate
