import React from "react"
import { styled } from "reakit"
import ErrorIcon from "@material-ui/icons/Error"
import { withStyles } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "white",
    boxShadow: "1px 1px 7px #cacaca",
    fontSize: 11,
    color: "#005369",
  },
}))(Tooltip)

const ErrorIconStyled = styled(ErrorIcon)`
  position: absolute;
  right: 0;
  color: #005369;
  z-index: 2;
`;

const CustomTooltip = props => {
  return (
    <LightTooltip title={props.tooltip}>
      <ErrorIconStyled />
    </LightTooltip>
  )
}

export default CustomTooltip
