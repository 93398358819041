import React, { useState } from "react"
import API from "lib/api"
import useCustomFetch from "lib/useCustomFetch"
import { getFormattedOptions } from "utils/get-formatted-options"
import FilterableList from "common/PermissionRequestForm/FilterableList"

const StepPermissions = props => {
  const [searchPermission, setSearchPermission] = useState("")

  // fetch permissions
  const {
    data: permissionsResponse,
    fetching: fetchingPermissions,
  } = useCustomFetch({
    endpoint: `${API.PERSON}/permissions?name=${searchPermission}&size=20`,
  })

  const permissions = getFormattedOptions(permissionsResponse)

  const onSearchPermissions = value => {
    setSearchPermission(value)
  }

  return (
    <FilterableList
      name="special-work"
      id="special-work"
      placeholder="Buscar"
      label="Qual o trabalho especial?"
      items={permissions}
      validate={props.validate}
      selectedItems={props.selectedItems}
      onChange={onSearchPermissions}
      loading={fetchingPermissions}
    />
  )
}

export default StepPermissions
