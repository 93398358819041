import React, { useEffect, useState } from "react"
import {
  useAlertAll,
  useAlertAllLastPage,
  useAlertsLoading,
  useLoadAllAlerts,
} from "store/hooks/alerts"
import { GetActivatedSorted } from "common/Sort"
import SortLabel from "common/SortLabel"
import Table, { ActionHeader, ShowMoreItens } from "common/Table"
import { format } from "date-fns"
import LoadingIndicator from "common/LoadingIndicator"
import Layout from "common/Layout"
import PageToolbar from "common/PageToolbar"
import Paper from "common/Paper"
import Text from "common/Text"
import { isEmpty, pathOr } from "ramda"
import Link from "common/Link"
import PrivateRoute from "common/PrivateRoute"
import AlertDetails from "pages/Alerts/AlertDetails"
import styled from "styled-components"
import { FaChevronRight } from "react-icons/fa"
import { Switch } from "react-router-dom"

export const LinkStyled = styled(Link)`
  align-items: center;
  display: flex;
`

const AlertsTableList = props => {
  const [currentPage, setCurrentPage] = useState(0)
  const loading = useAlertsLoading()
  const loadAlerts = useLoadAllAlerts()
  const alerts = useAlertAll()
  const lastPage = useAlertAllLastPage()

  useEffect(() => {
    loadAlerts(0, 20)
  }, [])

  const goToProfile = id => {
    props.history.push(`/perfil/${id}`)
  }

  const loadMore = () => {
    if (!loading) {
      setCurrentPage(currentPage + 1)
      loadAlerts(currentPage + 1)
    }
  }

  return (
    <Layout>
      <PageToolbar title="Listagem de Alertas" />
      {!isEmpty(alerts) ? (
        <Paper>
          <>
            <Table>
              <thead>
                <tr>
                  <th
                    style={{
                      backgroundColor: GetActivatedSorted(
                        "personName",
                        "alerts"
                      )
                        ? "rgb(233,233,233)"
                        : "white",
                    }}
                  >
                    Colaborador
                  </th>
                  <th
                    style={{
                      backgroundColor: GetActivatedSorted(
                        "policyName",
                        "alerts"
                      )
                        ? "rgb(233,233,233)"
                        : "white",
                    }}
                  >
                    Local
                  </th>
                  <th
                    style={{
                      backgroundColor: GetActivatedSorted("eventDate", "alerts")
                        ? "rgb(233,233,233)"
                        : "white",
                    }}
                  >
                    <SortLabel
                      props={{ setCurrentPage }}
                      request={loadAlerts}
                      name="eventDate"
                      label="Data/Hora"
                      screen="alerts"
                    />
                  </th>
                  <th>Duração</th>
                  <ActionHeader width="50px" />
                </tr>
              </thead>
              <tbody>
                {alerts
                  ? alerts.map((item, key) => {
                      return (
                        <tr
                          key={key}
                          style={{ cursor: "pointer" }}
                          data-testid="table-row"
                        >
                          <td onClick={() => goToProfile(item.id)}>
                            <Link to={`/perfil/${item.person.id}`}>
                              {pathOr("-", ["person", "name"], item)}
                            </Link>
                          </td>
                          <td>{pathOr("-", ["policy", "name"], item)}</td>
                          <td>
                            {format(item.eventDate, "DD/MM/YYYY [às] HH:mm")}
                          </td>
                          <td> 
                          {item.durationInSeconds > 0 ?
                            new Date(item.durationInSeconds * 1000)
                              .toISOString()
                              .substr(11, 8) : "00:00:01"
                          }
                          </td>
                          <td width="50px">
                            <LinkStyled
                              to={`${props.match.path}/detalhes/${item.id}`}
                            >
                              <FaChevronRight />
                            </LinkStyled>
                          </td>
                        </tr>
                      )
                    })
                  : ""}
              </tbody>
            </Table>
            {loading && <LoadingIndicator loading={true} />}
            {!loading && !lastPage && <ShowMoreItens handleClick={loadMore} />}
          </>
        </Paper>
      ) : (
        <Paper>
          <Text>Não há alertas cadastrado.</Text>
        </Paper>
      )}
      <Switch>
        <PrivateRoute
          path={`${props.match.path}/detalhes/:id`}
          component={AlertDetails}
        />
      </Switch>
    </Layout>
  )
}

export default AlertsTableList
