import { useDispatch, useSelector } from "react-redux"
import { useCallback } from "react"
import {
  findOrganizationRequestAction,
  listOrganizationRequestAction,
  loadOrganizationsRequest,
  organizationCreateRequestAction,
  organizationDeleteRequestAction,
  organizationResetAction,
  organizationUpdateRequestAction,
} from "../ducks/organizations"

export const useLoadOrganization = () => {
  const dispatch = useDispatch()
  return useCallback(
    (name, unpaged = false) => {
      dispatch(loadOrganizationsRequest(name, unpaged))
    },
    [dispatch]
  )
}

export const useListOrganizationRequestHandler = () => {
  const dispatch = useDispatch()
  return useCallback(
    page => {
      dispatch(listOrganizationRequestAction(page))
    },
    [dispatch]
  )
}

export const useFindOrganizationRequestHandler = () => {
  const dispatch = useDispatch()
  return useCallback(
    id => {
      dispatch(findOrganizationRequestAction(id))
    },
    [dispatch]
  )
}

export const useCreateOrganizationHandlers = () => {
  const dispatch = useDispatch()
  return useCallback(
      organizationUnity => {
      dispatch(organizationCreateRequestAction(organizationUnity))
    },
    [dispatch]
  )
}

export const useUpdateOrganizationHandlers = () => {
  const dispatch = useDispatch()
  return useCallback(
      organizationUnity => {
      dispatch(organizationUpdateRequestAction(organizationUnity))
    },
    [dispatch]
  )
}

export const useDeleteOrganizationHandlers = () => {
  const dispatch = useDispatch()
  return useCallback(
    id => {
      dispatch(organizationDeleteRequestAction(id))
    },
    [dispatch]
  )
}

export const useOrganizationsLoading = () => {
  return useSelector(state => state.organization.loading)
}

export const useOrganizations = () => {
  return useSelector(state => state.organization.organizationUnits)
}

export const useOrganizationUpdateSuccess = () => {
  return useSelector(state => state.organization.updateSuccess)
}

export const useOrganizationDeleteSuccess = () => {
  return useSelector(state => state.organization.deleteSuccess)
}

export const useOrganizationCreateSuccess = () => {
  return useSelector(state => state.organization.createSuccess)
}

export const useOrganization = () => {
  return useSelector(state => state.organization.organizationUnity)
}

export const useTotalPages = () => {
  return useSelector(state => state.organization.totalPages)
}

export const useOrganizationReset = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(organizationResetAction())
  }, [dispatch])
}
