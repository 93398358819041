import { Button as ReakitButton, styled } from "reakit"
import { ifProp, theme } from "styled-tools"

const Button = styled(ReakitButton)`
  border-radius: ${props => props.theme.baseline * 2}px;
  font-size: ${theme("typography.size.body2")};
  font-weight: ${ifProp(
    "bold",
    theme("typography.weight.bold"),
    theme("typography.weight.normal")
  )};
  height: auto;
  min-width: ${props => props.theme.baseline * 30}px;
  padding-bottom: ${theme("spacing.extrasmall")};
  padding-top: ${theme("spacing.extrasmall")};
  padding-left: ${theme("spacing.medium")};
  padding-right: ${theme("spacing.medium")};
  text-decoration: none;
  text-transform: uppercase;
  transition: all 300ms;
  width: ${ifProp("full", "100%", "auto")};
  margin-left: ${props => props.theme.spacing[props.marginLeft]};
`

export default Button
