import { call, put, takeLatest } from "redux-saga/effects"
import api from "../../services/api"
import API from "../../lib/api"
import {
  LOAD_RESPONSIBLES_REQUEST,
  loadResponsiblesError,
  loadResponsiblesSuccess,
} from "store/ducks/responsible"

export function* findResponsibles(action) {
  try {
    const { name, unpaged } = action.payload
    const findResponsibleDataApiCall = () => {
      return api
        .get(`${API.PERSON}/responsibles`, {
          params: {
            name,
            unpaged,
          },
        })
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }

    const { content } = yield call(findResponsibleDataApiCall)
    yield put(loadResponsiblesSuccess(content))
  } catch (err) {
    yield put(loadResponsiblesError(err))
  }
}
export default [takeLatest(LOAD_RESPONSIBLES_REQUEST, findResponsibles)]
