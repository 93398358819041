import React, {useCallback, useEffect, useState} from "react"

const CheckBoxFilter = ({setCurrentPage, filterFn, name, screen}) => {
  const [checked, setChecked] = useState(false)
  const [filterParam, setFilterParam] = useState("false")

  useEffect(() => {
    handleFilter()
  }, [])

  const handleFilter = useCallback(() => {
      setChecked(!checked)

      if (!checked) {
        setFilterParam("true")
      } else {
        setFilterParam("false")
      }

      if (filterParam) {

        const filter = localStorage.getItem("filter")
        let filterNewObject
        if (!filter) {
          filterNewObject = {
            [screen]: [],
          }
        } else {
          filterNewObject = JSON.parse(filter)
          if (!filterNewObject[screen]) {
            filterNewObject[screen] = []
          }
          filterNewObject[screen] = filterNewObject[screen].filter(
            i => i.field !== name
          )
        }
        filterNewObject[screen].push({
          field: name,
          operator: "contains",
          value: filterParam,
        })
        localStorage.setItem("filter", JSON.stringify(filterNewObject))
        setCurrentPage(0)
        setTimeout(() => filterFn(0), 0)
      }
    },
    [checked]
  )

  return (<div>
    <label htmlFor="status" style={{padding: "1px"}}>Apenas ativos</label>
    <input
      type="checkbox"
      id="status"
      checked={checked}
      onChange={handleFilter}
    />
  </div>)
}
export default CheckBoxFilter
export const GetActivatedFilter = (column, screen) => {
  const filter = JSON.parse(localStorage.getItem("filter"))
  return (filter && filter[screen] && !!filter[screen].find(item => item.field === column))
}
