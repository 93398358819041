import React, { useEffect, useState } from "react"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import { useCreateUserHandlers, useUserReset } from "../../../store/hooks/user"
import UserForm from "../UserForm"

const UserCreate = props => {
  const createUserRequest = useCreateUserHandlers()
  const userReset = useUserReset()
  const [user] = useState({
    name: "",
    email: "",
    profiles: [],
    password: "",
    // timezone: 1,
    confirmPassword: "",
    active: true,
  })

  useEffect(() => {
    return () => {
      userReset()
    }
  }, [])

  const onSubmit = values => {
    createUserRequest(values)
  }

  return (
    <LayoutSidebarOverlay
      title="Novo Usuário"
      afterClose={() => {
        props.history.push("/usuarios")
      }}
    >
      {({ closeSidebar }) => {
        return (
          <UserForm
            closeSidebar={closeSidebar}
            onSubmit={onSubmit}
            enableReinitialize={true}
            initialValues={user}
          />
        )
      }}
    </LayoutSidebarOverlay>
  )
}

export default UserCreate
