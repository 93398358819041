import React, {useEffect, useState} from "react"
import Table, {ActionData, ActionHeader, ShowMoreItens} from "common/Table"
import LoadingIndicator from "common/LoadingIndicator"
import {useFindCredentials} from "store/hooks/credential"
import {GetActivatedSorted} from "../Sort"
import SortLabel from "../SortLabel"
import Filter, {GetActivatedFilter} from "../Filter"
import {format} from "date-fns"
import {withRouter} from "react-router-dom"
import {getStatusLabel, SELECT_OPTIONS} from "../../utils/get-status";
import {usePermissionsFind} from "../../store/hooks/permission";
import {useLoadPersons, usePersons} from "../../store/hooks/person";
import PaperStyled from "../PaperStyled";
import {isEmpty} from "ramda";
import Text from "../Text";
import {StorageUtils} from "../../utils/StorageUtils";

const PermissionRequestTableList = props => {
  const credentialRequest = useFindCredentials()
  const findPermissions = usePermissionsFind()
  const getPersons = useLoadPersons()
  const persons = usePersons()

  const formatPermissions = perms => perms.map(perm => perm.name).join(", ")
  const goToProfile = id => {
    props.history.push(`/perfil/${id}`)
  }

  const [defaultPerson, setDefaultPerson] = useState()

  const findPersonId = () => {
    const filter = JSON.parse(localStorage.getItem("filter"));
    if(filter){
      const item = props.items.find(x => {
        return filter.permissions && filter.permissions.some(permission => x.person && x.person.id === permission.value);
      })
      setDefaultPerson(item && item.person && item.person.name);
    }
  };

  useEffect(() => {
    getPersons(0, 100, true)
    findPersonId()
  }, [props.items]);

  const selectFilterFields = () => {
    return persons && persons.map((x) => ({value: x.id, label: x.name}))
  }

  return (
    <>
      <Table>
        <thead>
        <tr>
          <th
            style={{
              backgroundColor: GetActivatedSorted("name", "credential")
                ? "rgb(233,233,233)"
                : "white",
            }}
          >
            <SortLabel
              props={props}
              request={findPermissions}
              name="personId"
              label="Nome"
              screen="permissions"
            />
            <Filter
              screen="permissions"
              name="namePersonId"
              applied={GetActivatedFilter("namePersonId", "permissions")}
              setCurrentPage={props.setCurrentPage}
              filterFn={findPermissions}
              defaultValue={defaultPerson}
              selectOptions={selectFilterFields()}
              filterType="selectFilter"
            />
          </th>
          <th>
            <label>Autorização</label>
          </th>
          <th
            style={{
              backgroundColor: GetActivatedSorted("createdAt", "credential")
                ? "rgb(233,233,233)"
                : "white",
            }}
          >
            <SortLabel
              props={props}
              request={findPermissions}
              name="createdAt"
              label="Criado em:"
              screen="permissions"
            />
            <Filter
              applied={GetActivatedFilter("createdAt", "permissions")}
              screen="permissions"
              name="createdAt"
              setCurrentPage={props.setCurrentPage}
              filterFn={findPermissions}
              defaultValue={StorageUtils.getDefaultStorageValue("permissions", "createdAt")}
              filterType="dateFilter"
            />
          </th>
          <th
            style={{
              backgroundColor: GetActivatedSorted("updatedAt", "credential")
                ? "rgb(233,233,233)"
                : "white",
            }}
          >
            <SortLabel
              props={props}
              request={findPermissions}
              name="updatedAt"
              label="Alterado em:"
              screen="permissions-request"
            />
            <Filter
              applied={GetActivatedFilter("updatedAt", "permissions")}
              screen="permissions"
              name="updatedAt"
              setCurrentPage={props.setCurrentPage}
              filterFn={findPermissions}
              defaultValue={StorageUtils.getDefaultStorageValue("permissions", "updatedAt")}
              filterType="dateFilter"
            />
          </th>
          <th
            style={{
              backgroundColor: GetActivatedSorted("status", "credential")
                ? "rgb(233,233,233)"
                : "white",
            }}
          >
            <SortLabel
              props={props}
              request={credentialRequest}
              name="status"
              label="Situação:"
              screen="credential"
            />
            <Filter
              applied={GetActivatedFilter("status", "permissions")}
              screen="permissions"
              items={props.items}
              name="status"
              setCurrentPage={props.setCurrentPage}
              filterFn={findPermissions}
              defaultValue={StorageUtils.getDefaultStorageValue("permissions", "status")}
              filterType="selectFilter"
              selectOptions={SELECT_OPTIONS}
            />
          </th>
          <ActionHeader>Ações</ActionHeader>
        </tr>
        </thead>
        {!isEmpty(props.items) ? (
          <tbody>
          {props.items.map((item, key) => {
            return (
              <tr key={key} data-testid="table-row">
                <td
                  onClick={() =>
                    item.person ? goToProfile(item.person.id) : " "
                  }
                >
                  {item.person ? item.person.name : " "}
                </td>
                <td>
                  {item.permissions ? formatPermissions(item.permissions) : ""}
                </td>
                <td>{format(item.createdAt, "DD/MM/YYYY hh:mm")}</td>
                <td>{format(item.updatedAt, "DD/MM/YYYY hh:mm")}</td>
                <td>{getStatusLabel(item.status)}</td>
                <ActionData>{props.renderActions(item)}</ActionData>
              </tr>
            );
          })}
          </tbody>
        ) : (
          <PaperStyled>
            <Text>Nenhum pedido de credencimento cadastrado.</Text>
          </PaperStyled>
        )}
      </Table>
      {props.loading && <LoadingIndicator loading={true}/>}
      {!props.loading && !props.last && (
        <ShowMoreItens handleClick={props.loadMore}/>
      )}
    </>
  )
}

export default withRouter(PermissionRequestTableList)
