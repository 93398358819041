import React, { useEffect } from "react"
import { ContainerStyled } from "../index.styled"
import { ErrorMessage, useFormikContext } from "formik"
import InputText from "../../../../common/InputText"
import { ErrorContainer, ErrorLabel } from "../../../../common/Error"
import {
  useCredentials,
  useLoadCredentials,
} from "../../../../store/hooks/credential"
import MultiSelectCheckboxV2 from "../../../../common/MultiSelectCheckboxV2"

const StepProcedureData = ({ values }) => {
  const { handleChange } = useFormikContext()
  const findCredentials = useLoadCredentials()
  const credentials = useCredentials()
  const sortByName = (a, b) => {
    return a.name.localeCompare(b.name)
  }

  useEffect(() => {
    findCredentials(1000, true)
  }, [])

  return (
    <ContainerStyled>
      <InputText
        value={values.name}
        name="name"
        id="name"
        label="NOME DA AUTORIZAÇÃO"
        onChange={handleChange}
      />
      <MultiSelectCheckboxV2
        name="credentials"
        id="credentials"
        list={credentials.sort(sortByName)}
        value={values.credentials || []}
        label="CREDENCIAIS"
        propertyToShow="name"
        valueProperty="id"
        uniqueProp
      />
      <ErrorContainer>
        <ErrorMessage
          name="name"
          render={msg => <ErrorLabel>{msg}</ErrorLabel>}
        />
      </ErrorContainer>
    </ContainerStyled>
  )
}

export default StepProcedureData
