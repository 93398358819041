import React from "react"
import { isNil } from "ramda"
import { Box, Flex, styled } from "reakit"
import { ifProp, theme } from "styled-tools"
import Heading from "common/Heading"
import Text from "common/Text"
import IconButton from "common/IconButton"

export const SidebarHeader = styled(Box)`
  margin-bottom: ${ifProp(
    "hasTitle",
    theme("spacing.large"),
    theme("spacing.none")
  )};
`

export const SidebarTitle = styled(Flex)`
  align-items: center;
  justify-content: space-between;
`

const SidebarLayerHeader = props => {
  return (
    <SidebarHeader hasTitle={!isNil(props.title)}>
      {props.subtitle && (
        <Text margin="none" palette="grayscale" variant="body3" uppercase>
          {props.subtitle}
        </Text>
      )}
      <SidebarTitle>
        <Heading
          margin="none"
          palette="primary"
          use="h1"
          variant="display3"
          uppercase
          maxWidth={props.maxWidthTitle}
        >
          {props.title}
        </Heading>
        <IconButton palette="primary" onClick={props.onClose}>
          close
        </IconButton>
      </SidebarTitle>
    </SidebarHeader>
  )
}

export default SidebarLayerHeader
