import { useDispatch, useSelector } from "react-redux"
import { useCallback } from "react"
import {
  createPersonRequest,
  deletePersonRequest,
  downloadTimeInPlacesRequest,
  findPersonTimeInPlacesRequest,
  findPersonTimeInPlacesReset,
  loadAutoCompletePersonsRequest,
  loadPersonBeaconsRequest,
  loadPersonPermissionsRequest,
  loadPersonRequest,
  loadPersonsRequest,
  loadUnpagedPersonsRequest,
  resetPerson,
  resetResetPersons,
  updatePersonRequest,
  findPersonTimeInPlacesSummaryRequest,
} from "../ducks/person"

export const usePersonTimeInPlacesSummaryRequest = () => {
  const dispatch = useDispatch()
  return useCallback(
    (startDate, endDate, personId, daysFilter) => {
      dispatch(
        findPersonTimeInPlacesSummaryRequest(
          startDate,
          endDate,
          personId,
          daysFilter
        )
      )
    },
    [dispatch]
  )
}

export const useFindPersonPermissions = () => {
  const dispatch = useDispatch()
  return useCallback(
    id => {
      dispatch(loadPersonPermissionsRequest(id))
    },
    [dispatch]
  )
}

export const useFindPersonBeacons = () => {
  const dispatch = useDispatch()
  return useCallback(
    id => {
      dispatch(loadPersonBeaconsRequest(id))
    },
    [dispatch]
  )
}

export const usePersonPermissions = () => {
  return useSelector(state => state.person.permissions)
}

export const usePersonBeacons = () => {
  return useSelector(state => state.person.beacons)
}

export const useSummaryLoading = () => {
  return useSelector(state => state.person.summaryLoading)
}

export const useAutoCompletePersons = () => {
  return useSelector(state => state.person.autocompletePersons)
}

export const useLoadAutoCompletePersons = () => {
  const dispatch = useDispatch()
  return useCallback(
    (name, unpaged = true) => {
      dispatch(loadAutoCompletePersonsRequest(name, unpaged))
    },
    [dispatch]
  )
}

export const useResetPersons = () => {
  const dispath = useDispatch()
  return useCallback(() => {
    dispath(resetResetPersons())
  }, [dispath])
}

export const useLoadPersons = () => {
  const dispatch = useDispatch()
  return useCallback(
    (page, size, force = false) => {
      dispatch(loadPersonsRequest(page, size, force))
    },
    [dispatch]
  )
}

export const useLoadUnpagedPersons = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(loadUnpagedPersonsRequest())
  }, [dispatch])
}

export const useFindPerson = () => {
  const dispatch = useDispatch()
  return useCallback(
    (id, withBeacons) => {
      dispatch(loadPersonRequest(id, withBeacons))
    },
    [dispatch]
  )
}

export const useDownloadTimePlacesRequest = () => {
  const dispatch = useDispatch()
  return useCallback(
    filter => {
      dispatch(downloadTimeInPlacesRequest(filter))
    },
    [dispatch]
  )
}

export const usePersonTimePlacesReset = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(findPersonTimeInPlacesReset())
  }, [dispatch])
}

export const usePersonTimePlacesRequest = () => {
  const dispatch = useDispatch()
  return useCallback(
    (filter, page, size) => {
      dispatch(findPersonTimeInPlacesRequest(filter, page, size))
    },
    [dispatch]
  )
}

export const useDeletePerson = () => {
  const dispatch = useDispatch()
  return useCallback(
    id => {
      dispatch(deletePersonRequest(id))
    },
    [dispatch]
  )
}

export const usePersonReset = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(resetPerson())
  }, [dispatch])
}

export const useLoadingPerson = () => {
  return useSelector(state => state.person.loading)
}

export const useDeletePersonSuccess = () => {
  return useSelector(state => state.person.deleteSuccess)
}

export const useUpdatePersonSuccess = () => {
  return useSelector(state => state.person.updateSuccess)
}

export const useCreatePersonSuccess = () => {
  return useSelector(state => state.person.createSuccess)
}

export const usePersons = () => {
  return useSelector(state => state.person.persons)
}

export const usePersonTimePlaces = () => {
  return useSelector(state => state.person.timePlaces)
}

export const usePersonTimePlacesLast = () => {
  return useSelector(state => state.person.timePlacesLast)
}

export const usePerson = () => {
  return useSelector(state => state.person.person)
}

export const usePersonsTotalPages = () => {
  return useSelector(state => state.person.totalPages)
}

export const usePersonFiltering = () => {
  return useSelector(state => state.person.filtering)
}

export const useUpdatePerson = () => {
  const dispatch = useDispatch()
  return useCallback(
    person => {
      dispatch(updatePersonRequest(person))
    },
    [dispatch]
  )
}

export const useCreatePerson = () => {
  const dispatch = useDispatch()
  return useCallback(
    person => {
      dispatch(createPersonRequest(person))
    },
    [dispatch]
  )
}

export const usePersonLast = () => {
  return useSelector(state => state.person.last)
}

export const usePersonsUnpaged = () => {
  return useSelector(state => state.person.personsUnpaged)
}

export const usePersonTimeInPlacesSummary = () => {
  return useSelector(state => state.person.timeInPlacesSummary)
}

export const usePersonTimeInPlacesSummaryChart = () => {
  return useSelector(state => state.person.timeInPlacesSummaryChart)
}
