import { Flex, Step, styled } from "reakit"

export const FormWizardWrapper = styled(Flex)`
  flex-direction: column;
  flex-grow: 1;

  form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
`

export const StepsWrapper = styled(Flex)`
  flex-direction: column;
  flex-grow: 1;
`

export const StepItem = styled(Step)`
  display: flex;
  flex-direction: column;
  height: 100%;
`
