import React from "react"
import { styled, Box, Flex } from "reakit"
import { theme } from "styled-tools"
import Heading from "common/Heading"
import IconRounded from "common/IconRounded"
import Text from "common/Text"

const LayerFeedbackStyled = styled(Flex)`
  align-items: center;
  align-self: center;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  max-width: 30rem;
`

const IconWrapper = styled(Box)`
  margin-bottom: ${theme("spacing.medium")};
`

const LayerFeedback = ({ icon, palette = "primary", title, message }) => {
  return (
    <LayerFeedbackStyled>
      <IconWrapper>
        <IconRounded size="large" palette={palette}>
          {icon}
        </IconRounded>
      </IconWrapper>
      <Heading
        margin="medium"
        palette={palette}
        use="h5"
        variant="display2"
        uppercase
      >
        {title}
      </Heading>
      <Text align="center">{message}</Text>
    </LayerFeedbackStyled>
  )
}

export default LayerFeedback
