import React from "react"
import {withRouter} from "react-router-dom"
import {format} from "date-fns";
import Table, {ShowMoreItens} from "../Table";
import LoadingIndicator from "../LoadingIndicator";
import {useEmergencyAlertLast} from "../../store/hooks/alerts";

const EmergencyAlertsList = props => {
  const last = props.last ? props.last : useEmergencyAlertLast()

  return (
    <>
      <Table>
        <thead>
        <tr>
          <th
          >
            <label>Local</label>
          </th>
          <th
          >
            <label>Node mac</label>
          </th>
          <th
          >
            <label>Data</label>
          </th>
        </tr>
        </thead>
        <tbody>
        {props.items
          ? props.items.map((item, key) => {
            return (
              <tr
                key={key}
                data-testid="table-row"
              >
                <td >{item.node && item.node.friendlyName}</td>
                <td >
                  {item.node && item.node.uuid}
                </td>
                <td>
                  {format(item.when, "DD/MM/YYYY HH:mm")}
                </td>
              </tr>
            )
          })
          : ""}
        </tbody>
      </Table>
      {props.loading && <LoadingIndicator loading={true} />}
      {!props.loading && !last && (
        <ShowMoreItens handleClick={props.loadMore} />
      )}
    </>
  )
}
export default withRouter(EmergencyAlertsList)
