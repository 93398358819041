import { Box, InlineFlex, styled } from "reakit"
import { palette, theme } from "styled-tools"
import Link from "common/Link"

export const LinkStyled = styled(Link)`
  align-items: center;
  display: flex;
`

export const TableHeader = styled(InlineFlex)`
  align-items: center;
  padding: ${theme("spacing.medium")} ${theme("spacing.extrasmall")};
`

export const PageToolbarContent = styled(InlineFlex)`
  align-items: center;
  flex-grow: 1;
  justify-content: space-between;
  padding-left: 1rem;
`

export const PaperGroup = styled(InlineFlex)`
  display: flex;
  width: 100%;
  align-items: ${props => props.alignItems};

  & > div {
    width: 50%;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
`
export const MonitoringGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, 110px);
  grid-gap: 20px;
  margin-bottom: 30px;
  @media (max-width: 1160px) {
    grid-template-rows: repeat(4, 110px);
  }
`

export const WhiteBox = styled.div`
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 25px 20px;
  box-shadow: -10px 10px 20px 0 rgba(30, 30, 30, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const WhiteBoxInfo = styled.div`
  opacity: ${({ setOpacity }) => (setOpacity ? 0.4 : 1)};
  display: flex;
  flex-direction: column;
  span {
    font-size: 24px;
    color: ${palette("primary")};
    font-weight: 500;
  }
  label {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.541327);
  }
`

export const GaugeContainer = styled.div`
  opacity: ${({ setOpacity }) => (setOpacity ? 0.4 : 1)};
  width: 100%;
  height: 100%;
  grid-column-end: 3;
  grid-column-start: 1;
  grid-row-start: 2;
  grid-row-end: 4;
  padding: 18px 38px;
  display: flex;
  box-shadow: -10px 10px 20px 0 rgba(30, 30, 30, 0.05);
  background-color: white;
  justify-content: space-between;
  @media (max-width: 1160px) {
    grid-row-end: 5;
    flex-wrap: wrap;
    justify-content: center;
  }
`

export const GaugeBox = styled(Box)`
  text-align: center;
  display: flex;
  flex-direction: column;
  label {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.541327);
  }
`

export const GaugeLabelText = styled.label`
  opacity: ${({ setOpacity }) => (setOpacity ? 0.4 : 1)};
`

export const GaugeReport = styled.div`
  opacity: ${({ setOpacity }) => (setOpacity ? 0.4 : 1)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  span {
    font-size: 24px;
    color: ${palette("primary")};
    font-weight: bold;
    margin-bottom: 13px;
    strong {
      font-size: 30px;
      color: rgb(185, 185, 185);
    }
  }
  label {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
    margin-bottom: 20px;
  }
  a {
    text-align: end;
  }
`

export const ViolationBox = styled.div`
  background: ${({ backgroundColor }) => backgroundColor()};
  width: 100%;
  height: 100%;
  grid-row-start: 1;
  grid-row-end: 4;
  grid-column-start: 3;
  display: flex;
  justify-content: space-between;
  padding: 26px 20px;
  flex-direction: column;
  a {
    text-align: end;
  }
  transition: all 0.8s ease;
`

export const ViolationTitle = styled.span`
  opacity: ${({ setOpacity }) => (setOpacity ? 0.4 : 1)};
  color: ${palette("primary")};
  font-size: 24px;
  font-weight: bold;
`

export const LastViolationContainer = styled.div`
  opacity: ${({ setOpacity }) => (setOpacity ? 0.4 : 1)};
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  span {
    margin-left: 10px;
    font-size: 14px;
    align-self: center;
    color: rgba(0, 0, 0, 0.54);
  }
`
