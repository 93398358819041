import React from "react"
import FieldText from "common/FieldText"
import FieldCheckbox from "common/FieldCheckbox"
import Text from "common/Text"

const FilterableList = props => {
  return (
    <div>
      <FieldText
        name={`${props.name}-search`}
        id={`${props.id}-search`}
        label={props.label}
        placeholder={props.placeholder}
        autoComplete="off"
        onChange={props.onChange}
      />

      {props.loading ? (
        <Text palette="grayscale" variant="body2">
          Carregando...
        </Text>
      ) : (
        props.items.map((item, key) => {
          const uniqId = `${props.name}-${item.id}`

          return (
            <FieldCheckbox
              key={key}
              name={props.name}
              id={uniqId}
              label={item.label}
              value={item.id}
              validate={props.validate}
            />
          )
        })
      )}
    </div>
  )
}

export default FilterableList
