import React from "react"
import { styled } from "reakit"
import { Link as RouterLink } from "react-router-dom"
import { ifProp } from "styled-tools"

const LinkStyled = styled(RouterLink)`
  color: inherit;
  text-decoration: none;
  margin-left: ${ifProp("marginleft", props => props.marginleft, "unset")};
`

const Link = ({ children, ...props }) => {
  return <LinkStyled {...props}>{children}</LinkStyled>
}

export default Link
