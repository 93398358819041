import React, { useEffect } from "react"
import { Flex } from "reakit"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import LayerFeedback from "common/LayerFeedback"
import Button from "common/Button"
import LoadingIndicator from "common/LoadingIndicator"
import PersonDestroyInfo from "common/PersonDestroyInfo"
import {
  useDeletePerson,
  useDeletePersonSuccess,
  useFindPerson,
  useLoadingPerson,
  usePerson, usePersonReset,
} from "../../../store/hooks/person"

const PersonDestroy = props => {
  const person = usePerson()
  const personLoading = useLoadingPerson()
  const deletePerson = useDeletePerson()
  const { id } = props.match.params
  const findPerson = useFindPerson()
  const deleteSuccess = useDeletePersonSuccess()
  const personReset = usePersonReset()

  useEffect(() => {
    if (id) {
      findPerson(id)
    }
  }, [findPerson, id])

  // handler called by destroy button
  const onDestroy = () => {
    deletePerson(id)
  }

  useEffect(() => {
    return () => {
      personReset()
    }
  }, [])

  return (
    <LayoutSidebarOverlay
      title="Excluir Pessoa"
      afterClose={() => {
        props.history.push("/pessoa")
      }}
    >
      {({ closeSidebar }) => {
        return (
          <LoadingIndicator loading={personLoading}>
            <Flex column maxWidth="33rem" flexGrow={1}>
              {deleteSuccess ? (
                <LayerFeedback
                  icon="check"
                  palette="secondary"
                  title="Pessoa excluída"
                  message="Pessoa foi excluida com sucesso."
                />
              ) : (
                <>
                  {person && (
                    <PersonDestroyInfo
                      person={person}
                      credentials={person.credentials}
                    />
                  )}
                </>
              )}
            </Flex>
            <LayoutSidebarOverlay.Footer>
              {deleteSuccess ? (
                <Button type="button" onClick={closeSidebar}>
                  Ok
                </Button>
              ) : (
                <>
                  <Button
                    disabled={personLoading}
                    type="button"
                    palette="white"
                    onClick={closeSidebar}
                  >
                    Cancelar
                  </Button>
                  <Button
                    disabled={personLoading}
                    type="button"
                    onClick={onDestroy}
                  >
                    Excluir
                  </Button>
                </>
              )}
            </LayoutSidebarOverlay.Footer>
          </LoadingIndicator>
        )
      }}
    </LayoutSidebarOverlay>
  )
}

export default PersonDestroy
