import React, { useEffect } from "react"
import Button from "../Button"
import { useFormikContext } from "formik"

const AdvanceFormButton = ({
  structure,
  step,
  children,
  closeSidebar,
  createSuccess,
  updateSuccess,
  deleteSuccess,
  renewCredentialError
}) => {
  const { handleSubmit, values, setTouched } = useFormikContext()
  const isLastStep = () => {
    return step.ids.length === step.current + 1
  }

  const isSubmitStep = () => {
    return structure.length === step.current + 1
  }

  const handleAdvance = event => {
    const stepStructure = structure && structure.find(p => p.step === step.current)
    if (
      stepStructure &&
      !!structure.find(p => p.step === step.current).requiredFields
    ) {
      const hasStepErrors = structure
      .find(p => p.step === step.current)
      .requiredFields.some(field => !values[field])
      if (isLastStep()) {
        closeSidebar(event)
      } else if (hasStepErrors || (isSubmitStep() && !hasStepErrors)) {
        handleSubmit()
      } else if (isSubmitStep()) {
        handleSubmit()
      } else {
        setTouched(false)
        step.next()
      }
    } else {
      closeSidebar(event)
    }
  }

  useEffect(() => {
    if (createSuccess || updateSuccess || deleteSuccess || renewCredentialError ) {
      step.next()
    }
  }, [createSuccess, updateSuccess, deleteSuccess, renewCredentialError])

  return (
    <Button
      type="button"
      disabled={false}
      onClick={event => {
        handleAdvance(event)
      }}
    >
      {children}
    </Button>
  )
}

export default AdvanceFormButton
