import React, { useEffect } from "react"
import { isNil } from "ramda"
import { Box, Flex, styled } from "reakit"
import { theme } from "styled-tools"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import Text from "common/Text"
import Button from "common/Button"
import LoadingIndicator from "common/LoadingIndicator"
import formatStringByPattern from "format-string-by-pattern"
import {
  useFindPerson,
  useLoadingPerson,
  usePerson,
} from "../../../store/hooks/person"

const BlockListItem = styled(Box)`
  padding-bottom: ${theme("spacing.large")};
  padding-top: ${theme("spacing.large")};
`

const PersonDetails = props => {
  const MASK_CPF = "XXX.XXX.XXX-XX"
  const person = usePerson()
  const { id } = props.match.params
  const loadingPerson = useLoadingPerson()
  const findPerson = useFindPerson()

  useEffect(() => {
    if (id) {
      findPerson(id)
    }
  }, [findPerson, id])
  return (
    <LayoutSidebarOverlay
      title="Pessoa"
      subtitle={person ? person.id : ""}
      afterClose={() => {
        props.history.push("/pessoa")
      }}
    >
      {({ closeSidebar }) => {
        return (
          <LoadingIndicator loading={loadingPerson}>
            {isNil(person) ? null : (
              <Flex column maxWidth="33rem" flexGrow={1}>
                <BlockListItem>
                  <Text margin="extralarge" palette="primary" variant="h6">
                    CRACHÁ ATIVO: {person.beacon && person.beacon.macAddress}
                  </Text>
                  <Text margin="extralarge" palette="primary" variant="h6">
                    NOME: {person.name}
                  </Text>
                  <Text margin="extralarge" palette="primary" variant="h6">
                    Nº DOCUMENTO: {person.docId}
                  </Text>
                  <Text margin="extralarge" palette="primary" variant="h6">
                    DATA NASCIMENTO: {person.birthDate}
                  </Text>
                  {/*<Text margin="extralarge" palette="primary" variant="h6">
                    TURNO DE TRABALHO: {person.shift}
                  </Text>
                  <Text margin="extralarge" palette="primary" variant="h6">
                    ATIVIDADE ESPECIAL: {person.atvd}
                  </Text>*/}
                  <Text margin="extralarge" palette="primary" variant="h6">
                    GESTOR DIRETO: {person.gestor}
                  </Text>
                </BlockListItem>
              </Flex>
            )}
            <LayoutSidebarOverlay.Footer>
              <Button type="button" onClick={closeSidebar}>
                Ok
              </Button>
            </LayoutSidebarOverlay.Footer>
          </LoadingIndicator>
        )
      }}
    </LayoutSidebarOverlay>
  )
}

export default PersonDetails
