import { useDispatch, useSelector } from "react-redux"
import { useCallback } from "react"
import {
  userCreateRequestAction,
  userDeleteRequestAction,
  userResetAction,
  userUpdateRequestAction,
  findUserRequestAction,
  findUsersRequestAction,
  findProfilesRequestAction,
} from "../ducks/user"

export const useFindUsersRequestHandler = () => {
  const dispatch = useDispatch()
  return useCallback(
    page => {
      dispatch(findUsersRequestAction(page))
    },
    [dispatch]
  )
}

export const useFindUserRequestHandler = () => {
  const dispatch = useDispatch()
  return useCallback(
    id => {
      dispatch(findUserRequestAction(id))
    },
    [dispatch]
  )
}

export const useCreateUserHandlers = () => {
  const dispatch = useDispatch()
  return useCallback(
    user => {
      dispatch(userCreateRequestAction(user))
    },
    [dispatch]
  )
}

export const useUpdateUserHandlers = () => {
  const dispatch = useDispatch()
  return useCallback(
    user => {
      dispatch(userUpdateRequestAction(user))
    },
    [dispatch]
  )
}

export const useDeleteUserHandlers = () => {
  const dispatch = useDispatch()
  return useCallback(
    id => {
      dispatch(userDeleteRequestAction(id))
    },
    [dispatch]
  )
}

export const useUsersLoading = () => {
  return useSelector(state => state.user.loading)
}

export const useUsers = () => {
  return useSelector(state => state.user.users)
}

export const useProfiles = () => {
  return useSelector(state => state.user.profiles)
}

export const useFindProfiles = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(findProfilesRequestAction())
  }, [dispatch])
}

export const useUserUpdateSuccess = () => {
  return useSelector(state => state.user.updateSuccess)
}

export const useUserDeleteSuccess = () => {
  return useSelector(state => state.user.deleteSuccess)
}

export const useUserCreateSuccess = () => {
  return useSelector(state => state.user.createSuccess)
}

export const useUser = () => {
  return useSelector(state => state.user.user)
}

export const useTotalPages = () => {
  return useSelector(state => state.user.totalPages)
}

export const useUserLast = () => {
  return useSelector(state => state.user.last)
}

export const useUserReset = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(userResetAction())
  }, [dispatch])
}
