import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #ecf0f5;
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export const Logo = styled.img`
  max-width: 400px;
  margin: 30px;
`
