import React from "react"
import { isEmpty, isNil, pathOr } from "ramda"
import { Box, Flex, InlineFlex, List, styled } from "reakit"
import { theme } from "styled-tools"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import Heading from "common/Heading"
import Text from "common/Text"
import Divider from "common/Divider"
import Avatar from "common/Avatar"
import Link from "common/Link"
import Tag from "common/Tag"
import LoadingIndicator from "common/LoadingIndicator"
import { getStatusLabel, getStatusPalette } from "utils/get-status"
import { useAlertAll } from "store/hooks/alerts"

const AvatarWrapper = styled(Flex)`
  justify-content: center;
  margin-bottom: ${theme("spacing.medium")};
`

const BlockListItem = styled(Box)`
  padding-bottom: ${theme("spacing.large")};
  padding-top: ${theme("spacing.large")};
`

const StatusWrapper = styled(InlineFlex)`
  align-items: center;
  justify-content: center;
  margin-bottom: ${theme("spacing.medium")};

  * {
    margin-right: ${theme("spacing.extrasmall")};
    margin-left: ${theme("spacing.extrasmall")};
  }
`

const AlertDetails = props => {
  const alerts = useAlertAll()
  const alert = alerts.find(alert => alert.id === props.match.params.id)

  if (isEmpty(alert) || isNil(alert)) {
    return null
  }

  return (
    <LayoutSidebarOverlay
      afterClose={() => {
        props.history.push("/alertas")
      }}
    >
      {() => {
        return (
          <LoadingIndicator loading={false}>
            <Flex column maxWidth="33rem" flexGrow={1}>
              <AvatarWrapper>
                <Avatar alt="" width="124px" height="124px" />
              </AvatarWrapper>
              <Heading
                align="center"
                margin="medium"
                palette="primary"
                variant="display3"
              >
                <Link to={`/perfil/${alert.person.id}`}>
                  {alert.person.name}
                </Link>
              </Heading>
              <Text
                align="center"
                margin="extrasmall"
                palette="grayscale"
                variant="body2"
              >
                {alert.person.registrationId}
              </Text>
              <Text
                align="center"
                margin="medium"
                palette="grayscale"
                variant="body2"
              >
                {`Contato: ${pathOr("n/a", ["person", "phone"], alert)}`}
              </Text>
              <StatusWrapper>
                <Text
                  align="center"
                  margin="none"
                  palette={getStatusPalette(alert.status)}
                  tone={2}
                  variant="body2"
                  uppercase
                >
                  Status
                </Text>
                <Tag palette={getStatusPalette(alert.status)} tone={2}>
                  {getStatusLabel(alert.status)}
                </Tag>
              </StatusWrapper>
              <Divider palette="primary" />
              <BlockListItem>
                <Text margin="big" palette="primary" variant="body" uppercase>
                  Alerta
                </Text>
                <List>
                  {pathOr([], ["permissions"], alert).map((permission, key) => {
                    return (
                      <Text
                        key={key}
                        margin="medium"
                        variant="body2"
                        use="li"
                        palette={getStatusPalette(alert.status)}
                        tone={2}
                        uppercase
                      >
                        {permission.name}
                      </Text>
                    )
                  })}
                </List>
              </BlockListItem>
              <Divider palette="primary" />
              <BlockListItem>
                <Text margin="big" palette="primary" variant="body" uppercase>
                  Credencial
                </Text>
                <List>
                  {pathOr([], ["credentials"], alert).map((credential, key) => {
                    return (
                      <li key={key}>
                        <Text margin="none" variant="body2">
                          {credential.name}
                        </Text>
                        <Text
                          margin="medium"
                          variant="body2"
                          palette={getStatusPalette(credential.type)}
                          tone={2}
                        >
                          {getStatusLabel(credential.type)}
                        </Text>
                      </li>
                    )
                  })}
                </List>
              </BlockListItem>
            </Flex>
          </LoadingIndicator>
        )
      }}
    </LayoutSidebarOverlay>
  )
}

export default AlertDetails
