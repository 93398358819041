import React, { useState } from "react"
import Text from "common/Text"
import Divider from "common/Divider"

const PolicyDestroyInfo = props => {
  const [policy] = useState(props.policy)
  return (
    <>
      {policy && (
        <>
          <Text palette="primary" variant="h4" align="left" margin="big" bold>
            {policy.name}
          </Text>
          <Text
            palette="grayscale"
            variant="body2"
            align="center"
            margin="medium"
          >
            {policy.id}
          </Text>

          <Divider palette="primary" />

          <Text
            palette="primary"
            variant="h6"
            align="left"
            margin="big"
            uppercase
            bold
            marginTop="medium"
          >
            {policy.description}
          </Text>
        </>
      )}
    </>
  )
}

export default PolicyDestroyInfo
