import { useDispatch, useSelector } from "react-redux"
import { useCallback } from "react"
import {
  changeModuleAction,
  changeModuleDialogDisplayAction,
} from "store/ducks/navigation"

export const useNavigationChangeModule = () => {
  const dispatch = useDispatch()
  return useCallback(
    (moduleItems, moduleName) => {
      dispatch(changeModuleAction(moduleItems, moduleName))
    },
    [dispatch]
  )
}

export const useNavigationChangeModuleDisplay = () => {
  const dispatch = useDispatch()
  return useCallback(
    display => {
      dispatch(changeModuleDialogDisplayAction(display))
    },
    [dispatch]
  )
}

export const useNavigationDialogDisplay = () => {
  return useSelector(state => state.navigation.dialogDisplay)
}

export const useNavigationModuleName = () => {
  return useSelector(state => state.navigation.moduleName)
}

export const useNavigationSelectedModule = () => {
  return useSelector(state => state.navigation.selectedModule)
}
