import React from "react"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import PermissionRequestForm from "common/PermissionRequestForm"
import { usePermissionCreate } from "store/hooks/permission"

const PermissionRequestCreate = props => {
  const createPermission = usePermissionCreate()

  const onSubmit = values => {
    // check if is required permissions.
    const isRequiredPermissions =
      values["credential-type"] === "credential-required"

    // get permission ids
    const permissions = isRequiredPermissions
      ? []
      : values["special-work"].map(item => ({ id: item }))
    // iterate over employees and create a permission request for each person.
    const data = values.employees.map(employee => {
      return {
        person: {
          id: employee,
        },
        permissions,
      }
    })

    createPermission(data, isRequiredPermissions)
  }

  return (
    <LayoutSidebarOverlay
      title="Novo pedido"
      afterClose={() => {
        props.history.push("/pedidos-de-credenciais")
      }}
    >
      {({ closeSidebar }) => {
        return (
          <PermissionRequestForm
            closeSidebar={closeSidebar}
            onSubmit={onSubmit}
            initialValues={{
              "credential-type": "credential-special",
            }}
          />
        )
      }}
    </LayoutSidebarOverlay>
  )
}

export default PermissionRequestCreate
