import React from "react"
import { useFormikContext } from "formik"
import { Switch } from "../Switch"

export function FormSwitch({
  onLabel,
  offLabel,
  bgOnColor,
  bgOffColor,
  textOnColor,
  textOffColor,
  type,
  value,
  name,
  size,
}) {
  const { setFieldValue } = useFormikContext()

  const handleChange = event => {
    if (name) {
      setFieldValue(name, event)
    }
  }

  return (
    <Switch
      onLabel={onLabel}
      offLabel={offLabel}
      bgOnColor={bgOnColor}
      bgOffColor={bgOffColor}
      textOnColor={textOnColor}
      textOffColor={textOffColor}
      type={type}
      value={value}
      size={size}
      onChange={handleChange}
    />
  )
}
