import React, { useCallback, useEffect, useState } from "react"
import { isEmpty } from "ramda"
import Layout from "common/Layout"
import PageToolbar from "common/PageToolbar"
import Paper from "common/Paper"
import Text from "common/Text"
import {
  useCheckpointFilter,
  useCheckpointsLoading,
  useTotalPages,
} from "store/hooks/checkpoint"
import EventsTableList from "../../common/EventsTableList"
import {
  useDownloadTimePlacesRequest,
  useLoadUnpagedPersons,
  usePersonsUnpaged,
  usePersonTimePlaces,
  usePersonTimePlacesRequest,
  usePersonTimePlacesReset,
} from "store/hooks/person"
import Button from "../../common/Button"
import { Formik } from "formik"
import FieldDate from "../../common/FieldDate"
import ExcelIcon from "assets/images/excel.svg"
import {
  useFindPoliciesUnpagedRequestHandler,
  usePolicies,
} from "store/hooks/policy"
import { Container, FieldContainerStyled } from "./index.styled"
import SimplePrimeSelect from "../../common/SimplePrimeSelectV2"

const Events = () => {
  const [filter, setFilter] = useState({})
  const [currentPage, setCurrentPage] = useState(0)
  const totalPages = useTotalPages()
  const loading = useCheckpointsLoading()
  const filtering = useCheckpointFilter()
  const findTimePlaces = usePersonTimePlacesRequest()
  const timePlaces = usePersonTimePlaces()
  const personRequest = useLoadUnpagedPersons()
  const policiesRequest = useFindPoliciesUnpagedRequestHandler()
  const persons = usePersonsUnpaged()
  const policies = usePolicies()
  const downloadCsv = useDownloadTimePlacesRequest()
  const resetTimeInPlaces = usePersonTimePlacesReset()

  const handleSubmitForm = useCallback(values => {
    resetTimeInPlaces()
    console.log(values)
    setFilter(values)
    findTimePlaces(values, 0)
    setCurrentPage(0)
  }, [])

  const loadMore = useCallback(() => {
    if (!loading) {
      findTimePlaces(filter, currentPage + 1)
      setCurrentPage(currentPage + 1)
    }
  }, [loading, currentPage, filter])

  useEffect(() => {
    personRequest()
    policiesRequest()
    findTimePlaces({}, currentPage)
    return () => resetTimeInPlaces()
  }, [])

  return (
    <Layout>
      <PageToolbar title="Consulta de Eventos" />
      <Formik
        initialValues={{
          policyId: filter && filter.policyId ? filter.policyId : "",
          dateBegin:
            filter && filter.dateBegin
              ? filter.dateBegin
              : new Date(Date.now() - 86400000),
          dateEnd: filter && filter.dateEnd ? filter.dateEnd : new Date(),
          personId: filter && filter.personId ? filter.personId : "",
        }}
        onSubmit={handleSubmitForm}
        enableReinitialize={true}
      >
        {({ values, handleChange, handleSubmit, resetForm }) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              background: "white",
              marginBottom: "1%",
              padding: "1% 2%",
            }}
          >
            <Container>
              <FieldContainerStyled>
                <SimplePrimeSelect
                  propertyToShow="name"
                  valueProperty="id"
                  label="Pessoa"
                  list={persons}
                  name="personId"
                  id="personId"
                  value={values.personId}
                  onChange={handleChange}
                />
              </FieldContainerStyled>
              <FieldContainerStyled>
                <SimplePrimeSelect
                  propertyToShow="name"
                  valueProperty="id"
                  label="Política"
                  list={policies}
                  name="policyId"
                  id="policyId"
                  value={values.policyId}
                  onChange={handleChange}
                />
              </FieldContainerStyled>
              <FieldContainerStyled>
                <FieldDate
                  id="dateBegin"
                  label="Data Inicial"
                  value={values.dateBegin}
                  onChange={handleChange}
                />
              </FieldContainerStyled>
              <FieldContainerStyled>
                <FieldDate
                  id="dateEnd"
                  label="Data Final"
                  value={values.dateEnd}
                  onChange={handleChange}
                />
              </FieldContainerStyled>
              <div style={{ display: "flex", alignSelf: "center" }}>
                <Button
                  type="button"
                  disabled={false}
                  onClick={event => {
                    handleSubmit(event)
                  }}
                >
                  Pesquisar
                </Button>
                <Button
                  style={{ marginLeft: "1%" }}
                  type="button"
                  disabled={false}
                  onClick={() => {
                    resetForm()
                    setFilter({})
                    handleSubmit()
                  }}
                >
                  Limpar
                </Button>
                <img
                  onClick={() => downloadCsv(values)}
                  style={{ marginLeft: "1%", width: "32px", cursor: "pointer" }}
                  src={ExcelIcon}
                  alt=""
                />
              </div>
            </Container>
          </div>
        )}
      </Formik>
      {!isEmpty(timePlaces) || filtering ? (
        <Paper>
          <EventsTableList
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            items={timePlaces}
            hasMore={totalPages}
            loading={loading}
            loadMore={loadMore}
          />
        </Paper>
      ) : (
        <Paper>
          <Text>Nenhum evento registrado!</Text>
        </Paper>
      )}
    </Layout>
  )
}

export default Events
