import { Box, Flex, InlineFlex, styled } from "reakit"
import { theme } from "styled-tools"
import Text from "common/Text"

export const ChartWrapper = styled(InlineFlex)`
  width: 100%;
  display: flex;
`

export const Legend = styled(Text)`
  align-items: center;
  display: flex;
  padding-bottom: ${theme("spacing.medium")};
  padding-top: ${theme("spacing.medium")};
  font-weight: ${theme("typography.weight.bold")};
  color: black;

  &::before {
    background-color: ${props => props.bulletColor};
    border-radius: 100%;
    content: "";
    display: block;
    height: 10px;
    margin-right: ${theme("spacing.big")};
    width: 10px;
  }
`

export const BoxLegend = styled(Flex)`
  align-items: flex-start;
  flex-direction: column;
  justify-content: initial;
  position: relative;
  flex: 3 1 0%;
`

export const BoxChart = styled(Box)`
    flex: 2 1 0%;
    place-self: center;
`
