import React from "react"
import {
  Footer,
  ForgotPassword,
  FormContainer,
  Input,
  SubmitButton,
} from "./styles"
import { ErrorMessage, Formik } from "formik"
import { useAuthenticated, useAuthHandlers } from "store/hooks/authentication"
import { Redirect } from "react-router-dom"
import { ValidationSchema } from "./ValidationSchema"
import { ErrorContainer, ErrorLabel } from "common/Error"
import { FormEnterListener } from "common/FormEnterListener"
import LoginContainer from "common/LoginContainer"
import { withRouter } from "react-router-dom"

const Login = props => {
  const authAction = useAuthHandlers()
  const authenticated = useAuthenticated()

  const handleSubmitLoginForm = formValue => {
    authAction(formValue)
  }

  const handleGoToForgotPassword = () => {
    props.history.push("/forgot-password")
  }

  if (authenticated) {
    return <Redirect to="/" />
  }

  return (
    <LoginContainer>
      <LoginForm
        handleSubmit={handleSubmitLoginForm}
        handleForgotPassword={handleGoToForgotPassword}
      />
    </LoginContainer>
  )
}

const LoginForm = ({ handleSubmit, handleForgotPassword }) => {
  const initialValues = {
    email: "",
    password: "",
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validationSchema={ValidationSchema}
    >
      {({ values, handleChange, handleSubmit }) => (
        <>
          <FormEnterListener />
          <FormContainer>
            <Input
              placeholder="Usuário ou E-mail"
              name="email"
              onChange={handleChange}
              value={values.email}
              type="email"
            />
            <Input
              placeholder="Senha"
              name="password"
              onChange={handleChange}
              value={values.password}
              type="password"
            />
            <ErrorContainer>
              <ErrorMessage
                name="email"
                render={msg => <ErrorLabel>{msg}</ErrorLabel>}
              />
            </ErrorContainer>
            <Footer>
              <SubmitButton type="submit" onClick={handleSubmit}>
                Entrar
              </SubmitButton>
              <ForgotPassword onClick={handleForgotPassword}>
                Esqueceu sua senha?
              </ForgotPassword>
            </Footer>
          </FormContainer>
        </>
      )}
    </Formik>
  )
}

export default withRouter(Login)
