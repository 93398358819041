import React, { useState } from "react"
import Text from "common/Text"
import Divider from "common/Divider"

const UserDestroyInfo = props => {
  const [user] = useState(props.user)
  return (
    <>
      {user && (
        <>
          <Text palette="primary" variant="h4" align="left" margin="big" bold>
            {user.name}
          </Text>
          <Text
            palette="grayscale"
            variant="body2"
            align="center"
            margin="medium"
          >
            {user.id}
          </Text>
          <Divider palette="primary" />
        </>
      )}
    </>
  )
}

export default UserDestroyInfo
