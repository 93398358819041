import React, { useState } from "react"
import { Form } from "react-final-form"
import { Step } from "reakit"
import { isNil, isEmpty } from "ramda"
import Button from "common/Button"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import LayerFeedback from "common/LayerFeedback"
import StepCredentialType from "./StepCredentialType"
import StepPermissions from "./StepPermissions"
import StepPersons from "./StepPersons"
import { StepItem, StepsWrapper } from "./index.styled"

const PermissionRequestForm = props => {
  const canSubmitForm = step => {
    return step.ids.length - 1 === step.current + 1
  }

  const isLastStep = step => {
    return step.ids.length === step.current + 1
  }

  const handleSubmitForm = data => event => {
    event.preventDefault()

    // It's needed because last layer is the feedback message.
    if (canSubmitForm(data.step)) {
      data.form.form.submit()
    }

    if (!data.step.hasNext()) {
      props.closeSidebar(event)
    }
  }

  const validateRequiredField = field => {
    return !isNil(field) && !isEmpty(field)
  }

  const getStepValidation = (values, step, isRequired) => {
    if (step.current === 0) {
      return validateRequiredField(values["credential-type"])
    }

    if (step.current === 1) {
      if (isRequired) {
        return validateRequiredField(values["employees"])
      }

      return validateRequiredField(values["special-work"])
    }

    if (step.current === 2) {
      return validateRequiredField(values["employees"])
    }

    return true
  }

  const getSubmitButtonLabel = step => {
    if (isLastStep(step)) {
      return "Fechar"
    }

    if (canSubmitForm(step)) {
      return "Concluir"
    }

    return "Avançar"
  }

  const [isRequiredPermissions, setIsRequiredPermissions] = useState(false)

  return (
    <Form
      initialValues={props.initialValues}
      onSubmit={props.onSubmit}
      render={({ handleSubmit, values, ...formProps }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Step.Container initialState={{ current: 0 }}>
              {step => {
                const isRequired =
                  values["credential-type"] === "credential-required"

                if (isRequiredPermissions !== isRequired) {
                  setIsRequiredPermissions(isRequired)

                  if (isRequired) {
                    step.unregister("1")
                  } else {
                    step.register("1", 1)
                  }
                }

                const isValidStep = getStepValidation(values, step, isRequired)

                return (
                  <>
                    <StepsWrapper>
                      <StepItem step="0" order={0} {...step}>
                        <StepCredentialType />
                      </StepItem>
                      <StepItem step="1" order={1} {...step}>
                        <StepPermissions
                          selectedItems={values["special-work"]}
                        />
                      </StepItem>
                      <StepItem step="2" order={2} {...step}>
                        <StepPersons selectedItems={values.employees} />
                      </StepItem>
                      <StepItem step="3" order={3} {...step}>
                        <LayerFeedback
                          icon="check"
                          palette="secondary"
                          title="Pedido realizado"
                          message="Seu pedido foi realizado com sucesso. Aguarde a emissão das credenciais."
                        />
                      </StepItem>
                    </StepsWrapper>
                    <LayoutSidebarOverlay.Footer>
                      {!isLastStep(step) && (
                        <Button
                          use={Step.Previous}
                          {...step}
                          type="button"
                          palette="white"
                        >
                          Voltar
                        </Button>
                      )}
                      <Button
                        use={Step.Next}
                        {...step}
                        type="button"
                        disabled={!isValidStep}
                        onClick={handleSubmitForm({
                          form: formProps,
                          step,
                        })}
                      >
                        {getSubmitButtonLabel(step)}
                      </Button>
                    </LayoutSidebarOverlay.Footer>
                  </>
                )
              }}
            </Step.Container>
          </form>
        )
      }}
    />
  )
}

export default PermissionRequestForm
