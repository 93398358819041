import React, { useCallback, useEffect } from "react"
import { Flex } from "reakit"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import LayerFeedback from "common/LayerFeedback"
import Button from "common/Button"
import LoadingIndicator from "common/LoadingIndicator"
import {
  useDeletePermissionSuccess,
  usePermissionDelete,
  usePermissionLoading,
  usePermissionReset,
} from "store/hooks/permission"

const PermissionRequestDestroy = props => {
  const { id } = props.match.params
  const loading = usePermissionLoading()
  const deletePermission = usePermissionDelete()
  const deleteSuccess = useDeletePermissionSuccess()
  const reset = usePermissionReset()

  const onDestroy = useCallback(() => {
    deletePermission(id)
  }, [id])

  useEffect(() => {
    reset()
    return () => {
      reset()
    }
  }, [reset])

  return (
    <LayoutSidebarOverlay
      title="Excluir Pedido"
      subtitle={id}
      afterClose={() => {
        props.history.push("/pedidos-de-credenciais")
      }}
    >
      {({ closeSidebar }) => {
        return (
          <LoadingIndicator loading={loading}>
            <Flex column maxWidth="33rem" flexGrow={1}>
              {deleteSuccess ? (
                <LayerFeedback
                  icon="check"
                  palette="secondary"
                  title="Pedido excluído"
                  message="Seu pedido foi excluido com sucesso."
                />
              ) : (
                <LayerFeedback
                  icon="warning"
                  palette="warning"
                  title="Pedido será excluído"
                  message="Seu pedido será excluído e não será possível desfazer essa ação."
                />
              )}
            </Flex>
            <LayoutSidebarOverlay.Footer>
              {deleteSuccess ? (
                <Button type="button" onClick={closeSidebar}>
                  Ok
                </Button>
              ) : (
                <>
                  <Button
                    disabled={loading}
                    type="button"
                    palette="white"
                    onClick={closeSidebar}
                  >
                    Cancelar
                  </Button>
                  <Button disabled={loading} type="button" onClick={onDestroy}>
                    Excluir
                  </Button>
                </>
              )}
            </LayoutSidebarOverlay.Footer>
          </LoadingIndicator>
        )
      }}
    </LayoutSidebarOverlay>
  )
}

export default PermissionRequestDestroy
