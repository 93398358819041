import React from "react"
import { Table as ReakitTable, styled } from "reakit"
import { palette, theme } from "styled-tools"
import { ShowMoreContainer, ShowMoreButton, ShowMoreText } from "./styles"

export const ShowMoreItens = props => {
  const { handleClick } = props
  return (
    <ShowMoreContainer>
      <ShowMoreButton>
        <ShowMoreText onClick={handleClick}>Mostrar mais</ShowMoreText>
      </ShowMoreButton>
    </ShowMoreContainer>
  )
}

export const ActionHeader = styled.th`
  width: ${props => props.width || "150px"};
`

export const ActionData = styled.td`
  width: ${props => props.width || "150px"};
`

const TableStyled = styled(ReakitTable)`
  border-collapse: collapse;
  width: ${theme("spacing.full")};
  position: relative;
  th {
    border-bottom: 2px solid ${palette("secondary")};
    color: ${palette("grayscale")};
    text-transform: uppercase;
  }

  tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  th,
  td {
    font-size: ${theme("typography.size.body2")};
    font-weight: ${theme("typography.weight.normal")};
    padding-bottom: ${theme("spacing.small")};
    padding-left: ${theme("spacing.extrasmall")};
    padding-right: ${theme("spacing.extrasmall")};
    padding-top: ${theme("spacing.small")};
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  td {
    color: ${palette("primary")};
  }

  tbody {
    display: block;
    overflow: auto;
    tr:hover {
      background-color: ${palette("pageBackground")};
    }
  }
`

const Table = ({ children, ...props }) => {
  return <TableStyled {...props}>{children}</TableStyled>
}

export default Table
