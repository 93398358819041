export class MaskUtils {
  static DECIMAL_SEPARATOR = "."
  static GROUP_SEPARATOR = ","
  static maskedId

  maskDDD = ["(", /[1-9]/, /[1-9]/, ")"]

  static maskTime = function(userInput) {
    const hora = userInput
    if (hora.startsWith("2")) {
      return [/[2]/, /[0-3]/, ":", /[0-5]/, /\d/]
    } else {
      return [/[0-1]/, /\d/, ":", /[0-5]/, /\d/]
    }
  }

  static formatPIS(valString) {
    if (!valString) {
      return ""
    }
    const val = valString.toString()
    const parts = this.unFormat(val).split(this.DECIMAL_SEPARATOR)
    const teste = parts[0].substring(0, 14)
    parts[0] = teste
    this.maskedId = this.pis(parts[0])
    return this.maskedId
  }

  static formatCnpj(valString) {
    if (!valString) {
      return ""
    }
    const val = valString.toString()
    const parts = this.unFormat(val).split(this.DECIMAL_SEPARATOR)
    const teste = parts[0].substring(0, 14)
    parts[0] = teste
    this.maskedId = this.cnpj(parts[0])
    return this.maskedId
  }

  static formatCpf(valString) {
    if (!valString) {
      return ""
    }
    const val = valString.toString()
    const parts = this.unFormat(val).split(this.DECIMAL_SEPARATOR)
    const teste = parts[0].substring(0, 11)
    parts[0] = teste
    this.maskedId = this.cpf_mask(parts[0])
    return this.maskedId
  }

  static formatDate(valString) {
    if (!valString) {
      return ""
    }
    const val = valString.toString()
    const parts = this.unFormat(val).split(this.DECIMAL_SEPARATOR)
    if (parts[0].length > 8) {
      const teste = parts[0].substring(0, 8)
      parts[0] = teste
    }
    this.maskedId = this.date_mask(parts[0])
    return this.maskedId
  }

  static formatCep(valString) {
    if (!valString) {
      return ""
    }
    const val = valString.toString()
    const parts = this.unFormat(val).split(this.DECIMAL_SEPARATOR)
    if (parts[0].length > 8) {
      const teste = parts[0].substring(0, 8)
      parts[0] = teste
    }
    this.maskedId = this.cep_mask(parts[0])
    return this.maskedId
  }

  static formatCpfCnpj(valString) {
    if (!valString) {
      return ""
    }
    const val = valString.toString()
    const parts = this.unFormat(val).split(this.DECIMAL_SEPARATOR)
    if (parts[0].length > 11) {
      const teste = parts[0].substring(0, 14)
      parts[0] = teste
      this.maskedId = this.cnpj(parts[0])
    } else {
      const teste = parts[0].substring(0, 11)
      parts[0] = teste
      this.maskedId = this.cpf_mask(parts[0])
    }
    return this.maskedId
  }

  static formatCel(valString) {
    if (!valString) {
      return ""
    }
    const val = valString.toString()
    const parts = this.unFormat(val).split(this.DECIMAL_SEPARATOR)
    if (parts[0].length > 11) {
      const teste = parts[0].substring(0, 11)
      parts[0] = teste
    }
    if (parts[0].length < 11) {
      this.maskedId = this.residencial_mask(parts[0])
    } else {
      this.maskedId = this.celular_mask(parts[0])
    }
    return this.maskedId
  }

  static formatResidencial(valString) {
    if (!valString) {
      return ""
    }
    const val = valString.toString()
    const parts = this.unFormat(val).split(this.DECIMAL_SEPARATOR)
    if (parts[0].length > 8) {
      const teste = parts[0].substring(0, 8)
      parts[0] = teste
    }
    this.maskedId = this.residencial_mask(parts[0])
    return this.maskedId
  }

  static formatarDDD(valString) {
    if (!valString) {
      return ""
    }
    const val = valString.toString()
    const parts = this.unFormat(val).split(this.DECIMAL_SEPARATOR)
    const teste = parts[0].substring(0, 2)
    parts[0] = teste
    this.maskedId = this.formatDdd(parts[0])
    return this.maskedId
  }

  static formatCns(valString) {
    if (!valString) {
      return ""
    }
    const val = valString.toString()
    const parts = this.unFormat(val).split(this.DECIMAL_SEPARATOR)
    const teste = parts[0].substring(0, 15)
    parts[0] = teste
    this.maskedId = this.cns_mask(parts[0])
    return this.maskedId
  }

  static unFormat(val) {
    if (!val) {
      return ""
    }
    val = val.replace(/\D/g, "")

    if (this.GROUP_SEPARATOR === ",") {
      return val.replace(/,/g, "")
    } else {
      return val.replace(/\./g, "")
    }
  }

  static cpf_mask(v) {
    v = v.replace(/\D/g, "") // Remove tudo o que não é dígito
    v = v.replace(/(\d{3})(\d)/, "$1.$2") // Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d)/, "$1.$2") // Coloca um ponto entre o sexo e o setimo dígitos
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2") // Coloca um hífen entre o terceiro e o quarto dígitos
    return v
  }

  static pis(v) {
    v = v.replace(/\D/g, "")
    v = v.replace(/(\d{3})(\d)/, "$1.$2")
    v = v.replace(/^(\d{3})\.(\d{5})(\d)/, "$1.$2.$3") // Coloca ponto entre o quinto e o sexto dígitos
    v = v.replace(/^(\d{3})\.(\d{5})\.(\d{2})(\d)/, "$1.$2.$3-$4") // Coloca ponto entre o quinto e o sexto dígitos
    return v
  }

  static cnpj(v) {
    v = v.replace(/\D/g, "") // Remove tudo o que não é dígito
    v = v.replace(/^(\d{2})(\d)/, "$1.$2") // Coloca ponto entre o segundo e o terceiro dígitos
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3") // Coloca ponto entre o quinto e o sexto dígitos
    v = v.replace(/\.(\d{3})(\d)/, ".$1/$2") // Coloca uma barra entre o oitavo e o nono dígitos
    v = v.replace(/(\d{4})(\d)/, "$1-$2") // Coloca um hífen depois do bloco de quatro dígitos
    return v
  }

  static cep_mask(v) {
    v = v.replace(/\D/g, "") // Remove tudo o que não é dígito
    v = v.replace(/(\d{5})(\d)/, "$1-$2") // Coloca um hífen depois do bloco de quatro dígitos
    return v
  }

  static celular_mask(v) {
    v = v.replace(/\D/g, "") // Remove tudo o que não é dígito
    v = v.replace(/(\d{2})(\d)/, "$1 $2") // Coloca espaço entre o primeiro e o segundo digito
    v = v.replace(/(\d{5})(\d)/, "$1-$2") // Coloca hífen entre o quinto e o sexto digito
    return v
  }

  static residencial_mask(v) {
    v = v.replace(/\D/g, "") // Remove tudo o que não é dígito
    v = v.replace(/(\d{2})(\d)/, "$1 $2") // Coloca espaço entre o primeiro e o segundo digito
    v = v.replace(/(\d{4})(\d)/, "$1-$2") // Coloca hífen entre o quarto e o quinto digito
    return v
  }

  static cns_mask(v) {
    v = v.replace(/\D/g, "") // Remove tudo o que não é dígito
    v = v.replace(/^[0]/, "") // Remove digito se o primeiro for 0
    v = v.replace(/^((?!(0))[0-9]{15})$/g, "$1")
    return v
  }

  static formatDdd(v) {
    v = v.replace(/\D/g, "") // Remove tudo o que não é dígito
    v = v.replace(/^(\d\d)/g, "($1)") // Coloca parênteses em volta dos dois primeiros dígitos
    return v
  }

  static date_mask(v) {
    v = v.replace(/\D/g, "") // Remove tudo o que não é dígito
    v = v.replace(/(\d{2})(\d)/, "$1/$2") // Coloca hífen entre o segundo e o terceiro digito
    v = v.replace(/(\d{2})(\d)/, "$1/$2") // Coloca hífen entre o quarto e o quinto digito
    return v
  }
}
