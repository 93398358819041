import React, {createContext, useCallback} from "react"
import {Box, Flex} from "reakit"
import {theme} from "styled-tools"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import LoadingIndicator from "common/LoadingIndicator"
import Typography from "@material-ui/core/Typography"
import {StyledTabs, StyledTab} from "./index.styled"
import ReportView from "./ReportView/index"
import Button from "common/Button"
import {
  useReportExpiredData,
  useLoadCredentialsExpired,
  useLoadingReport,
  useLoadCredentialsToExpire,
  useReportToExpireData,
  useLoadCredentialsRequested,
  useReportRequestedData,
} from "../../../store/hooks/report"

export const CredentialReportContext = createContext({})

function TabPanel(props) {
  const {children, value, index, ...other} = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

const CredentialReport = props => {
  const [value, setValue] = React.useState(0)
  const [credentialsReport, setCredentialsReport] = React.useState([])
  const reportLoading = useLoadingReport()
  const expiredData = useReportExpiredData()
  const loadExpiredData = useLoadCredentialsExpired()
  const requestedData = useReportRequestedData()
  const loadRequestedData = useLoadCredentialsRequested()
  const toExpireData = useReportToExpireData()
  const loadToExpireData = useLoadCredentialsToExpire()

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    }
  }

  const print = useCallback(() => {
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0)
        doc.text(
          "Overflow 'ellipsize' with one column with long content",
          14,
          20
        )
        doc.autoTable(
          [
            {title: "COLABORADOR", dataKey: "name"},
            {title: "CREDENCIAL", dataKey: "credential"},
          ],
          credentialsReport.filter(c => {
            return c.person && c.person.name && c.credential && c.credential.name
          }).map(c => {
            return {
              name: c.person.name,
              credential: c.credential.name
            }
          }),
          {
            theme: "striped",
          }
        )
        if (value === 0) {
          doc.save("CredentialsExpiredReport.pdf")
        } else if (value === 1) {
          doc.save("CredentialsToExpireReport.pdf")
        } else {
          doc.save("CredentialsRequestedReport.pdf")
        }
      })
    })
  }, [credentialsReport])

  return (
    <CredentialReportContext.Provider value={{setCredentialsReport}}>
      <LayoutSidebarOverlay
        title="Relatório Status Das Credencias"
        maxWidthTitle="28rem"
        afterClose={() => {
          props.history.push("/monitoramento")
        }}
      >
        {() => {
          return (
            <LoadingIndicator loading={false}>
              <Flex column flexGrow={1} maxWidth="34rem">
                <div>
                  <div>
                    <StyledTabs
                      value={value}
                      onChange={handleChange}
                      variant="fullWidth"
                      aria-label="full width tabs example"
                    >
                      <StyledTab label="Vencidas" {...a11yProps(0)} />
                      <StyledTab label="À Vencer" {...a11yProps(1)} />
                      <StyledTab label="Solicitadas" {...a11yProps(2)} />
                    </StyledTabs>
                  </div>
                  <TabPanel value={value} index={0} dir={theme.direction}>
                    <ReportView
                      data={expiredData}
                      loadData={loadExpiredData}
                      loading={reportLoading}
                      emptyLabel="Não há credenciais expiradas."
                    />
                  </TabPanel>
                  <TabPanel value={value} index={1} dir={theme.direction}>
                    <ReportView
                      data={toExpireData}
                      loadData={loadToExpireData}
                      loading={reportLoading}
                      emptyLabel="Não há credenciais à expirar."
                    />
                  </TabPanel>
                  <TabPanel value={value} index={2} dir={theme.direction}>
                    <ReportView
                      data={requestedData}
                      loadData={loadRequestedData}
                      loading={reportLoading}
                      emptyLabel="Não há credenciais solicitadas."
                    />
                  </TabPanel>
                </div>
              </Flex>
              <LayoutSidebarOverlay.Footer>
                <Button palette="white" uppercase onClick={() => print()}>
                  Imprimir
                </Button>
                <Button type="button">OK</Button>
              </LayoutSidebarOverlay.Footer>
            </LoadingIndicator>
          )
        }}
      </LayoutSidebarOverlay>
    </CredentialReportContext.Provider>
  )
}

export default CredentialReport
