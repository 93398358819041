import React, { useEffect, useState } from "react"
import { ContainerLabel, ContainerSwitch, Toogle } from "./styles"

export function Switch({
  onLabel = "Ativo",
  offLabel = "Inativo",
  bgOnColor = "#005369",
  bgOffColor = "#E7E9EF",
  textOnColor = "white",
  textOffColor = "#005369",
  type = "square",
  value,
  size = "md",
  onChange,
}) {
  const [localValue, setLocalValue] = useState(value)
  const [firstClick, setFirstClick] = useState(false)

  useEffect(() => {
    if (firstClick) {
      onChange(localValue)
    }
  }, [localValue, firstClick])

  useEffect(() => {
    setLocalValue(value)
  }, [value])

  return (
    <ContainerSwitch
      color={localValue ? textOnColor : textOffColor}
      backgroundColor={localValue ? bgOnColor : bgOffColor}
      textAlign={localValue}
      borderRadius={type}
      borderColor={bgOnColor}
      size={size}
      onClick={() => {
        setFirstClick(true);
        setLocalValue(localValue => !localValue);
      }}
    >
      {localValue ? (
        <ContainerLabel>{onLabel}</ContainerLabel>
      ) : (
        <ContainerLabel>{offLabel}</ContainerLabel>
      )}
      <Toogle
        right={localValue ? "0%" : type === "round" ? "65%" : "80%"}
        width={type}
        borderRadius={type}
      />
    </ContainerSwitch>
  )
}
