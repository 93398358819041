export const LOAD_ALERTS_REQUEST = "LOAD_ALERTS_REQUEST"
export const GROUP_FIND_REQUEST = "GROUP_FIND_REQUEST"
export const LOAD_ALERTS_SUCCESS = "LOAD_ALERTS_SUCCESS"
export const LOAD_ALERTS_ERROR = "LOAD_ALERTS_ERROR"
export const LOAD_GROUP_ERROR = "LOAD_GROUP_ERROR"
export const LOAD_PERSON_ALERTS_REQUEST = "LOAD_PERSON_ALERTS_REQUEST"
export const LOAD_PERSON_ALERTS_SUCCESS = "LOAD_PERSON_ALERTS_SUCCESS"
export const LOAD_PERSON_ALERTS_ERROR = "LOAD_PERSON_ALERTS_ERROR"
export const LOAD_ALL_ALERTS_REQUEST = "LOAD_ALL_ALERTS_REQUEST"
export const LOAD_ALL_TRIGGED_BUTTONS = "LOAD_ALL_TRIGGED_BUTTONS"
export const LOAD_ALL_GROUP_ALERTS = "LOAD_ALL_GROUP_ALERTS"
export const TRIGGED_BUTTONS_ERROR = "TRIGGED_BUTTONS_ERROR"
export const LOAD_ALL_TRIGGED_BUTTONS_SUCCESS = "LOAD_ALL_TRIGGED_BUTTONS_SUCCESS"
export const LOAD_ALL_GROUPS_ALERTS_SUCCESS = "LOAD_ALL_GROUPS_ALERTS_SUCCESS"
export const LOAD_ALL_ALERTS_SUCCESS = "LOAD_ALL_ALERTS_SUCCESS"
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS"
export const CREATE_GROUP_REQUEST = "CREATE_GROUP_REQUEST"
export const LOAD_ALL_ALERTS_ERROR = "LOAD_ALL_ALERTS_ERROR"
export const UPDATE_GROUP_ERROR = "UPDATE_GROUP_ERROR"
export const GROUP_DELETE_SUCCESS = "GROUP_DELETE_SUCCESS"
export const GROUP_FIND_SUCCESS = "GROUP_FIND_SUCCESS"
export const GROUP_DELETE_REQUEST = "GROUP_DELETE_REQUEST"
export const GROUP_RESET = "GROUP_RESET"
export const GROUP_ALERTS_ERROR = "GROUP_ALERTS_ERROR"

export const loadAlertsBlockedRequest = () => ({
  type: LOAD_ALERTS_REQUEST,
  payload: {
    status: "BLOCKED",
  },
})

export const loadAlertsIrregularRequest = () => ({
  type: LOAD_ALERTS_REQUEST,
  payload: {
    status: "IRREGULAR",
  },
})

export const loadAlertsSuccess = alerts => ({
  type: LOAD_ALERTS_SUCCESS,
  payload: {
    alerts,
  },
})

export const loadAlertsError = error => ({
  type: LOAD_ALERTS_ERROR,
  payload: {
    error,
  },
})

export const loadPersonAlertsRequest = (page, size, force, personId) => ({
  type: LOAD_PERSON_ALERTS_REQUEST,
  payload: {
    page,
    size,
    force,
    personId,
  },
})
export const deleteGroupRequestAction = id => ({
  type: GROUP_DELETE_REQUEST,
  payload: {
    id,
  },
})
export const loadPersonAlertsSuccess = (
  alerts,
  TotalPages,
  lastPageFind,
  last,
  filtering
) => ({
  type: LOAD_PERSON_ALERTS_SUCCESS,
  payload: {
    alerts,
    TotalPages,
    lastPageFind,
    last,
    filtering,
  },
})

export const loadPersonAlertsError = error => ({
  type: LOAD_PERSON_ALERTS_ERROR,
  payload: {
    error,
  },
})
export const findGroupRequestAction = id => ({
  type: GROUP_FIND_REQUEST,
  payload: {
    id,
  },
})

export const loadAllAlertsRequest = (page, size) => ({
  type: LOAD_ALL_ALERTS_REQUEST,
  payload: {
    page,
    size,
  },
})

export const updateGroupError = error => ({
  type: UPDATE_GROUP_ERROR,
  payload: {
    error,
  },
})

export const loadAllAlertsSuccess = (content, lastPage) => ({
  type: LOAD_ALL_ALERTS_SUCCESS,
  payload: {
    content,
    lastPage,
  },
})
export const requestTriggedButtons = (page, size) => ({
  type: LOAD_ALL_TRIGGED_BUTTONS,
  payload: {
    page,
    size
  },
})
export const loadGroupRequestAlerts = (page, size) => ({
  type: LOAD_ALL_GROUP_ALERTS,
  payload: {
    page,
    size
  },
})
export const resetGroup = () => ({
  type: GROUP_RESET,
})

export const deleteGroupSuccess = () => ({
  type: GROUP_DELETE_SUCCESS,
})

export const loadTriggedButtonsSuccess = (data, totalElements, last) => ({
  type: LOAD_ALL_TRIGGED_BUTTONS_SUCCESS,
  payload: {
    data,
    totalElements,
    last,
  },
})
export const loadGroupsSuccess = (groups, totalElements, last) => ({
  type: LOAD_ALL_GROUPS_ALERTS_SUCCESS,
  payload: {
    groups,
    totalElements,
    last,
  },
})

export const findGroupSuccessAction = group => ({
  type: GROUP_FIND_SUCCESS,
  payload: {
    group,
  },
})

export const loadGroupError = error => ({
  type: LOAD_GROUP_ERROR,
  payload: {
    error,
  },
})

export const loadAllAlertsError = error => ({
  type: LOAD_ALL_ALERTS_ERROR,
  payload: {
    error,
  },
})
export const triggedButtonsErrorAction = error => ({
  type: TRIGGED_BUTTONS_ERROR,
  payload: {
    error,
  },
})

export const createGroupSuccess = group => ({
  type: CREATE_GROUP_SUCCESS,
  payload: {
    group,
  },
})
export const createGroupRequest = group => ({
  type: CREATE_GROUP_REQUEST,
  payload: {
    group,
  },
})

export const groupAlertsErrorAction = error => ({
  type: GROUP_ALERTS_ERROR,
  payload: {
    error,
  },
})

const INITIAL_STATE = {
  error: null,
  loading: false,
  alerts: [],
  allAlerts: [],
  last: true,
  allAlertsLastPage: null,
}

const alertsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_PERSON_ALERTS_REQUEST:
      return {
        ...state,
        loading: true,
        alerts: [],
      }
    case CREATE_GROUP_REQUEST:
    case GROUP_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case LOAD_PERSON_ALERTS_SUCCESS: {
      const {
        alerts,
        totalPages,
        lastPageFind,
        last,
        filtering,
      } = action.payload
      return {
        ...state,
        alerts,
        totalPages,
        loading: false,
        lastPageFind,
        last,
        filtering,
      }
    }
    case LOAD_ALERTS_REQUEST:
      return {
        ...state,
        loading: true,
        alerts: [],
      }
    case LOAD_GROUP_ERROR:
    case LOAD_ALERTS_ERROR: {
      const {error} = action.payload
      return {
        ...state,
        error,
        loading: false,
      }
    }
    case LOAD_ALERTS_SUCCESS: {
      const {alerts} = action.payload
      return {
        ...state,
        alerts,
        loading: false,
      }
    }
    case CREATE_GROUP_SUCCESS:
      return {
        ...state,
        createSuccess: true,
        loading: false,
      }
    case LOAD_ALL_ALERTS_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case LOAD_ALL_TRIGGED_BUTTONS: {
      return {
        ...state,
        loading: true,
        last: true
      }
    }
    case LOAD_ALL_GROUP_ALERTS: {
      return {
        ...state,
        loading: true,
        last: true
      }
    }
    case GROUP_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteSuccess: true,
      }
    case LOAD_ALL_TRIGGED_BUTTONS_SUCCESS: {
      const {data, totalElements, last} = action.payload
      return {
        ...state,
        data,
        totalElements,
        last: last,
        loading: false,
      }
    }
    case LOAD_ALL_GROUPS_ALERTS_SUCCESS: {
      const {groups, totalElements, last} = action.payload
      return {
        ...state,
        groups,
        totalElements,
        last: last,
        loading: false,
      }
    }
    case GROUP_FIND_SUCCESS:
      const {group} = action.payload
      return {
        ...state,
        group,
        loading: false,
      }
    case GROUP_FIND_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case LOAD_ALL_ALERTS_SUCCESS: {
      const {content, lastPage} = action.payload
      return {
        ...state,
        allAlerts: content,
        allAlertsLastPage: lastPage,
        loading: false,
      }
    }
    case LOAD_ALL_ALERTS_ERROR: {
      const {error} = action.payload
      return {
        ...state,
        error,
        loading: false,
      }
    }
    case UPDATE_GROUP_ERROR: {
      const {error} = action.payload
      return {
        ...state,
        error,
        loading: false,
      }
    }
    case GROUP_RESET:
      return {
        ...state,
        group: null,
        deleteSuccess: false,
        updateSuccess: false,
        createSuccess: false,
        loading: false,
      }
    default:
      return state
  }
}

export default alertsReducer
