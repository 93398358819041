import React, {useEffect, useState} from "react"
import {isEmpty, pathOr} from "ramda"
import Table from "common/Table"
import Tag from "common/Tag"
import EmptyStateMessage from "common/EmptyStateMessage"
import {getStatusLabel, getStatusPalette} from "utils/get-status"
import {getFormattedDate} from "utils/get-formatted-date"
import {
  useCredentials,
  useLoadPersonsCredentials,
} from "store/hooks/credential"
import Filter, {GetActivatedFilter} from "../../../common/Filter";

const CredentialsTableList = props => {
  const [currentPage, setCurrentPage] = useState(0)

  const loadPersonsCredentials = useLoadPersonsCredentials()
  const personCredentials = useCredentials()
  useEffect(() => {
    loadPersonsCredentials(
      currentPage,
      props.limit || 20,
      false,
      props.profileId
    )
    setCurrentPage(currentPage + 1)
    localStorage.setItem("personId", JSON.stringify(props.profileId))
  }, [])
  return (
    <>
      <Table>
        <thead>
        <tr>
          <th>Credencial</th>
          <th>Status
            <Filter
              applied={GetActivatedFilter("status", "personCredentials")}
              screen="personCredentials"
              name="status"
              setCurrentPage={setCurrentPage}
              filterFn={loadPersonsCredentials}
              dropDownfilter={true}
            />
          </th>
          <th>Validade</th>
          {/* <th width="5%" /> */}
        </tr>
        </thead>
        <>
          {!isEmpty(personCredentials) ? (
            <tbody>
            {personCredentials.map((item, key) => {
              return (
                <tr key={key} data-testid="table-row">
                  <td>{pathOr("n/a", ["credential", "name"], item)}</td>
                  <td>
                    <Tag palette={getStatusPalette(item.status)}>
                      {getStatusLabel(item.status)}
                    </Tag>
                  </td>
                  <td>
                    {getFormattedDate(
                      pathOr(null, ["expiryIn"], item),
                      "DD/MM/YYYY [às] HH:mm"
                    )}
                  </td>
                  {/* <td>{props.renderActions(item)}</td> */}
                </tr>
              )
            })}
            </tbody>
          ) : (
            <EmptyStateMessage>Não há credenciais cadastradas.</EmptyStateMessage>
          )}
        </>
      </Table>
    </>
  )
}

export default CredentialsTableList
