import api from "../../services/api"
import API from "../../lib/api"
import { call, takeLatest } from "redux-saga/effects"
import { put } from "@redux-saga/core/effects"
import { concat } from "ramda"
import {
  FIND_ORGANIZATION_REQUEST,
  findOrganizationSuccessAction,
  LIST_ORGANIZATION_REQUEST,
  listOrganizationSuccessAction,
  LOAD_ORGANIZATION_REQUEST,
  loadOrganizationsError,
  loadOrganizationsSuccess,
  ORGANIZATION_CREATE_REQUEST,
  ORGANIZATION_DELETE_REQUEST,
  ORGANIZATION_UPDATE_REQUEST,
  organizationDeleteSuccessAction,
  organizationUpdateSuccessAction,
} from "../ducks/organizations"
import { useOrganizations } from "../hooks/organizations"

export function* loadOrganization(action) {
  try {
    const { name, unpaged } = action.payload
    const findOrganizationApiCall = () => {
      return api
        .get(`${API.PERSON}/organizations-unities`, {
          params: {
            name,
            unpaged,
          },
        })
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    const { content } = yield call(findOrganizationApiCall)
    yield put(loadOrganizationsSuccess(content))
  } catch (err) {
    yield put(loadOrganizationsError(err))
  }
}

export function* listOrganization(action) {
  try {
    const { page } = action.payload
    const findOrganizationApiCall = () => {
      return api
        .get(`${API.PERSON}/organizations-unities`, {
          params: {
            page,
            size: 10,
          },
        })
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    const { content, totalPage, first } = yield call(findOrganizationApiCall)
    if (!first) {
      const organizations = yield call(useOrganizations())
      yield put(
        listOrganizationSuccessAction(
          concat(organizations, content || []),
          totalPage
        )
      )
    } else {
      yield put(listOrganizationSuccessAction(content, totalPage))
    }
  } catch (err) {
    console.log(err)
    // yield put(authFailedAction(err));
  }
}

export function* findOrganization(action) {
  try {
    const { id } = action.payload
    const findOrganizationApiCall = () => {
      return api
        .get(`${API.PERSON}/organizations-unities/${id}`)
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    const response = yield call(findOrganizationApiCall)
    yield put(findOrganizationSuccessAction(response))
  } catch (err) {
    console.log(err)
    // yield put(authFailedAction(err));
  }
}

export function* deleteOrganization(action) {
  try {
    const { id } = action.payload
    const deleteOrganizationApiCall = () => {
      return api
        .delete(`${API.PERSON}/organizations-unities/${id}`)
        .then(response => {
          return
        })
        .catch(err => {
          throw err
        })
    }
    yield call(deleteOrganizationApiCall)
    yield put(organizationDeleteSuccessAction())
  } catch (err) {
    console.log(err)
  }
}

export function* createOrganization(action) {
  try {
    const { organizationUnity } = action.payload
    const createOrganizationApiCall = () => {
      return api
        .post(`${API.PERSON}/organizations-unities`, organizationUnity)
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    yield call(createOrganizationApiCall)
    // yield put(redirectAction('/sistema/procedures'));
  } catch (err) {
    console.log(err)
  }
}

export function* updateOrganization(action) {
  try {
    const { organizationUnity } = action.payload
    const updateOrganizationApiCall = () => {
      return api
        .put(
          `${API.PERSON}/organizations-unities/${organizationUnity.body.id}`,
          organizationUnity.body
        )
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    yield call(updateOrganizationApiCall)
    yield put(organizationUpdateSuccessAction())
  } catch (err) {
    console.log(err)
  }
}

export default [
  takeLatest(LOAD_ORGANIZATION_REQUEST, loadOrganization),
  takeLatest(LIST_ORGANIZATION_REQUEST, listOrganization),
  takeLatest(FIND_ORGANIZATION_REQUEST, findOrganization),
  takeLatest(ORGANIZATION_UPDATE_REQUEST, updateOrganization),
  takeLatest(ORGANIZATION_DELETE_REQUEST, deleteOrganization),
  takeLatest(ORGANIZATION_CREATE_REQUEST, createOrganization),
]
