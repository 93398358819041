import React from "react"
import { Step } from "reakit"
import Button from "common/Button"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import LayerFeedback from "common/LayerFeedback"
import StepPersonData from "./StepPersonData"
import { StepItem, StepsWrapper } from "./index.styled"
import { Formik } from "formik"
import { ValidationSchema } from "common/PersonForm/ValidationSchema"
import AdvanceFormButton from "common/AdvanceFormButton"
import { useCreatePersonSuccess, useDeletePersonSuccess, useUpdatePersonSuccess } from "store/hooks/person"

const PersonForm = props => {
  const updateSuccess = useUpdatePersonSuccess()
  const createSuccess = useCreatePersonSuccess()
  const deleteSuccess = useDeletePersonSuccess()

  const handleSubmitForm = values => {
    props.onSubmit(values)
  }

  const personValidationStructure = [
    {
      step: 0,
      requiredFields: ["name", "docId", "birthDate"],
    },
  ]

  const canSubmitForm = step => {
    return step.ids.length - 1 === step.current + 1
  }

  const isLastStep = step => {
    return step.ids.length === step.current + 1
  }

  const getSubmitButtonLabel = step => {
    if (isLastStep(step)) {
      return "Fechar"
    }

    if (canSubmitForm(step)) {
      return "Concluir"
    }

    return "Avançar"
  }

  return (
    <>
      <Formik
        initialValues={props.initialValues}
        onSubmit={handleSubmitForm}
        validationSchema={ValidationSchema}
        enableReinitialize={true}
      >
        {({ values, isValid }) => (
          <>
            <Step.Container initialState={{ current: 0 }}>
              {step => {
                return (
                  <>
                    <StepsWrapper>
                      <StepItem step="0" order={0} {...step}>
                        <StepPersonData values={values} />
                      </StepItem>
                      <StepItem step="1" order={1} {...step}>
                        <LayerFeedback
                          icon="check"
                          palette="secondary"
                          title={
                            values.id
                              ? "Registro atualizado"
                              : "Cadastro realizado"
                          }
                          message={
                            values.id
                              ? "Pessoa foi atualizada com sucesso."
                              : "Pessoa foi cadastrada com sucesso."
                          }
                        />
                      </StepItem>
                    </StepsWrapper>
                    <LayoutSidebarOverlay.Footer>
                      {!isLastStep(step) || (step > 0 && !isValid) ? (
                        <Button
                          use={Step.Previous}
                          {...step}
                          type="button"
                          palette="white"
                        >
                          Voltar
                        </Button>
                      ) : (
                        ""
                      )}
                      <AdvanceFormButton
                        closeSidebar={props.closeSidebar}
                        step={step}
                        structure={personValidationStructure}
                        createSuccess={createSuccess}
                        updateSuccess={updateSuccess}
                        deleteSuccess={deleteSuccess}
                      >
                        {getSubmitButtonLabel(step)}
                      </AdvanceFormButton>
                    </LayoutSidebarOverlay.Footer>
                  </>
                )
              }}
            </Step.Container>
          </>
        )}
      </Formik>
    </>
  )
}

export default PersonForm
