import React, { useEffect, useState } from "react"
import { Switch } from "react-router-dom"
import { isEmpty } from "ramda"
import Layout from "common/Layout"
import PageToolbar, { PageToolbarContent } from "common/PageToolbar"
import Paper from "common/Paper"
import Text from "common/Text"
import Link from "common/Link"
import IconButton from "common/IconButton"
import Button from "common/Button"
import CredentialCreate from "./CredentialCreate"
import CredentialTableList from "../../common/CredentialTableList"
import {
  useCredentialCreateSuccess,
  useCredentialDeleteSuccess,
  useCredentialFiltering,
  useCredentials,
  useCredentialUpdateSuccess,
  useFindCredentials,
  useTotalPages,
} from "store/hooks/credential"
import CredentialEdit from "./CredentialEdit"
import CredentialDestroy from "./CredentialDestroy"
import CredentialDetails from "./CredentialDetails"
import PrivateRoute from "common/PrivateRoute"

const Credential = props => {
  const [currentPage, setCurrentPage] = useState(0)
  const findCredentials = useFindCredentials()
  const credentials = useCredentials()
  const filtering = useCredentialFiltering()
  const totalPages = useTotalPages()
  const updateSuccess = useCredentialUpdateSuccess()
  const deleteSuccess = useCredentialDeleteSuccess()
  const createSuccess = useCredentialCreateSuccess()

  const loadMore = () => {
    setCurrentPage(currentPage + 1)
  }

  useEffect(() => {
    findCredentials(currentPage, 20)
  }, [currentPage, updateSuccess, deleteSuccess, createSuccess])

  return (
    <Layout>
      <PageToolbar title="Credenciais">
        <PageToolbarContent>
          <Link to={`${props.match.path}/new`} marginleft="auto">
            <Button bold palette="secondary">
              Nova credencial
            </Button>
          </Link>
        </PageToolbarContent>
      </PageToolbar>
        <Paper>
          <CredentialTableList
            proceduresFiltering={filtering}
            setCurrentPage={setCurrentPage}
            items={credentials}
            loadMore={loadMore}
            hasMore={totalPages}
            renderActions={item => (
              <div style={{display: "flex", justifyContent: "space-around"}}>
              <Link to={`${props.match.path}/delete/${item.id}`}>
                  <IconButton>delete_outline</IconButton>
                </Link>
                <Link to={`${props.match.path}/edit/${item.id}`}>
                  <IconButton>create</IconButton>
                </Link>
                <Link to={`${props.match.path}/detail/${item.id}`}>
                  <IconButton>navigate_next</IconButton>
                </Link>
              </div>
            )}
          />
        </Paper>
      <Switch>
        <PrivateRoute
          path={`${props.match.path}/new`}
          component={CredentialCreate}
        />
        <PrivateRoute
          path={`${props.match.path}/edit/:id`}
          component={CredentialEdit}
        />
        <PrivateRoute
          path={`${props.match.path}/detail/:id`}
          component={CredentialDetails}
        />
        <PrivateRoute
          path={`${props.match.path}/delete/:id`}
          component={CredentialDestroy}
        />
      </Switch>
    </Layout>
  )
}

export default Credential
