import { Flex, Step, styled } from "reakit"
import { FormLabel, TextField } from "@material-ui/core"
import Radio from "@material-ui/core/Radio"
import FormControlLabel from "@material-ui/core/FormControlLabel"

export const FormWizardWrapper = styled(Flex)`
  flex-direction: column;
  flex-grow: 1;

  form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
`

export const StepsWrapper = styled(Flex)`
  flex-direction: column;
  flex-grow: 1;
`

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
`

export const StepItem = styled(Step)`
  display: flex;
  flex-direction: column;
  height: 100%;
`



export const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5%;
  .MuiRadio-colorSecondary.Mui-checked:hover,
  .MuiIconButton-colorSecondary:hover {
    background-color: rgba(0, 83, 105, 0.08);
  }
  .MuiRadio-colorSecondary.Mui-checked {
    color: #005369;
  }
`

export const RadioStyled = styled(Radio)``

export const FormLabelStyled = styled(FormLabel)`
  color: #005369 !important;
  font-size: 1.2rem !important;
`

export const FormControlLabelStyled = styled(FormControlLabel)`
  color: gray;
  .MuiTypography-body1 {
    font-size: 1.3rem;
  }
`
