import { useDispatch, useSelector } from "react-redux"
import { useCallback } from "react"
import {
  procedureCreateRequestAction,
  procedureDeleteRequestAction,
  procedureResetAction,
  procedureUpdateRequestAction,
  findProcedureRequestAction,
  findProceduresRequestAction,
} from "../ducks/procedure"

export const useFindProceduresRequestHandler = () => {
  const dispatch = useDispatch()
  return useCallback(
    page => {
      dispatch(findProceduresRequestAction(page))
    },
    [dispatch]
  )
}

export const useFindProcedureRequestHandler = () => {
  const dispatch = useDispatch()
  return useCallback(
    id => {
      dispatch(findProcedureRequestAction(id))
    },
    [dispatch]
  )
}

export const useCreateProcedureHandlers = () => {
  const dispatch = useDispatch()
  return useCallback(
    procedure => {
      dispatch(procedureCreateRequestAction(procedure))
    },
    [dispatch]
  )
}

export const useUpdateProcedureHandlers = () => {
  const dispatch = useDispatch()
  return useCallback(
    procedure => {
      dispatch(procedureUpdateRequestAction(procedure))
    },
    [dispatch]
  )
}

export const useDeleteProcedureHandlers = () => {
  const dispatch = useDispatch()
  return useCallback(
    id => {
      dispatch(procedureDeleteRequestAction(id))
    },
    [dispatch]
  )
}

export const useProceduresLoading = () => {
  return useSelector(state => state.procedure.loading)
}

export const useProcedures = () => {
  return useSelector(state => state.procedure.procedures)
}

export const useProcedureUpdateSuccess = () => {
  return useSelector(state => state.procedure.updateSuccess)
}

export const useProcedureDeleteSuccess = () => {
  return useSelector(state => state.procedure.deleteSuccess)
}

export const useProcedureCreateSuccess = () => {
  return useSelector(state => state.procedure.createSuccess)
}

export const useProcedure = () => {
  return useSelector(state => state.procedure.procedure)
}

export const useTotalPages = () => {
  return useSelector(state => state.procedure.totalPages)
}

export const useProceduresFiltering = () => {
  return useSelector(state => state.procedure.filtering)
}

export const useProcedureLast = () => {
  return useSelector(state => state.procedure.last)
}

export const useProcedureReset = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(procedureResetAction())
  }, [dispatch])
}
