import React, {useEffect, useState} from "react"
import Paper from "common/Paper"
import Text from "common/Text"
import {Flex} from "reakit"
import Chip from "common/Chip"
import {Line} from "react-chartjs-2"
import classnames from "classnames"
import {addDays, subDays} from "date-fns"
import ExcelIcon from "../../../assets/images/excel.svg"
import LoadingIndicator from "../../../common/LoadingIndicator"
import {
  usePersonTimeInPlacesSummaryChart,
  usePersonTimeInPlacesSummaryRequest,
  useSummaryLoading,
  usePerson,
  useLoadingPerson
} from "store/hooks/person"
import moment from "moment"

const TimeInPlacesDashboard = ({personId}) => {
  const [activeLineFilter, setActiveLineFilter] = useState(7)
  const [datesFilter, setDatesFilter] = useState({})
  const findTimeInPlacesSummary = usePersonTimeInPlacesSummaryRequest()
  const timeInPlacesSummary = usePersonTimeInPlacesSummaryChart()
  const isLoading = useSummaryLoading()
  const isFileReadyToDownload = useLoadingPerson()
  const person = usePerson()
  const today = new Date()

  useEffect(() => {
    const {dateBegin, dateEnd} = datesFilter

    if (!dateBegin || !dateEnd) {
      return
    }

    findTimeInPlacesSummary(dateBegin, dateEnd, personId, activeLineFilter)
  }, [datesFilter, personId])

  useEffect(() => {
    setDatesFilter({
      dateBegin: subDays(today, activeLineFilter).toISOString(),
      dateEnd: addDays(today, 1).toISOString()
    })
  }, [activeLineFilter])

  const downloadXls = () => {
    const params = new URLSearchParams({})
    const {dateBegin, dateEnd} = datesFilter

    const dateBeginFileName = moment(dateBegin).format('DD-MM-YY')
    const dateEndFileName = moment(dateEnd).format('DD-MM-YY')

    params.append('fileName', `Relatório horas ${person.name} - ${dateBeginFileName} até ${dateEndFileName}`)
    personId && params.append('_personId', personId)
    dateBegin && params.append('_dateBegin', dateBegin)
    dateEnd && params.append('_dateEnd', dateEnd)

    return window.open(`/person/v1/time-in-places/xls?${params.toString()}`)
  }

  return (
    <Paper>
      <div style={{
        display: "flex",
        width: '100%',
        paddingRight: '3%',
        paddingLeft: '20%'
      }}
      >
        <Flex style={{margin: "auto"}}>
          <Text
            variant="body"
            palette="primary"
            uppercase
            bold
            marginRight="small"
          >
            Locais Visitados
          </Text>
          <Text variant="body" marginRight="small">
            nos últimos
          </Text>
          <Chip
            uppercase
            onClick={() => setActiveLineFilter(7)}
            className={classnames({
              active: activeLineFilter === 7,
            })}
          >
            7 dias
          </Chip>
          <Chip
            uppercase
            onClick={() => setActiveLineFilter(15)}
            className={classnames({
              active: activeLineFilter === 15,
            })}
          >
            15 dias
          </Chip>
          <Chip
            uppercase
            onClick={() => setActiveLineFilter(30)}
            className={classnames({
              active: activeLineFilter === 30,
            })}
          >
            30 dias
          </Chip>
        </Flex>
        <LoadingIndicator loading={isFileReadyToDownload}>
          <img
            onClick={downloadXls}
            style={{marginLeft: "auto", width: "32px", cursor: "pointer", justifyContent: 'flex-end', alignSelf: 'flex-start'}}
            src={ExcelIcon}
          />
        </LoadingIndicator>
      </div>
      <Flex >
        <LoadingIndicator loading={isLoading}>
          <div style={{position: "relative", height: "auto", width: "80vw"}}>
            <Line data={timeInPlacesSummary} height={300} options={options}/>
          </div>
        </LoadingIndicator>
      </Flex>
    </Paper>
  )
}

const options = {
  responsive: true,
  maintainAspectRatio: false,
  aspectRatio: 1,
  legend: {
    display: true,
    position: "bottom",
    align: "start",
    labels: {
      usePointStyle: true,
      boxWidth: 5,
      padding: 20,
    },
  }
}

export default TimeInPlacesDashboard
