import { format } from "date-fns"
import pt from "date-fns/locale/pt"

const colors = [
  {
    backgroundColor: "rgb(0,65,89)",
    borderColor: "rgb(0,65,89)",
  },
  {
    backgroundColor: "rgb(101,168,196)",
    borderColor: "rgb(101,168,196)",
  },
  {
    backgroundColor: "rgb(170,206,226)",
    borderColor: "rgb(170,206,226)",
  },
  {
    backgroundColor: "rgb(140,101,211)",
    borderColor: "rgb(140,101,211)",
  },
  {
    backgroundColor: "rgb(154,147,236)",
    borderColor: "rgb(154,147,236)",
  },
  {
    backgroundColor: "rgb(0,82,165)",
    borderColor: "rgb(0,82,165)",
  },
  {
    backgroundColor: "rgb(65,179,247)",
    borderColor: "rgb(65,179,247)",
  },
  {
    backgroundColor: "rgb(0,173,206)",
    borderColor: "rgb(0,173,206)",
  },
  {
    backgroundColor: "rgb(89,219,241)",
    borderColor: "rgb(89,219,241)",
  },
  {
    backgroundColor: "rgb(0,197,144)",
    borderColor: "rgb(0,197,144)",
  },
]

export const getRandomColors = () => {
  const randomNumber = Math.floor(Math.random() * 10)
  return colors[randomNumber]
}

export const getChartData = (data, daysFilter) => {
  if (!data || data.length === 0) {
    return {}
  }
  const formatDayOfWeek = daysFilter === 7
  return {
    labels: data[0].values.map(
      v =>
        format(v.date, formatDayOfWeek ? "dddd" : "DD/MM", {
          locale: pt,
        }).split("-")[0]
    ),
    datasets: data.map(obj => ({
      label: obj.name,
      fill: false,
      lineTension: 0,
      ...getRandomColors(),
      data: obj.values.map(
        v => parseFloat(new Date(v.totalDurationInSeconds * 1000).toISOString().slice(11, 16).replace(/:/, '.'))
      ),
    })),
  }
}
