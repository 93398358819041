import React, { Fragment } from "react"
import { Provider as ReakitProvider } from "reakit"
import byondTheme from "theme/byond"
import WebFontLoader from "lib/WebFontLoader"
import { CONFIG } from "lib/WebFontLoader/constants"
import GlobalStyle from "theme/global-styles"

const ThemeProvider = ({ children }) => {
  return (
    <Fragment>
      <GlobalStyle webfont={CONFIG} theme={byondTheme} />
      <ReakitProvider theme={byondTheme}>
        <WebFontLoader config={CONFIG}>{children}</WebFontLoader>
      </ReakitProvider>
    </Fragment>
  )
}

export default ThemeProvider
