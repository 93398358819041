import React, { useEffect, useState } from "react"
import { Input, InlineFlex, styled } from "reakit"
import { palette, theme } from "styled-tools"
import Icon from "common/Icon"
import { withRouter } from "react-router-dom"
import InputText from "../InputText"
import { ContainerStyled } from "../../pages/Policy/PolicyForm/index.styled"
import { Formik } from "formik"
import ValidationSchema from "../../pages/Policy/PolicyForm/ValidationSchema"
import SimplePrimeSelect from "../SimplePrimeSelectV2"
import { FieldContainerStyled } from "../../pages/Events/index.styled"
import {
  useAutoCompletePersons,
  useLoadAutoCompletePersons,
  useLoadUnpagedPersons,
  usePersonsUnpaged,
} from "../../store/hooks/person"
import { AutoComplete } from "primereact/autocomplete"

const GlobalSearchStyled = styled(InlineFlex)`
  align-items: center;
  width: ${theme("spacing.full")};

  i {
    position: relative;
    top: 1px;
  }
`

const InputStyled = styled(Input)`
  color: ${palette("grayscale")};
  font-size: ${theme("typography.size.body")};
  margin-left: ${theme("spacing.extrasmall")};
  width: ${theme("spacing.full")};
`

const GlobalSearch = ({ history }) => {
  const persons = useAutoCompletePersons()
  const personRequest = useLoadAutoCompletePersons()

  const filterSingle = event => {
    personRequest(event.query)
  }
  useEffect(() => {
    personRequest()
  }, [])

  return (
    <GlobalSearchStyled>
      <Icon size="medium" palette="grayscale">
        search
      </Icon>
      <Formik
        initialValues={{ person: "" }}
        onSubmit={() => console.log("a")}
        enableReinitialize={true}
      >
        {({ values, handleChange }) => (
          <AutoComplete
            value={values.person}
            name="person"
            suggestions={persons}
            completeMethod={filterSingle}
            field="name"
            size={30}
            placeholder="Procurar pessoa"
            minLength={1}
            onChange={handleChange}
            onSelect={e => {
              history.push(`/perfil/${e.value.id}`);
            }}
          />
        )}
      </Formik>
    </GlobalSearchStyled>
  )
}

export default withRouter(GlobalSearch)
