import React, { useEffect, useState } from "react"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import {
  useCreatePolicyHandlers,
  usePolicyCreateSuccess,
} from "../../../store/hooks/policy"
import PolicyForm from "../PolicyForm"

const PolicyCreate = props => {
  const createPolicyRequest = useCreatePolicyHandlers()
  const createSuccess = usePolicyCreateSuccess()
  const [policy] = useState({
    name: "",
    description: "",
    active: true,
    deleted: false,
    floor: 0,
    latitude: null,
    longitude: null,
    checkInNodes: [],
    checkInRSSI: 0,
    checkOutNodes: [],
    checkOutRSSI: 0,
    checkInMinRSSI: "",
    checkOutMaxRSSI: "",
    isEnableIO: false,
    minRSSIToEnableIO: 0,
    type: "",
    permissions: [],
  })

  const onSubmit = values => {
    createPolicyRequest(values)
  }

  useEffect(() => {
    if (createSuccess) {
      props.history.push("/politicas")
    }
  }, [createSuccess])

  return (
    <LayoutSidebarOverlay
      title="Nova política"
      afterClose={() => {
        props.history.push("/politicas")
      }}
    >
      {({ closeSidebar }) => {
        return (
          <PolicyForm
            closeSidebar={closeSidebar}
            onSubmit={onSubmit}
            enableReinitialize={true}
            initialValues={policy}
          />
        )
      }}
    </LayoutSidebarOverlay>
  )
}

export default PolicyCreate
