import React from "react"
import { TextStyled } from "./styles"
import { Metrics } from "configs"

const TextPattern = ({
  size = Metrics.fontSize.md,
  color = "black",
  children,
  textAlign = "center",
  bolder = false,
  width,
  style,
  uppercase,
  fontWeigth,
}) => {
  return (
    <TextStyled
      uppercase={uppercase}
      width={width}
      textAlign={textAlign}
      color={color}
      size={size}
      bolder={bolder}
      fontWeigth={fontWeigth}
      style={style}
    >
      {children}
    </TextStyled>
  )
}

export default TextPattern
