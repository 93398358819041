import React from "react"
import InputText from "../../InputText"
import {ContainerStyled} from "common/PersonForm/index.styled"
import TodoList from "../../list/TodoList";

const StepGroupData = ({ values, setFieldValue }) => {
  const handleSaveEmail = email => {
    const updatedEmails = [...values.emails, email];
    setFieldValue("emails", updatedEmails);
  };
  const nonEmptyEmails = values.emails.filter(item => item.email.trim() !== "");

  return (
        <ContainerStyled>
            <InputText
                name="friendlyName"
                id="name"
                label="Nome do Grupo"
                value={values.friendlyName || ""}
            />
            <TodoList
              title="Emails"
              valueKey="email"
              data={nonEmptyEmails}
              onSaveItem={handleSaveEmail}
              getItems={updatedItems => setFieldValue("emails", updatedItems)}
            />
        </ContainerStyled>
    )
}

export default StepGroupData
