import { combineReducers } from "redux"
import auth from "./authentication"
import policy from "./policy"
import checkpoint from "./checkpoint"
import area from "./area"
import map from "./map"
import procedure from "./procedure"
import user from "./user"
import person from "./person"
import beacon from "./beacon"
import credential from "store/ducks/credential"
import organization from "store/ducks/organizations"
import responsible from "store/ducks/responsible"
import report from "store/ducks/report"
import permission from "store/ducks/permission"
import monitoring from "store/ducks/monitoring"
import alerts from "store/ducks/alerts"
import headcount from "store/ducks/headcount"
import navigation from "store/ducks/navigation"
import permissionRequest from "store/ducks/permission-request"

export default combineReducers({
  auth,
  policy,
  headcount,
  checkpoint,
  area,
  map,
  procedure,
  user,
  person,
  beacon,
  credential,
  responsible,
  report,
  permission,
  monitoring,
  alerts,
  navigation,
  organization,
  permissionRequest,
})
