import { call, put, select, takeLatest } from "redux-saga/effects"
import api from "../../services/api"
import API from "../../lib/api"
import {
  reportRequestError,
  loadExpiredSuccess,
  loadToExpireSuccess,
  loadRequestedSuccess,
  LOAD_EXPIRED_REQUEST,
  LOAD_TOEXPIRE_REQUEST,
  LOAD_REQUESTED_REQUEST, LOAD_PERSON_CREDENTIAL_REQUEST, loadPersonCredentialSuccess, LOAD_PERSON_CREDENTIAL_RENEW_REQUEST, personCredentialRenewSuccessAction, renewCredentialError, loadExpiredSuccessDeletedFalse, LOAD_EXPIRED_REQUEST_DELETED_FALSE,
} from "store/ducks/report"
import { concat } from "ramda"

export function* findCredentialsExpired(action) {
  try {
    const { page, size, force } = action.payload
    const findCredentialsExpiredApiCall = () => {
      return api
      .get(`${API.PERSON}/person-credentials`, {
        params: {
          page,
          size: size,
          force,
          status: "EXPIRED",
        },
      })
      .then(response => {
        return response.data
      })
      .catch(err => {
        throw err
      })
    }
    const { expired } = yield select(state => state.report)
    const { content, first, last } = yield call(findCredentialsExpiredApiCall)
    if (!first) {
      yield put(loadExpiredSuccess(concat(expired, content || []), last))
    } else {
      yield put(loadExpiredSuccess(content, last))
    }
  } catch (e) {
    yield put(reportRequestError(e))
  }
}

export function* findCredentialsExpiredV2(action) {
  try {
    const { page, size, force } = action.payload
    const findCredentialsExpiredApiCallV2 = () => {
      return api
      .get(`${API.PERSONV2}/person-credentials`, {
        params: {
          page,
          size: size,
          force,
          status: "EXPIRED",
        },
      })
      .then(response => {
        return response.data
      })
      .catch(err => {
        throw err
      })
    }
    const { expired } = yield select(state => state.report)
    const { content, first, last, totalPages } = yield call(findCredentialsExpiredApiCallV2)
    if (!first) {
      yield put(loadExpiredSuccessDeletedFalse(concat(expired, content || []), last, totalPages))
    } else {
      yield put(loadExpiredSuccessDeletedFalse(content, last, totalPages))
    }
  } catch (e) {
    yield put(reportRequestError(e))
  }
}

export function* findCredentialsToExpire(action) {
  try {
    const { page, size } = action.payload
    const findCredentialsToExpireApiCall = () => {
      return api
      .get(`${API.PERSON}/person-credentials`, {
        params: {
          page,
          size,
          expired: false,
          daysToExpire: 30,
        },
      })
      .then(response => {
        return response.data
      })
      .catch(err => {
        throw err
      })
    }
    const { toExpire } = yield select(state => state.report)
    const { content, first, last } = yield call(findCredentialsToExpireApiCall)
    if (!first) {
      yield put(loadToExpireSuccess(concat(toExpire, content || []), last))
    } else {
      yield put(loadToExpireSuccess(content, last))
    }
  } catch (e) {
    yield put(reportRequestError(e))
  }
}

export function* findCredentialsRequested(action) {
  try {
    const { page, size } = action.payload
    const findCredentialsRequestedApiCall = () => {
      return api
      .get(`${API.PERSON}/person-credentials`, {
        params: {
          page,
          size,
          status: "REQUESTED",
        },
      })
      .then(response => {
        return response.data
      })
      .catch(err => {
        throw err
      })
    }
    const { requested } = yield select(state => state.report)
    const { content, first, last } = yield call(findCredentialsRequestedApiCall)
    if (!first) {
      yield put(loadRequestedSuccess(concat(requested, content || []), last))
    } else {
      yield put(loadRequestedSuccess(content, last))
    }
  } catch (e) {
    yield put(reportRequestError(e))
  }
}
export function* findPersonCredential(action) {
  try {
    const { id } = action.payload
    const findCredentialsRequestedApiCall = () => {
      return api
      .get(`${API.PERSON}/person-credentials/${id}`)
      .then(response => {
        return response.data
      })
      .catch(err => {
        throw err
      })
    }
    const response = yield call(findCredentialsRequestedApiCall)
    yield put(loadPersonCredentialSuccess(response))
  } catch (err) {
    yield put(reportRequestError(err))
  }
}

export function* renewCredential(action) {
  try {
    const { id } = action.payload
    const renewCredentialApiCall = () => {
      return api
      .post(`${API.PERSON}/person-credentials/${id}/renew`)
      .then(response => {
        return response.data
      })
      .catch(err => {
        throw err
      })
    }
    const response = yield call(renewCredentialApiCall)
    yield put(personCredentialRenewSuccessAction(response))
  } catch (err) {
    yield put(renewCredentialError(err))
  }
}
export default [
  takeLatest(LOAD_EXPIRED_REQUEST, findCredentialsExpired),
  takeLatest(LOAD_EXPIRED_REQUEST_DELETED_FALSE, findCredentialsExpiredV2),
  takeLatest(LOAD_TOEXPIRE_REQUEST, findCredentialsToExpire),
  takeLatest(LOAD_REQUESTED_REQUEST, findCredentialsRequested),
  takeLatest(LOAD_PERSON_CREDENTIAL_REQUEST, findPersonCredential),
  takeLatest(LOAD_PERSON_CREDENTIAL_RENEW_REQUEST, renewCredential),
]
