import React from "react"

import Table, { ShowMoreItens } from "common/Table"
import LoadingIndicator from "common/LoadingIndicator"
import SortLabel from "../SortLabel"
import Filter, { GetActivatedFilter } from "../Filter"
import { ActionData, ActionHeader } from "../Table"
import { GetActivatedSorted } from "../Sort"

function DataTable({
  headers,
  source,
  request,
  onPageChange,
  isLoading,
  isLastItem,
  onShowMore,
}) {
  const renderHeaders = () => {
    return headers.map(header => {
      const { name, label, screen, hasFilter } = header

      if (header.isAction) {
        return <ActionHeader>{header.label}</ActionHeader>
      }

      const styles = {
        column: {
          backgroundColor: GetActivatedSorted(name, screen)
            ? "rgb(233,233,233)"
            : "white",
        },
      }
      return (
        <th style={styles.column}>
          <SortLabel
            request={request}
            label={label}
            name={name}
            screen={screen}
            onPageChange={onPageChange}
          />
          {hasFilter && (
            <Filter
              applied={GetActivatedFilter(name, screen)}
              screen={screen}
              name={name}
              setCurrentPage={onPageChange}
              filterFn={request}
            />
          )}
        </th>
      )
    })
  }

  const renderDataRow = (accessor, data, renderRow) => {
    return !!renderRow ? renderRow(data) : data[accessor]
  }

  const renderSource = () => {
    return source.map((data, key) => (
      <tr key={key}>
        {headers.map(header => {
          if (header.isAction) {
            return (
              <ActionData>
                {renderDataRow(header.accessor, data, header.renderRow)}
              </ActionData>
            )
          }

          return (
            <td>{renderDataRow(header.accessor, data, header.renderRow)}</td>
          )
        })}
      </tr>
    ))
  }

  return (
    <>
      <Table>
        <thead>
          <tr>{renderHeaders()}</tr>
        </thead>
        <tbody>{renderSource()}</tbody>
      </Table>
      {isLoading && <LoadingIndicator loading />}
      {!isLoading && !isLastItem && <ShowMoreItens handleClick={onShowMore} />}
    </>
  )
}

export default DataTable
