import React, { useEffect, useState } from "react"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import PersonForm from "common/PersonForm"
import {
  useFindPerson,
  usePerson,
  usePersonReset,
  useUpdatePerson,
} from "store/hooks/person"

const PersonEdit = props => {
  const { id } = props.match.params
  const findPerson = useFindPerson()
  const personData = usePerson()
  const updatePerson = useUpdatePerson()
  const personReset = usePersonReset()
  const [person, setPerson] = useState({
    id: null,
    name: "",
    docId: "",
    birthDate: "",
    shift: "",
    permissionsId: [],
    managerId: "",
    registrationId: "",
  })

  useEffect(() => {
    if (id) {
      findPerson(id)
    }
  }, [findPerson, id])

  useEffect(() => {
    return () => {
      personReset()
    }
  }, [])

  useEffect(() => {
    if (personData) {
      setPerson(personData)
    }
  }, [personData])

  const onSubmit = values => {
    updatePerson(values)
  }

  return (
    <LayoutSidebarOverlay
      title="Editar pessoa"
      subtitle={person ? person.id : ""}
      afterClose={() => {
        props.history.push("/pessoa")
      }}
    >
      {({ closeSidebar }) => {
        return (
          <PersonForm
            closeSidebar={closeSidebar}
            onSubmit={onSubmit}
            initialValues={person}
          />
        )
      }}
    </LayoutSidebarOverlay>
  )
}

export default PersonEdit
