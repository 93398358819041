export const CREDENTIAL_FIND_REQUEST = "CREDENTIAL_FIND_REQUEST"
export const CREDENTIAL_FIND_SUCCESS = "CREDENTIAL_FIND_SUCCESS"
export const CREDENTIAL_FIND_ERROR = "CREDENTIAL_FIND_ERROR"
export const CREDENTIAL_UPDATE_REQUEST = "CREDENTIAL_UPDATE_REQUEST"
export const CREDENTIAL_UPDATE_SUCCESS = "CREDENTIAL_UPDATE_SUCCESS"
export const CREDENTIAL_UPDATE_ERROR = "CREDENTIAL_UPDATE_ERROR"
export const CREDENTIAL_CREATE_REQUEST = "CREDENTIAL_CREATE_REQUEST"
export const CREDENTIAL_CREATE_SUCCESS = "CREDENTIAL_CREATE_SUCCESS"
export const CREDENTIAL_CREATE_ERROR = "CREDENTIAL_CREATE_ERROR"
export const CREDENTIAL_DELETE_REQUEST = "CREDENTIAL_DELETE_REQUEST"
export const CREDENTIAL_DELETE_SUCCESS = "CREDENTIAL_DELETE_SUCCESS"
export const CREDENTIAL_DELETE_ERROR = "CREDENTIAL_DELETE_ERROR"
export const CREDENTIALS_LOAD_REQUEST = "CREDENTIALS_LOAD_REQUEST"
export const CREDENTIALS_LOAD_SUCCESS = "CREDENTIALS_LOAD_SUCCESS"
export const CREDENTIALS_LOAD_ERROR = "CREDENTIALS_LOAD_ERROR"
export const CREDENTIALS_FIND_REQUEST = "CREDENTIALS_FIND_REQUEST"
export const CREDENTIALS_FIND_SUCCESS = "CREDENTIALS_FIND_SUCCESS"
export const CREDENTIALS_FIND_ERROR = "CREDENTIALS_FIND_ERROR"
export const CREDENTIAL_RESET = "CREDENTIAL_RESET"
export const CREDENTIALS_PERSON_REQUEST = "CREDENTIALS_PERSON_REQUEST"
export const CREDENTIALS_PERSON_SUCCESS = "CREDENTIALS_PERSON_SUCCESS"
export const CREDENTIALS_PERSON_ERROR = "CREDENTIALS_PERSON_ERROR"

export const loadPersonCredentialsRequest = (page, size, force, personId) => ({
  type: CREDENTIALS_PERSON_REQUEST,
  payload: {
    page,
    size,
    force,
    personId,
  },
})

export const loadPersonCredentialsSuccess = (
  credentials,
  totalPages,
  lastPageFind,
  last,
  filtering
) => ({
  type: CREDENTIALS_PERSON_SUCCESS,
  payload: {
    credentials,
    totalPages,
    lastPageFind,
    last,
    filtering,
  },
})

export const loadPersonCredentialsError = error => ({
  type: CREDENTIALS_PERSON_ERROR,
  payload: {
    error,
  },
})

export const findCredentialRequestAction = id => ({
  type: CREDENTIAL_FIND_REQUEST,
  payload: {
    id,
  },
})

export const findCredentialSuccessAction = credential => ({
  type: CREDENTIAL_FIND_SUCCESS,
  payload: {
    credential,
  },
})

export const findCredentialsRequestAction = (page, size) => ({
  type: CREDENTIALS_FIND_REQUEST,
  payload: {
    page,
    size,
  },
})

export const findCredentialsSuccessAction = (credentials, totalPages) => ({
  type: CREDENTIALS_FIND_SUCCESS,
  payload: {
    credentials,
    totalPages,
  },
})

export const credentialCreateRequestAction = credential => ({
  type: CREDENTIAL_CREATE_REQUEST,
  payload: {
    credential,
  },
})

export const credentialUpdateRequestAction = credential => ({
  type: CREDENTIAL_UPDATE_REQUEST,
  payload: {
    credential,
  },
})

export const credentialUpdateSuccessAction = () => ({
  type: CREDENTIAL_UPDATE_SUCCESS,
})

export const credentialCreateSuccessAction = () => ({
  type: CREDENTIAL_CREATE_SUCCESS,
})

export const credentialDeleteRequestAction = id => ({
  type: CREDENTIAL_DELETE_REQUEST,
  payload: {
    id,
  },
})

export const credentialDeleteSuccessAction = () => ({
  type: CREDENTIAL_DELETE_SUCCESS,
})

export const credentialResetAction = () => ({
  type: CREDENTIAL_RESET,
})

export const loadCredentialsRequest = (size, unpaged) => ({
  type: CREDENTIALS_LOAD_REQUEST,
  payload: {
    size,
    unpaged,
  },
})
//
// export const findCredentialsRequest = size => ({
//   type: CREDENTIALS_LOAD_REQUEST,
//   payload: {
//     size,
//   },
// })

export const loadCredentialsSuccess = (credentials, last, filtering) => ({
  type: CREDENTIALS_LOAD_SUCCESS,
  payload: {
    credentials,
    last,
    filtering,
  },
})

export const loadCredentialsError = error => ({
  type: CREDENTIALS_LOAD_ERROR,
  payload: {
    error,
  },
})

const INITIAL_STATE = {
  error: null,
  loading: false,
  credentials: [],
  credential: null,
  filtering: false,
  updateSuccess: false,
  deleteSuccess: false,
  createSuccess: false,
  last: true,
}

const credentialsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREDENTIALS_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case CREDENTIALS_LOAD_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        error,
        loading: false,
      }
    }
    case CREDENTIALS_LOAD_SUCCESS: {
      const { credentials, last, filtering } = action.payload
      return {
        ...state,
        credentials,
        loading: false,
        last,
        filtering,
      }
    }
    case CREDENTIAL_FIND_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case CREDENTIALS_FIND_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case CREDENTIALS_FIND_SUCCESS:
      const { credentials } = action.payload
      return {
        ...state,
        credentials,
        loading: false,
      }
    case CREDENTIAL_FIND_SUCCESS:
      const { credential } = action.payload
      return {
        ...state,
        credential,
        loading: false,
      }
    case CREDENTIAL_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        updateSuccess: false,
      }
    case CREDENTIAL_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        updateSuccess: true,
      }
    case CREDENTIAL_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        createSuccess: false,
      }
    case CREDENTIAL_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        createSuccess: true,
      }
    case CREDENTIAL_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case CREDENTIAL_DELETE_SUCCESS:
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    case CREDENTIAL_RESET:
      return {
        ...state,
        loading: false,
        updateSuccess: false,
        deleteSuccess: false,
        createSuccess: false,
      }
    case CREDENTIALS_PERSON_SUCCESS: {
      const {
        credentials,
        totalPages,
        lastPageFind,
        last,
        filtering,
      } = action.payload
      return {
        ...state,
        credentials,
        totalPages,
        loading: false,
        lastPageFind,
        last,
        filtering,
      }
    }
    default:
      return state
  }
}

export default credentialsReducer
