import React from "react"
import { useFormikContext } from "formik"
import {
  FormControlLabelStyled,
  FormLabelStyled,
  RadioContainer,
  RadioStyled,
} from "../../pages/Policy/PolicyForm/index.styled"
import RadioGroup from "@material-ui/core/RadioGroup"

const RadioInput = ({ name, label, value, options }) => {
  const { setFieldValue } = useFormikContext()

  const handleChange = event => {
    const value = event.target.value
    setFieldValue(name, value)
  }

  return (
    <RadioContainer>
      <FormLabelStyled component="legend">{label}</FormLabelStyled>
      <RadioGroup
        aria-label={name}
        name={name}
        value={value || " "}
        onChange={handleChange}
      >
        {options.map((o, i) => (
          <FormControlLabelStyled
            key={i}
            value={o.value}
            control={<RadioStyled />}
            label={o.label}
          />
        ))}
      </RadioGroup>
    </RadioContainer>
  )
}

export default RadioInput
