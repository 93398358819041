import React from "react"
import { format } from "date-fns"
import Table, { ShowMoreItens } from "common/Table"
import LoadingIndicator from "common/LoadingIndicator"
import Filter, { GetActivatedFilter } from "../Filter"
import { GetActivatedSorted } from "../Sort"
import SortLabel from "../SortLabel"
import { FaCheck, FaTimes } from "react-icons/fa"
import {
  useCheckpointLast,
  useFindCheckpointsRequestHandler,
} from "../../store/hooks/checkpoint"
import {StorageUtils} from "../../utils/StorageUtils";

const CheckpointTableList = props => {
  const last = useCheckpointLast()
  const checkpointRequest = useFindCheckpointsRequestHandler()

  const selectFilterFields = () => {
    return props && props.items && props.items.map((x) => ({value: x.friendlyName, label: x.friendlyName}))
  }

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th
              style={{
                backgroundColor: GetActivatedSorted(
                  "friendlyName",
                  "checkpoint"
                )
                  ? "rgb(233,233,233)"
                  : "white",
              }}
            >
              <SortLabel
                props={props}
                request={checkpointRequest}
                name="friendlyName"
                label="Nome"
                screen="checkpoint"
              />
              <Filter
                applied={GetActivatedFilter("friendlyName", "checkpoint")}
                screen="checkpoint"
                name="friendlyName"
                setCurrentPage={props.setCurrentPage}
                filterFn={checkpointRequest}
                defaultValue={StorageUtils.getDefaultStorageValue("checkpoint", "friendlyName")}
                selectOptions={selectFilterFields()}
                filterType="selectFilter"
              />
            </th>
            <th
              style={{
                backgroundColor: GetActivatedSorted("uuid", "checkpoint")
                  ? "rgb(233,233,233)"
                  : "white",
              }}
            >
              <SortLabel
                props={props}
                request={checkpointRequest}
                name="uuid"
                label="Mac_Address"
                screen="checkpoint"
              />
              <Filter
                applied={GetActivatedFilter("uuid", "checkpoint")}
                screen="checkpoint"
                name="uuid"
                setCurrentPage={props.setCurrentPage}
                filterFn={checkpointRequest}
                filterType="inputFilter"
              />
            </th>
            <th>Status</th>
            <th>Visto em:</th>
          </tr>
        </thead>
        <tbody>
          {props.items.map((item, key) => {
            return (
              <tr key={key} data-testid="table-row">
                <td>{item.friendlyName}</td>
                <td>{item.uuid}</td>
                <td>
                  {new Date().getTime() -
                    new Date(
                      item.nodeDetail && item.nodeDetail.dateLastPing
                        ? item.nodeDetail.dateLastPing
                        : 0
                    ).getTime() >
                  120000 ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <FaTimes style={{ color: "red", marginRight: "3px" }} />
                      <label>Offline</label>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <FaCheck
                        style={{ color: "#74d900", marginRight: "3px" }}
                      />
                      <label>Online</label>
                    </div>
                  )}
                </td>
                <td>
                  {item.nodeDetail
                    ? format(item.nodeDetail.dateLastPing, "DD/MM/YYYY HH:mm")
                    : ""}
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      <>
        {props.fetching && <LoadingIndicator loading={true} />}
        {!props.fetching && !last && (
          <ShowMoreItens handleClick={props.loadMore} />
        )}
      </>
    </>
  )
}

export default CheckpointTableList
