import React from "react"
import InputLabel from "common/InputLabel"
import Input from "@material-ui/core/Input/Input"
import { pathOr } from "ramda"
import { styled } from "reakit"
import { theme } from "styled-tools"
import CustomTooltip from "../CustomTooltip"
import { useFormikContext } from "formik"
import { Dropdown } from "primereact/dropdown"

const StyledSelect = styled(Dropdown)`
  min-width: 100%;
  max-width: 336px;
  .MuiSelect-select {
    font-size: ${theme("typography.size.body2")};
    &:focus {
      background: transparent;
    }
  }
`

const SelectContainer = styled.div`
  margin-top: 2%;
  position: relative;
  margin-bottom: ${theme("spacing.medium")};
  .MuiInput-underline:after {
    border-bottom: 2px solid #005369 !important;
  }
`

const InputLabelStyled = styled(InputLabel)`
  font-size: 1.3rem;
  margin-bottom: 0 !important;
`

const SimplePrimeSelect = ({
  input,
  meta,
  value,
  valueProperty,
  name,
  propertyToShow,
  ...props
}) => {
  const { setFieldValue } = useFormikContext()

  const handleChangeSelect = event => {
    const value = event.target.value
    setFieldValue(name, value)
  }

  return (
    <SelectContainer>
      {props.tooltip ? <CustomTooltip tooltip={props.tooltip} /> : ""}
      <InputLabelStyled id={props.id}>{props.label}</InputLabelStyled>
      <StyledSelect
        options={pathOr([], ["list"], props).map(i => ({
          label: i[propertyToShow],
          value: valueProperty ? i[valueProperty] : i,
        }))}
        filter={true}
        filterPlaceholder="Selecione"
        filterBy="label,value"
        value={value}
        labelId={props.id}
        id={props.id}
        {...props}
        {...input}
        input={<Input />}
        onChange={handleChangeSelect}
        dataKey="value"
      >
        {/*{pathOr([], ["list"], props).map((item, key) => {*/}
        {/*  return (*/}
        {/*    <MenuItem*/}
        {/*      key={key}*/}
        {/*      value={valueProperty ? item[valueProperty] : item}*/}
        {/*    >*/}
        {/*      {item[propertyToShow]}*/}
        {/*    </MenuItem>*/}
        {/*  )*/}
        {/*})}*/}
      </StyledSelect>
    </SelectContainer>
  )
}

export default SimplePrimeSelect
