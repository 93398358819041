import React, { useState } from "react"
import Icon from "common/Icon"
import {
  ExpandableBlockStyled,
  Head,
  Title,
  TextStyled,
  Content,
} from "./index.styled"

const ExpandableBlock = props => {
  const [open, setOpen] = useState(false)

  const handleClick = event => {
    event.preventDefault()
    setOpen(!open)
  }

  const getIcon = status => {
    switch (status) {
      case "REJECTED":
        return "close"

      case "APPROVED":
        return "done"

      default:
        break
    }
  }

  return (
    <ExpandableBlockStyled>
      <Head onClick={handleClick}>
        <Title>
          {props.status && (
            <Icon palette="grayscale">{getIcon(props.status)}</Icon>
          )}
          <TextStyled
            done={props.status !== "REQUESTED"}
            palette={props.status !== "REQUESTED" ? "grayscale" : "primary"}
          >
            {props.title}
          </TextStyled>
        </Title>
        <Icon palette="primary">expand_more</Icon>
      </Head>
      {open && <Content>{props.children}</Content>}
    </ExpandableBlockStyled>
  )
}

export default ExpandableBlock
