import React from "react";
import Select from 'react-select'

const SelectFilter = (props) => {

  return (
    <div>
      <Select
        options={props.selectOptions}
        defaultInputValue={props.defaultValue}
        onChange={props.onChange}
      />
    </div>
  )
}
export default SelectFilter;
