import React from "react"
import { isNil } from "ramda"
import { InputStyled, FieldWrapper, CustomCheckbox } from "./index.styled"

const Checkbox = ({ checked, onChange, ...props }) => {
  const handleChange = event => {
    if (!isNil(onChange)) onChange(event)
  }

  return (
    <FieldWrapper>
      <CustomCheckbox checked={checked} />
      <InputStyled
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        {...props}
      />
    </FieldWrapper>
  )
}

export default Checkbox
