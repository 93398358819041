import styled from "styled-components"
import { MdClose } from "react-icons/md"

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1000;
  overflow: hidden;
  @media (max-width: 660px) {
    overflow: scroll;
    display: block;
  }
`

export const WhiteContainer = styled.div`
  background: #fff;
  height: 300px;
  padding: 15px 30px 30px 30px;
  border-radius: 8px;
  display: grid;
  grid-template-rows: 20px auto;
  grid-template-columns: repeat(
    ${({ moduleslenght }) => moduleslenght || 3},
    1fr
  );
  grid-gap: 10px 20px;
  position: relative;

  @media (max-width: 660px) {
    height: auto;
    display: flex;
    flex-wrap: wrap;
  }
`

export const CloseIcon = styled(MdClose)`
  font-size: 20px;
  cursor: pointer;
  justify-self: end;
  grid-column-start: ${({ moduleslenght }) => moduleslenght || 3};
  margin-right: -5px;
  margin-left: auto;
`

export const ModuleContainer = styled.div`
  padding: 20px;
  width: 100%;
  border: 2px solid #80808059;
  border-radius: 8px;
  grid-row-start: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  transition: 0.3s all ease;
  :hover {
    background: #8080801f;
    cursor: pointer;
  }
  span:nth-child(2) {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
  }
  @media (max-width: 660px) {
    margin-bottom: 20px;
  }
`

export const ModuleIcon = styled.img`
  width: 100px;
  opacity: 0.5;
  margin-bottom: 20px;
`
