import styled from 'styled-components';

export const TextStyled = styled.label`
  color: ${({ color }) => color};
  font-family: system-ui;
  text-align: ${({ textAlign }) => textAlign};
  font-size: ${({ size }) => size};
  font-weight: ${({ fontWeigth, bolder }) =>
    fontWeigth ? fontWeigth : bolder ? 'bold' : 'normal'};
  ${({ width }) => {
    if (width) {
      return `width: ${width}%`;
    }
    return ``;
  }}
  ${({ uppercase }) => {
    if (uppercase) {
      return `text-transform: uppercase`;
    }
    return ``;
  }}
`;
