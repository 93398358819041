import React, { useCallback } from "react"
import { format } from "date-fns"
import {
  useFindUsersRequestHandler,
  useUpdateUserHandlers,
  useUserLast,
  useUsersLoading,
} from "store/hooks/user"
import { Switch } from "../Switch"
import DataTable from "../DataTable"

const UserTableList = props => {
  const loading = useUsersLoading()
  const last = useUserLast()
  const updateUser = useUpdateUserHandlers()
  const usersRequest = useFindUsersRequestHandler()

  const handleUpdate = useCallback(
    (active, item) => {
      if (item) {
        updateUser({ ...item, active: active })
      }
    },
    [updateUser]
  )

  const headers = [
    {
      name: "name",
      accessor: "name",
      label: "Nome Completo",
      hasFilter: true,
      screen: "user",
    },
    {
      name: "email",
      accessor: "email",
      label: "E-mail",
      screen: "user",
    },
    {
      name: "dateLastLogin",
      accessor: "dateLastLogin",
      label: "Último acesso",
      screen: "user",
      renderRow: ({ dateLastLogin }) => {
        return format(dateLastLogin, "DD/MM/YYYY hh:mm")
      },
    },
    {
      name: "createdAt",
      accessor: "createdAt",
      label: "Data de criação",
      screen: "user",
      renderRow: ({ createdAt }) => {
        return format(createdAt, "DD/MM/YYYY hh:mm")
      },
    },
    {
      name: "active",
      label: "Status",
      screen: "user",
      renderRow: data => {
        return (
          <Switch
            value={data && data.active}
            size="sm"
            onChange={newValue => handleUpdate(newValue, data)}
          />
        )
      },
    },
    {
      label: "Ações",
      isAction: true,
      renderRow: data => {
        return props.renderActions(data)
      },
    },
  ]

  return (
    <DataTable
      headers={headers}
      source={props.items}
      onPageChange={props.setCurrentPage}
      request={usersRequest}
      isLastItem={last}
      isLoading={loading}
      onShowMore={props.loadMore}
    />
  )
}

export default UserTableList
