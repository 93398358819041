import React from "react"
import { InlineFlex, styled } from "reakit"
import { theme } from "styled-tools"
import PageTitle from "common/PageTitle"

const PageToolbarStyled = styled(InlineFlex)`
  align-items: center;
  justify-content: space-between;
  flex-grow: 0;
  padding-bottom: ${theme("spacing.medium")};
  padding-top: ${theme("spacing.medium")};
  width: 100%;
`

export const PageToolbarContent = styled(InlineFlex)`
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
  padding-left: 1rem;
`

const PageToolbar = props => {
  return (
    <PageToolbarStyled>
      <PageTitle>{props.title}</PageTitle>
      {props.children}
    </PageToolbarStyled>
  )
}

export default PageToolbar
