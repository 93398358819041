import {call, put, select, takeLatest} from "redux-saga/effects"
import api from "../../services/api"
import API from "../../lib/api"
import {
  CREATE_GROUP_REQUEST,
  createGroupSuccess, deleteGroupSuccess,
  findGroupSuccessAction, GROUP_DELETE_REQUEST, GROUP_FIND_REQUEST,
  groupAlertsErrorAction,
  LOAD_ALERTS_REQUEST,
  LOAD_ALL_ALERTS_REQUEST,
  LOAD_ALL_GROUP_ALERTS,
  LOAD_ALL_TRIGGED_BUTTONS,
  loadAlertsError,
  loadAlertsSuccess,
  loadGroupError,
  loadGroupsSuccess,
  loadTriggedButtonsSuccess,
  TRIGGED_BUTTONS_ERROR,
  triggedButtonsErrorAction,
  updateGroupError,
} from "store/ducks/alerts"
import {concat} from "ramda"

import {LOAD_PERSON_ALERTS_REQUEST, loadAllAlertsError, loadAllAlertsSuccess, loadPersonAlertsError, loadPersonAlertsSuccess,} from "../ducks/alerts"
import {loadPersonsSuccess} from "../ducks/person"
import {monitoringErrorAction} from "../ducks/monitoring";
import {DateUtils} from "../../utils/DateUtils";
import {showErrorToast} from "../../utils/toast";
import {deletePermissionSuccess, errorPermission, PERMISSION_DELETE_REQUEST} from "../ducks/permission";
import {deletePermission} from "./permission";




export function* deleteGroup(action) {
  try {
    const {id} = action.payload
    const deleteGroupApiCall = () => {
      return api
      .delete(`${API.ALERTS}/notifications-groups/${id}`)
      .then(response => response)
      .catch(err => {
        throw err
      })
    }
    yield call(deleteGroupApiCall)
    yield put(deleteGroupSuccess())
  } catch (err) {
    yield put(loadGroupError(err))
  }
}

export function* findGroup(action) {
  try {
    const {id} = action.payload
    const findGroupApiCall = () => {
      return api
      .get(`${API.ALERTS}/notifications-groups/${id}`)
      .then(response => {
        return response.data
      })
      .catch(err => {
        throw err
      })
    }
    const response = yield call(findGroupApiCall)
    yield put(findGroupSuccessAction(response))
  } catch (err) {
    yield put(loadGroupError(err))
  }
}

export function* createGroup(action) {
  try {
    const { group } = action.payload
    const createGroupApiCall = () => {
      return api
      .post(`${API.ALERTS}/notifications-groups`, group)
      .then(response => response.data)
      .catch(err => {
        throw err
      })
    }
    const response = yield call(createGroupApiCall)
    yield put(createGroupSuccess(response))
  } catch (err) {
    if (err && err.data && err.data.message) {
      showErrorToast(err.data.message)
    }
    yield put(updateGroupError(err))
  }
}

export function* findGroupAlert(action) {
  try {
    const {size, page} = action.payload
    const apiCall = () => {
      return api
      .get(`${API.ALERTS}/notifications-groups
`, {
        params: {
          page,
          size: size,
          sort: "createdAt,desc",
        },
      })
      .then(response => {
        return response.data
      })
      .catch(err => {
        throw err
      })
    }
    const {alerts} = yield select(state => state)
    const {content, last, first, totalElements} = yield call(apiCall)
    if (!first) {
      yield put(loadGroupsSuccess(concat(alerts.data, content || []),
        totalElements, last))
    } else {
      yield put(loadGroupsSuccess(content, totalElements, last))
    }
  } catch (err) {
    yield put(groupAlertsErrorAction(err))
  }
}

export function* findTriggedButtons(action) {
  try {
    const {size, page} = action.payload
    const apiCall = () => {
      return api
      .get(`${API.ALERTS}/alerts/details/INPUT_TOGGLE/allDetails`, {
        params: {
          page,
          size: size,
          sort: "createdAt,desc",
        },
      })
      .then(response => {
        return response.data
      })
      .catch(err => {
        throw err
      })
    }
    const {alerts} = yield select(state => state)
    const {content, last, first, totalElements} = yield call(apiCall)
    if (!first) {
      yield put(loadTriggedButtonsSuccess(concat(alerts.data, content || []),
        totalElements, last))
    } else {
      yield put(loadTriggedButtonsSuccess(content, totalElements, last))
    }
  } catch (err) {
    yield put(triggedButtonsErrorAction(err))
  }
}

export function* findAllAlerts(action) {
  try {
    const {page, size} = action.payload
    const filter = JSON.parse(localStorage.getItem("filter"))
    const sort = JSON.parse(localStorage.getItem("sort"))
    // const emptyFilter = filter ? !isEmpty(filter.filter) : false
    const apiCall = () => {
      let params
      params = {
        page,
        size: size,
        sort: sort && sort.alerts ? sort.alerts.sortString : "eventDate,desc",
      }
      if (filter && filter.alerts) {
        filter.alerts.forEach(f => {
          params[f.field] = f.value
        })
      }

      return api
      .get(`${API.PERSON}/alerts`, {
        params,
      })
      .then(response => {
        return response.data
      })
      .catch(err => {
        throw err
      })
    }
    const {content, last, first} = yield call(apiCall)
    if (!first) {
      const alerts = yield select(state => state.alerts.allAlerts)
      yield put(loadAllAlertsSuccess(concat(alerts, content || []), last))
    } else {
      yield put(loadAllAlertsSuccess(content, false))
    }
  } catch (err) {
    yield put(loadAllAlertsError(err))
  }
}

export function* findAlertsPolicies(action) {
  try {
    const startDate = DateUtils.dateNowMinusDays(90)
    const endDate = new Date();

    const {status} = action.payload
    const findAlertsPoliciesCall = () => {
      return api
      .get(`${API.PERSON}/alerts/policies`, {
        params: {
          startDate,
          endDate,
          unpaged: true,
          status
        },
      })
      .then(response => {
        return response.data
      })
      .catch(err => {
        throw err
      })
    }
    const response = yield call(findAlertsPoliciesCall)
    if (response) {
      const {totalViolationByPolicies} = response
      yield put(loadAlertsSuccess(totalViolationByPolicies.content))
    } else {
      yield put(loadAlertsSuccess([]))
    }
  } catch (err) {
    yield put(loadAlertsError(err))
  }
}

export function* findPersonsAlerts(action) {
  try {
    const {page, size, force, personId} = action.payload
    const findPersonsAlertsDataApiCall = () => {
      let params
      params = {
        page,
        size: size,
        sort: "createdAt,desc",
      }
      return api
      .get(`${API.PERSON}/persons/${personId}/alerts`, {
        params,
      })
      .then(response => {
        return response.data
      })
      .catch(err => {
        throw err
      })
    }
    const {alerts, lastPageFind, totalPages: totalPagesPerson} = yield select(
      state => state.person
    )
    if (page === lastPageFind && !force) {
      yield put(loadPersonsSuccess(alerts, totalPagesPerson, page))
    }
    const {content, totalPages, first, last} = yield call(
      findPersonsAlertsDataApiCall
    )
    if (!first && !force) {
      yield put(
        loadPersonAlertsSuccess(concat(alerts, content || []), totalPages, last)
      )
    } else {
      yield put(loadPersonAlertsSuccess(content, totalPages, page, last))
    }
  } catch (err) {
    yield put(loadPersonAlertsError(err))
  }
}

export default [
  takeLatest(LOAD_ALERTS_REQUEST, findAlertsPolicies),
  takeLatest(LOAD_PERSON_ALERTS_REQUEST, findPersonsAlerts),
  takeLatest(GROUP_DELETE_REQUEST, deleteGroup),
  takeLatest(TRIGGED_BUTTONS_ERROR, monitoringErrorAction()),
  takeLatest(LOAD_ALL_ALERTS_REQUEST, findAllAlerts),
  takeLatest(LOAD_ALL_TRIGGED_BUTTONS, findTriggedButtons),
  takeLatest(LOAD_ALL_GROUP_ALERTS, findGroupAlert),
  takeLatest(CREATE_GROUP_REQUEST, createGroup),
  takeLatest(GROUP_FIND_REQUEST, findGroup),
]
