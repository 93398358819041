export const defaultDashboardOptions = {
  chart: {
    type: "area",
    height: 120,
  },
  title: {
    text: "",
  },
  legend: {
    layout: "vertical",
    align: "right",
    verticalAlign: "middle",
  },
  yAxis: {
    title: {
      text: "",
    },
  },
  tooltip: {
    shared: true,
  },
  credits: {
    enabled: false,
  },
}
