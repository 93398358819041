import React, { useEffect } from "react"
import { ContainerStyled } from "../index.styled"
import { ErrorMessage, useFormikContext } from "formik"
import InputText from "../../../../common/InputText"
import { ErrorContainer, ErrorLabel } from "../../../../common/Error"
import MultiSelectCheckboxV2 from "../../../../common/MultiSelectCheckboxV2"
import { useFindProfiles, useProfiles } from "../../../../store/hooks/user"
import { FormSwitch } from "../../../../common/FormSwitch"
import InputPassword from "../../../../common/InputPassword"
import SimpleSelectV2 from "../../../../common/SimpleSelectV2"

const StepUserData = ({ values }) => {
  const { handleChange } = useFormikContext()
  const findProfiles = useFindProfiles()
  const profiles = useProfiles()

  useEffect(() => {
    findProfiles()
  }, [])

  return (
    <ContainerStyled>
      <InputText
        value={values.name}
        name="name"
        id="name"
        label="NOME COMPLETO"
        onChange={handleChange}
      />
      <InputText
        value={values.email}
        name="email"
        id="email"
        label="E-MAIL"
        onChange={handleChange}
      />
      <MultiSelectCheckboxV2
        name="profiles"
        id="profiles"
        list={profiles}
        value={values.profiles || []}
        label="PERFIS"
        propertyToShow="name"
        valueProperty="id"
        uniqueProp
      />
      {!values.id && (
        <>
          <InputPassword
            value={values.password}
            name="password"
            id="password"
            label="SENHA"
            onChange={handleChange}
          />
          <InputPassword
            value={values.confirmPassword}
            name="confirmPassword"
            id="confirmPassword"
            label="CONFIRMAR SENHA"
            onChange={handleChange}
          />
        </>
      )}
      {/*<SimpleSelectV2*/}
      {/*  propertyToShow="label"*/}
      {/*  list={[*/}
      {/*    { id: 1, label: "Brasília (0)" },*/}
      {/*    { id: 2, label: "Fernando de Noronha (+1)" },*/}
      {/*    { id: 3, label: "Amazonas (-1)" },*/}
      {/*    { id: 4, label: "Acre (-2)" },*/}
      {/*  ]}*/}
      {/*  valueProperty="id"*/}
      {/*  name="timezone"*/}
      {/*  id="timezone"*/}
      {/*  label="FUSO HORÁRIO"*/}
      {/*  value={values.timezone}*/}
      {/*/>*/}
      <FormSwitch
        onLabel={"ATIVO"}
        offLabel={"INATIVO"}
        value={values.active}
        name="active"
        type="round"
        onChange={handleChange}
      />
      {/*<FieldSwitch*/}
      {/*  value={values.active}*/}
      {/*  label={values.active ? "ATIVO" : "INATIVO"}*/}
      {/*  name="active"*/}
      {/*  id="active"*/}
      {/*  onChange={handleChange}*/}
      {/*/>*/}
      <ErrorContainer>
        <ErrorMessage
          name="name"
          render={msg => <ErrorLabel>{msg}</ErrorLabel>}
        />
        <ErrorMessage
          name="email"
          render={msg => <ErrorLabel>{msg}</ErrorLabel>}
        />
        <ErrorMessage
          name="password"
          render={msg => <ErrorLabel>{msg}</ErrorLabel>}
        />
        <ErrorMessage
          name="confirmPassword"
          render={msg => <ErrorLabel>{msg}</ErrorLabel>}
        />
      </ErrorContainer>
    </ContainerStyled>
  )
}

export default StepUserData
