export const FIND_PROCEDURES_REQUEST = "FIND_PROCEDURES_REQUEST"
export const FIND_PROCEDURES_SUCCESS = "FIND_PROCEDURES_SUCCESS"
export const FIND_PROCEDURES_ERROR = "FIND_PROCEDURES_ERROR"
export const FIND_PROCEDURE_REQUEST = "FIND_PROCEDURE_REQUEST"
export const FIND_PROCEDURE_SUCCESS = "FIND_PROCEDURE_SUCCESS"
export const FIND_NODES_REQUEST = "FIND_NODES_REQUEST"
export const FIND_NODES_SUCCESS = "FIND_NODES_SUCCESS"
export const FIND_PROCEDURE_ERROR = "FIND_PROCEDURE_ERROR"
export const PROCEDURE_UPDATE_REQUEST = "PROCEDURE_UPDATE_REQUEST"
export const PROCEDURE_UPDATE_SUCCESS = "PROCEDURE_UPDATE_SUCCESS"
export const PROCEDURE_UPDATE_ERROR = "PROCEDURE_UPDATE_ERROR"
export const PROCEDURE_CREATE_REQUEST = "PROCEDURE_CREATE_REQUEST"
export const PROCEDURE_CREATE_SUCCESS = "PROCEDURE_CREATE_SUCCESS"
export const PROCEDURE_CREATE_ERROR = "PROCEDURE_CREATE_ERROR"
export const PROCEDURE_DELETE_REQUEST = "PROCEDURE_DELETE_REQUEST"
export const PROCEDURE_DELETE_SUCCESS = "PROCEDURE_DELETE_SUCCESS"
export const PROCEDURE_DELETE_ERROR = "PROCEDURE_DELETE_ERROR"
export const PROCEDURE_RESET = "PROCEDURE_RESET"

export const findProceduresRequestAction = page => ({
  type: FIND_PROCEDURES_REQUEST,
  payload: {
    page,
  },
})

export const findProceduresSuccessAction = (
  procedures,
  totalPages,
  last,
  filtering
) => ({
  type: FIND_PROCEDURES_SUCCESS,
  payload: {
    procedures,
    totalPages,
    last,
    filtering,
  },
})

export const findNodesRequestAction = () => ({
  type: FIND_NODES_REQUEST,
})

export const findNodesSuccessAction = nodes => ({
  type: FIND_NODES_SUCCESS,
  payload: {
    nodes,
  },
})

export const findProceduresErrorAction = error => ({
  type: FIND_PROCEDURES_ERROR,
  payload: {
    error,
  },
})

export const findProcedureRequestAction = id => ({
  type: FIND_PROCEDURE_REQUEST,
  payload: {
    id,
  },
})

export const findProcedureSuccessAction = procedure => ({
  type: FIND_PROCEDURE_SUCCESS,
  payload: {
    procedure,
  },
})

export const procedureCreateRequestAction = procedure => ({
  type: PROCEDURE_CREATE_REQUEST,
  payload: {
    procedure,
  },
})

export const procedureCreateSuccessAction = () => ({
  type: PROCEDURE_CREATE_SUCCESS,
})

export const procedureUpdateRequestAction = procedure => ({
  type: PROCEDURE_UPDATE_REQUEST,
  payload: {
    procedure,
  },
})

export const procedureUpdateSuccessAction = () => ({
  type: PROCEDURE_UPDATE_SUCCESS,
})

export const procedureDeleteRequestAction = id => ({
  type: PROCEDURE_DELETE_REQUEST,
  payload: {
    id,
  },
})

export const procedureDeleteSuccessAction = () => ({
  type: PROCEDURE_DELETE_SUCCESS,
})

export const procedureResetAction = () => ({
  type: PROCEDURE_RESET,
})

const INITIAL_STATE = {
  error: null,
  loading: false,
  nodes: [],
  procedures: [],
  procedure: null,
  totalPages: 0,
  filtering: false,
  updateSuccess: false,
  createSuccess: false,
  deleteSuccess: false,
  nodesSuccess: false,
  last: true,
}

const procedureReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FIND_PROCEDURES_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FIND_PROCEDURES_SUCCESS:
      const { procedures, totalPages, last, filtering } = action.payload
      return {
        ...state,
        procedures,
        totalPages,
        filtering,
        loading: false,
        last,
      }
    case FIND_PROCEDURES_ERROR:
      const { error } = action.payload
      return {
        ...state,
        error,
        loading: false,
      }
    case FIND_PROCEDURE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FIND_PROCEDURE_SUCCESS:
      const { procedure } = action.payload
      return {
        ...state,
        procedure,
        loading: false,
      }
    case PROCEDURE_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case PROCEDURE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        updateSuccess: true,
      }
    case PROCEDURE_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        createSuccess: false,
      }
    case PROCEDURE_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        createSuccess: true,
      }
    case PROCEDURE_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case PROCEDURE_DELETE_SUCCESS:
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    case PROCEDURE_RESET:
      return {
        ...state,
        loading: false,
        updateSuccess: false,
        createSuccess: false,
        deleteSuccess: false,
      }
    default:
      return state
  }
}

export default procedureReducer
