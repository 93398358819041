import { useFetch } from "@bjornagh/use-fetch"
import { getToken } from "lib/token"

const useCustomFetch = ({ method, endpoint, ...rest }) => {
  const authorization = getToken()
    ? {
        Authorization: `Bearer ${getToken()}`,
      }
    : {}

  const headers = {
    ...authorization,
    "Content-type": "application/json",
  }

  return useFetch({
    url: `${process.env.REACT_APP_API_URL}/${endpoint}`,
    method,
    init: {
      headers,
    },
    ...rest,
  })
}

export default useCustomFetch
