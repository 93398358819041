import React, { useEffect } from "react"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import {
  useFindAreaRequestHandler,
  useArea,
  useUpdateAreaHandlers,
} from "../../../store/hooks/area"
import AreaForm from "../AreaForm"
import { withRouter } from "react-router-dom"

const AreaEdit = props => {
  const updateAreaRequest = useUpdateAreaHandlers()
  const findArea = useFindAreaRequestHandler()
  const area = useArea()
  const { id } = props.match.params

  const onSubmit = values => {
    if (!values.scope) {
      values.scope = "GLOBAL"
    }
    updateAreaRequest(values)
  }

  useEffect(() => {
    findArea(id)
  }, [findArea, id])

  return (
    <LayoutSidebarOverlay
      title="Editar política"
      afterClose={() => {
        props.history.push("/areas")
      }}
    >
      {({ closeSidebar }) => {
        return (
          <AreaForm
            closeSidebar={closeSidebar}
            onSubmit={onSubmit}
            initialValues={area}
          />
        )
      }}
    </LayoutSidebarOverlay>
  )
}

export default withRouter(AreaEdit)
