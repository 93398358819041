import { isNil } from "ramda"

const STATUS_LABELS = {
  REQUESTED: "REQUISITADO",
  IN_PROCESS: "EM ANDAMENTO",
  FINALIZED: "FINALIZADO",
  BLOCKED: "BLOQUEADO",
  IRREGULAR: "IRREGULAR",
  NOT_FOUND: "Ausente",
  EXPIRED: "Expirada",
  RECUSED: "Recusada",
  APPROVED: "Aceita",
}
export const SELECT_OPTIONS =
  [
    {value: 'REQUESTED', label: 'REQUISITADO'},
    {value: 'IN_PROCESS', label: 'EM ANDAMENTO'},
    {value: 'FINALIZED', label: 'FINALIZADO'},
    {value: 'BLOCKED', label: 'BLOQUEADO'},
    {value: 'NOT_FOUND', label: 'Ausente'},
    {value: 'EXPIRED', label: 'Expirada'},
    {value: 'RECUSED', label: 'Recusada'},
    {value: 'APPROVED', label: 'Aceita'}
  ]


const STATUS_PALETTE = {
  BLOCKED: "error",
  IRREGULAR: "warning",
  NOT_FOUND: "warning",
  EXPIRED: "warning",
  RECUSED: "warning",
  APPROVED: "success",
  REQUESTED: "warning",
  IN_PROCESS: "success",
  FINALIZED: "success",
}

export const getStatusLabel = value => {
  if (isNil(value)) {
    return null
  }

  const status = value.toUpperCase()
  return STATUS_LABELS[status] ? STATUS_LABELS[status] : status
}

export const getStatusPalette = value => {
  if (isNil(value)) {
    return null
  }

  const status = value.toUpperCase()
  return STATUS_PALETTE[status] ? STATUS_PALETTE[status] : status
}

export const getCredentialTypeLabel = value => {
  if (isNil(value)) {
    return null
  }

  const status = value.toUpperCase()

  switch (status) {
    case "IRREGULAR":
      return "ESPECIAL"

    case "BLOCKED":
      return "OBRIGATÓRIA"

    default:
      return null
  }
}
