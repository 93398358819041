import React, { useEffect } from "react"
import { withRouter, useParams } from "react-router-dom"
import { useAuthenticated, useAuthFromTokenHandlers, useAuthLoading } from "store/hooks/authentication"
import LoadingIndicator from "common/LoadingIndicator"
import Text from "common/Text"


const RouteFromToken = ({ component: Component, ...rest }) => {
  const authLoading = useAuthLoading()
  const authenticated = useAuthenticated()
  const authenticate = useAuthFromTokenHandlers()
  const { token } = useParams()

  useEffect(() => {
    if (token && !authenticated && !authLoading) {
      authenticate(token)
    }
  }, [token, authLoading, authenticated, authenticate])

  if (authLoading) {
    return <LoadingIndicator loading={true} />
  }

  if (!authenticated) {
    return <Text palette="primary" variant="h4" align="center" bold>Usúario não autorizado.</Text>
  }

  return <Component {...rest} />
}

export default withRouter(RouteFromToken)
