import * as Yup from "yup"

const ValidationSchema = Yup.lazy(values => {
  return Yup.object().shape({
    name: Yup.string()
      .nullable()
      .required("O campo Nome é obrigatório."),
    email: Yup.string()
      .nullable()
      .email("O E-mail informado não é válido.")
      .required("O campo E-mail é obrigatório."),
    password: values.id
      ? null
      : Yup.string()
          .trim()
          .nullable()
          .matches(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/,
            " A senha deve conter mais de 6 caracteres com letras maiúsculas, minúsculas e números"
          )
          .required("O campo senha é obrigatório."),
    confirmPassword: values.id
      ? null
      : Yup.string()
          .trim()
          .required("O campo Confirmação de Senha é obrigatório.")
          .oneOf([Yup.ref("password"), null], "As senhas precisam ser iguais."),
  })
})

export default ValidationSchema
