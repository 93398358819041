import { css } from "reakit"
import { palette as p } from "styled-tools"
import defaultTheme from "reakit-theme-default"

const baseline = 4
const fontSizeBase = 10

function getValueInREM(multipleOfBaseline = 1) {
  return `${(baseline * multipleOfBaseline) / fontSizeBase}rem`
}

const spacing = {
  none: 0,
  extrasmall: getValueInREM(2), // 8px
  small: getValueInREM(3), // 12px
  medium: getValueInREM(4), // 16px
  big: getValueInREM(6), // 24px
  large: getValueInREM(8), // 32px
  extralarge: getValueInREM(12), // 48px
  full: "100%",
}

const palette = {
  ...defaultTheme.palette,

  pageBackground: "#fafafa",

  primary: ["#005369"],
  primaryText: [p("white")],

  secondary: ["#00cfc3", "#0eb7ae"],
  secondaryText: [p("white"), p("white")],

  error: ["#f14f3b", "#d0021b"],

  warning: ["#fbc328", "#f5a623"],

  grayscale: ["#757575", "#2b3034", "#cfd0d1"],
}
const typography = {
  size: {
    body: getValueInREM(4), // 16px
    body2: getValueInREM(3.5), // 14px
    body3: getValueInREM(3), // 12px
    h1: getValueInREM(10), // 40px
    h2: getValueInREM(8),
    h3: getValueInREM(7),
    h4: getValueInREM(6),
    h5: getValueInREM(5),
    h6: getValueInREM(4), // 24px
    display1: getValueInREM(4),
    display2: getValueInREM(5),
    display3: getValueInREM(6),
    display4: getValueInREM(7),
    display5: getValueInREM(8),
  },
  weight: {
    bold: 700,
    medium: 500,
    normal: 400,
  },
}

const Field = css``
const Input = css``
const Label = css``
const Box = css``
const Heading = css``
const Table = css``
const Paragraph = css``
const List = css`
  list-style: none;
  margin: 0;
  padding: 0;
`

// Export Byond Theme
export default {
  ...defaultTheme,
  baseline,
  fontSizeBase,
  palette,
  spacing,
  typography,

  // components
  Field,
  Input,
  Label,
  Box,
  Heading,
  Table,
  Paragraph,
  List,
}
