import React, { useCallback } from "react"
import { format } from "date-fns"
import Table, { ActionData, ActionHeader, ShowMoreItens } from "common/Table"
import LoadingIndicator from "common/LoadingIndicator"
import {usePolicy, usePolicyLast} from "store/hooks/policy"
import Filter, { GetActivatedFilter } from "../Filter"
import {
  useFindPoliciesRequestHandler,
  useUpdatePolicyStatusHandlers,
} from "../../store/hooks/policy"
import { GetActivatedSorted } from "../Sort"
import SortLabel from "../SortLabel"
import { Switch } from "../Switch"
import {StorageUtils} from "../../utils/StorageUtils";

const PolicyTableList = props => {
  const last = usePolicyLast()
  const policiesRequest = useFindPoliciesRequestHandler()
  const updatePolicy = useUpdatePolicyStatusHandlers()

  const handleUpdate = useCallback(
    (active, item) => {
      if (item) {
        updatePolicy({ id: item.id, active: active })
      }
    },
    [updatePolicy]
  )

  const selectFilterFields = () => {
    return props && props.items && props.items.map((x) => ({value: x.name, label: x.name}))
  }

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th
              style={{
                backgroundColor: GetActivatedSorted("name", "policy")
                  ? "rgb(233,233,233)"
                  : "white",
              }}
            >
              <SortLabel
                props={props}
                request={policiesRequest}
                name="name"
                label="Nome"
                screen="policy"
              />
              <Filter
                applied={GetActivatedFilter("name", "policy")}
                screen="policy"
                name="name"
                selectOptions={selectFilterFields()}
                setCurrentPage={props.setCurrentPage}
                defaultValue={StorageUtils.getDefaultStorageValue("policy", "name")}
                filterFn={policiesRequest}
                filterType="selectFilter"
              />
            </th>
            <th>Tecnologia</th>
            <th
              style={{
                backgroundColor: GetActivatedSorted("type", "policy")
                  ? "rgb(233,233,233)"
                  : "white",
              }}
            >
              <SortLabel
                props={props}
                request={policiesRequest}
                name="type"
                label="Tipo"
                screen="policy"
              />
            </th>
            <th
              style={{
                backgroundColor: GetActivatedSorted("createdAt", "policy")
                  ? "rgb(233,233,233)"
                  : "white",
              }}
            >
              <SortLabel
                props={props}
                request={policiesRequest}
                name="createdAt"
                label="Data Criação"
                screen="policy"
              />
              <Filter
                applied={GetActivatedFilter("createdAt", "policy")}
                screen="policy"
                name="createdAt"
                setCurrentPage={props.setCurrentPage}
                filterFn={policiesRequest}
              />
            </th>
            <th
              style={{
                backgroundColor: GetActivatedSorted("active", "policy")
                  ? "rgb(233,233,233)"
                  : "white",
              }}
            >
              <SortLabel
                props={props}
                request={policiesRequest}
                name="active"
                label="Status"
                screen="policy"
              />
            </th>
            <ActionHeader>Ações</ActionHeader>
          </tr>
        </thead>
        <tbody>
          {props.items.map((item, key) => {
            return (
              <tr key={key} data-testid="table-row">
                <td>{item.name}</td>
                <td>{item.scope}</td>
                <td>{item.type}</td>
                <td>{format(item.createdAt, "DD/MM/YYYY HH:mm")}</td>
                <td>
                  <Switch
                    value={item.active}
                    size="sm"
                    onChange={newValue => handleUpdate(newValue, item)}
                  />
                </td>
                <ActionData>{props.renderActions(item)}</ActionData>
              </tr>
            )
          })}
        </tbody>
      </Table>
      <>
        {props.fetching && <LoadingIndicator loading={true} />}
        {!props.fetching && !last && (
          <ShowMoreItens handleClick={props.loadMore} />
        )}
      </>
    </>
  )
}

export default PolicyTableList
