import React from "react"
import { Flex, styled } from "reakit"
import { ImpulseSpinner } from "react-spinners-kit"

const SpinnerContainer = styled(Flex)`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 50px;
  width: 100%;
`

const LoadingIndicator = ({ loading, children }) => {
  if (loading) {
    return (
      <SpinnerContainer width="100%">
        <ImpulseSpinner backColor="#005369" frontColor="#00cfc3" />
      </SpinnerContainer>
    )
  }

  return children
}

export default LoadingIndicator
