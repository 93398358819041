const headerSize = 50

const spacingMinimun = "4px"
const spacingSM = "8px"
const spacingMD = "12px"
const spacingXMD = "20px"
const spacingLG = "28px"
const spacingXLG = "36px"

const defaultRadius = "6px"

const fontSize = {
  xxxxsm: "9px",
  xxxsm: "12px",
  xxsm: "14px",
  xsm: "16px",
  sm: "19px",
  md: "22px",
  lg: "24px",
  xlg: "26px",
  xxlg: "28px",
}

export default {
  fontSize,
  spacingMinimun,
  spacingSM,
  spacingMD,
  spacingXMD,
  spacingLG,
  spacingXLG,
  headerSize,
  defaultRadius,
}
