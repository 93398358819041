import Cookies from "js-cookie"

const COOKIE_NAME = "Authorization"

export const getToken = () => {
  return Cookies.get(COOKIE_NAME)
}

export const setToken = (token = "") => {
  Cookies.set(COOKIE_NAME, token)
}

export const clearToken = callback => {
  Cookies.remove(COOKIE_NAME)

  if (callback) {
    callback()
  }
}
