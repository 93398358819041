import * as Yup from "yup"

const schema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{7,})/, "Invalid")
    .required("O campo senha é obrigatório."),
  confirmPassword: Yup.string()
    .trim()
    .required("O campo Confirmação de Senha é obrigatório.")
    .oneOf([Yup.ref("password"), null], "As senhas precisam ser iguais."),
})

export default schema
