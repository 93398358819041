import { useDispatch, useSelector } from "react-redux"
import { useCallback } from "react"
import {
  findPermissionRequestAction,
  findPermissionsRequestAction,
  deletePermissionRequestAction,
  createPermissionRequestAction,
  updatePermissionRequestAction,
  resetPermission,
} from "store/ducks/permission"

export const usePermissionsFind = () => {
  const dispatch = useDispatch()
  return useCallback(
    (page, size) => {
      dispatch(findPermissionsRequestAction(page, size))
    },
    [dispatch]
  )
}

export const usePermissionFind = () => {
  const dispatch = useDispatch()
  return useCallback(
    id => {
      dispatch(findPermissionRequestAction(id))
    },
    [dispatch]
  )
}

export const usePermissionDelete = () => {
  const dispatch = useDispatch()
  return useCallback(
    id => {
      dispatch(deletePermissionRequestAction(id))
    },
    [dispatch]
  )
}

export const usePermissionCreate = () => {
  const dispatch = useDispatch()
  return useCallback(
    (permission, isRequired) => {
      dispatch(createPermissionRequestAction(permission, isRequired))
    },
    [dispatch]
  )
}

export const usePermissionUpdate = () => {
  const dispatch = useDispatch()
  return useCallback(
    permission => {
      dispatch(updatePermissionRequestAction(permission))
    },
    [dispatch]
  )
}

export const usePermissionReset = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(resetPermission())
  }, [dispatch])
}

export const usePermissions = () => {
  return useSelector(state => state.permission.permissions)
}

export const usePermission = () => {
  return useSelector(state => state.permission.permission)
}

export const usePermissionLoading = () => {
  return useSelector(state => state.permission.loading)
}

export const useDeletePermissionSuccess = () => {
  return useSelector(state => state.permission.deleteSuccess)
}

export const useUpdatePermissionSuccess = () => {
  return useSelector(state => state.permission.updateSuccess)
}

export const useCreatePermissionSuccess = () => {
  return useSelector(state => state.permission.createSuccess)
}

export const usePermissionsLast = () => {
  return useSelector(state => state.permission.last)
}
