import React, { useCallback, useEffect } from "react"
import { styled } from "reakit"
import { useFormikContext } from "formik"
import { TextField } from "@material-ui/core"
import CustomTooltip from "../CustomTooltip"
import { theme } from "styled-tools"

export const InputStyled = styled(TextField)`
  .MuiInputBase-input {
    font-size: 1.6rem;
  }
  .MuiFormLabel-root {
    font-size: 1.6rem !important;
  }
  .MuiInput-underline:after {
    border-bottom: 2px solid #005369;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #005369;
    font-size: 1.2rem;
  }
`

const Container = styled.div`
  margin-bottom: ${theme("spacing.big")};
  position: relative;
  .MuiInput-underline:after {
    border-bottom: 2px solid #005369 !important;
  }
  div {
    width: 100%;
  }
`

const InputText = ({
  name,
  label,
  tooltip,
  inputType,
  value,
  disabled,
}) => {
  const { setFieldValue } = useFormikContext()

  const handleChange = useCallback(
    event => {
      const value = event.target.value
      setFieldValue(name, value)
    },
    [setFieldValue]
  )

  return (
    <Container>
      {tooltip ? <CustomTooltip tooltip={tooltip} /> : ""}
      <InputStyled
        value={value || ""}
        type={inputType}
        name={name}
        id={name}
        label={label}
        onChange={handleChange}
        disabled={disabled}
      />
    </Container>
  )
}

export default InputText
