import React, {useEffect, useState} from "react"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import GroupForm from "../../EmergencyAlertList/groupForm";
import {useCreateGroup, useGroup, useGroupReset} from "../../../store/hooks/alerts";
import {useFindGroupRequestHandler} from "../../../store/hooks/alerts";

const GroupEdit = props => {
  const { id } = props.match.params

  const createGroupRequest = useCreateGroup()
  const resetGroup = useGroupReset()
  const groupFind = useFindGroupRequestHandler()
  const groupData = useGroup()

  const [group, setGroup] = useState({
    friendlyName: "",
    emails: [{ email: "", active: true }],
  });
  useEffect(() => {
    if (id) {
      groupFind(id)
    }
  }, [groupFind, id])

  useEffect(() => {
    if (groupData) {
      setGroup(groupData)
    }
  }, [groupData])

  const handleSubmit = values => {
    createGroupRequest(values)
  }

  useEffect(() => {
    return () => {
      resetGroup()
    }
  }, [])
  return (
    <LayoutSidebarOverlay
      title="Editar grupo"
      afterClose={() => {
        props.history.push("/emergency-alerts/alert-group")
      }}
    >
      {({ closeSidebar }) => {
        return (
          <GroupForm
            closeSidebar={closeSidebar}
            onSubmit={handleSubmit}
            initialValues={group}
          />
        )
      }}
    </LayoutSidebarOverlay>
  )
}
export default GroupEdit
