import React from "react"
import { styled } from "reakit"
import { ifProp, palette, theme } from "styled-tools"
import Button from "common/Button"

const ButtonStyled = styled(Button)`
  background-color: ${palette("white")};
  border: 1px solid ${ifProp("active", palette("secondary"), palette("white"))};
  border-radius: ${theme("spacing.none")};
  color: ${ifProp("active", palette("secondary"), palette("primary"))};
  font-size: ${theme("typography.size.body3")};
  min-width: 5rem;
  padding-bottom: ${theme("baseline")}px;
  padding-left: ${theme("baseline")}px;
  padding-right: ${theme("baseline")}px;
  padding-top: ${theme("baseline")}px;

  &:hover,
  &.active {
    background-color: ${palette("white")};
    border-color: ${palette("secondary")};
    color: ${palette("secondary")};
    box-shadow: none;
  }
`

const Chip = ({ children, ...props }) => {
  return <ButtonStyled {...props}>{children}</ButtonStyled>
}

export default Chip
