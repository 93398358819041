import React from "react"
import { Heading, styled } from "reakit"
import { palette, theme } from "styled-tools"

const HeadingStyled = styled(Heading)`
  color: ${palette("primary")};
  font-size: 2.4rem;
  font-weight: ${theme("typography.weight.bold")};
  margin: 0;
`

const PageTitle = ({ children, as, ...props }) => {
  return (
    <HeadingStyled as="h1" {...props}>
      {children}
    </HeadingStyled>
  )
}

export default PageTitle
