import React from "react"
import { isEmpty } from "ramda"
import { Flex, InlineFlex, styled } from "reakit"
import Text from "common/Text"
import EmptyStateMessage from "common/EmptyStateMessage"
import Divider from "common/Divider"
import { FaRegCheckCircle } from "react-icons/fa"

const BlockWrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
`

const BlockItem = styled(InlineFlex)`
  width: 100%;

  & > * {
    width: 100%;
  }
`

const InfoBlockBeacon = props => {
  return (
    <>
      <Text variant="body" palette="primary" margin="small" uppercase bold>
        {props.title}
      </Text>
      <BlockWrapper>
        {!isEmpty(props.items) ? (
          props.items.map((item, key) => {
            return (
              <div key={key} style={{ position: "relative" }}>
                {item.active && (
                  <FaRegCheckCircle size={40} style={styles.activeIcon} />
                )}
                <BlockItem>
                  <Text
                    variant="body2"
                    palette="grayscale"
                    margin="extrasmall"
                    uppercase
                  >
                    MAC ADDRESS
                  </Text>
                  <Text
                    variant="body2"
                    palette="grayscale"
                    align="right"
                    margin="extrasmall"
                    uppercase
                  >
                    {item.macAddress}
                  </Text>
                </BlockItem>
                <BlockItem>
                  <Text
                    variant="body2"
                    palette="grayscale"
                    margin="extrasmall"
                    uppercase
                  >
                    DATA ENTREGA
                  </Text>
                  <Text
                    variant="body2"
                    palette="grayscale"
                    align="right"
                    margin="extrasmall"
                    uppercase
                  >
                    {item.dateDelivery}
                  </Text>
                </BlockItem>
                <BlockItem>
                  <Text
                    variant="body2"
                    palette="grayscale"
                    margin="extrasmall"
                    uppercase
                  >
                    DATA DEVOLUÇÃO
                  </Text>
                  <Text
                    variant="body2"
                    palette="grayscale"
                    align="right"
                    margin="extrasmall"
                    uppercase
                  >
                    {item.dateReturn}
                  </Text>
                </BlockItem>

                <div style={styles.divider} />
              </div>
            )
          })
        ) : (
          <EmptyStateMessage>{props.emptyState}</EmptyStateMessage>
        )}
      </BlockWrapper>
      {!props.withoutDivider && <Divider palette="primary" />}
    </>
  )
}

const styles = {
  divider: {
    width: "100%",
    borderBottom: "1px solid black",
    marginBottom: "10px",
    opacity: "30%",
  },
  activeIcon: {
    position: "absolute",
    top: "10%",
    left: "43%",
    color: "green",
    opacity: "20%",
  },
}

export default InfoBlockBeacon
