import { Box, Flex, styled } from "reakit"
import { theme } from "styled-tools"

export const SideNavigationStyled = styled(Flex)`
  align-items: center;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 95px;
`
export const SideNavigationInner = styled(Flex)`
  align-items: center;
  background-color: white;
  box-shadow: 0 0 5px 0 #e5e9f2;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  list-style: none;
  width: ${props => props.baseline * 20}px;
`

export const SideLogo = styled(Box)`
  bottom: ${theme("spacing.large")};
  left: 50%;
  position: absolute;
  width: ${props => props.baseline * 10}px;
  transform: translateX(-50%);

  img {
    display: block;
    margin: 0 auto;
    width: 100%;
  }
`
