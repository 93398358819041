import axios from "axios"
import { getToken } from "lib/token"
import persistedStore from "store"
import { authLogoutSuccessAction } from "store/ducks/authentication"

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/`,
})

const requestHandler = request => {
  const token = persistedStore.store.getState().auth.token || getToken()
  request.headers["Access-Control-Allow-Origin"] = "*"
  if (token) {
    request.headers["Authorization"] = `Bearer ${token}`
  }
  return request
}

api.interceptors.request.use(request => requestHandler(request))

const errorResponseHandler = error => {
  // if has response show the error
  if (error.response) {
    if (error.response.status === 401) {
      persistedStore.store.dispatch(authLogoutSuccessAction())
    }
  }
  throw JSON.parse(JSON.stringify(error.response))
}

export const handleParamsApi = filters => {
  if (!filters) {
    return
  }
  const params = filters.reduce((previous, next) => {
    previous[next.field] = next.value
    return previous
  }, {})

  return params
}

api.interceptors.response.use(response => response, errorResponseHandler)

export default api
