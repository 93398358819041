import React from "react"
import { styled } from "reakit"
import Icon from "common/Icon"

const ButtonStyled = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: inline-flex;
  padding: 0;
`

const IconButton = ({ children, onClick, palette, size, label, ...props }) => {
  return (
    <ButtonStyled onClick={onClick} {...props}>
      <Icon palette={palette} size={size}>
        {children}
      </Icon>
      {label}
    </ButtonStyled>
  )
}

export default IconButton
