import { call, select, takeLatest, put } from "redux-saga/effects"
import api from "../../services/api"
import API from "../../lib/api"
import {
  FIND_PROCEDURES_REQUEST,
  FIND_PROCEDURE_REQUEST,
  findProceduresSuccessAction,
  findProcedureSuccessAction,
  PROCEDURE_CREATE_REQUEST,
  PROCEDURE_DELETE_REQUEST,
  PROCEDURE_UPDATE_REQUEST,
  procedureDeleteSuccessAction,
  procedureUpdateSuccessAction,
  procedureCreateSuccessAction,
} from "../ducks/procedure"
import { concat, isEmpty } from "ramda"

export function* findProcedures(action) {
  try {
    const page = action.payload.page
    const filter = JSON.parse(localStorage.getItem("filter"))
    const sort = JSON.parse(localStorage.getItem("sort"))
    const emptyFilter = filter ? !isEmpty(filter.filter) : false
    const findProceduresApiCall = () => {
      let params
      if (page === null) {
        params = {
          paged: false,
          size: 1000,
        }
      } else {
        params = {
          page,
          size: 10,
          sort: sort && sort.procedure ? sort.procedure.sortString : "createdAt,desc",
        }
        if (filter && filter.procedure) {
          filter.procedure.forEach(f => {
            if (f.field === 'updatedAt') {
              params.updatedSince = f.value.startDate;
              params.updatedUntil = f.value.endDate;
            } else {
              params[f.field] = f.value;
            }
          })
        }
      }
      return api
      .get(`${API.PERSON}/permissions`, {
        params,
      })
      .then(response => {
        return response.data
      })
      .catch(err => {
        throw err
      })
    }
    const { content, totalPage, first, last } = yield call(
      findProceduresApiCall
    )
    if (!first) {
      const { procedures } = yield select(state => state.procedure)
      yield put(
        findProceduresSuccessAction(
          concat(procedures, content || []),
          totalPage,
          last,
          emptyFilter
        )
      )
    } else {
      yield put(
        findProceduresSuccessAction(
          content,
          totalPage,
          last,
          emptyFilter
        )
      )
    }
  } catch (err) {
    console.log(err)
    // yield put(authFailedAction(err));
  }
}

export function* findProcedure(action) {
  try {
    const { id } = action.payload
    const findProcedureApiCall = () => {
      return api
      .get(`${API.PERSON}/permissions/${id}`)
      .then(response => {
        return response.data
      })
      .catch(err => {
        throw err
      })
    }
    const response = yield call(findProcedureApiCall)
    response.credentials = response.credentials.map(c => c.id)
    yield put(findProcedureSuccessAction(response))
  } catch (err) {
    console.log(err)
    // yield put(authFailedAction(err));
  }
}

export function* deleteProcedure(action) {
  try {
    const { id } = action.payload
    const deleteProcedureApiCall = () => {
      return api
      .delete(`${API.PERSON}/permissions/${id}`)
      .then(response => {
        return
      })
      .catch(err => {
        throw err
      })
    }
    yield call(deleteProcedureApiCall)
    yield put(procedureDeleteSuccessAction())
  } catch (err) {
    console.log(err)
  }
}

export function* createProcedure(action) {
  try {
    const { procedure } = action.payload
    const createProcedureApiCall = () => {
      const credentials = []
      procedure.credentials.forEach(c => credentials.push({ id: c }))
      const procedureRequest = {
        ...procedure,
        credentials,
      }
      return api
      .post(`${API.PERSON}/permissions`, procedureRequest)
      .then(response => {
        return response.data
      })
      .catch(err => {
        throw err
      })
    }
    yield call(createProcedureApiCall)
    yield put(procedureCreateSuccessAction())
    // yield put(redirectAction('/sistema/procedures'));
  } catch (err) {
    console.log(err)
  }
}

export function* updateProcedure(action) {
  try {
    const { procedure } = action.payload
    const credentials = []
    procedure.credentials.forEach(c => credentials.push({ id: c }))
    procedure.credentials = credentials
    const updateProcedureApiCall = () => {
      return api
      .put(`${API.PERSON}/permissions/${procedure.id}`, procedure)
      .then(response => {
        return response.data
      })
      .catch(err => {
        throw err
      })
    }
    const updateProcedureCredentialsApiCall = () => {
      return api
      .put(
        `${API.PERSON}/permissions/${procedure.id}/credentials`,
        credentials
      )
      .then(response => {
        return response.data
      })
      .catch(err => {
        throw err
      })
    }
    yield call(updateProcedureApiCall)
    yield call(updateProcedureCredentialsApiCall)
    yield put(procedureUpdateSuccessAction())
  } catch (err) {
    console.log(err)
  }
}

export default [
  takeLatest(FIND_PROCEDURES_REQUEST, findProcedures),
  takeLatest(FIND_PROCEDURE_REQUEST, findProcedure),
  takeLatest(PROCEDURE_UPDATE_REQUEST, updateProcedure),
  takeLatest(PROCEDURE_DELETE_REQUEST, deleteProcedure),
  takeLatest(PROCEDURE_CREATE_REQUEST, createProcedure),
]
