import React, { useEffect, useState } from "react"
import { concat, isEmpty, pathOr } from "ramda"
import Table from "common/Table"
import InfiniteScroll from "react-infinite-scroller"
import LoadingIndicator from "common/LoadingIndicator"
import EmptyStateMessage from "common/EmptyStateMessage"
import useCustomFetch from "lib/useCustomFetch"
import API from "lib/api"
import { format } from "date-fns"
import { InfiniteScrollContainer } from "common/InfiniteScroll"

const ViolationsTableList = () => {
  const [currentPage, setCurrentPage] = useState(0)
  const [violations, setViolations] = useState([])
  const [pagesError] = useState(new Set())
  const [highPageSuccess, setHighPageSuccess] = useState(-1)
  const pagesErrorEmpty = () => {
    return pagesError.size === 0
  }

  const lastViolationsRequests = useCustomFetch({
    endpoint: `${API.BLEZONE}/violations?sort=createdAt,desc&size=20&page=${currentPage}`,
    lazy: true,
    onSuccess: () => {
      if (highPageSuccess !== -1) {
        setCurrentPage(highPageSuccess)
        setHighPageSuccess(-1)
      }
    },
    onError: () => {
      pagesError.add(currentPage)
    },
  })

  const { data, fetching, doFetch } = lastViolationsRequests

  const loadMore = () => {
    setTimeout(() => {
      if (!pagesErrorEmpty()) {
        const errorValue = pagesError.values().next().value
        if (errorValue < currentPage) {
          setHighPageSuccess(currentPage)
        }
        setCurrentPage(errorValue)
        pagesError.delete(errorValue)
      } else {
        setCurrentPage(currentPage + 1)
      }
      doFetch()
    }, 1000)
  }

  useEffect(() => {
    doFetch()
  }, [])

  useEffect(() => {
    setViolations(concat(violations, pathOr([], ["content"], data)))
  }, [data])

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>POLICY TYPE</th>
            <th>NAME</th>
            <th>BEACON</th>
            <th>BLUFI</th>
            <th>CREATED</th>
          </tr>
        </thead>
      </Table>
      {!isEmpty(violations) ? (
        <>
          <InfiniteScrollContainer>
            <InfiniteScroll
              pageStart={0}
              loadMore={loadMore}
              hasMore={data && data.totalPages > currentPage}
              useWindow={false}
            >
              <Table>
                <tbody>
                  {violations.map((item, key) => {
                    return (
                      <tr key={key} data-testid="table-row">
                        <td>{item.policy ? item.policy.type : ""}</td>
                        <td>{item.policy ? item.policy.name : ""}</td>
                        <td>
                          {item.root && item.root.beacon
                            ? item.root.beacon.id
                            : ""}
                        </td>
                        <td>{item.root ? item.root.nodeMac : ""}</td>
                        <td>{format(item.createdAt, "DD/MM/YYYY HH:mm")}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
              {fetching && <LoadingIndicator loading={true} />}
            </InfiniteScroll>
          </InfiniteScrollContainer>
        </>
      ) : (
        <EmptyStateMessage textAlign="center">
          Nenhum alerta registrado.
        </EmptyStateMessage>
      )}
    </>
  )
}

export default ViolationsTableList
