import React from "react"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import withStyles from "@material-ui/core/styles/withStyles"

export const StyledTabs = withStyles({
  root: {
    marginBottom: "30px",
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    "& > div": {
      width: "100%",
      backgroundColor: "#005369",
    },
  },
})(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />)

export const StyledTab = withStyles(theme => ({
  root: {
    textTransform: "none",
    fontSize: 12,
    minWidth: 50,
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1,
    },
  },
  selected: {
    color: "#005369 !important",
    fontWeight: "bold",
    fontSize: 16,
  },
}))(props => <Tab disableRipple {...props} />)
