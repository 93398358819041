import { Label, styled } from "reakit"
import { palette, theme } from "styled-tools"

const LabelStyled = styled(Label)`
  font-size: ${theme("typography.size.body")};
  font-weight: ${theme("typography.weight.medium")};
  color: ${palette("primary")};
  display: block;
  margin-top: ${theme("spacing.none")};
  margin-right: ${theme("spacing.none")};
  margin-bottom: ${theme("spacing.extrasmall")};
  margin-left: ${theme("spacing.none")};
  padding: 0;
`

export default LabelStyled
