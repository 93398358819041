import React from "react"
import ReactDOM from "react-dom"
import { Route, Switch } from "react-router-dom"
import { FetchProvider } from "@bjornagh/use-fetch"
import ThemeProvider from "lib/ThemeProvider"
import RouterProvider from "lib/RouterProvider"
import ApplicationRoutes from "./routes"
import "primereact/resources/themes/nova-light/theme.css"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"
import "leaflet/dist/leaflet.css"
import "leaflet/dist/leaflet.js"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import PreLoader from "common/PreLoader"
const cache = new Map()

const Root = () => {
  toast.configure()
  return (
    <PreLoader>
      <ThemeProvider>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          closeOnClick
          rtl={false}
          draggable
          pauseOnHover
        />
        <FetchProvider cache={cache}>
          <RouterProvider>
            <Switch>
              <Route path="/" component={ApplicationRoutes} />
            </Switch>
          </RouterProvider>
        </FetchProvider>
      </ThemeProvider>
    </PreLoader>
  )
}

ReactDOM.render(<Root />, document.getElementById("root"))
