import React, {useEffect, useState} from "react"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import GroupForm from "../EmergencyAlertList/groupForm";
import {useCreateGroup, useGroupReset} from "../../store/hooks/alerts";

const FormAlertGroup = props => {
  const createGroupRequest = useCreateGroup()
  const resetGroup = useGroupReset()

  const [group] = useState({
    friendlyName: "",
    emails: [{ email: "", active: true }],
  });


  const handleSubmit = values => {
    createGroupRequest(values)
  }

  useEffect(() => {
    return () => {
      resetGroup()
    }
  }, [])
  return (
    <LayoutSidebarOverlay
      title="Novo grupo"
      afterClose={() => {
        props.history.push("/emergency-alerts/alert-group")
      }}
    >
      {({ closeSidebar }) => {
        return (
          <GroupForm
            closeSidebar={closeSidebar}
            onSubmit={handleSubmit}
            initialValues={group}
          />
        )
      }}
    </LayoutSidebarOverlay>
  )
}
export default FormAlertGroup
