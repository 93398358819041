export const LIST_AREA_REQUEST = "LIST_AREA_REQUEST"
export const LIST_AREA_SUCCESS = "LIST_AREA_SUCCESS"
export const LIST_AREA_ERROR = "LIST_AREA_ERROR"
export const FIND_AREA_REQUEST = "FIND_AREA_REQUEST"
export const FIND_AREA_SUCCESS = "FIND_AREA_SUCCESS"
export const FIND_NODES_REQUEST = "FIND_NODES_REQUEST"
export const FIND_NODES_SUCCESS = "FIND_NODES_SUCCESS"
export const FIND_AREA_ERROR = "FIND_AREA_ERROR"
export const AREA_UPDATE_REQUEST = "AREA_UPDATE_REQUEST"
export const AREA_UPDATE_SUCCESS = "AREA_UPDATE_SUCCESS"
export const AREA_UPDATE_ERROR = "AREA_UPDATE_ERROR"
export const AREA_CREATE_REQUEST = "AREA_CREATE_REQUEST"
export const AREA_CREATE_SUCCESS = "AREA_CREATE_SUCCESS"
export const AREA_CREATE_ERROR = "AREA_CREATE_ERROR"
export const AREA_DELETE_REQUEST = "AREA_DELETE_REQUEST"
export const AREA_DELETE_SUCCESS = "AREA_DELETE_SUCCESS"
export const AREA_DELETE_ERROR = "AREA_DELETE_ERROR"
export const AREA_RESET = "AREA_RESET"

export const listAreaRequestAction = page => ({
  type: LIST_AREA_REQUEST,
  payload: {
    page,
  },
})

export const listAreaSuccessAction = (policies, totalPages) => ({
  type: LIST_AREA_SUCCESS,
  payload: {
    policies,
    totalPages,
  },
})

export const findNodesRequestAction = () => ({
  type: FIND_NODES_REQUEST,
})

export const findNodesSuccessAction = nodes => ({
  type: FIND_NODES_SUCCESS,
  payload: {
    nodes,
  },
})

export const listAreaErrorAction = error => ({
  type: LIST_AREA_ERROR,
  payload: {
    error,
  },
})

export const findAreaRequestAction = id => ({
  type: FIND_AREA_REQUEST,
  payload: {
    id,
  },
})

export const findAreaSuccessAction = area => ({
  type: FIND_AREA_SUCCESS,
  payload: {
    area,
  },
})

export const areaCreateRequestAction = area => ({
  type: AREA_CREATE_REQUEST,
  payload: {
    area,
  },
})

export const areaUpdateRequestAction = area => ({
  type: AREA_UPDATE_REQUEST,
  payload: {
    area,
  },
})

export const areaUpdateSuccessAction = () => ({
  type: AREA_UPDATE_SUCCESS,
})

export const areaDeleteRequestAction = id => ({
  type: AREA_DELETE_REQUEST,
  payload: {
    id,
  },
})

export const areaDeleteSuccessAction = () => ({
  type: AREA_DELETE_SUCCESS,
})

export const areaResetAction = () => ({
  type: AREA_RESET,
})

const INITIAL_STATE = {
  error: null,
  loading: false,
  nodes: [],
  policies: [],
  area: null,
  totalPages: 0,
  updateSuccess: false,
  createSuccess: false,
  deleteSuccess: false,
  nodesSuccess: false,
}

const areaReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LIST_AREA_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case LIST_AREA_SUCCESS:
      const { policies, totalPages } = action.payload
      return {
        ...state,
        policies,
        totalPages,
        loading: false,
      }
    case LIST_AREA_ERROR:
      const { error } = action.payload
      return {
        ...state,
        error,
        loading: false,
      }
    case FIND_AREA_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FIND_AREA_SUCCESS:
      const { area } = action.payload
      return {
        ...state,
        area,
        loading: false,
      }
    case AREA_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        updateSuccess: false,
      }
    case AREA_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        updateSuccess: true,
      }
    case AREA_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        createSuccess: false,
      }
    case AREA_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        createSuccess: true,
      }
    case AREA_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case AREA_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case AREA_RESET:
      return {
        ...state,
        loading: false,
        updateSuccess: false,
        createSuccess: false,
      }
    default:
      return state
  }
}

export default areaReducer
