import React, { Component } from "react"
import SidebarLayer from "common/SidebarLayer"
import SidebarLayerHeader from "common/SidebarLayerHeader"
import { SidebarOverlayContent, SidebarOverlayFooter } from "./index.styled"

class LayoutSidebarOverlay extends Component {
  state = {
    visible: false,
  }

  DELAY_TO_ANIMATE_ON_MOUNT = 100
  DELAY_TO_ANIMATE_ON_UNMOUNT = 300

  timerMount = null
  timerUnmount = null

  componentDidMount() {
    this.timerMount = setTimeout(() => {
      this.setState({ visible: true })
    }, this.DELAY_TO_ANIMATE_ON_MOUNT)
  }

  componentWillUnmount() {
    clearTimeout(this.timerMount)
    clearTimeout(this.timerUnmount)
  }

  onCloseSidebar = event => {
    if (event) {
      event.preventDefault()
    }
    this.setState({ visible: false })

    this.timerUnmount = setTimeout(() => {
      this.props.afterClose()
    }, this.DELAY_TO_ANIMATE_ON_UNMOUNT)
  }

  render() {
    return (
      <SidebarLayer
        sidebarPosition="right"
        visible={this.state.visible}
        maxWidthParam={this.props.maxWidthParam}
        handleBackdropClick={this.onCloseSidebar}
      >
        <SidebarLayerHeader
          title={this.props.title}
          subtitle={this.props.subtitle}
          maxWidthTitle={this.props.maxWidthTitle}
          onClose={this.onCloseSidebar}
        />
        <SidebarOverlayContent>
          {this.props.children({
            closeSidebar: this.onCloseSidebar,
            isVisible: this.state.visible,
          })}
        </SidebarOverlayContent>
      </SidebarLayer>
    )
  }
}

export default Object.assign(LayoutSidebarOverlay, {
  Footer: SidebarOverlayFooter,
})
