import React, { useEffect, useState } from "react"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import {
  useCreateProcedureHandlers,
  useProcedureReset,
} from "../../../store/hooks/procedure"
import ProcedureForm from "../ProcedureForm"

const ProcedureCreate = props => {
  const createProcedureRequest = useCreateProcedureHandlers()
  const procedureReset = useProcedureReset()
  const [procedure] = useState({
    name: "",
    credentials: [],
  })

  useEffect(() => {
    return () => {
      procedureReset()
    }
  }, [])

  const onSubmit = values => {
    createProcedureRequest(values)
  }

  return (
    <LayoutSidebarOverlay
      title="Nova Autorização"
      afterClose={() => {
        props.history.push("/autorizacao")
      }}
    >
      {({ closeSidebar }) => {
        return (
          <ProcedureForm
            closeSidebar={closeSidebar}
            onSubmit={onSubmit}
            enableReinitialize={true}
            initialValues={procedure}
          />
        )
      }}
    </LayoutSidebarOverlay>
  )
}

export default ProcedureCreate
