import React, { useEffect } from "react"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import {
  useFindPolicyRequestHandler,
  usePolicy,
  useUpdatePolicyHandlers,
  useUpdatePolicySuccess,
} from "../../../store/hooks/policy"
import PolicyForm from "../PolicyForm"
import { withRouter } from "react-router-dom"

const PolicyEdit = props => {
  const updatePolicyRequest = useUpdatePolicyHandlers()
  const findPolicy = useFindPolicyRequestHandler()
  const policy = usePolicy()
  const updateSuccess = useUpdatePolicySuccess()
  const { id } = props.match.params

  const onSubmit = values => {
    updatePolicyRequest(values)
  }

  useEffect(() => {
    findPolicy(id)
  }, [findPolicy, id])

  useEffect(() => {
    if (updateSuccess) {
      props.history.push("/politicas")
    }
  }, [updateSuccess])

  return (
    <LayoutSidebarOverlay
      title="Editar política"
      afterClose={() => {
        props.history.push("/politicas")
      }}
    >
      {({ closeSidebar }) => {
        return (
          <PolicyForm
            closeSidebar={closeSidebar}
            onSubmit={onSubmit}
            initialValues={policy}
          />
        )
      }}
    </LayoutSidebarOverlay>
  )
}

export default withRouter(PolicyEdit)
