import { createGlobalStyle } from "reakit"

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    text-size-adjust: 100%;
  }

  *,
  *::after,
  *::before {
    box-sizing: inherit;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;

    background-color: ${props => props.theme.palette.pageBackground};
    font-family: ${props => props.webfont.google.families[0]}, sans-serif;
    font-size: ${props => props.theme.fontSizeBase}px;
  }

  :focus {
    outline-color: transparent;
    outline-style: none;
  }

  #root {
    overflow: auto;
    height: 100%;
  }
  body .p-inputtext {
    width: 100%;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 1px solid gray;
    border-radius: 0 !important;
    background: transparent !important;
  }
  body .p-inputtext:enabled:focus {
    outline-color: transparent !important;
    outline-style: none !important;
    border-color: none !important;
    box-shadow: none !important;
  }
  body .p-dropdown {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 1px solid gray;
    border-radius: 0 !important;
    max-width: 100% !important;
    width: 100% !important;
  }
  .p-dropdown-filter-icon {
    color: #005369 !important;
  }
  body .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background-color: #005369 !important;
  }  
  body .p-paginator .p-paginator-pages .p-paginator-page {
    line-height: unset !important;
    height: 100% !important;
  }
  body .p-paginator {
    margin-top: 10px;
  }
  body .p-paginator .p-paginator-first, body .p-paginator .p-paginator-prev, body .p-paginator .p-paginator-next, body .p-paginator .p-paginator-last {
    height: 1.5em !important;
  }
  body .p-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2em transparent !important;
  }
  body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight a {
    background-color: #fff !important;
    margin-right: 0 !important;
    border: 1px solid #cecece !important;
    color: gray !important;
  }
  body .p-tabview.p-tabview-top .p-tabview-nav li a:not(.p-disabled):focus {
    box-shadow: none !important;
  }
  body .p-tabview.p-tabview-top .p-tabview-nav li a {
    color: #757575 !important;
  }
  body .p-tabview .p-tabview-panels {
    padding: 0 !important;
  }
  body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight {
    border-width: 3px 0 0 0 !important;
    border-color: #1C5C72 !important;
    background-color: #1C5C72 !important;
    padding-top: 3px !important;
  }
  body .p-autocomplete {
    display: flex !important;
    justify-content: center !important;
    width: 100% !important;
  }
  body .p-inputtext:enabled:focus {
    border-color: #1C5C72 !important;
  }
  body .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-list-item {
    padding: 1.5rem !important;
  }
  body .p-autocomplete .p-autocomplete-panel {
    max-height: 350px !important;
  }
  body .p-autocomplete-loader {
    display: none !important;
  }
`

export default GlobalStyle
