import React from "react"
import { LinkStyled, LinkExternalStyled } from "./index.styled"

const SideNavigationItem = ({ children, to, title, external }) => {
  if (external) {
    return (
      <li>
        <LinkExternalStyled
          href={to}
          title={title}
          data-testid="navigation-item"
          target="_blank"
        >
          {children}
        </LinkExternalStyled>
      </li>
    )
  }

  return (
    <li>
      <LinkStyled to={to} title={title} data-testid="navigation-item">
        {children}
      </LinkStyled>
    </li>
  )
}

export default SideNavigationItem
