import React, { createContext, useEffect, useState } from "react"
import { pathOr, prepend } from "ramda"
import { Redirect, Route, Switch } from "react-router-dom"
import PermissionRequest from "pages/PermissionRequest"
import PermissionManagement from "pages/PermissionManagement"
import Monitoring from "pages/Monitoring"
import EmployeeProfile from "pages/EmployeeProfile"
import Person from "pages/PersonManagement"
import ExpiredCredentials from "./pages/ExpiredCredentials";
import useCustomFetch from "lib/useCustomFetch"
import API from "lib/api"
import Credential from "./pages/Credentials"
import Dashboard from "pages/Dashboard"
import Policy from "./pages/Policy"
import Procedure from "./pages/Procedure"
import Area from "./pages/Area"
import MonitoringMap from "./pages/MonitoringMap"
import Login from "./pages/Login"
import PrivateRoute from "common/PrivateRoute"
import {
  useNavigationChangeModuleDisplay,
  useNavigationDialogDisplay,
  useNavigationModuleName,
  useNavigationSelectedModule,
} from "store/hooks/navigation"
import User from "./pages/User"
import { MODULES } from "common/ModuleChangeV2"
import ForgotPassword from "pages/ForgotPassword"
import ResetPassword from "pages/ResetPassword"
import { ROLES } from "common/constants"
import Checkpoint from "./pages/Checkpoint"
import AlertsTableList from "pages/Alerts"
import Events from "./pages/Events"
import Headcount from "./pages/Headcount"
import HeadcountDetail from "./pages/Headcount/Detail"
import ExternalHeadcount from "./pages/ExternalHeadcount"
import ExternalHeadcountDetail from "./pages/ExternalHeadcount/Detail"
import RouteFromToken from "common/RouteFromToken"
import EmergencyAlerts from "./pages/EmergencyAlerts";
import AlertGroup from "./pages/Groups/AlertGroup";
import FormAlertGroup from "./common/FormAlertGroup";
import GroupEdit from "./common/FormAlertGroup/GroupEdit";
import GroupRequestDestroy from "./pages/GroupRequestDestroy";

export const WebsocketContext = createContext({})

const ApplicationRoutes = () => {
  const dialogDisplay = useNavigationDialogDisplay()
  const handleChangeDialogDisplayAction = useNavigationChangeModuleDisplay()
  const moduleName = useNavigationModuleName()

  useEffect(() => {
    if (!moduleName) {
      handleChangeDialogDisplayAction(true)
    }
  }, [moduleName, dialogDisplay])
  // Fetch presence alerts and set on local state.
  const presenceAlerts = useCustomFetch({
    endpoint: `${API.PERSON}/presence-alerts/summary`,
  })

  // Fetch alerts and set it on local state.
  const { data: alertsData } = useCustomFetch({
    endpoint: `${API.PERSON}/alerts?sort=eventDate,desc`,
  })

  useEffect(() => {
    setEmployeePresent(pathOr("...", ["data", "present"], presenceAlerts))
    setEmployeeAbsent(pathOr("...", ["data", "absent"], presenceAlerts))
  }, [presenceAlerts])

  useEffect(() => {
    setAlerts(pathOr([], ["content"], alertsData))
  }, [alertsData])

  // Set initial states
  const [employeePresent, setEmployeePresent] = useState("...")
  const [employeeAbsent, setEmployeeAbsent] = useState("...")
  const [alerts, setAlerts] = useState([])

  const handleWebsocketMessage = message => {
    const response = JSON.parse(message.payloadInJson)

    switch (message.type) {
      case "PRESENCE":
        setEmployeePresent(response.present)
        setEmployeeAbsent(response.absent)
        break

      case "ALERT":
        setAlerts(prepend(response, alerts))
        break

      default:
        break
    }
  }

  return (
    <>
      {/*<SockJsClient
        url="/ws/websocket"
        topics={["/topic/companies/5bf847a07928400001320ab2/v1/alerts"]}
        onConnect={() => {
          console.log("ws connected.")
        }}
        onMessage={handleWebsocketMessage}
      />*/}
      <WebsocketContext.Provider
        value={{
          alerts,
          employeePresent,
          employeeAbsent,
        }}
      >
        <Switch>
          <Route path="/login" component={Login} exact />
          <Route
            path="/external-headcount/:token"
            component={props => <RouteFromToken component={ExternalHeadcount} {...props} />}
            exact
          />
          <Route
            path="/external-headcount-detail/:token/:datetime/:policyId/:name"
            component={ExternalHeadcountDetail}
            exact
          />
          <Route path="/emergency-alerts/alert-group" component={AlertGroup} exact />
          <Route path="/emergency-alerts/alert-group/editar/:id" component={GroupEdit} exact />
          <Route path="/emergency-alerts/alert-group/delete/:id" component={GroupRequestDestroy} exact />
          <Route path="/alert-group/new-group" component={FormAlertGroup} exact />
          <Route path="/forgot-password" component={ForgotPassword} exact />
          <Route path="/reset-password/:key" component={ResetPassword} exact />
          <PrivateRoute path="/expired-credentials" component={ExpiredCredentials}/>
          <PrivateRoute path="/emergency-alerts" component={EmergencyAlerts}/>
          <PrivateRoute
            path="/pedidos-de-credenciais"
            component={PermissionRequest}
          />
          <PrivateRoute
            path="/politicas"
            component={Policy}
            roles={[ROLES.ADMIN]}
          />
          <PrivateRoute path="/areas" component={Area} />
          <PrivateRoute path="/autorizacao" component={Procedure} />
          <PrivateRoute
            path="/credenciamento"
            component={PermissionManagement}
          />
          <PrivateRoute path="/monitoramento" component={Monitoring} />
          <PrivateRoute path="/alertas" component={AlertsTableList} />
          <PrivateRoute path="/events-view" component={Events} />
          <PrivateRoute path="/perfil/:profileId" component={EmployeeProfile} />
          <PrivateRoute path="/pessoa" component={Person} />
          <PrivateRoute
            path="/usuarios"
            component={User}
            roles={[ROLES.ADMIN]}
          />
          <PrivateRoute path="/headcount" component={Headcount} />
          <PrivateRoute
            path="/headcount-detail/:id/:name/:time"
            component={HeadcountDetail}
          />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/checkpoint-nodes" component={Checkpoint} />
          <PrivateRoute path="/credenciais" component={Credential} />
          <PrivateRoute path="/maps" component={MonitoringMap} />
          <PrivateRoute path="/" component={RedirectFirstRoute} />
        </Switch>
      </WebsocketContext.Provider>
    </>
  )
}

const RedirectFirstRoute = () => {
  const [firstRoute, setFirstRoute] = useState()
  const selectedModule = useNavigationSelectedModule()

  useEffect(() => {
    if (selectedModule) {
      const moduleFind = MODULES.find(module => module.id === selectedModule)
      setFirstRoute(
        moduleFind
          ? moduleFind.menuItems
            ? moduleFind.menuItems[0]
            : null
          : null
      )
    }
  }, [selectedModule])

  if (firstRoute) {
    return <Redirect to={firstRoute.to} />
  }
  return null
}

export default ApplicationRoutes
