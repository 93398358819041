import React from "react"
import { Box } from "reakit"
import { Slider } from "./index.styled"

const HardwareInfoBar = props => {
  const filled = (props.active * 100) / props.total

  const palette = props.palette || "error"
  const tone = props.tone || 0

  return (
    <Box>
      <Slider filled={filled} palette={palette} tone={tone}>
        <Box>
          <span className="hardwareName">{props.legend}</span>
          <span className="total">{`${props.active}/${props.total -
            props.active}`}</span>
        </Box>
        <Box />
      </Slider>
    </Box>
  )
}

export default HardwareInfoBar
