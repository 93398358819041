import {call, put, select, takeLatest} from "redux-saga/effects"
import api from "../../services/api"
import API from "../../lib/api"
import {
  CREDENTIAL_CREATE_REQUEST,
  CREDENTIAL_DELETE_REQUEST,
  CREDENTIAL_FIND_REQUEST,
  CREDENTIAL_UPDATE_REQUEST,
  CREDENTIALS_FIND_REQUEST,
  CREDENTIALS_LOAD_REQUEST,
  findCredentialSuccessAction,
  loadCredentialsError,
  loadCredentialsSuccess,
} from "store/ducks/credential"
import {loadPersonError, loadPersonsSuccess, updatePersonError} from "../ducks/person"
import {concat, isEmpty} from "ramda"
import {
  credentialCreateSuccessAction,
  credentialDeleteSuccessAction,
  CREDENTIALS_PERSON_REQUEST,
  credentialUpdateSuccessAction, loadPersonCredentialsError, loadPersonCredentialsSuccess,
} from "../ducks/credential"

export function* findPersonsCredentials(action) {
  try {
    const filter = JSON.parse(localStorage.getItem("filter"))
    const {page, size, force, personId} = action.payload
    const personStorageId = JSON.parse(localStorage.getItem("personId"))
    let personIdValue;
    if (personId) {
      personIdValue = personId
    } else {
      personIdValue = personStorageId
    }
    const emptyFilter = filter ? !isEmpty(filter.filter) : false
    const findPersonsCredentialsDataApiCall = () => {
      let params
      params = {
        page,
        size: size,
        sort: "createdAt,desc",
      }
      if (filter && filter.personCredentials) {
        filter.personCredentials.forEach(f => {
          params[f.field] = f.value
        })
      }
      return api
      .get(`${API.PERSON}/persons/${personIdValue}/person-credentials`, {
        params,
      })
      .then(response => {
        return response.data
      })
      .catch(err => {
        throw err
      })
    }
    const {
      credentials,
      lastPageFind,
      totalPages: totalPagesPerson,
    } = yield select(state => state.person)
    if (page === lastPageFind && !force) {
      yield put(loadPersonsSuccess(credentials, totalPagesPerson, page, emptyFilter))
    }
    const {content, totalPages, first, last} = yield call(
      findPersonsCredentialsDataApiCall
    )
    if (!first && !force) {
      yield put(
        loadPersonCredentialsSuccess(
          concat(credentials, content || []),
          totalPages,
          last,
          emptyFilter
        )
      )
    } else {
      yield put(loadPersonCredentialsSuccess(content, totalPages, page, last, emptyFilter))
    }
  } catch (err) {
    yield put(loadPersonCredentialsError(err))
  }
}

export function* findCredentials(action) {
  try {
    const {size, unpaged} = action.payload
    const findCredentialDataApiCall = () => {
      return api
      .get(`${API.PERSON}/credentials`, {
        params: {
          size,
          unpaged,
          sort: "createdAt,desc",
        },
      })
      .then(response => {
        return response.data
      })
      .catch(err => {
        throw err
      })
    }
    const {content} = yield call(findCredentialDataApiCall)
    yield put(loadCredentialsSuccess(content))
  } catch (err) {
    yield put(loadCredentialsError(err))
  }
}

export function* findPagedCredentials(action) {
  try {
    const {size, page} = action.payload
    const filter = JSON.parse(localStorage.getItem("filter"))
    const sort = JSON.parse(localStorage.getItem("sort"))
    const emptyFilter = filter ? !isEmpty(filter.filter) : false
    const findCredentialDataApiCall = () => {
      let params
      params = {
        page,
        size: size,
        sort:
          sort && sort.credential
            ? sort.credential.sortString
            : "createdAt,desc",
      }
      if (filter && filter.credential) {
        filter.credential.forEach(f => {
          if (f.field === 'updatedAt') {
            params.updatedSince = f.value.startDate;
            params.updatedUntil = f.value.endDate;
          } else {
            params[f.field] = f.value;
          }
        })
      }
      return api
      .get(`${API.PERSON}/credentials`, {
        params,
      })
      .then(response => {
        return response.data
      })
      .catch(err => {
        throw err
      })
    }
    const {credentials} = yield select(state => state.credential)
    const {content, first, last} = yield call(findCredentialDataApiCall)
    if (!first) {
      yield put(
        loadCredentialsSuccess(
          concat(credentials, content || []),
          last,
          emptyFilter
        )
      )
    } else {
      yield put(loadCredentialsSuccess(content, last, emptyFilter))
    }
  } catch (err) {
    yield put(loadCredentialsError(err))
  }
}

export function* createCredential(action) {
  try {
    const {credential} = action.payload
    const {organizationUnity} = credential
    const createOrganizationUnityApiCall = () => {
      return api
      .post(`${API.PERSON}/organizations-unities`, organizationUnity)
      .then(response => {
        return response.data
      })
      .catch(err => {
        throw err
      })
    }
    const createCredentialApiCall = () => {
      return api
      .post(`${API.PERSON}/credentials`, credential)
      .then(response => response.data)
      .catch(err => {
        throw err
      })
    }
    if (organizationUnity && !organizationUnity.id) {
      credential.organizationUnity = yield call(createOrganizationUnityApiCall)
    }
    const response = yield call(createCredentialApiCall)
    yield put(credentialCreateSuccessAction(response))
  } catch (err) {
    yield put(updatePersonError(err))
  }
}

export function* findCredential(action) {
  try {
    const {id} = action.payload
    const findCredentialApiCall = () => {
      return api
      .get(`${API.PERSON}/credentials/${id}`)
      .then(response => {
        return response.data
      })
      .catch(err => {
        throw err
      })
    }
    const response = yield call(findCredentialApiCall)
    yield put(findCredentialSuccessAction(response))
  } catch (err) {
    yield put(loadPersonError(err))
  }
}

export function* deleteCredential(action) {
  try {
    const {id} = action.payload
    const deleteCredentialApiCall = () => {
      return api
      .delete(`${API.PERSON}/credentials/${id}`)
      .then(() => {
        return
      })
      .catch(err => {
        throw err
      })
    }
    yield call(deleteCredentialApiCall)
    yield put(credentialDeleteSuccessAction())
  } catch (err) {
    console.log(err)
  }
}

export function* updateCredential(action) {
  try {
    const {credential} = action.payload
    const {organizationUnity} = credential
    const createOrganizationUnityApiCall = () => {
      return api
      .post(`${API.PERSON}/organizations-unities`, organizationUnity)
      .then(response => {
        return response.data
      })
      .catch(err => {
        throw err
      })
    }
    const updateCredentialApiCall = () => {
      return api
      .put(`${API.PERSON}/credentials/${credential.id}`, credential)
      .then(response => {
        return response.data
      })
      .catch(err => {
        throw err
      })
    }
    if (organizationUnity && !organizationUnity.id) {
      credential.organizationUnity = yield call(createOrganizationUnityApiCall)
    }
    yield call(updateCredentialApiCall)
    yield put(credentialUpdateSuccessAction())
  } catch (err) {
    console.log(err)
  }
}

export default [
  takeLatest(CREDENTIALS_PERSON_REQUEST, findPersonsCredentials),
  takeLatest(CREDENTIALS_LOAD_REQUEST, findCredentials),
  takeLatest(CREDENTIAL_DELETE_REQUEST, deleteCredential),
  takeLatest(CREDENTIAL_UPDATE_REQUEST, updateCredential),
  takeLatest(CREDENTIALS_FIND_REQUEST, findPagedCredentials),
  takeLatest(CREDENTIAL_FIND_REQUEST, findCredential),
  takeLatest(CREDENTIAL_CREATE_REQUEST, createCredential),
]
