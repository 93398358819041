export const DOWNLOAD_TIME_AND_PLACES_REQUEST =
  "DOWNLOAD_TIME_AND_PLACES_REQUEST"
export const DOWNLOAD_TIME_AND_PLACES_SUCCESS =
  "DOWNLOAD_TIME_AND_PLACES_SUCCESS"
export const FIND_PERSON_TIME_AND_PLACES_RESET =
  "FIND_PERSON_TIME_AND_PLACES_RESET"
export const FIND_PERSON_TIME_AND_PLACES_REQUEST =
  "FIND_PERSON_TIME_AND_PLACES_REQUEST"
export const FIND_PERSON_TIME_AND_PLACES_SUCCESS =
  "FIND_PERSON_TIME_AND_PLACES_SUCCESS"
export const FIND_PERSON_TIME_AND_PLACES_ERROR =
  "FIND_PERSON_TIME_AND_PLACES_ERROR"
export const LOAD_UNPAGED_PERSONS_REQUEST = "LOAD_UNPAGED_PERSONS_REQUEST"
export const LOAD_UNPAGED_PERSONS_SUCCESS = "LOAD_UNPAGED_PERSONS_SUCCESS"
export const LOAD_UNPAGED_PERSONS_ERROR = "LOAD_UNPAGED_PERSONS_ERROR"
export const LOAD_PERSONS_REQUEST = "LOAD_PERSONS_REQUEST"
export const LOAD_PERSONS_SUCCESS = "LOAD_PERSONS_SUCCESS"
export const LOAD_PERSONS_ERROR = "LOAD_PERSONS_ERROR"
export const LOAD_PERSON_REQUEST = "LOAD_PERSON_REQUEST"
export const LOAD_PERSON_SUCCESS = "LOAD_PERSON_SUCCESS"
export const LOAD_PERSON_ERROR = "LOAD_PERSON_ERROR"
export const FIND_PERSON_TIME_AND_PLACES_LAST = "FIND_PERSON_TIME_AND_PLACES_LAST"
export const DELETE_PERSON_REQUEST = "DELETE_PERSON_REQUEST"
export const DELETE_PERSON_SUCCESS = "DELETE_PERSON_SUCCESS"
export const DELETE_PERSON_ERROR = "DELETE_PERSON_ERROR"
export const CREATE_PERSON_REQUEST = "CREATE_PERSON_REQUEST"
export const CREATE_PERSON_SUCCESS = "CREATE_PERSON_SUCCESS"
export const CREATE_PERSON_ERROR = "CREATE_PERSON_ERROR"
export const UPDATE_PERSON_REQUEST = "UPDATE_PERSON_REQUEST"
export const UPDATE_PERSON_SUCCESS = "UPDATE_PERSON_SUCCESS"
export const UPDATE_PERSON_ERROR = "UPDATE_PERSON_ERROR"
export const PERSON_RESET = "PERSON_RESET"
export const AUTO_COMPLETE_PERSONS_RESET = "AUTO_COMPLETE_PERSONS_RESET"
export const LOAD_AUTO_COMPLETE_PERSONS_ERROR =
  "LOAD_AUTO_COMPLETE_PERSONS_ERROR"
export const LOAD_AUTO_COMPLETE_PERSONS_SUCCESS =
  "LOAD_AUTO_COMPLETE_PERSONS_SUCCESS"
export const LOAD_AUTO_COMPLETE_PERSONS_REQUEST =
  "LOAD_AUTO_COMPLETE_PERSONS_REQUEST"
export const LOAD_PERSON_PERMISSIONS_REQUEST = "LOAD_PERSON_PERMISSIONS_REQUEST"
export const LOAD_PERSON_PERMISSIONS_SUCCESS = "LOAD_PERSON_PERMISSIONS_SUCCESS"
export const LOAD_PERSON_PERMISSIONS_ERROR = "LOAD_PERSON_PERMISSIONS_ERROR"
export const LOAD_PERSON_BEACONS_REQUEST = "LOAD_PERSON_BEACONS_REQUEST"
export const LOAD_PERSON_BEACONS_SUCCESS = "LOAD_PERSON_BEACONS_SUCCESS"
export const LOAD_PERSON_BEACONS_ERROR = "LOAD_PERSON_BEACONS_ERROR"
export const FIND_PERSON_TIME_AND_PLACES_SUMMARY_REQUEST =
  "FIND_PERSON_TIME_AND_PLACES_SUMMARY_REQUEST"
export const FIND_PERSON_TIME_AND_PLACES_SUMMARY_SUCCESS =
  "FIND_PERSON_TIME_AND_PLACES_SUMMARY_SUCCESS"
export const FIND_PERSON_TIME_AND_PLACES_SUMMARY_ERROR =
  "FIND_PERSON_TIME_AND_PLACES_SUMMARY_ERROR"

export const downloadTimeInPlacesRequest = filter => ({
  type: DOWNLOAD_TIME_AND_PLACES_REQUEST,
  payload: {
    filter,
  },
})

export const findPersonTimeInPlacesReset = () => ({
  type: FIND_PERSON_TIME_AND_PLACES_RESET,
})

export const downloadTimeInPlacesSuccess = () => ({
  type: DOWNLOAD_TIME_AND_PLACES_SUCCESS,
})

export const findPersonTimeInPlacesRequest = (filter, page, size) => ({
  type: FIND_PERSON_TIME_AND_PLACES_REQUEST,
  payload: {
    filter,
    page,
    size,
  },
})

export const findPersonTimeInPlacesSuccess = timePlaces => ({
  type: FIND_PERSON_TIME_AND_PLACES_SUCCESS,
  payload: {
    timePlaces,
  },
})

export const findPersonTimeInPlacesLast = timePlacesLast => ({
  type: FIND_PERSON_TIME_AND_PLACES_LAST,
  payload: {
    timePlacesLast,
  },
})

export const findPersonTimeInPlacesError = error => ({
  type: FIND_PERSON_TIME_AND_PLACES_ERROR,
  payload: {
    error,
  },
})

export const loadPersonPermissionsRequest = id => ({
  type: LOAD_PERSON_PERMISSIONS_REQUEST,
  payload: {
    id,
  },
})

export const loadPersonPermissionsSuccess = permissions => ({
  type: LOAD_PERSON_PERMISSIONS_SUCCESS,
  payload: {
    permissions,
  },
})

export const loadPersonPermissionsError = error => ({
  type: LOAD_PERSON_PERMISSIONS_ERROR,
  payload: {
    error,
  },
})

export const loadPersonBeaconsRequest = id => ({
  type: LOAD_PERSON_BEACONS_REQUEST,
  payload: {
    id,
  },
})

export const loadPersonBeaconsSuccess = beacons => ({
  type: LOAD_PERSON_BEACONS_SUCCESS,
  payload: {
    beacons,
  },
})

export const loadPersonBeaconsError = error => ({
  type: LOAD_PERSON_BEACONS_ERROR,
  payload: {
    error,
  },
})
export const loadAutoCompletePersonsRequest = (name, unpaged) => ({
  type: LOAD_AUTO_COMPLETE_PERSONS_REQUEST,
  payload: {
    name,
    unpaged,
  },
})

export const loadAutoCompletePersonsSuccess = autocompletePersons => ({
  type: LOAD_AUTO_COMPLETE_PERSONS_SUCCESS,
  payload: {
    autocompletePersons,
  },
})

export const loadAutoCompletePersonsError = error => ({
  type: LOAD_AUTO_COMPLETE_PERSONS_ERROR,
  payload: {
    error,
  },
})

export const resetResetPersons = () => ({
  type: AUTO_COMPLETE_PERSONS_RESET,
})

export const loadUnpagedPersonsRequest = () => ({
  type: LOAD_UNPAGED_PERSONS_REQUEST,
  payload: {},
})

export const loadUnpagedPersonsSuccess = personsUnpaged => ({
  type: LOAD_UNPAGED_PERSONS_SUCCESS,
  payload: {
    personsUnpaged,
  },
})

export const loadPersonsRequest = (page, size, force) => ({
  type: LOAD_PERSONS_REQUEST,
  payload: {
    page,
    size,
    force,
  },
})

export const loadPersonsSuccess = (
  persons,
  totalPages,
  lastPageFind,
  last,
  filtering
) => ({
  type: LOAD_PERSONS_SUCCESS,
  payload: {
    persons,
    totalPages,
    lastPageFind,
    last,
    filtering,
  },
})

export const loadPersonsError = error => ({
  type: LOAD_PERSONS_ERROR,
  payload: {
    error,
  },
})

export const loadPersonRequest = (id, withBeacons) => ({
  type: LOAD_PERSON_REQUEST,
  payload: {
    id,
    withBeacons,
  },
})

export const loadPersonSuccess = person => ({
  type: LOAD_PERSON_SUCCESS,
  payload: {
    person,
  },
})

export const loadPersonError = error => ({
  type: LOAD_PERSON_ERROR,
  payload: {
    error,
  },
})

export const deletePersonRequest = id => ({
  type: DELETE_PERSON_REQUEST,
  payload: {
    id,
  },
})

export const deletePersonSuccess = () => ({
  type: DELETE_PERSON_SUCCESS,
})

export const resetPerson = () => ({
  type: PERSON_RESET,
})

export const deletePersonError = error => ({
  type: DELETE_PERSON_ERROR,
  payload: {
    error,
  },
})

export const updatePersonRequest = person => ({
  type: UPDATE_PERSON_REQUEST,
  payload: {
    person,
  },
})

export const updatePersonSuccess = person => ({
  type: UPDATE_PERSON_SUCCESS,
  payload: {
    person,
  },
})

export const updatePersonError = error => ({
  type: UPDATE_PERSON_ERROR,
  payload: {
    error,
  },
})

export const createPersonRequest = person => ({
  type: CREATE_PERSON_REQUEST,
  payload: {
    person,
  },
})

export const createPersonSuccess = person => ({
  type: CREATE_PERSON_SUCCESS,
  payload: {
    person,
  },
})

export const createPersonError = error => ({
  type: CREATE_PERSON_ERROR,
  payload: {
    error,
  },
})

export const findPersonTimeInPlacesSummaryRequest = (
  startDate,
  endDate,
  personId,
  daysFilter
) => ({
  type: FIND_PERSON_TIME_AND_PLACES_SUMMARY_REQUEST,
  payload: {
    startDate,
    endDate,
    personId,
    daysFilter,
  },
})

export const findPersonTimeInPlacesSummarySuccess = (
  timeInPlacesSummary,
  timeInPlacesSummaryChart
) => ({
  type: FIND_PERSON_TIME_AND_PLACES_SUMMARY_SUCCESS,
  payload: {
    timeInPlacesSummary,
    timeInPlacesSummaryChart,
  },
})

export const findPersonTimeInPlacesSummaryError = error => ({
  type: FIND_PERSON_TIME_AND_PLACES_SUMMARY_ERROR,
  payload: {
    error,
  },
})

const INITIAL_STATE = {
  error: null,
  loading: false,
  persons: [],
  personsUnpaged: [],
  timePlaces: [],
  timePlacesLast: true,
  person: null,
  totalPages: null,
  filtering: false,
  lastPageFind: null,
  deleteSuccess: false,
  updateSuccess: false,
  createSuccess: false,
  last: true,
  cpfValid: false,
  cpfInvalid: false,
  autocompletePersons: [],
  beacons: [],
  permissions: [],
  timeInPlacesSummary: [],
  timeInPlacesSummaryChart: {},
}

const personReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FIND_PERSON_TIME_AND_PLACES_REQUEST:
    case DOWNLOAD_TIME_AND_PLACES_REQUEST:
    case LOAD_PERSONS_REQUEST:
    case LOAD_PERSON_REQUEST:
    case DELETE_PERSON_REQUEST:
    case CREATE_PERSON_REQUEST:
    case LOAD_UNPAGED_PERSONS_REQUEST:
    case UPDATE_PERSON_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case FIND_PERSON_TIME_AND_PLACES_ERROR:
    case LOAD_PERSON_ERROR:
    case LOAD_PERSONS_ERROR:
    case UPDATE_PERSON_ERROR:
    case CREATE_PERSON_ERROR:
    case DELETE_PERSON_ERROR:
    case FIND_PERSON_TIME_AND_PLACES_SUMMARY_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        error,
        summaryLoading: false,
      }
    }
    case FIND_PERSON_TIME_AND_PLACES_SUMMARY_REQUEST:
      return {
        ...state,
        summaryLoading: true
      }
    case LOAD_PERSONS_SUCCESS: {
      const {
        persons,
        totalPages,
        lastPageFind,
        last,
        filtering,
      } = action.payload
      return {
        ...state,
        persons,
        totalPages,
        loading: false,
        lastPageFind,
        last,
        filtering,
      }
    }
    case LOAD_UNPAGED_PERSONS_SUCCESS: {
      const { personsUnpaged } = action.payload
      return {
        ...state,
        personsUnpaged,
        loading: false,
      }
    }
    case LOAD_PERSON_SUCCESS: {
      const { person } = action.payload
      return {
        ...state,
        person,
        loading: false,
      }
    }
    case UPDATE_PERSON_SUCCESS:
      return {
        ...state,
        updateSuccess: true,
        loading: false,
      }
    case DOWNLOAD_TIME_AND_PLACES_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case DELETE_PERSON_SUCCESS:
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    case PERSON_RESET:
      return {
        ...state,
        person: null,
        deleteSuccess: false,
        updateSuccess: false,
        createSuccess: false,
        loading: false,
      }
    case CREATE_PERSON_SUCCESS:
      return {
        ...state,
        createSuccess: true,
        loading: false,
      }
    case LOAD_AUTO_COMPLETE_PERSONS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case LOAD_AUTO_COMPLETE_PERSONS_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        error,
        loading: false,
      }
    }
    case LOAD_AUTO_COMPLETE_PERSONS_SUCCESS: {
      const { autocompletePersons } = action.payload
      return {
        ...state,
        autocompletePersons,
        loading: false,
      }
    }
    case AUTO_COMPLETE_PERSONS_RESET: {
      return {
        ...state,
        autocompletePersons: [],
        loading: false,
      }
    }
    case FIND_PERSON_TIME_AND_PLACES_RESET: {
      return {
        ...state,
        timePlaces: [],
        loading: false,
      }
    }
    case FIND_PERSON_TIME_AND_PLACES_SUCCESS:
      const { timePlaces } = action.payload
      return {
        ...state,
        timePlaces,
        loading: false,
      }
    case FIND_PERSON_TIME_AND_PLACES_LAST:
      const { timePlacesLast } = action.payload
      return {
        ...state,
        timePlacesLast,
      }
    case FIND_PERSON_TIME_AND_PLACES_SUMMARY_SUCCESS:
      const { timeInPlacesSummary, timeInPlacesSummaryChart } = action.payload
      return {
        ...state,
        timeInPlacesSummary,
        timeInPlacesSummaryChart,
        summaryLoading: false,
      }
    default:
      return state
  }
}

export default personReducer
