export const Sort = (page, sortFn, name, screen) => {
  const previousSortLs = JSON.parse(localStorage.getItem("sort"))
  const previousSort = previousSortLs ? previousSortLs : null
  let sortNewObject
  let order = "desc"
  if (!previousSort) {
    sortNewObject = {
      [screen]: {},
    }
  } else {
    sortNewObject = previousSort
    if (!sortNewObject[screen]) {
      sortNewObject[screen] = {}
    } else {
      order = sortNewObject[screen].order === "asc" ? "desc" : "asc"
    }
  }

  sortNewObject[screen] = {
    field: name,
    order: order,
    sortString: name.concat(",").concat(order),
  }

  localStorage.setItem("sort", JSON.stringify(sortNewObject))
  page(0)
  setTimeout(() => sortFn(0), 0)
}

export const GetActivatedSorted = (column, screen) => {
  const sort = JSON.parse(localStorage.getItem("sort"))
  return sort && sort[screen] ? sort[screen].field === column : false
}
