import React from "react"
import { Field as ReakitField, styled } from "reakit"
import { theme } from "styled-tools"
import { Calendar } from "primereact/calendar"

const FieldStyled = styled(ReakitField)``

const InputLabel = styled.label`
  font-weight: 500;
  color: #005369;
  display: block;
  margin-top: 0.5rem;
  margin-right: 0;
  margin-left: 0;
  padding: 0;
  font-size: 1.3rem;
`

let es = {
  firstDayOfWeek: 1,
  dayNames: [
    "domingo",
    "segunda",
    "terça",
    "quarta",
    "quinta",
    "sexta",
    "sábado",
  ],
  dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
  dayNamesMin: ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
  monthNames: [
    "janeiro",
    "fevereiro",
    "março",
    "abril",
    "maio",
    "junho",
    "julho",
    "agosto",
    "setembro",
    "outubro",
    "novembro",
    "dezembro",
  ],
  monthNamesShort: [
    "jan",
    "fev",
    "mar",
    "abr",
    "mai",
    "jun",
    "jul",
    "ago",
    "set",
    "out",
    "nov",
    "dez",
  ],
  today: "Hoje",
  clear: "Limpar",
  dateFormat: "dd/mm/yy",
  weekHeader: "Sm",
}

const FieldDate = props => {
  return (
    <FieldStyled>
      <InputLabel>{props.label}</InputLabel>
      <Calendar
        dateFormat="dd/mm/yy"
        locale={es}
        {...props}
        style={{ width: "100%" }}
      />
    </FieldStyled>
  )
}

export default FieldDate
