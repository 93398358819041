import React, { useEffect } from "react"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import {
  useFindProcedureRequestHandler,
  useProcedure,
  useProcedureReset,
  useUpdateProcedureHandlers,
} from "../../../store/hooks/procedure"
import ProcedureForm from "../ProcedureForm"
import { withRouter } from "react-router-dom"

const ProcedureEdit = props => {
  const updateProcedureRequest = useUpdateProcedureHandlers()
  const findProcedure = useFindProcedureRequestHandler()
  const procedure = useProcedure()
  const procedureReset = useProcedureReset()
  const { id } = props.match.params

  const onSubmit = values => {
    if (!values.scope) {
      values.scope = "GLOBAL"
    }
    updateProcedureRequest(values)
  }

  useEffect(() => {
    return () => {
      procedureReset()
    }
  }, [])

  useEffect(() => {
    findProcedure(id)
  }, [findProcedure, id])

  return (
    <LayoutSidebarOverlay
      title="Editar Autorização"
      afterClose={() => {
        props.history.push("/autorizacao")
      }}
    >
      {({ closeSidebar }) => {
        return (
          <ProcedureForm
            closeSidebar={closeSidebar}
            onSubmit={onSubmit}
            initialValues={procedure}
          />
        )
      }}
    </LayoutSidebarOverlay>
  )
}

export default withRouter(ProcedureEdit)
