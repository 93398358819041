import React from "react"
import { ErrorMessage, Formik } from "formik"
import { ValidationSchema } from "./ValidationSchema"
import {
  Footer,
  FormContainer,
  InfoBox,
  Input,
  SubmitButton,
  GoLoginButton,
} from "./styles"
import { ErrorContainer, ErrorLabel } from "common/Error"
import { useForgotPasswordRequest } from "store/hooks/authentication"
import { withRouter } from "react-router-dom"
import LoginContainer from "common/LoginContainer"

const ForgotPassword = props => {
  const forgotPasswordAction = useForgotPasswordRequest()
  const initialValues = {
    email: "",
  }

  const handleForgotPassword = formValue => {
    forgotPasswordAction(formValue)
  }

  const handleGoToLogin = () => {
    props.history.push("/login")
  }

  return (
    <LoginContainer>
      <Formik
        initialValues={initialValues}
        onSubmit={handleForgotPassword}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
      >
        {({ values, handleChange, handleSubmit }) => (
          <>
            <FormContainer>
              <InfoBox>
                Informe abaixo seu e-mail e você receberá um link para alterar
                sua senha.
              </InfoBox>
              <Input
                placeholder="E-mail"
                name="email"
                onChange={handleChange}
                value={values.email}
                type="email"
              />
              <ErrorContainer>
                <ErrorMessage
                  name="email"
                  render={msg => <ErrorLabel>{msg}</ErrorLabel>}
                />
              </ErrorContainer>
              <Footer>
                <SubmitButton type="submit" onClick={handleSubmit}>
                  Recuperar senha
                </SubmitButton>
                <GoLoginButton onClick={handleGoToLogin}>
                  Efetuar login
                </GoLoginButton>
              </Footer>
            </FormContainer>
          </>
        )}
      </Formik>
    </LoginContainer>
  )
}

export default withRouter(ForgotPassword)
