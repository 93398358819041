import { call, put, takeLatest, takeEvery } from "redux-saga/effects"
import api from "../../services/api"
import API from "../../lib/api"
import {
  findHeadcountDetailSuccessAction,
  findHeadcountsDashboardDataSuccessAction,
  findHeadcountsSuccessAction,
  HEADCOUNTS_FIND_DASHBOARD_DATA_REQUEST,
  HEADCOUNTS_FIND_DETAIL_REQUEST,
  HEADCOUNTS_FIND_REQUEST,
  headCountsError,
} from "../ducks/headcount"
import { format, subHours } from "date-fns"

export function* findHeadcounts() {
  try {
    const findPolicyApiCall = id => {
      return api
        .get(`${API.BLEZONE}/policies/${id}`)
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
    const headCountApiCall = id => {
      const startDate = subHours(new Date(), 6)
      const endDate = new Date()
      return api
        .get(
          `${
            API.PERSON
          }/head-counts-by-policy/${id}?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
        )
        .then(response => response.data)
        .catch(err => {
          throw err
        })
    }
    const policyList = [
      "5f21e2acd1d7a26586569db4",
      "5f0f1e2b29641f4722eb85c1",
      "5f0f1e6e29641f4722eb85c2",
      "5f0f1ea929641f4722eb85c3",
    ]
    const responseList = []
    for (let p of policyList) {
      const response = yield call(findPolicyApiCall, p)
      const responseHeadcount = yield call(headCountApiCall, p)
      responseList.push({ ...response, ...responseHeadcount })
    }

    yield put(findHeadcountsSuccessAction(responseList))
  } catch (err) {
    console.log(err)
  }
}

export function* findHeadcountDetail(action) {
  const { id, time } = action.payload
  try {
    const findPolicyApiCall = () => {
      return api
        .get(`${API.PERSON}/head-count-persons-history/${id}?eventDate=${time}`)
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }

    const response = yield call(findPolicyApiCall)
    yield put(findHeadcountDetailSuccessAction(response))
  } catch (err) {
    console.log(err)
  }
}

export function* findHeadcountDashboardData(action) {
  const { policyId } = action.payload
  const startDate = subHours(new Date(), 6)
  const endDate = new Date()
  try {
    const apiCall = () => {
      return api
        .get(
          `${
            API.PERSON
          }/head-counts-by-policy/${policyId}?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
        )
        .then(response => response.data)
        .catch(err => {
          throw err
        })
    }
    const response = yield call(apiCall)
    yield put(findHeadcountsDashboardDataSuccessAction(response, policyId))
  } catch (err) {
    yield put(headCountsError(err))
  }
}

export default [
  takeLatest(HEADCOUNTS_FIND_REQUEST, findHeadcounts),
  takeLatest(HEADCOUNTS_FIND_DETAIL_REQUEST, findHeadcountDetail),
  takeEvery(HEADCOUNTS_FIND_DASHBOARD_DATA_REQUEST, findHeadcountDashboardData),
]
