import api from "services/api"
import API from "lib/api"
import { call, put, select, takeLatest } from "redux-saga/effects"
import qs from "qs"
import {
  findMonitoringAlertsSuccess,
  findMonitoringDataSuccess,
  MONITORING_ALERTS_REQUEST,
  MONITORING_DATA_REQUEST,
  monitoringErrorAction,
} from "store/ducks/monitoring"
import { concat } from "ramda"

function* genericApi(url, params) {
  const genericApiCall = () => {
    return api
      .get(url, {
        params: {
          ...params,
        },
        paramsSerializer: params => {
          return qs.stringify(params, {
            arrayFormat: "repeat",
          })
        },
      })
      .then(response => {
        return response.data
      })
      .catch(err => {
        return null
      })
  }
  return yield call(genericApiCall)
}
export function* findMonitoringData(action) {
  try {
    const countApi = `${API.PERSON}/person-credentials/count`
    const triggedButtons = `${API.ALERTS}/alerts/details/INPUT_TOGGLE/allDetails`
    const credentialsExpired = `${API.PERSON}/person-credentials/`
    const permissionsRequestApi = `${API.PERSON}/permissions-request/count`
    const sumarryApi = `${API.PERSONV2}/presence-alerts/summary`
    const presencesSummary = `${API.PERSONV2}/presences/summary`
    const lastAlertApi = `${API.PERSON}/alerts/lastAlert`
    const { total: news } = yield call(genericApi, permissionsRequestApi, {
      status: "REQUESTED",
    })
    const { total: expireds } = yield call(genericApi, countApi, {
      status: ["EXPIRED"],
    })
    const { totalElements: totalTriggedButtons } = yield call(genericApi, triggedButtons)

    const { total: finalized } = yield call(genericApi, permissionsRequestApi, {
      status: "FINALIZED",
    })
    const { total: total, totalCredentialExpiried: totalCredentialExpiried, totalCredentialOk: totalCredentialOk } = yield call(genericApi, presencesSummary, {
    })
    const { totalBeaconActive: totalBeaconActives, totalPersonViolatedToday: totalPersonViolatedTodays} = yield call(
      genericApi,
      sumarryApi,
      {}
    )
    const { content: content} = yield call(  genericApi, credentialsExpired,{
        status: ["EXPIRED"],
      }
    )
    const today = new Date()
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)
    const lastAlert = yield call(genericApi, lastAlertApi, {
      startDate: yesterday.toISOString(),
      endDate: today.toISOString(),
    })
    yield put(
      findMonitoringDataSuccess({
        news,
        expireds,
        totalTriggedButtons,
        finalized,
        totalBeaconActives,
        totalPersonViolatedTodays,
        lastAlert,
        total,
        totalCredentialExpiried,
        totalCredentialOk,
        credentialsExpired,
        content
      })
    )
  } catch (err) {
    yield put(monitoringErrorAction(err))
  }
}

export function* findMonitoringAlerts(action) {
  try {
    const { page, size } = action.payload
    const apiCall = () => {
      return api
        .get(`${API.PERSON}/alerts?sort=eventDate,desc`, {
          params: {
            page,
            size,
          },
        })
        .then(r => r.data)
        .catch(err => {
          throw err
        })
    }
    const { content, last, first } = yield call(apiCall)

    if (!first) {
      const { alerts } = yield select(state => state.monitoring)
      yield put(
        findMonitoringAlertsSuccess(concat(alerts, content || []), last)
      )
    } else {
      yield put(findMonitoringAlertsSuccess(content, last))
    }
  } catch (err) {
    yield put(monitoringErrorAction(err))
  }
}

export default [
  takeLatest(MONITORING_DATA_REQUEST, findMonitoringData),
  takeLatest(MONITORING_ALERTS_REQUEST, findMonitoringAlerts),
]
