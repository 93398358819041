import { call, put, select, takeLatest } from "redux-saga/effects"
import api from "../../services/api"
import {
  AUTH_LOGOUT,
  AUTH_REQUEST,
  AUTH_FROM_TOKEN_REQUEST,
  authFailedAction,
  authLogoutFailedAction,
  authLogoutSuccessAction,
  authSuccessAction,
  authUserSuccessAction,
  changePasswordRequestError,
  changePasswordRequestSuccess,
  FORGOT_PASSWORD_REQUEST,
  forgotPasswordFailedAction,
  forgotPasswordSuccessAction,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_VALIDATE_KEY,
  resetPasswordFailedAction,
  resetPasswordRedirectAction,
  resetPasswordRequestSuccess,
  resetPasswordValidKeyAction,
  UPDATE_PASSWORD_REQUEST,
} from "../ducks/authentication"
import API from "lib/api"
import { clearToken, setToken } from "lib/token"
import { showErrorToast, showSuccessToast } from "utils/toast"
import { changeModuleAction } from "store/ducks/navigation"
import { MODULES } from "common/ModuleChangeV2"
import { take } from "ramda"

export function* findAuthUser(action) {
  try {
    const apiCall = () => {
      return api
        .get(`${API.AUTH}/users/me`)
        .then(response => response.data)
        .catch(err => {
          throw err
        })
    }
    const response = yield call(apiCall)
    yield put(authUserSuccessAction(response))
    return response
  } catch (error) {
    throw error
  }
}

export function* authenticate(action) {
  try {
    const { email, password } = action.payload
    const req = {
      email,
      password,
    }
    const authApiCall = () => {
      return api
        .post(`${API.AUTH}/login`, req)
        .then(response => response.data)
        .catch(err => {
          throw err
        })
    }
    const { token } = yield call(authApiCall)
    if (MODULES) {
      const firstModule = MODULES[0]
      yield put(
        changeModuleAction(
          firstModule ? firstModule.id : null,
          firstModule ? firstModule.name : null
        )
      )
    }
    yield call(setToken, token)
    yield call(findAuthUser)
    yield put(authSuccessAction(token))
  } catch (err) {
    const { data } = err
    showErrorToast(`Falha ao autenticar: ${data.message || ""}`)
    yield put(authFailedAction(err))
  }
}

export function* authenticateFromToken(action) {
  try {
    const { token } = action.payload
    yield call(setToken, token)
    yield call(findAuthUser)
    yield put(authSuccessAction(token))
  } catch (err) {
    const { data } = err
    showErrorToast(`Falha ao autenticar: ${data.message || ""}`)
    yield put(authFailedAction(err))
  }
}

export function* forgotPassword(action) {
  try {
    const { email } = action.payload
    const forgotPasswordApiCall = () => {
      return api
        .post(`${API.AUTH}/recovery-password`, { email })
        .then(response => response.data)
        .catch(err => {
          throw err
        })
    }
    yield call(forgotPasswordApiCall)
    yield put(forgotPasswordSuccessAction())
    showSuccessToast("Verifique seu email para resetar a senha")
  } catch (err) {
    const { data } = err
    showErrorToast(`Problema ao recuperar senha: ${data.message || ""}`)
    yield put(forgotPasswordFailedAction(err))
  }
}

export function* logout(event) {
  try {
    const logoutApiCall = () => {
      return api
        .get(`${API.AUTH}/logout`)
        .then(response => response.data)
        .catch(err => {
          throw err
        })
    }
    yield call(logoutApiCall)
    yield put(authLogoutSuccessAction())
    yield call(clearToken)
  } catch (err) {
    yield put(authLogoutFailedAction(err))
  }
}

export function* resetPassword(action) {
  try {
    const { newPassword } = action.payload
    const { key } = action.payload
    const resetPasswordApiCall = () => {
      return api
        .put(`${API.AUTH}/reset-password/${key}`, { newPassword })
        .then(response => response.data)
        .catch(err => {
          throw err
        })
    }
    yield call(resetPasswordApiCall)
    yield put(resetPasswordRequestSuccess())
    showSuccessToast(`A sua senha foi atualizada com sucesso!`)
    yield put(resetPasswordRedirectAction(true))
  } catch (err) {
    const { data } = err
    showErrorToast(`Problema ao resetar senha: ${data.message || ""}`)
    yield put(resetPasswordFailedAction(err))
    yield put(resetPasswordRedirectAction(true))
  }
}

export function* validateKey(action) {
  try {
    const { key } = action.payload
    const validateKeyApiCall = () => {
      return api
        .get(`${API.AUTH}/recovery-password/${key}`)
        .then(response => response.data)
        .catch(err => {
          throw err
        })
    }
    yield call(validateKeyApiCall)
    yield put(resetPasswordValidKeyAction())
  } catch (err) {
    showErrorToast(`Chave nao encontrada ou já utilizada!`)
    yield put(resetPasswordRedirectAction(true))
  }
}

export function* changePassword(action) {
  try {
    const { data } = action.payload
    const { user } = yield select(state => state.auth)
    const apiCall = () => {
      return api
        .put(`${API.AUTH}/reset-current-password/${user.id}`, data)
        .then(response => response.data)
        .catch(err => {
          throw err
        })
    }
    yield call(apiCall)
    yield put(changePasswordRequestSuccess())
  } catch (err) {
    showErrorToast(
      `Ocorreu um erro ao alterar a senha: ${(err &&
        err.data &&
        err.data.message) ||
        ""}`
    )
    yield put(changePasswordRequestError())
  }
}

export default [
  takeLatest(AUTH_REQUEST, authenticate),
  takeLatest(FORGOT_PASSWORD_REQUEST, forgotPassword),
  takeLatest(AUTH_LOGOUT, logout),
  takeLatest(RESET_PASSWORD_REQUEST, resetPassword),
  takeLatest(RESET_PASSWORD_VALIDATE_KEY, validateKey),
  takeLatest(UPDATE_PASSWORD_REQUEST, changePassword),
  takeLatest(AUTH_FROM_TOKEN_REQUEST, authenticateFromToken)
]
