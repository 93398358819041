import { styled } from "reakit"

export const FieldContainerStyled = styled.div`
  width: 210px;
  max-width: 210px;
`

export const Container = styled.div`
  display: flex;
  & > :not(:first-child) {
    margin-left: 20px;
  }
`
