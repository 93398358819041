import React, {useCallback, useEffect, useState} from "react"
import { useFormikContext } from "formik"
import InputLabel from "../InputLabel"
import { ButtonStyled, Container, StyledAutoComplete } from "./styles"

export const FieldAutoCompleteWithCreateButton = props => {
  const {
    id,
    suggestions,
    fieldName,
    minLength,
    onSelect,
    disabled,
    name,
    label,
    completeMethod,
    value,
  } = props
  const [displayedValue, setDisplayedValue] = useState(null)
  const { setFieldValue } = useFormikContext()


  useEffect(() => {
    if(value && value[fieldName]) {
      setDisplayedValue(value[fieldName])
    } else {
      setDisplayedValue(null)
    }
  }, [value])

  const findItems = ({ query }) => {
    completeMethod(query)
  }

  const selectItem = ({ value: valueP }) => {
    if (name) {
      setFieldValue(name, valueP)
    }
  }

  const changeItem = (event) => {
    setDisplayedValue(event.value)
    if (name) {
      setFieldValue(name, {
        [fieldName]: event.value
      })
    }
  }

  return (
    <Container>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <StyledAutoComplete
        value={displayedValue}
        suggestions={suggestions}
        field={fieldName || null}
        minLength={minLength || 3}
        completeMethod={findItems}
        onChange={e => changeItem(e)}
        onSelect={onSelect || selectItem}
        disabled={!!disabled}
      />
    </Container>
  )
}

export default FieldAutoCompleteWithCreateButton
