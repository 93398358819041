import { Box } from "reakit"
import styled from "styled-components"
import { ifProp, palette, theme } from "styled-tools"
import Text from "common/Text"

export const BlockListItem = styled(Box)`
  padding-bottom: ${theme("spacing.large")};
  padding-top: ${theme("spacing.large")};
`

export const CustomListItem = styled(Text)`
  align-items: center;

  // 3.6rem is the icon width + margin-right.
  padding-left: ${ifProp("customAlignment", "3.6rem", theme("spacing.none"))};

  i {
    margin-right: ${theme("spacing.small")};
    color: ${ifProp(
      "completed",
      palette("grayscale", 2),
      palette("transparent")
    )};
  }

  span {
    color: ${ifProp("completed", palette("grayscale", 2), palette("primary"))};
    text-decoration: ${ifProp("completed", "line-through", "none")};
  }
`
