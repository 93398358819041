import React, { useCallback, useEffect, useState } from "react"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import {
  useFindUserRequestHandler,
  useUpdateUserHandlers,
  useUser,
  useUserReset,
} from "../../../store/hooks/user"
import UserChangePasswordForm from "../UserChangePasswordForm"

const UserChangePassword = props => {
  const updateUserRequest = useUpdateUserHandlers()
  const userReset = useUserReset()
  const findUser = useFindUserRequestHandler()
  const foundUser = useUser()
  const { id } = props.match.params
  const user = useUser()

  useEffect(() => {
    return () => {
      userReset()
    }
  }, [])

  const onSubmit = useCallback(
    values => {
      updateUserRequest({ ...foundUser, ...values })
    },
    [foundUser]
  )

  useEffect(() => {
    findUser(id)
  }, [findUser, id])

  return (
    <LayoutSidebarOverlay
      title="Alterar Senha de Usuário"
      afterClose={() => {
        props.history.push("/usuarios")
      }}
    >
      {({ closeSidebar }) => {
        return (
          <UserChangePasswordForm
            closeSidebar={closeSidebar}
            onSubmit={onSubmit}
            enableReinitialize={true}
            initialValues={user}
          />
        )
      }}
    </LayoutSidebarOverlay>
  )
}

export default UserChangePassword
