import React, { useCallback, useState } from "react"
import { styled } from "reakit"
import { useFormikContext } from "formik"
import { TextField } from "@material-ui/core"
import CustomTooltip from "../CustomTooltip"
import { palette, theme } from "styled-tools"

export const InputStyled = styled(TextField)`
  .MuiInputBase-input {
    font-size: 1.6rem;
    margin-right: 30px;
  }
  .MuiFormLabel-root {
    font-size: 1.6rem !important;
  }
  .MuiInput-underline:after {
    border-bottom: 2px solid #005369;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #005369;
    font-size: 1.2rem;
  }
`

const Container = styled.div`
  margin-bottom: ${theme("spacing.big")};
  position: relative;
  .MuiInput-underline:after {
    border-bottom: 2px solid #005369 !important;
  }
  div {
    width: 100%;
  }
`

const IconStyled = styled.i`
  color: ${palette(props =>
    props.palette === "primary" ? props.pallete : "gray"
  )};
  font-size: small;
  position: absolute;
  right: 0;
  bottom: 4px;
  cursor: pointer;
  opacity: ${props => (props.active ? "1" : "0.5")};
`

const InputPassword = ({ name, label, tooltip, value, disabled }) => {
  const { setFieldValue } = useFormikContext()
  const [showPassword, setShowPassword] = useState(false)

  const handleChange = useCallback(
    event => {
      const value = event.target.value
      setFieldValue(name, value)
    },
    [setFieldValue]
  )

  return (
    <Container>
      {tooltip ? <CustomTooltip tooltip={tooltip} /> : ""}
      <InputStyled
        value={value}
        type={showPassword ? "text" : "password"}
        name={name}
        id={name}
        label={label}
        onChange={handleChange}
        disabled={disabled}
      />
      <IconStyled
        palette="primary"
        active={!showPassword}
        className="material-icons"
        onClick={() => setShowPassword(showPwd => !showPwd)}
      >
        remove_red_eye
      </IconStyled>
    </Container>
  )
}

export default InputPassword
