import React from "react"
import { Step } from "reakit"
import Button from "common/Button"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import LayerFeedback from "common/LayerFeedback"
import StepAreaData from "./StepAreaData"
import { StepItem, StepsWrapper } from "./index.styled"
import StepAreaDetail from "./StepAreaDetail"
import { Formik } from "formik"
import ValidationSchema from "./ValidationSchema"
import AdvanceFormButton from "../../../common/AdvanceFormButton"

const AreaForm = props => {
  const handleSubmitForm = values => {
    props.onSubmit(values)
  }

  const areaCreationStructure = [
    {
      step: 0,
      requiredFields: [
        "name",
        "description",
        "namespace",
        "type",
        "areaType",
      ],
    },
    {
      step: 1,
      requiredFields: ["minDbToEnableIO", "checkInMinRSSI", "checkOutMaxRSSI"],
    },
  ]

  const canSubmitForm = step => {
    return step.ids.length - 1 === step.current + 1
  }

  const isLastStep = step => {
    return step.ids.length === step.current + 1
  }

  const getSubmitButtonLabel = step => {
    if (isLastStep(step)) {
      return "Fechar"
    }

    if (canSubmitForm(step)) {
      return "Concluir"
    }

    return "Avançar"
  }

  return (
    <>
      <Formik
        initialValues={props.initialValues}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmitForm}
        enableReinitialize={true}
      >
        {({ isValid, values }) => (
          <Step.Container initialState={{ current: 0 }}>
            {step => {
              return (
                <>
                  <>
                    <StepsWrapper>
                      <StepItem step="0" order={0} {...step}>
                        <StepAreaData values={values} />
                      </StepItem>
                      <StepItem step="1" order={1} {...step}>
                        <StepAreaDetail values={values} />
                      </StepItem>
                      <StepItem step="2" order={2} {...step}>
                        <LayerFeedback
                          icon="check"
                          palette="secondary"
                          title="Cadastro realizado"
                          message="Área foi cadastrada com sucesso."
                        />
                      </StepItem>
                    </StepsWrapper>
                    <LayoutSidebarOverlay.Footer>
                      {!isLastStep(step) || (step > 0 && !isValid) ? (
                        <Button
                          use={Step.Previous}
                          {...step}
                          type="button"
                          palette="white"
                        >
                          Voltar
                        </Button>
                      ) : (
                        ""
                      )}
                      <AdvanceFormButton
                        closeSidebar={props.closeSidebar}
                        step={step}
                        structure={areaCreationStructure}
                      >
                        {getSubmitButtonLabel(step)}
                      </AdvanceFormButton>
                    </LayoutSidebarOverlay.Footer>
                  </>
                </>
              )
            }}
          </Step.Container>
        )}
      </Formik>
    </>
  )
}

export default AreaForm
