import { Flex, styled } from "reakit"

export const SidebarOverlayContent = styled(Flex)`
  flex-direction: column;
  flex-grow: 1;

  form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
`

export const SidebarOverlayFooter = styled(Flex)`
  flex-direction: row;
  flex-grow: 0;
  justify-content: space-between;

  & > button:only-child {
    margin-left: auto;
  }
`
