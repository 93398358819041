export const FIND_POLICIES_UNPAGED_REQUEST = "FIND_POLICIES_UNPAGED_REQUEST"
export const FIND_POLICIES_UNPAGED_SUCCESS = "FIND_POLICIES_UNPAGED_SUCCESS"
export const FIND_POLICIES_REQUEST = "FIND_POLICIES_REQUEST"
export const FIND_POLICIES_SUCCESS = "FIND_POLICIES_SUCCESS"
export const FIND_POLICIES_ERROR = "FIND_POLICIES_ERROR"
export const FIND_POLICY_REQUEST = "FIND_POLICY_REQUEST"
export const FIND_POLICY_SUCCESS = "FIND_POLICY_SUCCESS"
export const FIND_NODES_REQUEST = "FIND_NODES_REQUEST"
export const FIND_NODES_SUCCESS = "FIND_NODES_SUCCESS"
export const FIND_POLICY_ERROR = "FIND_POLICY_ERROR"
export const POLICY_UPDATE_REQUEST = "POLICY_UPDATE_REQUEST"
export const POLICY_UPDATE_STATUS_REQUEST = "POLICY_UPDATE_STATUS_REQUEST"
export const POLICY_UPDATE_SUCCESS = "POLICY_UPDATE_SUCCESS"
export const POLICY_UPDATE_ERROR = "POLICY_UPDATE_ERROR"
export const POLICY_CREATE_REQUEST = "POLICY_CREATE_REQUEST"
export const POLICY_CREATE_SUCCESS = "POLICY_CREATE_SUCCESS"
export const POLICY_CREATE_ERROR = "POLICY_CREATE_ERROR"
export const POLICY_DELETE_REQUEST = "POLICY_DELETE_REQUEST"
export const POLICY_DELETE_SUCCESS = "POLICY_DELETE_SUCCESS"
export const POLICY_DELETE_ERROR = "POLICY_DELETE_ERROR"
export const POLICY_RESET = "POLICY_RESET"

export const findPoliciesUnpagedRequestAction = () => ({
  type: FIND_POLICIES_UNPAGED_REQUEST,
})

export const findPoliciesUnpagedSuccessAction = policies => ({
  type: FIND_POLICIES_UNPAGED_SUCCESS,
  payload: {
    policies,
  },
})

export const findPoliciesRequestAction = page => ({
  type: FIND_POLICIES_REQUEST,
  payload: {
    page,
  },
})

export const findPoliciesSuccessAction = (
  policies,
  totalPages,
  last,
  filtering
) => ({
  type: FIND_POLICIES_SUCCESS,
  payload: {
    policies,
    totalPages,
    last,
    filtering,
  },
})

export const findNodesRequestAction = () => ({
  type: FIND_NODES_REQUEST,
})

export const findNodesSuccessAction = nodes => ({
  type: FIND_NODES_SUCCESS,
  payload: {
    nodes,
  },
})

export const findPoliciesErrorAction = error => ({
  type: FIND_POLICIES_ERROR,
  payload: {
    error,
  },
})

export const findPolicyRequestAction = id => ({
  type: FIND_POLICY_REQUEST,
  payload: {
    id,
  },
})

export const findPolicySuccessAction = policy => ({
  type: FIND_POLICY_SUCCESS,
  payload: {
    policy,
  },
})

export const policyCreateRequestAction = policy => ({
  type: POLICY_CREATE_REQUEST,
  payload: {
    policy,
  },
})

export const policyUpdateStatusRequestAction = data => ({
  type: POLICY_UPDATE_STATUS_REQUEST,
  payload: {
    data,
  },
})

export const policyUpdateRequestAction = policy => ({
  type: POLICY_UPDATE_REQUEST,
  payload: {
    policy,
  },
})

export const policyUpdateSuccessAction = () => ({
  type: POLICY_UPDATE_SUCCESS,
})

export const policyCreateSuccessAction = () => ({
  type: POLICY_CREATE_SUCCESS,
})

export const policyDeleteRequestAction = id => ({
  type: POLICY_DELETE_REQUEST,
  payload: {
    id,
  },
})

export const policyDeleteSuccessAction = () => ({
  type: POLICY_DELETE_SUCCESS,
})

export const policyResetAction = () => ({
  type: POLICY_RESET,
})

const INITIAL_STATE = {
  error: null,
  loading: false,
  nodes: [],
  policies: [],
  policy: null,
  totalPages: 0,
  updateSuccess: false,
  createSuccess: false,
  filtering: false,
  deleteSuccess: false,
  nodesSuccess: false,
  last: true,
}

const policyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FIND_NODES_REQUEST:
      return {
        ...state,
        nodesSuccess: false,
        loading: true,
      }
    case FIND_NODES_SUCCESS:
      const { nodes } = action.payload
      return {
        ...state,
        nodesSuccess: true,
        nodes,
        loading: false,
      }
    case FIND_POLICIES_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FIND_POLICIES_UNPAGED_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FIND_POLICIES_UNPAGED_SUCCESS:
      const { policies: pol } = action.payload
      return {
        ...state,
        policies: pol,
        loading: false,
      }
    case FIND_POLICIES_SUCCESS:
      const { policies, totalPages, last, filtering } = action.payload
      return {
        ...state,
        policies,
        totalPages,
        filtering,
        loading: false,
        last,
      }
    case FIND_POLICIES_ERROR:
      const { error } = action.payload
      return {
        ...state,
        error,
        loading: false,
      }
    case FIND_POLICY_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FIND_POLICY_SUCCESS:
      const { policy } = action.payload
      return {
        ...state,
        policy,
        loading: false,
      }
    case POLICY_UPDATE_REQUEST:
    case POLICY_UPDATE_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
        updateSuccess: false,
      }
    case POLICY_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        updateSuccess: true,
      }
    case POLICY_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        createSuccess: false,
      }
    case POLICY_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        createSuccess: true,
      }
    case POLICY_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case POLICY_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteSuccess: true,
      }
    case POLICY_RESET:
      return {
        ...state,
        loading: false,
        updateSuccess: false,
        createSuccess: false,
        deleteSuccess: false,
      }
    default:
      return state
  }
}

export default policyReducer
