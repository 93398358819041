import React, {useEffect, useState} from "react"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import LoadingIndicator from "common/LoadingIndicator"
import {
  usePermissionLoading,
} from "store/hooks/permission"
import {useFindPersonCredentials, usePersonCredentials, useRenewPersonCredentials, useReportClearState} from "../../../store/hooks/report";
import CredentialForm from "../../Credentials/CredentialForm";

const ExpiredCredentialsRenew = props => {

  const {id} = props.match.params
  const loading = usePermissionLoading()
  const findPermission = useFindPersonCredentials()
  const personCredentials = usePersonCredentials()
  const renewPersonCredential = useRenewPersonCredentials()
  const clear = useReportClearState()

  const [permissionRequestId, setPermissionRequestId] = useState()

  const [credential, setCredential] = useState({
    categoria: null,
    responsavel: null,
    name: "",
    validityInDays: "",
    id: "",
    renew: null
  })
  const onSubmit = () => {
    renewPersonCredential(permissionRequestId)
  }
  useEffect(() => {
    clear()
  },[])
  useEffect(() => {
    if (id) {
      findPermission(id)
    }
  }, [findPermission, id])

  useEffect(() => {
    const personName = personCredentials && personCredentials.person && personCredentials.person.name || ''
    const credentialName = personCredentials && personCredentials.credential && personCredentials.credential.name || ''
    const validityDays = personCredentials && personCredentials.credential && personCredentials.credential.validityInDays || ''

    const id = personCredentials && personCredentials.id || ''
    setPermissionRequestId(id)

    setCredential({
      categoria: credentialName,
      name: personName,
      validityInDays: validityDays,
      id: id,
      renew: true
    })
  }, [personCredentials])
  return (
    <LayoutSidebarOverlay
      title="Renovar Credencial"
      afterClose={() => {
        props.history.push("/expired-credentials")
      }}
    >
      {({closeSidebar}) => {
        return (
          <LoadingIndicator loading={loading}>
            <CredentialForm
              closeSidebar={closeSidebar}
              onSubmit={onSubmit}
              initialValues={credential}
            />
          </LoadingIndicator>
        )
      }}
    </LayoutSidebarOverlay>
  )
}
export default ExpiredCredentialsRenew
