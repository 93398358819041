export const LOAD_RESPONSIBLES_REQUEST = "LOAD_RESPONSIBLES_REQUEST"
export const LOAD_RESPONSIBLES_SUCCESS = "LOAD_RESPONSIBLES_SUCCESS"
export const LOAD_RESPONSIBLES_ERROR = "LOAD_RESPONSIBLES_ERROR"
export const RESPONSIBLES_RESET = "RESPONSIBLES_RESET"

export const loadResponsiblesRequest = (name, unpaged) => ({
  type: LOAD_RESPONSIBLES_REQUEST,
  payload: {
    name,
    unpaged,
  },
})

export const loadResponsiblesSuccess = responsibles => ({
  type: LOAD_RESPONSIBLES_SUCCESS,
  payload: {
    responsibles,
  },
})

export const loadResponsiblesError = error => ({
  type: LOAD_RESPONSIBLES_ERROR,
  payload: {
    error,
  },
})

export const resetResponsibles = () => ({
  type: RESPONSIBLES_RESET,
})

const INITIAL_STATE = {
  error: null,
  loading: false,
  responsibles: [],
  responsible: null,
}

const responsiblesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_RESPONSIBLES_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case LOAD_RESPONSIBLES_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        error,
        loading: false,
      }
    }
    case LOAD_RESPONSIBLES_SUCCESS: {
      const { responsibles } = action.payload
      return {
        ...state,
        responsibles,
        loading: false,
      }
    }
    case RESPONSIBLES_RESET: {
      return {
        ...state,
        responsibles: [],
        responsible: null,
        loading: false,
      }
    }
    default:
      return state
  }
}

export default responsiblesReducer
