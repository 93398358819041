export const MAP_DATA_REQUEST = "MAP_DATA_REQUEST"
export const SET_MAP_CENTER = "SET_MAP_CENTER"
export const SET_DETAIL = "SET_DETAIL"
export const MAP_DATA_SUCCESS = "MAP_DATA_SUCCESS"
export const MAP_UNPREPARED_DATA_SUCCESS = "MAP_UNPREPARED_DATA_SUCCESS"
export const MAP_DATA_ERROR = "MAP_DATA_ERROR"
export const MAP_DATA_INITIAL_REQUEST = "MAP_DATA_INITIAL_REQUEST"
export const MAPING_DATA_SUCCESS = "MAPING_DATA_SUCESS"
export const MAPING_DATA_REQUEST = "MAPING_DATA_REQUEST"

export const mapDataRequestAction = () => ({
    type: MAP_DATA_REQUEST,
})

export const mapDataInitialRequestAction = () => ({
    type: MAP_DATA_INITIAL_REQUEST,
})

export const useMapDataRequest = () => ({
    type: MAPING_DATA_REQUEST,
})

export const setMapCenterAction = center => ({
    type: SET_MAP_CENTER,
    payload: {
        center,
    },
})

export const setDetailAction = detail => ({
    type: SET_DETAIL,
    payload: {
        detail,
    },
})

export const mapDataSuccessAction = map => ({
    type: MAP_DATA_SUCCESS,
    payload: {
        map,
    },
})

export const useMapDataSucess = data => ({
    type: MAPING_DATA_SUCCESS,
    payload: {
        data,
    },
})

export const mapUnpreparedDataSuccessAction = mapUnprepared => ({
    type: MAP_UNPREPARED_DATA_SUCCESS,
    payload: {
        mapUnprepared,
    },
})

const INITIAL_STATE = {
    error: null,
    loading: false,
    center: null,
    map: null,
    data: null,
    detail: null,
    mapUnprepared: null,
    totalPages: 0,
    updateSuccess: false,
    createSuccess: false,
    deleteSuccess: false,
}

const mapReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MAP_DATA_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case MAPING_DATA_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case MAP_DATA_INITIAL_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case SET_MAP_CENTER:
            const {center} = action.payload
            return {
                ...state,
                center,
                loading: false,
            }
        case SET_DETAIL:
            const {detail} = action.payload
            return {
                ...state,
                detail,
            }
        case MAP_UNPREPARED_DATA_SUCCESS:
            const {mapUnprepared} = action.payload
            return {
                ...state,
                mapUnprepared,
                loading: false,
            }
        case MAP_DATA_SUCCESS:
            const {map} = action.payload
            return {
                ...state,
                map,
                loading: false,
            }
        case MAPING_DATA_SUCCESS:
            const {data} = action.payload
            return {
                ...state,
                data,
                loading: false,
            }
        default:
            return state
    }
}

export default mapReducer
