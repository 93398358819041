import React from "react"
import { Flex, styled } from "reakit"
import { palette } from "styled-tools"
import Icon from "common/Icon"

const IconRoundedStyled = styled(Flex)`
  align-items: center;
  border: solid 1px ${palette("grayscale")};
  border-radius: 100%;
  height: 5.6rem;
  justify-content: center;
  width: 5.6rem;
`

const IconRounded = ({ children, ...props }) => {
  return (
    <IconRoundedStyled>
      <Icon {...props}>{children}</Icon>
    </IconRoundedStyled>
  )
}

export default IconRounded
