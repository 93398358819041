import React from "react"
import { InlineFlex, styled } from "reakit"
import { palette, theme, ifProp } from "styled-tools"

const TagStyled = styled(InlineFlex)`
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-size: ${theme("typography.size.body3")};
  color: ${palette("white")};
  background: ${props => palette(props.palette, props.tone)};
  padding-left: ${theme("spacing.small")};
  padding-right: ${theme("spacing.small")};
  padding-top: ${theme("baseline")}px;
  padding-bottom: ${theme("baseline")}px;
  border-radius: ${theme("baseline")}px;
  margin-left: ${ifProp("marginLeft", "10px", "unset")};
}
`

const Tag = ({ children, ...props }) => {
  return (
    <TagStyled palette="error" tone={2} {...props}>
      {children}
    </TagStyled>
  )
}

export default Tag
