import React, { useEffect } from "react"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import {
  useFindUserRequestHandler,
  useUser,
  useUpdateUserHandlers,
  useUserReset,
} from "../../../store/hooks/user"
import UserForm from "../UserForm"
import { withRouter } from "react-router-dom"

const UserEdit = props => {
  const updateUserRequest = useUpdateUserHandlers()
  const userReset = useUserReset()
  const findUser = useFindUserRequestHandler()
  const user = useUser()
  const { id } = props.match.params

  const onSubmit = values => {
    updateUserRequest(values)
  }

  useEffect(() => {
    return () => {
      userReset()
    }
  }, [])

  useEffect(() => {
    findUser(id)
  }, [findUser, id])

  return (
    <LayoutSidebarOverlay
      title="Editar usuário"
      afterClose={() => {
        props.history.push("/usuarios")
      }}
    >
      {({ closeSidebar }) => {
        return (
          <UserForm
            closeSidebar={closeSidebar}
            onSubmit={onSubmit}
            initialValues={user}
          />
        )
      }}
    </LayoutSidebarOverlay>
  )
}

export default withRouter(UserEdit)
