export const PERMISSIONS_REQUEST = "PERMISSIONS_REQUEST"
export const PERMISSIONS_SUCCESS = "PERMISSIONS_SUCCESS"
export const PERMISSION_REQUEST = "PERMISSION_REQUEST"
export const PERMISSION_SUCCESS = "PERMISSION_SUCCESS"
export const PERMISSION_CREATE_REQUEST = "PERMISSION_CREATE_REQUEST"
export const PERMISSION_CREATE_SUCCESS = "PERMISSION_CREATE_SUCCESS"
export const PERMISSION_UPDATE_REQUEST = "PERMISSION_UPDATE_REQUEST"
export const PERMISSION_UPDATE_SUCCESS = "PERMISSION_UPDATE_SUCCESS"
export const PERMISSION_DELETE_REQUEST = "PERMISSION_DELETE_REQUEST"
export const PERMISSION_DELETE_SUCCESS = "PERMISSION_DELETE_SUCCESS"
export const PERMISSION_RESET = "PERMISSION_RESET"
export const PERMISSION_ERROR = "PERMISSION_ERROR"

export const findPermissionsRequestAction = (page, size) => ({
  type: PERMISSIONS_REQUEST,
  payload: {
    page,
    size,
  },
})

export const findPermissionsSuccess = (permissions, last) => ({
  type: PERMISSIONS_SUCCESS,
  payload: {
    permissions,
    last,
  },
})

export const findPermissionSuccess = permission => ({
  type: PERMISSION_SUCCESS,
  payload: {
    permission,
  },
})

export const findPermissionRequestAction = id => ({
  type: PERMISSION_REQUEST,
  payload: {
    id,
  },
})

export const deletePermissionRequestAction = id => ({
  type: PERMISSION_DELETE_REQUEST,
  payload: {
    id,
  },
})

export const deletePermissionSuccess = () => ({
  type: PERMISSION_DELETE_SUCCESS,
})

export const createPermissionRequestAction = (permission, isRequired) => ({
  type: PERMISSION_CREATE_REQUEST,
  payload: {
    permission,
    isRequired,
  },
})

export const createPermissionSuccess = () => ({
  type: PERMISSION_CREATE_SUCCESS,
})

export const updatePermissionRequestAction = permission => ({
  type: PERMISSION_UPDATE_REQUEST,
  payload: {
    permission,
  },
})

export const updatePermissionSuccess = () => ({
  type: PERMISSION_UPDATE_SUCCESS,
})

export const resetPermission = () => ({
  type: PERMISSION_RESET,
})

export const errorPermission = error => ({
  type: PERMISSION_ERROR,
  payload: {
    error,
  },
})

const INITIAL_STATE = {
  error: null,
  loading: false,
  permissions: [],
  permission: null,
  updateSuccess: false,
  deleteSuccess: false,
  createSuccess: false,
  last: true,
}

const permissionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PERMISSION_DELETE_REQUEST:
    case PERMISSION_UPDATE_REQUEST:
    case PERMISSION_CREATE_REQUEST:
    case PERMISSION_REQUEST:
    case PERMISSIONS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case PERMISSIONS_SUCCESS:
      const { permissions, last } = action.payload
      return {
        ...state,
        permissions,
        last,
        loading: false,
      }
    case PERMISSION_SUCCESS:
      const { permission } = action.payload
      return {
        ...state,
        permission,
        loading: false,
      }
    case PERMISSION_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        createSuccess: true,
      }
    case PERMISSION_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        updateSuccess: true,
      }
    case PERMISSION_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteSuccess: true,
      }
    case PERMISSION_RESET:
      return {
        ...state,
        updateSuccess: false,
        createSuccess: false,
        deleteSuccess: false,
        loading: false,
      }
    case PERMISSION_ERROR:
      const { error } = action.payload
      return {
        ...state,
        error,
        loading: false,
      }
    default:
      return state
  }
}

export default permissionReducer
