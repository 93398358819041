import React from "react"
import { isNil, join, pipe, reverse, split } from "ramda"
import { Flex, styled } from "reakit"
import { isValid } from "date-fns"
import { palette, theme } from "styled-tools"
import formatString from "format-string-by-pattern"
import Button from "common/Button"
import FieldText from "common/FieldText"
import { usePermissionRequestUpdateStatus } from "store/hooks/permission-request"

const ButtonStyled = styled(Button)`
  i {
    margin-right: ${theme("spacing.small")};
  }
`

const ButtonAccept = styled(ButtonStyled)`
  color: ${palette("primary")};
  border: 2px solid ${palette("primary")}; 
`

const ButtonDeny = styled(ButtonStyled)`
  color: ${palette("error")};
  border: 2px solid ${palette("error")};
`

const getFormattedDate = value => {
  if (isNil(value)) {
    return value
  }

  return new Date(
    pipe(
      split("/"),
      reverse,
      join("/")
    )(value)
  )
}

const CredentialManagementForm = props => {
  const MASK = "DD/MM/AAAA"
  const updateStatus = usePermissionRequestUpdateStatus()

  const submitForm = customValues => {
    const status = customValues.accepted ? "APPROVED" : "REJECTED"
    const date = getFormattedDate(props.values.expiryIn)
    let expiryInDate

    if (isNil(date) || !isValid(date)) {
      expiryInDate = null
    } else {
      expiryInDate = date.toISOString()
    }

    const data = {
      ...props.permissionRequestCredential,
      credential: {
        id: props.permissionRequestCredential.credential.id,
      },
      expiryIn: expiryInDate,
      observation: props.values.observation || "",
      permissionRequestId: props.permissionRequest.id,
      status,
      person: {
        id: props.permissionRequest.person.id,
      },
      version: 0,
    }

    console.log(data)
    updateStatus(data)
  }

  const acceptOrDenyPermission = accepted => event => {
    event.preventDefault()
    submitForm({ accepted })
  }

  const readOnly = props.permissionRequestCredential.status !== "REQUESTED"

  return (
    <form onSubmit={submitForm}>
      <FieldText
        name="expiryIn"
        id="expiryIn"
        label="Credencial válida até:"
        parse={formatString(MASK)}
        placeholder={MASK}
        readOnly={readOnly}
      />
      <FieldText
        name="observation"
        id="observation"
        label="Observação:"
        readOnly={readOnly}
      />
      <Flex justifyContent="space-between">
        {!readOnly && (
          <>
            <ButtonDeny
              onClick={acceptOrDenyPermission(false)}
              type="button"
              uppercase
              palette="white"
              disabled={isNil(props.values.observation)}
            >
              Não aceito
            </ButtonDeny>
            <ButtonAccept
              onClick={acceptOrDenyPermission(true)}
              type="button"
              uppercase
              palette="white"
              disabled={isNil(props.values.expiryIn)}
            >
              Aceito
            </ButtonAccept>
          </>
        )}
      </Flex>
    </form>
  )
}

export default CredentialManagementForm
