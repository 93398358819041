import React, { useCallback, useEffect } from "react"
import { Flex } from "reakit"
import LayoutSidebarOverlay from "common/LayoutSidebarOverlay"
import LayerFeedback from "common/LayerFeedback"
import Button from "common/Button"
import LoadingIndicator from "common/LoadingIndicator"
import {
  usePermissionLoading,
} from "store/hooks/permission"
import {useDeleteGroupSuccess, useGroupDelete, useGroupReset} from "../../store/hooks/alerts";

const GroupRequestDestroy = props => {
  const { id } = props.match.params
  const loading = usePermissionLoading()
  const deleteGroup = useGroupDelete()
  const deleteGroupSuccess = useDeleteGroupSuccess()
  const reset = useGroupReset()
  const onDestroy = useCallback(() => {
    deleteGroup(id)
  }, [id])

  useEffect(() => {
    reset()
    return () => {
      reset()
    }
  }, [reset])

  return (
    <LayoutSidebarOverlay
      title="Excluir Grupo"
      subtitle={id}
      afterClose={() => {
        props.history.push("/emergency-alerts/alert-group")
      }}
    >
      {({ closeSidebar }) => {
        return (
          <LoadingIndicator loading={loading}>
            <Flex column maxWidth="33rem" flexGrow={1}>
              {deleteGroupSuccess ? (
                <LayerFeedback
                  icon="check"
                  palette="secondary"
                  title="Grupo excluído"
                  message="O grupo foi excluido com sucesso."
                />
              ) : (
                <LayerFeedback
                  icon="warning"
                  palette="warning"
                  title="Grupo será excluído"
                  message="O grupo será excluído e não será possível desfazer essa ação."
                />
              )}
            </Flex>
            <LayoutSidebarOverlay.Footer>
              {deleteGroupSuccess ? (
                <Button type="button" onClick={closeSidebar}>
                  Ok
                </Button>
              ) : (
                <>
                  <Button
                    disabled={loading}
                    type="button"
                    palette="white"
                    onClick={closeSidebar}
                  >
                    Cancelar
                  </Button>
                  <Button disabled={loading} type="button" onClick={onDestroy}>
                    Excluir
                  </Button>
                </>
              )}
            </LayoutSidebarOverlay.Footer>
          </LoadingIndicator>
        )
      }}
    </LayoutSidebarOverlay>
  )
}
export default GroupRequestDestroy
