import React, { useEffect, useState } from "react"
import InputLabel from "common/InputLabel"
import { palette, theme } from "styled-tools"
import { AutoComplete } from "primereact/autocomplete"
import { styled } from "reakit"
import { useFormikContext } from "formik"

/*
 * PARAMETROS OBRIGATORIOS:
 *  completeMethod ( metodo para buscar itens ),
 *  suggestions ( itens para exibicao )
 * PARAMETROS:
 *  minLenght ( minimo de caracteres para realizar busca, default 3 )
 *  fieldName ( campo de dentro do objeto suggestions que sera exibido )
 *  fieldValue ( campo que sera setado o valor do objeto dentro de value )
 * */

const StyledAutoComplete = styled(AutoComplete)`
  width: 100% !important;
  margin-bottom: ${theme("spacing.big")};
  input {
    background-color: ${palette("white")};
    border: none !important;
    border-bottom: 1px solid #3f3f3f !important;
    box-sizing: border-box;
    color: ${palette("grayscale")};
    display: block;
    font-size: ${theme("typography.size.body2")};
    width: 100%;
    border-radius: 0 !important;
    box-shadow: none !important;
    padding: 0 !important;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const FieldAutoCompleteV2 = props => {
  const {
    id,
    suggestions,
    fieldName,
    minLength,
    onSelect,
    disabled,
    name,
    label,
    completeMethod,
    fieldValue,
    propertyToShow,
  } = props
  const [displayedValue, setDisplayedValue] = useState(null)
  const { setFieldValue } = useFormikContext()

  const findItems = ({ query }) => {
    completeMethod(query)
  }

  const selectItem = ({ value: valueP }) => {
    if (name) {
      if (fieldValue) {
        valueP = valueP[fieldValue]
      }
      setFieldValue(name, valueP)
    }
  }

  useEffect(() => {
    if (props.value !== displayedValue) {
      setDisplayedValue(
        propertyToShow
          ? props.value
            ? props.value[propertyToShow]
            : ""
          : props.value
      )
    }
  }, [props.value])

  return (
    <Container>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <StyledAutoComplete
        value={displayedValue}
        suggestions={suggestions}
        field={fieldName || null}
        minLength={minLength || 3}
        completeMethod={findItems}
        onChange={e => setDisplayedValue(e.value)}
        onSelect={onSelect || selectItem}
        disabled={!!disabled}
      />
    </Container>
  )
}

export default FieldAutoCompleteV2
