import { styled } from "reakit"

export const EventContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 1%;
  height: 100%;
`

export const EventTitle = styled.div`
  width: 100%;
  font-size: large;
  text-align: left;
  color: #005369;
  margin-bottom: 4%;
`

export const EventLine = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease;
  padding: 0 1%;
  background: ${({ stripped }) => (stripped ? "#f3f3f3" : "unset")};
`

export const EventHeader = styled.div`
  background: #145269;
  color: white;
  padding: 2%;
  text-transform: uppercase;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5%;
`
export const PrintBtn = styled.label`
  font-size: 1.4rem;
  font-weight: bolder;
  text-transform: uppercase;
  :hover {
    cursor: pointer;
  }
`
